import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { STATUS } from '../../utils/status';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import Error from '../error';
import { createBids, getBids, createActions } from '../redux/bidding/bidSlice';
import { updateTruck } from '../redux/truck slice/addTruckSlice';

const BidingTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [editedRow, setEditedRow] = useState<any>({});
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    const payload = {
      enquiry_id : 'null',
      root_bid_id : null,
      rate : '23',
      unit : 'KG',
      no_of_trucks : '23',
      notes  : 'Hello',
    };
    dispatch(createBids(payload));
}, [ dispatch]);

  const bidsData = useSelector((state:RootState)=> state.bids.bids)

  useEffect(()=>{
    dispatch(getBids())
  },[dispatch])

  const getBidStatus = useSelector((state: RootState) => state.bids.getBidStatus);

  const headers = [
    "Name",
    "Corporate Rate",
    "Corporate Qty Supplier",
    "Service Provider",
    "Rate",
    "Units",
    "No. of Trucks",
    "Re Bid",
    "Actions"
  ];

  const handleEdit = (row: any) => {
    setEditedRow({ ...row });
    setEditingId(row.id);
  };

  const handleSave = () => {
    dispatch(updateTruck({ id: editedRow.id, updatedData: editedRow }));
    setEditingId(null);
    setEditedRow({});
  };

  const handleAction = (
    enquiryId: string,
    companyId: string,
    actionType: 'rebid' | 'accept' | 'reject'
  ) => {
    const payload = {  // or type it more specifically if possible
      enquiry_id: 'bfa9f727-0305-4f59-953b-9a8cc14aa08c',
      company_id: 'edccf616-233f-4f04-b6f6-5f4e6bb69852',
      rate: 1.9, // Example rate
      unit: 'KG', // Example unit
      no_of_trucks: 5, // Example number of trucks
      action: actionType,
    };
    dispatch(createActions(payload));
  };
  
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value;
    setEditedRow((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <TableContainer>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <Table sx={{ minWidth: 850 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} align="left" sx={{ fontFamily: 'Montserrat', fontSize: '10px', fontWeight: 700, lineHeight: '14px', letterSpacing: '1px', color: '#10383D' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {getBidStatus === STATUS.SUCESS && bidsData && bidsData.results.length > 0 ? (
              bidsData.results.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>
                    {editingId === row.id ? (
                      <TextField
                        value={editedRow.truck_number || ''}
                        onChange={(e:any) => handleFieldChange(e, 'truck_number')}
                      />
                    ) : (
                      row.notes
                    )}
                  </TableCell>
                  <TableCell>
                    {editingId === row.id ? (
                      <TextField
                        value={editedRow.notes || ''}
                        onChange={(e:any) => handleFieldChange(e, 'notes')}
                      />
                    ) : (
                      row.notes
                    )}
                  </TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>{row.rate}</TableCell>
                  <TableCell>{row.unit}</TableCell>
                  <TableCell>{row.no_of_trucks}</TableCell>
                  {/* <TextField sx={{width:'4rem', color:'black'}} ></TextField> */}
                  {/* <TableCell>{row.notes}</TableCell> */}
                  <TableCell>
                    {/* <Typography>Re Bid</Typography> */}
                    {editingId === row.id ? (
                      <Button onClick={handleSave} variant="contained" color="primary">
                        Save
                      </Button>
                    ) : (
                      <Button onClick={() => handleAction(row.enquiry_id, row.company_id, 'rebid')} variant="outlined" color="primary">
                      Re Bid
                    </Button>
                    )}
                  </TableCell>
                  <TableCell>
                        <span onClick={() => handleAction(row.enquiry_id, row.company_id, 'accept')} style={{ color: 'green', cursor:'pointer' }}>Accept</span>{' '}
                        <span onClick={() => handleAction(row.enquiry_id, row.company_id, 'reject')} style={{ color: 'red', cursor:'pointer' }}>Reject</span>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <Error message="There are no trucks added as of yet. You can add a truck to see it here." />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default BidingTable;
