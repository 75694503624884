import React from 'react';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import ReceiverListHeading from './receiverListHeading';
import ReceiverTable from './receiverListTable';

const ReceiverListMain = () => {
  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <ReceiverListHeading />
      </Box>
    
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <ReceiverTable/>
        </Paper>

      </Box>
    </div>
  );
};

export default ReceiverListMain;
