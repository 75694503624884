import { Button, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, {  useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { AddMoreButtonofUserEnquiry } from '../../assets/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { fetchCompanies } from '../redux/company and model slice/companySlice';

export interface FormState {
  corporate: string[];
  motor_owner: string[];
  transporter: string[];
}

const UserForm = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(10); 
  const [searchTerm, setSearchTerm] = React.useState('');
  const dispatch = useDispatch<AppDispatch>();
  const companyData = useSelector((state: RootState)=> state.companies.companies)
  

  React.useEffect(() => {
      dispatch(fetchCompanies({
        search: searchTerm, page: currentPage, pageSize: itemsPerPage,
        sort: '',
        order: ''
      }))
  }, [dispatch, currentPage, searchTerm, itemsPerPage]);

  const [companyDetails, setCompanyDetails] = useState<FormState>({
    corporate: [''],
    motor_owner: [''],
    transporter: [''],
  });

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState, index: number) => {
    const value = event.target.value;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: prevDetails[fieldName].map((item, i) => (i === index ? value : item)),
    }));
  };

  const handleAddTransporter = () => {
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      transporter: [...prevDetails.transporter, ''],
    }));
  };

  const handleAddMotorOwner = () => {
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      motor_owner: [...prevDetails.motor_owner, ''],
    }));
  };

  const handleAddCorporate = () => {
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      corporate: [...prevDetails.corporate, ''],
    }));
  };

  return (
    <>
    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '0.3rem' , marginTop: '2rem'}}>Confirm Booking</Typography>
        <div style={{display:'flex'}}>

        <div style={{display:'flex', flexDirection:'column'}}>
        {companyDetails.transporter.map((transporter, index) => (
        <div key={`transporter-${index}`} style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '90%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Transporter</Typography>
          <Select
            value={transporter}
            onChange={(e) => handleSelectFieldChange(e, 'transporter', index)}
            fullWidth
            displayEmpty
            size='small'
          >
            <MenuItem value="" disabled>
              Select transporter
            </MenuItem>
            {companyData.results
              .filter((company) => company.role === 'TRANSPORTER')
              .map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
          </Select>
          
        </div>
      ))}
      <AddMoreButtonofUserEnquiry onClick={handleAddTransporter}>Add Transporter</AddMoreButtonofUserEnquiry>
      </div>

      <div style={{display:'flex', flexDirection:'column'}}>
       {companyDetails.motor_owner.map((motor_owner, index) => (
        <div key={`motor_owner-${index}`} style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '90%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Motor Owner</Typography>
        <Select
            value={motor_owner}
            onChange={(e) => handleSelectFieldChange(e,'motor_owner', index)}
            fullWidth
            displayEmpty
            size='small'
          >
            <MenuItem value="" disabled>
              Select motor owner
            </MenuItem>
            {companyData.results
              .filter((company) => company.role === 'MOTOR OWNER')
              .map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        ))}
        <AddMoreButtonofUserEnquiry onClick={handleAddMotorOwner}>Add Motor Owner</AddMoreButtonofUserEnquiry>
      </div>

      <div style={{display:'flex', flexDirection:'column'}}>
       {companyDetails.corporate.map((corporate, index) => (
        <div key={`corporate-${index}`} style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
        <Typography key={`corporate-${index}`} sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Corporate</Typography>
        <Select
            value={corporate}
            onChange={(e) => handleSelectFieldChange(e,'corporate', index)}
            fullWidth
            displayEmpty
            size='small'
          >
            <MenuItem value="" disabled>
              Select corporate
            </MenuItem>
            {companyData.results
              .filter((company) => company.role === 'CORPORATE')
              .map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
          </Select>
      </div>
      ))}
      <AddMoreButtonofUserEnquiry onClick={handleAddCorporate}>Add Corporate</AddMoreButtonofUserEnquiry>
      </div>

        </div>

        
    </>
  )
}

export default UserForm