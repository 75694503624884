import {  Box, Button, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { createReceiver, getReceiver, setCreateReceiverStatus, setUpdateReceiverStatus, updateReceiver } from '../redux/receiver/receiver';
import { SnackbarContent, Snackbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import { STATUS } from '../../utils/status';

export interface FormState {
  id:string;
  receiver: string;
  name: string;
  email: string;
  address: string;
  phone: string;
  note: string;
}

const ReceiverForm = () => {
  const [companyDetails, setCompanyDetails] = useState<FormState>({
    id:'',
    receiver:'',
    name:'',
    email: '',
    address:'',
    phone: '',
    note:''
  });
  const dispatch = useDispatch<AppDispatch>()
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const receiver = useSelector((state:RootState)=> state.receiver.receiver)
  const navigate = useNavigate()
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const location = useLocation();

  const { goodsDetails: initialGoodsDetails, id: goodsId } = location.state || { goodsDetails: null, id: '' };
  const createReceiverStatus = useSelector((state:RootState)=> state.receiver.createReceiverStatus)
  const updateReceiverStatus = useSelector((state:RootState)=> state.receiver.updateReceiverStatus)


  useEffect(() => {
    if (initialGoodsDetails) {
      setCompanyDetails(initialGoodsDetails)
      handleSnackbar('You can make your changes now.', 'info');

    }
  }, [initialGoodsDetails]);
  
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);
  
    const errors: { [key: string]: string } = {};
    if (!companyDetails.name) {
      errors.name = "Name is Required";
    }
    if (!companyDetails.email) {
      errors.email = "Email is Required";
    }
    if (!companyDetails.phone) {
      errors.phone = "Phone is Required";
    }
    if (!companyDetails.address) {
      errors.address = "Address is Required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields.', 'warning');
      return;
    }
          const payload = {
            id: companyDetails.id,
            name: companyDetails.name,
            email: companyDetails.email,
            address: companyDetails.address,
            pincode: '321001',
            phone: companyDetails.phone,
            note: companyDetails.note,
          };

          if(goodsId){
          dispatch(updateReceiver(payload));
          }
          else{
          dispatch(createReceiver(payload));
          }
      };

      useEffect(()=>{
        if(updateReceiverStatus == STATUS.SUCESS){
          handleSnackbar('Receiver Updated Successfully', 'success');
          setTimeout(() => {
              navigate('/receiver');
            }, 1000);
            dispatch(setUpdateReceiverStatus(STATUS.NOT_STARTED))
          }
    
          if(updateReceiverStatus == STATUS.FAILED){
            handleSnackbar('Error Occured, Please try again!', 'error');
          }
      },[updateReceiverStatus])
    
      useEffect(()=>{
        if(createReceiverStatus == STATUS.SUCESS){
          handleSnackbar('Receiver Added Successfully', 'success');
            setTimeout(() => {
              navigate('/receiver');
            }, 1000);
            dispatch(setCreateReceiverStatus(STATUS.NOT_STARTED))
          }
    
          if(createReceiverStatus == STATUS.FAILED){
            handleSnackbar('Error Occured, Please try again!', 'error');
            setTimeout(() => {
              handleSnackbar('Either the receiver already exist or you entered wrong information', 'warning');
            }, 7000);
          }
      },[createReceiverStatus])

      const handlePhoneFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "phone" && value.length > 10) {
          return;
        }
      
        setCompanyDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      };
    
  return (
    <>
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">

        <div style={{display:'flex'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Customer/Company Name</Typography>
          <TextField
              placeholder="Enter name"
              name="name"
              value={companyDetails.name}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.name ? true : false}
              helperText={formSubmitted && formErrors.name}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Email ID</Typography>
        <TextField
              placeholder="Enter email ID"
              name="email"
              value={companyDetails.email}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.email ? true : false}
              helperText={formSubmitted && formErrors.email}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Customer Address</Typography>
        <TextField
              placeholder="Enter address"
              name="address"
              value={companyDetails.address}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.address ? true : false}
              helperText={formSubmitted && formErrors.address}
            />
      </div>
        </div>

        <div style={{display:'flex'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Phone</Typography>
          <TextField
              type="tel"
              autoComplete="tel"
              placeholder="Enter phone number"
              name="phone"
              value={companyDetails.phone}
              onChange={handlePhoneFieldChange}
              size="small"
              maxLength={10}
                  {...({} as any)}
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.phone ? true : false}
              helperText={formSubmitted && formErrors.phone}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '65.5%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Note</Typography>
        <TextField
              placeholder="Enter note here"
              name="note"
              value={companyDetails.note}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
        </div>
        </div>

        <Button type='submit' variant="contained" sx={{ mt: 2, fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '100%', backgroundColor: '#03BBD2', borderRadius: '8px' }}>Save Receiver</Button>

        <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',

            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>

        </Box>
    </>
  )
}

export default ReceiverForm