import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Paper, Typography,FormControl, Divider, Grid } from '@mui/material';
import SlabHeading from './slabHeading';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NonDeductTDS from './nonDeductTable';
import DeductTDS from './deductTable'; 
import { getTdsCount } from '../redux/tds/tdsKPI';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';

const SlabMain = () => {
  const [alignment, setAlignment] = React.useState('web');
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(getTdsCount({}));
    
  }, [dispatch]);

  const tdscount = useSelector((state: RootState) => state.tdscount.tdsCount);

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const stats = [
    { title: 'TOTAL TRANSPORTERS', value: tdscount.total_transporters, change: -1, color: '#FFFFFF' },
    { title: 'UPLOADED DOCUMENTS', value: tdscount.total_uploaded_documents, change: 1, color: '#FFFFFF' },
    { title: 'NOT UPLOADED', value: tdscount.total_not_uploaded_documents, change: 2, color: '#FFFFFF' },
    { title: 'VERIFIED DOCUMENTS', value: tdscount.total_verified_documents, change: 2, color: '#FFFFFF' }
  ];

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <SlabHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>

      <Paper elevation={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          padding: '24px',
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          mb: '2rem',
        }}>
         {stats.map((stat, index) => (
            <React.Fragment key={index}>
              <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '8px 16px' }}>
              <Typography variant="subtitle2" component="p" sx={{ fontSize: '10px', fontWeight: '600', fontFamily: 'Montserrat' , color:'#10383D', lineHeight:'20px'}}>
        {stat.title}
      </Typography>
     
     { stat.title === 'TOTAL TRANSPORTERS' ? (
            <Typography variant="h6" component="p" sx={{marginTop:'10px', fontSize: '40px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D', lineHeight:'32px' }}>
              {stat.value}
            </Typography>
          ) : (
            <Typography variant="h6" component="p" sx={{marginTop:'10px', fontSize: '24px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D' , lineHeight:'24px'}}>
              {stat.value}
            </Typography>
          )}
              </Grid>
              {index !== stats.length - 1 && (
                <Divider orientation="vertical" flexItem sx={{ width: '1px', bgcolor: '#DDDDDD', mx: 2 }} />
              )}
            </React.Fragment>
          ))}
        </Paper>
        {/* Table Layout */}
        <Paper
          sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            padding: '1.5rem',
            mb: '2rem',
            boxShadow: 2,
          }}
        >
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <ToggleButtonGroup
              color="standard"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
            >
              <ToggleButton
                value="web"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'web' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'web' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform:'none'
                }}
              >
                Non-deduct TDS
              </ToggleButton>
              <ToggleButton
                value="android"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'android' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'android' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform:'none'
                }}
              >
                Deduct TDS
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {alignment === 'web' && (
            <div style={{ marginBottom: '1rem' }}>
              <NonDeductTDS />
            </div>
          )}
          {alignment === 'android' && <DeductTDS/>}
        </Paper>
      </Box>
    </div>
  );
};

export default SlabMain;
