import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGoodsType, updateGoodsType } from '../redux/goodsSlice/goodsSlice';
import { TextField, Button, Typography, Box, IconButton, Modal, Paper, Divider, MenuItem, Select, SelectChangeEvent, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { createUserModalType } from '../redux/company and model slice/userModelSlice';
import { AppDispatch, RootState } from '../redux/store/appStore';

interface FormState {
  id:string;
  primary_phone: string;
  first_name: string;
  last_name: string;
  email: string;
  kyc_documents_links: string[];
  photourl: string;
  doj: string;
  role:string
}

export interface User extends FormState {}

interface UserModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (updatedData: User) => void;
  users: User[];
  companyId: string | null;
}

const UserModal: React.FC<UserModalProps> = ({ open, onClose, onUpdate, users, companyId }) => {

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const dispatch = useDispatch<AppDispatch>()
  const [userDetails, setUserDetails] = useState<FormState>({
    id:'',
    primary_phone: '',
    first_name: '',
    last_name: '',
    email: '',
    kyc_documents_links: [''],
    photourl: '',
    doj: getCurrentDate(),
    role:'',
  });

  useEffect(() => {
    
    setUserDetails(prevState => ({
      ...prevState,
      doj: getCurrentDate()
    }));
  }, []);

  


  const [openUserModal, setOpenUserModal] = useState(false);
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const fileInputRefKyc = useRef<HTMLInputElement>(null);
  const fileInputRefPhoto = useRef<HTMLInputElement>(null);
  const [imageKyc, setImageKyc] = useState<File | null>(null);
  const [imagePhoto, setImagePhoto] = useState<File | null>(null);
  const [tableVisible, setTableVisible] = useState(false);
  const [access,setAccess] = useState(false);

  const [errors, setErrors] = useState<Partial<FormState>>({});

  const handleAddUser = (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const newUser: User = {
        ...userDetails
      };
      onUpdate(newUser);
      const payload = {
        company_id: companyId,
        primary_phone: userDetails.primary_phone,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        kyc_documents_links: [
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
        ],
        photourl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        doj: userDetails.doj,
        user_role: userDetails.role,
        allow_platform_access: access,
      };
      dispatch(createUserModalType(payload));
      onClose();
      setUserDetails({ 
        id: '',
        primary_phone: '',
        first_name: '',
        last_name: '',
        email: '',
        kyc_documents_links: [''],
        photourl: '',
        doj:  getCurrentDate(),
        role:''
      });
      setErrors({});
    }
  };
  
  const handlePhoneFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "primary_phone" && value.length > 10) {
      return;
    }
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const validateForm = (): boolean => {
    let formIsValid = true;
    const newErrors: Partial<FormState> = {};

    if (!userDetails.first_name.trim()) {
      newErrors.first_name = 'First Name is required';
      formIsValid = false;
    }

    if (!userDetails.last_name.trim()) {
      newErrors.last_name = 'Last Name is required';
      formIsValid = false;
    }

    if (!userDetails.email.trim()) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
      newErrors.email = 'Email is not valid';
      formIsValid = false;
    }

    if (!userDetails.primary_phone.trim()) {
      newErrors.primary_phone = 'Phone Number is required';
      formIsValid = false;
    } else if (!/^\d{10}$/.test(userDetails.primary_phone)) {
      newErrors.primary_phone = 'Phone Number must be 10 digits';
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const triggerFileInputKyc = () => {
    if (fileInputRefKyc.current) {
      fileInputRefKyc.current.click();
    }
  };

  const handleFileChangeKyc = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageKyc(file);
      const url = URL.createObjectURL(file); 
      setPhotoUrl(url); 
    }
  };

  const triggerFileInputPhoto = () => {
    if (fileInputRefPhoto.current) {
      fileInputRefPhoto.current.click();
    }
  };

  const handleFileChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImagePhoto(file);
      const url = URL.createObjectURL(file); 
      setPhotoUrl(url);
    }
  };

  const deleteImage = () => {
    setImageKyc(null);
    setImagePhoto(null);
    setPhotoUrl('');
  };

  useEffect(() => {
    if (users.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [users]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccess(event.target.checked);
  };

  return (
    <>
    
      <Modal open={open} onClose={onClose}>
        <Paper sx={{ position: 'absolute', minWidth: '640px', bgcolor: 'background.paper', boxShadow: 24, p: 4, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem' }}>Add User</Typography>
            <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
          </div>
          <Divider />
          <form onSubmit={handleAddUser}>
            <div style={{ display: 'flex', marginTop: '2rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px' }}>First Name</Typography>
                <TextField
                  placeholder="Enter first Name"
                  variant="outlined"
                  sx={{ width: '296px', marginRight: '10px' }}
                  name="first_name"
                  size='small'
                  value={userDetails.first_name}
                  onChange={handleFieldChange}
                  error={!!errors.first_name}
                  helperText={errors.first_name}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px' }}>Last Name</Typography>
                <TextField
                  placeholder="Enter last Name"
                  variant="outlined"
                  sx={{ width: '296px' }}
                  name="last_name"
                  size='small'
                  value={userDetails.last_name}
                  onChange={handleFieldChange}
                  error={!!errors.last_name}
                  helperText={errors.last_name}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px', marginTop: '10px' }}>Email ID</Typography>
                <TextField
                  placeholder="Enter email ID"
                  variant="outlined"
                  sx={{ width: '296px', marginRight: '10px' }}
                  name="email"
                  size='small'
                  value={userDetails.email}
                  onChange={handleFieldChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px', marginTop: '10px' }}>Phone Number</Typography>
                <TextField
                  type="tel"
                  autoComplete="tel"
                  placeholder="Enter phone number"
                  name="primary_phone"
                  value={userDetails.primary_phone}
                  onChange={handlePhoneFieldChange}
                  error={!!errors.primary_phone}
                    helperText={errors.primary_phone}
                  size="small"
                  maxLength={10}
                      {...({} as any)}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  sx={{ width: '296px' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>
                  KYC Documents
                </Typography>
                <input
                  type="file"
                  ref={fileInputRefKyc}
                  style={{ display: 'none' }}
                  onChange={handleFileChangeKyc}
                />
                <Button
                  onClick={triggerFileInputKyc}
                  variant="outlined"
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '16px',
                    letterSpacing: '0.025em',
                    color: '#03BBD2',
                    border: 'none',
                    mt: '5px',
                    textTransform: 'none',
                    '&:hover': {
                      border: 'none',
                      backgroundColor: 'none',
                    },
                  }}
                >
                  Upload Documents <FileUploadIcon fontSize='small' />
                </Button>
              </div>
              {imageKyc && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={photoUrl}
                    alt="Uploaded File"
                    style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                  />
                  <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                    {imageKyc?.name}
                  </Typography>
                  <IconButton onClick={deleteImage}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}

              <div style={{ display: 'flex', flexDirection: 'column', marginLeft:'7rem' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', marginLeft:'15px' }}>
                  Photo
                </Typography>
                <input
                  type="file"
                  ref={fileInputRefPhoto}
                  style={{ display: 'none' }}
                  onChange={handleFileChangePhoto}
                />
                <Button
                  onClick={triggerFileInputPhoto}
                  variant="outlined"
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '16px',
                    letterSpacing: '0.025em',
                    color: '#03BBD2',
                    border: 'none',
                    mt: '5px',
                    textTransform: 'none',
                    '&:hover': {
                      border: 'none',
                      backgroundColor: 'none',
                    },
                  }}
                >
                  Upload Photo <FileUploadIcon fontSize='small' />
                </Button>
              </div>
              {imagePhoto && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={photoUrl}
                    alt="Uploaded File"
                    style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                  />
                  <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                    {imagePhoto?.name}
                  </Typography>
                  <IconButton onClick={deleteImage}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}

            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '                  0px', textAlign: 'left', color: '#10383D', marginBottom: '10px', marginTop: '10px' }}>Date of Joining</Typography>
                <input type='date' 
                  name='doj' 
                  onChange={handleFieldChange} 
                  value={userDetails.doj}
                  style={{minHeight:'40px', minWidth:'296px', marginRight: '10px', borderRadius:'4px', border:'1px solid', borderColor:'#939ba2', padding:'8px, 16px, 8px, 16px', gap:'12px'}} 
            />
              </div>


              <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D',marginBottom: '10px', marginTop: '10px' }}>Role</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
              value={userDetails.role}
              onChange={(e) => handleSelectFieldChange(e,'role')}
              fullWidth
              error={!!errors.role}
              displayEmpty
              size='small'
              sx={{ width: '296px' }}
              >
              <MenuItem value="" disabled>
                  Select role
              </MenuItem>
               <MenuItem value="DRIVER">DRIVER</MenuItem>
              <MenuItem value="OWNER">OWNER</MenuItem>
              <MenuItem value="CO-OWNER">CO-OWNER</MenuItem> 
              <MenuItem value="BRANCH INCHARGE">BRANCH INCHARGE</MenuItem> 
    
    
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>

            </div>

            <FormGroup sx={{marginTop:'10px'}}>
      <FormControlLabel
  control={<Checkbox  checked={access} onChange={handleCheckboxChange} />}
  label="Allow platform access"
/>

      </FormGroup>

            <Button type="submit" variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px', backgroundColor: '#03BBD2', borderRadius: '8px', textTransform:'none' }}>
                Add User
              </Button>
            </form>
          </Paper>
        </Modal>
      </>
    );
  };
  
  export default UserModal;


