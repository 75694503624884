import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';


export interface CreateGoodsTypePayload {
  name: string;
  id: string;
}

export interface GoodsState {
  expenseName: {
    count: number | undefined;
    results: CreateGoodsTypePayload[];
  };
  error: string | null;
  getExpenseNameStatus: STATUS;
}

const initialState: GoodsState = {
  expenseName: { 
    count: undefined,
    results: [] },
  error: null,
  
  getExpenseNameStatus: STATUS.NOT_STARTED,
};

interface GetGoodsPayload {
    search?: string;
    page?: number;
    pageSize?: number;
    sort?: string;
    order?: string;
  }
  
  export const getGlobalExpense = createAsyncThunk(
    'expense/global/get',
    async (payload: GetGoodsPayload = {}, thunkAPI) => {
      const { search, page, pageSize, sort, order} = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort && { sort }),
        ...(order && { order }),
      }).toString();
      const response = await api.get(`expense/global?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

const expenseNameSlice = createSlice({
  name: 'expenseName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalExpense.pending, (state, action) => {
        state.getExpenseNameStatus = STATUS.PENDING;
      })
      .addCase(getGlobalExpense.fulfilled, (state, action) => {
        state.expenseName = action.payload;
        state.getExpenseNameStatus = STATUS.SUCESS;
        console.log('GET GOODS TYPES SUCCESS');
      })
      .addCase(getGlobalExpense.rejected, (state, action) => {
        state.getExpenseNameStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
  },
});

export default expenseNameSlice.reducer;
