import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface Enquiry 
{
    id:string;
}

interface dashboardState {
  payments: {
    results: Enquiry[];
  };
  error: string | null;
  getPaymentsStatus: STATUS,
}


const initialState: dashboardState = {
  payments: { 
    results: []
   },
  error: null,
  getPaymentsStatus: STATUS.NOT_STARTED,
};

export const getPayments = createAsyncThunk(
  'payment/get',
  async (_, thunkAPI) => {
    const response = await api.get('payment');
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const paymentsReducer = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.pending, (state, action) => {
        state.getPaymentsStatus = STATUS.PENDING
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.getPaymentsStatus = STATUS.SUCESS; 
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.getPaymentsStatus = STATUS.FAILED;
      })
  },
});

export default paymentsReducer.reducer;
