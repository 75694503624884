import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenses,updateExpense} from '../redux/expenses/expensesSlice'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Checkbox from '@mui/material/Checkbox';
import { Button, Typography, CircularProgress, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, TextField, MenuItem, Select } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add'
import ChecklistEnquiryForm from './checklistEnquiryForm';
import CommissionForm from './commissionForm';
import { FormState, updateFormState, updateSelectedExpenseIds } from '../redux/reducers/expenseForm';
import ExpenseEnquiryTable from './expenseTable';



const ExpenseEnquiryForm = () => {
  return (
  <>
  <ExpenseEnquiryTable/>
      <div style={{display:'flex', marginTop:'1rem'}}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#13282A', marginTop: '1rem' }}>Expenses Total Amount</Typography>
        <TextField  
              name="expense_amount_total"
              // value={expense_amount_total}
              // onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
        </div>
      <FormControl>
        
      <FormLabel id="demo-row-radio-buttons-group-label" sx={{color:'#10383D', marginTop:'1rem',fontSize: '14px'}}>Do you want expenses to be included in invoice?</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel sx={{color:'#13282A', fontSize:'14px', fontWeight:'400'}} value="Yes" control={<Radio sx={{color:'#13282A', fontSize:'14px'}}/>} label="Yes"/>
        <FormControlLabel sx={{color:'#13282A', fontSize:'14px', fontWeight:'400'}} value="No" control={<Radio sx={{color:'#13282A', fontSize:'14px'}}/>} label="No" />
      </RadioGroup>
    </FormControl>
      </div>

      <ChecklistEnquiryForm/>
      <CommissionForm/>


    </>
  )
}


export default ExpenseEnquiryForm