import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Paper, Typography, Snackbar, SnackbarContent, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import ChecklistHeading from './checklistHeading';
import ChecklistTable from './checklistTable';
import { createChecklistItem, getChecklistItems, setCreateChecklistStatus } from '../redux/checklist/checklistSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import ClearIcon from '@mui/icons-material/Clear';
import { STATUS } from '../../utils/status';
import { getGlobalChecklist } from '../redux/checklist/globalSlice';


const CheckListMain = () => {
  const [checklistName, setChecklistName] = useState('');
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string>('item_name');
  const [sortOrder, setSortOrder] = useState<string>('asc');

  const dispatch = useDispatch<AppDispatch>();

  const checklistItems = useSelector((state: RootState) => state.checklists.checklists);
  const createStatus = useSelector((state: RootState) => state.checklists.createStatus);
  console.log({createStatus})

  const handleAddChecklist = async () => {
    // if (!inputValue) {
    //   setError('Name is required');
    //   return;
    // }
  
    const newItem = {
      item_name: inputValue,
    };
    
      await dispatch(createChecklistItem(newItem));
      await dispatch(getChecklistItems({ page: currentPage, sort: sortColumn, order: sortOrder }));
      setChecklistName('');
      setError('');
      setInputValue('');
  };
  

  useEffect(() => {
      dispatch(getChecklistItems({ page: currentPage, sort: sortColumn, order: sortOrder }))
  }, [dispatch, currentPage, sortColumn, sortOrder]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(()=>{
    if(createStatus == STATUS.SUCESS){
      handleSnackbar('Checklist Added Successfully', 'success');
        dispatch(setCreateChecklistStatus(STATUS.NOT_STARTED))
      }

      if(createStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either this checklist already exist or you entered wrong information', 'warning');
        }, 5000);
      }
  },[createStatus])

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [selectedExpense, setSelectedExpense] = useState<{ label: string; value: string; } | null>(null);
  const companies = useSelector((state: RootState) => state.checklistName.checklistName);
  console.log({companies})
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<{ label: string; value: string; }[]>([]);
  useEffect(() => {
    dispatch(getGlobalChecklist({}))
  }, [dispatch]);

  useEffect(() => {
    setOptions(companies.results.map((company) => ({ label: company.item_name, value: company.id })));
  }, [companies]);
  
  const handleInputChange = (event:any, newInputValue:any) => {
    setInputValue(newInputValue);

    console.log(selectedExpense); 
    dispatch(getGlobalChecklist({ search: newInputValue }));
  };

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <ChecklistHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        {/* Table Layout */}
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div style={{ marginBottom: '1rem' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#10383D' }}>Enter New Checklist Item</Typography>
            <div style={{ display: 'flex' }}>
              {/* <TextField
                id="outlined-basic"
                placeholder='eg. Box'
                variant="outlined"
                size='small'
                value={checklistName}
                onChange={(e) => setChecklistName(e.target.value)}
                sx={{ border: error ? '1px solid red' : '1px', borderRadius: '4px', gap: '12px', width: '248px', marginBottom: '4px', marginRight: '8px' }}
                InputLabelProps={{
                  shrink: false
                }}
              /> */}
              <Autocomplete
                      options={companies.results}
                      getOptionLabel={(option:any) => option.item_name}
                      inputValue={inputValue}
                      onInputChange={handleInputChange}
                      freeSolo
                      sx={{
                        borderRadius: '4px',
                        width: '248px',
                        marginRight: '8px',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Checklist Name"
                          // error={formErrors.expenseName !== undefined}
                          // helperText={formErrors.expenseName}
                          size="small"
                          InputLabelProps={{
                            shrink: false,
                          }}
                        />
                      )}
                    />
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                disableElevation
                onClick={handleAddChecklist}
                sx={{fontWeight:'600', padding: '8px, 24px, 8px, 24px', border: '1px', borderRadius: '8px', marginLeft: '4px', width: '194px', marginBottom: '4px', fontSize: '14px', textTransform: 'none', backgroundColor: '#03BBD2', '&:hover': { backgroundColor: '#03BBD2', } }}>
                Add Checklist Item
              </Button>
            </div>
            {error && (
              <Typography sx={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{error}</Typography>
            )}
          </div>
          <ChecklistTable data={checklistItems.results} count={!!checklistItems?.count ? checklistItems.count : 0} />
        </Paper>
      </Box>
      {/* Snackbar for success and error messages */}
      <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </div>
  );
};

export default CheckListMain;