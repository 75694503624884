import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGoodsType, getGoodsType, setCreateGoodsStatus, setUpdateGoodsStatus, updateGoodsType } from '../redux/goodsSlice/goodsSlice';
import { TextField, Button, Typography, Box, MenuItem, Select, FormControl, FormHelperText, SnackbarContent, Snackbar, SelectChangeEvent, Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import { TypographyWithMargin, TypographyWithoutMargin } from '../../assets/typography';
import { STATUS } from '../../utils/status';
import CloseIcon from '@mui/icons-material/Close';
import { getHotGoods } from '../redux/goodsSlice/hotGoodsSlice';
import { getGoodsName } from '../redux/goodsSlice/fetchGoods';

interface FormState {
  goods_details: {
    id?: string;
    goods_name: string;
    hsn_code: string;
    gst_tax_type: string;
  };
  goods_value: {
    id?: string;
    value: number ;
    value_unit: string;
  };
  short_allowances: {
    id?: string;
    goods_type_id?: string;
    allowance_type: 'DEPENDANT' | 'NON_DEPENDANT';
    short_allowance: string|number;
    short_allowance_unit: string;
    weight_range_start: string | number;
    weight_range_end: string | number;
  }[];
}



enum GoodsValueFields {
  ValueUnit = 'value_unit',
  GSTUnit = 'gst_tax_type',
  ShortAllowanceunit = 'short_allowance_unit'
}

interface CustomAutocompleteRenderInputParams extends AutocompleteRenderInputParams {
  inputValue?: string;
}

const GoodsTypeForm = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { goodsDetails: initialGoodsDetails, id: goodsId } = location.state || { goodsDetails: null, id: '' };
  const createGoodStatus = useSelector((state:RootState)=> state.goods.createGoodsStatus)
  const updateGoodStatus = useSelector((state:RootState)=> state.goods.updateGoodsStatus)

  const [goodsDetails, setGoodsDetails] = useState<FormState>({
    goods_details: {
      id:'',
      goods_name: '',
      hsn_code: '',
      gst_tax_type: '',
    },
    goods_value: {
      id:'',
      value: 0,
      value_unit: '',
    },
    short_allowances: [
      {
        id:'',
        allowance_type: 'DEPENDANT',
        short_allowance: 0,
        short_allowance_unit: '',
        weight_range_start: 0,
        weight_range_end: 0
      },
    ],
  });

  const [additionalFields, setAdditionalFields] = useState<number>(1);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

useEffect(() => {
  if (initialGoodsDetails) {
    initialGoodsDetails.shortages.sort((a:any, b:any) => a.weight_range_start - b.weight_range_start || a.weight_range_end - b.weight_range_end);
    
    const updatedGoodsDetails = {
      goods_details: {
        id: initialGoodsDetails.id ?? '',
        goods_name: initialGoodsDetails.goods_name ?? '',
        hsn_code: initialGoodsDetails.hsn_code ?? '',
        gst_tax_type: initialGoodsDetails.gst_tax_type ?? 'EXEMPTED',
      },
      goods_value: {
        id: initialGoodsDetails.goods_name_id ?? '',
        value: initialGoodsDetails.value ?? 0,
        value_unit: initialGoodsDetails.value_unit ?? '',
      },
      short_allowances: initialGoodsDetails.shortages.map((shortage: {
        id?: string;
        goods_type_id?: string;
        short_allowance?: number;
        short_allowance_unit?: string;
        weight_range_start?: number;
        weight_range_end?: number;
      }) => {
        return {
          id: shortage.id ?? '',
          goods_type_id: shortage.goods_type_id ?? '',
          allowance_type: 'DEPENDANT',
          short_allowance: shortage.short_allowance ?? 0,
          short_allowance_unit: shortage.short_allowance_unit ?? '',
          weight_range_start: shortage.weight_range_start ?? 0,
          weight_range_end: shortage.weight_range_end ?? 0,
        };
      }),
    };

    setGoodsDetails(updatedGoodsDetails);
    setAdditionalFields(updatedGoodsDetails.short_allowances.length - 1);
    handleSnackbar('You can make your changes now.', 'info');
  }
}, [initialGoodsDetails]);

  


  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    if (name === 'short_allowance' || name === 'short_allowance_unit') {
      setGoodsDetails((prevDetails) => ({
        ...prevDetails,
        short_allowances: prevDetails.short_allowances.map((item, index) =>
          index === 0
            ? {
                ...item,
                [name]: value,
              }
            : item
        ),
      }));
    } else {
      setGoodsDetails((prevDetails) => ({
        ...prevDetails,
        goods_details: {
          ...prevDetails.goods_details,
          [name]: value,
        },
        goods_value: {
          ...prevDetails.goods_value,
          [name]: value,
        },
      }));
    }
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: GoodsValueFields) => {
    const value = event.target.value;
    setFormErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    if (fieldName === GoodsValueFields.ShortAllowanceunit) {
      setGoodsDetails((prevDetails) => ({
        ...prevDetails,
        short_allowances: prevDetails.short_allowances.map((item, index) =>
          index === 0
            ? {
                ...item,
                [fieldName]: value,
              }
            : item
        ),
      }));
    } else {
      setGoodsDetails((prevDetails) => ({
        ...prevDetails,
        goods_details: {
          ...prevDetails.goods_details,
          [fieldName]: value,
        },
        goods_value: {
          ...prevDetails.goods_value,
          [fieldName]: value,
        },
      }));
    }
  };

  const handleWeightRangeStartChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const parsedValue = parseFloat(value);
  
    if (value === '' || !isNaN(parsedValue)) {
      setGoodsDetails((prevDetails) => ({
        ...prevDetails,
        short_allowances: prevDetails.short_allowances.map((item, idx) =>
          idx === index
            ? {
                ...item,
                weight_range_start: value === '' ? '' : parsedValue,
              }
            : item
        ) as {
          id?: string;
          goods_type_id?: string;
          allowance_type: 'DEPENDANT' | 'NON_DEPENDANT';
          short_allowance: string| number;
          short_allowance_unit: string;
          weight_range_start: string | number; 
          weight_range_end: string| number;
        }[],
      }));
    }
  };
  
  
  
  
  const handleWeightRangeEndChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const parsedValue = parseFloat(value);
  
    if (value === '' || !isNaN(parsedValue)) {
      setGoodsDetails((prevDetails) => ({
        ...prevDetails,
        short_allowances: prevDetails.short_allowances.map((item, idx) =>
          idx === index
            ? {
                ...item,
                weight_range_end: value === '' ? '' : parsedValue,
              }
            : item
        ) as {
          id?: string;
          goods_type_id?: string;
          allowance_type: 'DEPENDANT' | 'NON_DEPENDANT';
          short_allowance: string| number;
          short_allowance_unit: string;
          weight_range_start: string | number; 
          weight_range_end: string| number;
        }[],
      }));
    }
  };
  
  const handleShortAllowanceChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const parsedValue = parseFloat(value);
  
    if (value === '' || !isNaN(parsedValue)) {
      setGoodsDetails((prevDetails) => ({
        ...prevDetails,
        short_allowances: prevDetails.short_allowances.map((item, idx) =>
          idx === index
            ? {
                ...item,
                short_allowance: value === '' ? '' : parsedValue,
              }
            : item
        ) as {
          id?: string;
          goods_type_id?: string;
          allowance_type: 'DEPENDANT' | 'NON_DEPENDANT';
          short_allowance: string| number;
          short_allowance_unit: string;
          weight_range_start: string | number; 
          weight_range_end: string| number;
        }[],
      }));
    }
  };
  

  const handleShortAllowanceUnitChange = (event: SelectChangeEvent<string>, index: number) => {
    const { value } = event.target;
    setGoodsDetails((prevDetails) => ({
      ...prevDetails,
      short_allowances: prevDetails.short_allowances.map((item, idx) =>
        idx === index
          ? {
              ...item,
              short_allowance_unit: value,
            }
          : item
      ),
    }));
  };

  const handleAddMore = () => {
    setGoodsDetails((prevDetails) => ({
      ...prevDetails,
      short_allowances: [
        ...prevDetails.short_allowances,
        {
          allowance_type: 'DEPENDANT',
          short_allowance: 0,
          short_allowance_unit: '',
          weight_range_start: 0,
          weight_range_end: 0
        }
      ]
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const navigate = useNavigate();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);
  
    const errors: { [key: string]: string } = {};
    if (!goodsDetails.goods_details.goods_name || !inputValue) {
      errors.goods_name = "Goods Name is Required";
    }
    if (!goodsDetails.goods_details.gst_tax_type) {
      errors.gst_tax_type = "GST Type is Required";
    }
    if (!goodsDetails.goods_details.hsn_code) {
      errors.hsn_code = "HSN Code is Required";
    }
    if (!goodsDetails.goods_value.value) {
      errors.value = "Value is Required";
    }
    if (!goodsDetails.goods_value.value_unit) {
      errors.value_unit = "Unit is Required";
    }
    if (!goodsDetails.short_allowances[0].short_allowance) {
      errors.short_allowance = "Allowance is Required";
    }
    if (!goodsDetails.short_allowances[0].short_allowance_unit) {
      errors.short_allowance_unit = "Allowance Unit is Required";
    }
    // if (goodsDetails.short_allowances.length > 1) {
    //   goodsDetails.short_allowances.slice(1).forEach((allowance, index) => {
    //     if (allowance.weight_range_start <= 0 || allowance.weight_range_end <= 0) {
    //       errors[`short_allowances[${index + 1}].weight_range`] =
    //         'Truck weight range start and end must be greater than 0.';
    //     } else if (allowance.weight_range_start >= allowance.weight_range_end) {
    //       errors[`short_allowances[${index + 1}].weight_range`] =
    //         'Truck weight range start must be less than end weight.';
    //     }
    //   });
    // }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields.', 'warning');
      return;
    }
    let selectedGoodsId = "";
    let selectedGoodsName = "";
  
    if (goodsOptions.results.length > 0) {
      selectedGoodsId = goodsOptions.results[0].id; 
      selectedGoodsName = goodsOptions.results[0].goods_name;
    }
  
    if (inputValue) {
      selectedGoodsName = inputValue;
    }

    

    let goodsDetailsPayload: { goods_name: string; hsn_code: string; gst_tax_type: string; id?: string } = {
      goods_name: selectedGoodsName,
      hsn_code: goodsDetails.goods_details.hsn_code,
      gst_tax_type: goodsDetails.goods_details.gst_tax_type,
    };
    
    if (selectedGoodsId) {
      goodsDetailsPayload.id = selectedGoodsId;
    }
    
    let shortAllowancePayload = {
      allowance_type: 'DEPENDANT',
      short_allowance: goodsDetails.short_allowances[0].short_allowance,
      short_allowance_unit: goodsDetails.short_allowances[0].short_allowance_unit,
      weight_range_start: goodsDetails.short_allowances[0].weight_range_start,
      weight_range_end: goodsDetails.short_allowances[0].weight_range_end,
    };
  
    let additionalShortAllowancesPayload = goodsDetails.short_allowances
      .slice(1)
      .map((item) => ({
        allowance_type: 'DEPENDANT',
        short_allowance: item.short_allowance,
        short_allowance_unit: item.short_allowance_unit,
        weight_range_start: item.weight_range_start,
        weight_range_end: item.weight_range_end,
      }));
  
    let shortAllowancesPayload = [shortAllowancePayload, ...additionalShortAllowancesPayload];

    if (goodsId) {
      const payload = {
        goods_details: {
          id: goodsDetails.goods_value.id,
          goods_name: selectedGoodsName,
          hsn_code: goodsDetails.goods_details.hsn_code,
          gst_tax_type: goodsDetails.goods_details.gst_tax_type,
        },
        goods_value: {
          id: goodsDetails.goods_details.id,
          goods_name_id: goodsDetails.goods_value.id,
          value: goodsDetails.goods_value.value,
          value_unit: goodsDetails.goods_value.value_unit,
        },
        short_allowances: shortAllowancesPayload
      }
      
      dispatch(updateGoodsType(payload));
      
    } else {
      const payload = {
        goods_details: goodsDetailsPayload,
        goods_value: {
          value: goodsDetails.goods_value.value,
          value_unit: goodsDetails.goods_value.value_unit,
        },
        short_allowances: shortAllowancesPayload
      };

      dispatch(createGoodsType(payload));
    }
  
    
  };

  useEffect(()=>{
    if(updateGoodStatus == STATUS.SUCESS){
      handleSnackbar('Goods Updated Successfully', 'success');
      setTimeout(() => {
          navigate('/goods');
        }, 1000);
        dispatch(setUpdateGoodsStatus(STATUS.NOT_STARTED))
      }

      if(updateGoodStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
      }
  },[updateGoodStatus])

  useEffect(()=>{
    if(createGoodStatus == STATUS.SUCESS){
      handleSnackbar('Goods Added Successfully', 'success');
        setTimeout(() => {
          navigate('/goods');
        }, 1000);
        dispatch(setCreateGoodsStatus(STATUS.NOT_STARTED))
      }

      if(createGoodStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either this good name already exist or you entered wrong information', 'warning');
        }, 7000);
      }
  },[createGoodStatus])

  const handleRemove = (indexToRemove: number) => {
    setGoodsDetails((prevDetails) => ({
      ...prevDetails,
      short_allowances: prevDetails.short_allowances.filter((_, index) => index !== indexToRemove),
    }));
  };

  const goodsOptions = useSelector((state:RootState) => state.goodsName.goodsName);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<{ id: string; goods_name: string }[]>([]);

  useEffect(() => {
        
    if (initialGoodsDetails) {
      setInputValue(initialGoodsDetails.goods_name);
    }
  }, [initialGoodsDetails]);

  const handleInputChange = (event:any, newInputValue:any) => {
    setInputValue(newInputValue);
    setGoodsDetails((prevDetails) => ({
      ...prevDetails,
      goods_details: {
        ...prevDetails.goods_details,
        goods_name: newInputValue,
      },
    }));
    dispatch(getGoodsName({ goods_name: newInputValue}));
  };
  
  const isGoodsTypeId = location.pathname.includes(`goods-type/${id}`);

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <TypographyWithoutMargin variant="h1">Goods Type Details</TypographyWithoutMargin>
      <div>
        <div style={{display:'flex', flexDirection:'column', width:'49%'}}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Goods Type Name</Typography>
       <Autocomplete
      options={goodsOptions.results}
      getOptionLabel={(option:any) => option.goods_name}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      freeSolo
      disabled={isGoodsTypeId}
      renderInput={(params) => (
        <TextField
          {...params}
          
          placeholder="Goods Name"
          error={formErrors.goods_name !== undefined}
          helperText={formErrors.goods_name}
          size="small"
          InputLabelProps={{
            shrink: false,
          }}
          
        />
      )}
    />


     
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width:'49%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Value</Typography>
            <TextField
              placeholder="Enter Value"
              name="value"
              type="number"
              value={goodsDetails.goods_value.value}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.value ? true : false}
              helperText={formSubmitted && formErrors.value}
            />
          </div>
          
          <div style={{ display: 'flex', flexDirection: 'column', width:'49%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Units</Typography>
            <FormControl  error={formSubmitted && formErrors.value_unit ? true : false}>
            <Select
              value={goodsDetails.goods_value.value_unit}
              onChange={(e) => handleSelectFieldChange(e, GoodsValueFields.ValueUnit)}
              name="value_unit"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              size="small"
            >
              <MenuItem value="">
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '400', color: '#A8A8A8' }}>Select Unit</Typography>
              </MenuItem>
              <MenuItem value="TON">TON</MenuItem>
              <MenuItem value="KG">KG</MenuItem>
              <MenuItem value="QUINTAL">QUINTAL</MenuItem>
              <MenuItem value="RS">RS</MenuItem>
              <MenuItem value="LITRE">LITRE</MenuItem>
              <MenuItem value="PER BOX">PER BOX</MenuItem>
            </Select>
              {formSubmitted && formErrors.value_unit && (
                <FormHelperText>{formErrors.value_unit}</FormHelperText>
              )}
            </FormControl>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width:'49%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Shortage Allowance</Typography>
            <TextField
            placeholder="Shortage Allowance"
            name="short_allowance"
            type="number"
            value={goodsDetails.short_allowances[0]?.short_allowance ?? 0}
            onChange={handleFieldChange}
            size="small"
            InputLabelProps={{
              shrink: false,
            }}
            error={formSubmitted && formErrors.short_allowance ? true : false}
              helperText={formSubmitted && formErrors.short_allowance}
          />

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width:'49%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Shortage Unit Type</Typography>
            <FormControl  error={formSubmitted && formErrors.short_allowance_unit ? true : false}>
              <Select
                value={goodsDetails.short_allowances[0]?.short_allowance_unit?? 0}
                onChange={(e) => handleSelectFieldChange(e,GoodsValueFields.ShortAllowanceunit)}
                name="short_allowance_unit"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size="small"
              >
                <MenuItem value="">
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '400', color: '#A8A8A8' }}>Select Unit</Typography>
                </MenuItem>
                <MenuItem value="KG">KG</MenuItem>
                <MenuItem value="PERCENTAGE">PERCENTAGE</MenuItem>
                <MenuItem value="PER BOX">PER BOX</MenuItem>
              </Select>
              {formSubmitted && formErrors.short_allowance_unit && (
                <FormHelperText>{formErrors.short_allowance_unit}</FormHelperText>
              )}
              
            </FormControl>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width:'49%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>HSN Code</Typography>
            <TextField
              placeholder="HSN Code"
              name="hsn_code"
              value={goodsDetails.goods_details.hsn_code}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.hsn_code ? true : false}
              helperText={formSubmitted && formErrors.hsn_code}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width:'49%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>GST Tax Type</Typography>
            <FormControl error={formSubmitted && formErrors.gst_tax_type ? true : false}>
              <Select
                value={goodsDetails.goods_details.gst_tax_type}
                onChange={(e) => handleSelectFieldChange(e,GoodsValueFields.GSTUnit)}
                name="gst_tax_type"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size="small"
              >
                <MenuItem value="">
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '400', color: '#A8A8A8' }}>Select Type</Typography>
                </MenuItem>
                <MenuItem value="EXEMPTED">EXEMPT</MenuItem>
                <MenuItem value="NON EXEMPTED">NON EXEMPT</MenuItem>
              </Select>
              {formSubmitted && formErrors.gst_tax_type && (
                <FormHelperText>{formErrors.gst_tax_type}</FormHelperText>
              )}
            </FormControl>
          </div>
        </div>
        
        <TypographyWithMargin variant="h1">Additional Details</TypographyWithMargin>
        {goodsDetails.short_allowances.slice(1).map((shortAllowance, index) => (
        <div key={index} style={{ display: 'flex', marginTop: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
          <Typography sx={{ backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>Truck Weight Range</Typography>
            <div style={{ display: 'flex' }}>
              <TextField
                placeholder="Start Weight"
                name={`weight_range_start_${index + 1}`}
                value={shortAllowance.weight_range_start ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleWeightRangeStartChange(e, index + 1)}
                sx={{  marginRight: '20px' }}
                size="small"
                error={formSubmitted && (shortAllowance.weight_range_start ?? '') <= 0}
                helperText={formSubmitted && (shortAllowance.weight_range_start ?? '') <= 0 ? "Must be greater than 0" : ""}
              />
              <span style={{ marginTop: '12px', marginRight: '20px' }}>to</span>
              <TextField
                placeholder="End Weight"
                name={`weight_range_end_${index + 1}`}
                value={shortAllowance.weight_range_end ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleWeightRangeEndChange(e, index + 1)}
                sx={{  marginRight: '20px' }}
                size="small"
                error={formSubmitted && (shortAllowance.weight_range_end ?? '') <= 0}
                helperText={formSubmitted && (shortAllowance.weight_range_end ?? '') <= 0 ? "Must be greater than 0" : ""}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
          <Typography sx={{ backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>Short Allowance Unit Type</Typography>
            <Select
              value={shortAllowance.short_allowance_unit ?? ''}
              onChange={(e: SelectChangeEvent<string>) => handleShortAllowanceUnitChange(e, index + 1)}
              name={`short_allowance_unit_${index + 1}`}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{  marginRight: '20px' }}
              size="small"
            >
              <MenuItem value="">
                <Typography>Select Unit</Typography>
              </MenuItem>
              <MenuItem value="KG">KG</MenuItem>
              <MenuItem value="PERCENTAGE">PERCENTAGE</MenuItem>
              <MenuItem value="PER BOX">PER BOX</MenuItem>
            </Select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
          <Typography sx={{ backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>Short Allowance</Typography>
            <TextField
              placeholder="Short Allowance"
              name={`shortage_allowance_${index + 1}`}
              value={shortAllowance.short_allowance ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleShortAllowanceChange(e, index + 1)}
              size="small"
            />
          </div>
          <CloseIcon onClick={() => handleRemove(index+1)} sx={{marginTop:'60px', marginLeft:'10px', cursor:'pointer'}}/>
        </div>
      ))}

          <Button
          onClick={handleAddMore}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '0.025em',
          textAlign: 'center',
          color: 'inherit',
          marginRight: '18rem',
          border: 'none',
          textTransform: 'none',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
          }}
          >
            Add More
          </Button>

        <Button type="submit" variant="contained" sx={{ mt: 2, fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '100%', backgroundColor: '#03BBD2', borderRadius: '8px' }}>{goodsId ? 'Update Goods Type' : 'Save Goods Type'}</Button>
      </div>

      <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',

            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Box>

    
  );
};

export default GoodsTypeForm;
