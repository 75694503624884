import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, MenuItem, Select, FormControl, TextField, SelectChangeEvent, InputLabel, CircularProgress, Snackbar, SnackbarContent, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CompanyListHeading from './companyListHeading';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { fetchCompanies } from '../redux/company and model slice/companySlice';
import  { User } from '../company/userDetails';
import UserModal from '../company/modal';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Error from '../error';
import { AddButton } from '../../assets/button';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import ClearIcon from '@mui/icons-material/Clear';

const CompanyListMain = () => {
    const navigate = useNavigate();
    
    const dispatch = useDispatch<AppDispatch>();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<string>('name');
    const [openUserModal, setOpenUserModal] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false); 
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

    const [userData, setUserData] = useState({
      id:'',
      primary_phone: '',
      first_name: '',
      last_name: '',
      email: '',
      kyc_documents_links: [''],
      photourl: '',
      doj: '',
      role:'',
    })

    const companies = useSelector((state: RootState) => state.companies.companies);
    const getCompaniesStatus = useSelector((state: RootState) => state.companies.fetchCompaniesStatus);

  
    useEffect(() => {
      setLoading(true);
      dispatch(fetchCompanies({ search: searchTerm, page: currentPage, sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }))
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => setLoading(false));
    }, [dispatch,searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);

  
    const handleSort = (column: string) => {
      const newOrder = sortColumn === column && sortOrder === column ? `-${column}` : column;
      setSortColumn(column);
      setSortOrder(newOrder);
    };
    
  
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    };
  
    const handleEditClick = (row: any) => {
      handleSnackbar('We are redirecting you in editing mode.', 'info');
    setTimeout(() => {
      navigate(`/company-form/${row.id}`, { state: { companyDetails: row, id: row.id } });
    }, 2000);
      
      
    };
    
  
    const handleAddCompanyClick = () => {
      navigate('/company-form');
    };
    const handlePaginate = (pageNumber: number) => {
      setCurrentPage(pageNumber);
    };

    const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
      setItemsPerPage(event.target.value as number);
      setCurrentPage(1);
    };
    

    const handleUserUpdate = (updatedData: User)=>{
      setUserData(updatedData)
      setUsers((prevUsers) => {
        if (prevUsers) {
          return [...prevUsers, updatedData];
        } else {
          return [updatedData];
        }
      });
      handleSnackbar('User Added Successfully.', 'success');
      dispatch(fetchCompanies({}))
    }

    const handleAddUsersClick = (companyId: string) => {
      setSelectedCompanyId(companyId);
      setOpenUserModal(true);
    };

    const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
    const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };

    function formatDate(dateString: string): string {
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  
    return (
      <div style={{ width: '100vw', overflow: 'hidden' }}>
        <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
          <CompanyListHeading />
        </Box>

        
      
        <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
          
          <Paper sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            padding: '1.5rem',
            mb: '2rem',
            boxShadow: 2,
          }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Search"
                value={searchTerm}
                size="small"
                onChange={handleSearchChange}
                sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon color="action" />
                  )
                }}
                InputLabelProps={{
                  shrink: false
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', marginRight: '20px' }}>
                  <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>SHOW</Typography>
                  <FormControl sx={{ m: 1, minWidth: '40' }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    size="small"
                    IconComponent={ArrowDropDownIcon}
                    sx={{ height: '40px' }}
                    value={itemsPerPage} 
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                  </FormControl>
                  <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>ENTRIES</Typography>
                </div>
                <AddButton onClick={handleAddCompanyClick}>Add Company</AddButton>
              </div>
            </div>
  
            <TableContainer>
            {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                  <TableRow>
                    <TableCell align="left" onClick={() => handleSort('name')} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                    <div style={{ display: 'flex',alignItems: 'center' }}>
                      NAME
                       
                      <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                            <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'name' ? 'visible' : 'visible' }} />
                            <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === '-name' ? 'visible' : 'visible' }} />
                          </div>
                        
                      </div>
                    </TableCell>
                    <TableCell align="left" onClick={() => handleSort('role')} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                    <div style={{ display: 'flex',alignItems: 'center' }}>
                      ROLE
                       
                          <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                            <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'role' ? 'visible' : 'visible' }} />
                            <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === '-role' ? 'visible' : 'visible' }} />
                          </div>
                        
                      </div>
                    </TableCell>
                    <TableCell align="left" sx={{fontFamily:'Montserrat', fontSize: '10px', fontWeight: 700, lineHeight: '14px', letterSpacing:'1px', color:'#10383D'}}>EMAIL</TableCell>
                    <TableCell align="left" sx={{fontFamily:'Montserrat', fontSize: '10px', fontWeight: 700, lineHeight: '14px', letterSpacing:'1px', color:'#10383D'}}>PHONE</TableCell>
                    <TableCell align="left" sx={{fontFamily:'Montserrat', fontSize: '10px', fontWeight: 700, lineHeight: '14px', letterSpacing:'1px', color:'#10383D'}}>CREATED AT</TableCell>
                    <TableCell align="right" sx={{fontFamily:'Montserrat', fontSize: '10px', fontWeight: 700, lineHeight: '14px', letterSpacing:'1px', color:'#10383D'}}>TOTAL USER ADDED</TableCell>
                    
                    {/* <TableCell align="right"></TableCell> */}
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {getCompaniesStatus === STATUS.SUCESS && companies && companies.results && companies.results.length > 0 ? (
                  companies.results.map((row: any) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                      <TableCell component="th" scope="row" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
                       <div style={{display:'flex', alignItems:'center'}}> {row.name} <KeyboardArrowRightIcon fontSize='small' onClick={() => handleEditClick(row)}/> </div>
                      </TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.role}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.email}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.phone}</TableCell>
                      <TableCell align="center" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{formatDate(row.created_at)}</TableCell>
                      <TableCell align="right" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.users && row.users.length}</TableCell>
                      <TableCell align="right" sx={{fontFamily:'Montserrat', color:'#13282A', fontWeight:'400',fontSize:'14px', lineHeight:'24px', cursor:'pointer'}}>
                      <Button
                        onClick={() => handleAddUsersClick(row.id)}
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 600,
                          lineHeight: '16px',
                          letterSpacing: '2.5%',
                          textAlign: 'center',
                          color: '#03BBD2',
                          border: 'none',
                          width: '7rem',
                          textTransform: 'none',
                          '&:hover': {
                            border: 'none',
                            backgroundColor: 'none',
                          },
                        }}
                      >
                        Add Users
                      </Button>
                      
                  
                      </TableCell>
                    </TableRow>
                   ))
                   ) : (
                     <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                       <TableCell colSpan={8} >
                         <Error message="There are no companies added as of yet. You can add a company to see it here."/>
                       </TableCell>
                     </TableRow>
                   )}
                </TableBody>
              </Table>
              )}
            </TableContainer>
  
          
  
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}>  
              <Typography sx={{ color: '#10383D', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
            {getCompaniesStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getCompaniesStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, companies.count || 0) 
              : '-'}{' '}
            of {getCompaniesStatus === STATUS.SUCESS ? (companies.count || 0) : '-'}
          </Typography>
              </Typography>
              </div>
          <Button
            onClick={() => handlePaginate(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ChevronLeftIcon
                sx={{
                  color: 'black',
                  backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                  width: '32px',
                  height: '32px',
                }}
              />
            </div>
          </Button>
          <Button
            onClick={() => handlePaginate(currentPage + 1)}
            disabled={!companies.results || companies.results.length < itemsPerPage}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ChevronRightIcon
                sx={{
                  color: 'black',
                  backgroundColor: !companies.results || companies.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                  width: '32px',
                  height: '32px',
                }}
              />
            </div>
          </Button>
        </div>
          </Paper>
  
        </Box>
        <UserModal
        open={openUserModal}
        onClose={() => setOpenUserModal(false)}
        onUpdate={handleUserUpdate}
        users={users}
        companyId={selectedCompanyId} 
      />
<Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',

            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
      
      </div>

      
    );
  };

export default CompanyListMain