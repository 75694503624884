import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { getTruck } from '../redux/truck slice/addTruckSlice';
import { getTruckCount, selectTruckCount } from '../redux/truck slice/truckKpi';
import AllTable from './allTruckTable';
import AdminTruckTable from './adminTruckTable';
import UsersTable from './otherUsersTable';
import TruckListHeading from './truckListHeading';

const TruckMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [alignment, setAlignment] = useState('mac');

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const truckCount = useSelector(selectTruckCount);
  

  useEffect(() => {
    dispatch(getTruckCount({}));
  }, [dispatch]);

  const stats = [
    { title: 'ADMIN ADDED TRUCKS', value: truckCount.data[1]?.total_trucks || 0, change: -1, color: '#FFFFFF' },
    { title: 'MOTOR OWNER ADDED TRUCKS', value: truckCount.data[3]?.total_trucks || 0, change: 1, color: '#FFFFFF' },
    { title: 'TRANSPORTER ADDED TRUCKS', value: truckCount.data[2]?.total_trucks || 0, change: 2, color: '#FFFFFF' },
  ];

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <TruckListHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        <Paper elevation={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          padding: '24px',
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          mb: '2rem',
        }}>
          {/* Stats */}
          <Grid container alignItems="center" justifyContent="flex-start">
            {stats.map((stat, index) => (
              <React.Fragment key={index}>
              <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '8px 16px' }}>
              <Typography variant="subtitle2" component="p" sx={{ fontSize: '10px', fontWeight: '700', fontFamily: 'Montserrat' , color:'#10383D', lineHeight:'20px'}}>
                {stat.title}
              </Typography>
            
            { stat.title === 'ADMIN ADDED TRUCKS' ? (
            <Typography variant="h6" component="p" sx={{marginTop:'10px', fontSize: '40px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D', lineHeight:'32px' }}>
              {stat.value}
            </Typography>
          ) : (
            <Typography variant="h6" component="p" sx={{ marginTop:'10px', fontSize: '24px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D' , lineHeight:'24px'}}>
              {stat.value}
            </Typography>
          )}
              </Grid>
              {index !== stats.length - 1 && (
                <Divider orientation="vertical" flexItem sx={{ width: '1px', bgcolor: '#DDDDDD', mx: 2 }} />
              )}
            </React.Fragment>
            ))}
          </Grid>
        </Paper>
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <ToggleButtonGroup
              color="standard"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
            >
              <ToggleButton
                value="mac"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'mac' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'mac' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value="web"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'web' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'web' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Admin
              </ToggleButton>
              <ToggleButton
                value="android"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'android' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'android' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Other Users
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          
          {alignment === 'mac' && (
            <div>
              <AllTable/>
              
            </div>
          )}

          {alignment === 'web' && (
            <div>
              <AdminTruckTable/>
              
            </div>
          )}

          {alignment === 'android' && (
            <div>
              <UsersTable/>
              
            </div>
          )}
        </Paper>
      </Box>
    </div>
  );
                    }
  export default TruckMain;   
