import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface HotGoodsState {
  hotGoods: any[]; 
  getHotGoodsStatus: STATUS;
}

const initialState: HotGoodsState = {
  hotGoods: [],
  getHotGoodsStatus: STATUS.NOT_STARTED,
};

export const getHotGoods = createAsyncThunk(
  'hotGoods/getHot',
  async (_, thunkAPI) => {
    try {
      const response = await api.get('goodstype/hot_goods');
      const { status, data } = response;
      if (status === 200) {
        console.log({ data });
        return data.hot_goods; 
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error:any) {
      console.error('Error fetching hot goods:', error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const hotGoodsSlice = createSlice({
  name: 'hotGoods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHotGoods.pending, (state, action) => {
        state.getHotGoodsStatus = STATUS.PENDING;
        console.log('GET HOT GOODS PENDING');
      })
      .addCase(getHotGoods.fulfilled, (state, action) => {
        state.hotGoods = action.payload;
        state.getHotGoodsStatus = STATUS.SUCESS; // Correct typo
        console.log('GET HOT GOODS SUCCESS');
      })
      .addCase(getHotGoods.rejected, (state, action) => {
        state.getHotGoodsStatus = STATUS.FAILED;
        console.log('GET HOT GOODS FAILED');
      });
  },
});

export default hotGoodsSlice.reducer;
