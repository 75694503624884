import { Typography } from '@mui/material';
import React from 'react';
import noData from '../assets/img/noData.png';

interface Props {
  message: string;
}

const Error: React.FC<Props> = ({ message }) => {
  return (
    <>
      <img style={{ width: '128px', height: '140px', marginLeft: '45%' }} src={noData} alt="No Data" />
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16', fontWeight: '600', lineHeight: '24px', textAlign: 'center' }}>Nothing here yet!</Typography>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14', fontWeight: '100', lineHeight: '20px', textAlign: 'center', marginTop: '10px' }}>{message}</Typography>
    </>
  );
};

export default Error;
