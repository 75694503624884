import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

// Interfaces for type definitions
interface CreateBranchPayload {
    id: string;
    name: string;
    gstin: string;
    phone: string;
    email: string;
    kyc_documents: string[];
}

export interface GoodsState {
    branch:{
        results: CreateBranchPayload[];
      }
      getBranchStatus: STATUS;
      deleteBranchStatus: STATUS;
      updateBranchStatus: STATUS;
}

const initialState: GoodsState = {
    branch:{
        results: [],
      },
      getBranchStatus: STATUS.NOT_STARTED,
      deleteBranchStatus: STATUS.NOT_STARTED,
      updateBranchStatus: STATUS.NOT_STARTED,
};

export const createBranchType = createAsyncThunk(
  'company/branch/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post('company/branch/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getBranchType = createAsyncThunk(
  'company/branch/get',
  async (payload:any, thunkAPI) => {
    const response = await api.get('company/branch/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateBranchType = createAsyncThunk(
  'company/branch/patch',
  async (payload: any, thunkAPI) => {
   
    const response = await api.patch('company/branch/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const deleteBranchType = createAsyncThunk(
  'company/branch/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('company/branch/', { data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload: payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createBranchType.fulfilled, (state, action) => {
        state.branch.results.push(action.payload);
      })
      .addCase(getBranchType.pending, (state, action) => {
        state.getBranchStatus = STATUS.PENDING;
        console.log('BRANCH TYPES PENDING');
      })
      .addCase(getBranchType.fulfilled, (state, action) => {
        state.branch = action.payload;
        state.getBranchStatus = STATUS.SUCESS;
        console.log('GET BRANCH TYPES SUCCESS');
      })
      .addCase(getBranchType.rejected, (state, action) => {
        state.getBranchStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
      .addCase(deleteBranchType.pending, (state, action) => {
        state.deleteBranchStatus = STATUS.PENDING;
        console.log('DELETE GOODS TYPE PENDING');
      })
      .addCase(deleteBranchType.fulfilled, (state, action) => {
        const deletedId = action.payload.requestPayload.id;
        state.branch.results = state.branch.results.filter((item) => item.id !== deletedId);
        state.deleteBranchStatus = STATUS.SUCESS;
        console.log('DELETE GOODS TYPE SUCCESS');
      })
      .addCase(deleteBranchType.rejected, (state, action) => {
        state.deleteBranchStatus = STATUS.FAILED;
        console.log('DELETE GOODS TYPE FAILED', action.payload);
      })
      .addCase(updateBranchType.pending, (state, action) => {
        state.updateBranchStatus = STATUS.PENDING;
        console.log('UPDATE GOODS TYPE PENDING');
      })
      .addCase(updateBranchType.fulfilled, (state, action) => {
        const updatedGoodsType = action.payload;
        const index = state.branch.results.findIndex((item) => item.id === updatedGoodsType.id);
        if (index !== -1) {
          state.branch.results[index] = updatedGoodsType;
        }
      })
      .addCase(updateBranchType.rejected, (state, action) => {
        state.updateBranchStatus = STATUS.FAILED;
        console.log('UPDATE GOODS TYPE FAILED', action.payload);
      })
  },
});

export default branchSlice.reducer;
