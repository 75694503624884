import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import { TextField, Button, Typography, Box, IconButton, Modal, Paper, TableCell, TableRow, TableHead, TableBody, Table, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useParams } from 'react-router-dom';
import { createBranchType, deleteBranchType, getBranchType, updateBranchType } from '../redux/company and model slice/branchModelSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store/appStore';
import { updateUserModalType } from '../redux/company and model slice/userModelSlice';
import DeleteBranchModal from './deleteBranchModal';

interface FormState {
  id: string;
  name: string;
  gstin: string;
  phone: string;
  email: string;
  kyc_documents_links: string[];
}

export interface Branch {
  id: string;
  name: string;
  gstin: string;
  phone: string;
  email: string;
  kyc_documents_links: string[];
}

interface BranchDetailsProps {
  onUpdate: (branchData: FormState) => void;
  onAdd: (branchData: FormState) => void;
  onDelete: (id: string) => void;
  branches: Branch[];
  isEditEnable: boolean;
}

const BranchDetails: React.FC<BranchDetailsProps> = ({ onUpdate, onAdd, onDelete, branches, isEditEnable }) => {
  const location = useLocation();
  const { companyDetails: initialBranchDetails, id: branchId } = location.state || { companyDetails: null, id: '' };

  const [branchDetails, setBranchDetails] = useState<FormState>({
    name: '',
    id: '',
    gstin: '',
    phone: '',
    email: '',
    kyc_documents_links: [''],
  });

  useEffect(() => {
    if (initialBranchDetails) {
      setBranchDetails(initialBranchDetails);
    }
  }, [initialBranchDetails]);

  const [openBranchModal, setOpenBranchModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<File[]>([]); 
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);
  const [tableVisible, setTableVisible] = useState(false);
  const [errors, setErrors] = useState<Partial<FormState>>({});
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editBranchId, setEditBranchId] = useState<string>('');
  const [editBranch, setEditBranch] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<'add' | 'update'>('add');
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [branchIdToDelete, setBranchIdToDelete] = useState(""); 

  const cancelDelete = () => {
    setShowDeleteModal(false); 
    
  };
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    
    if (branchIdToDelete && isEditEnable) {
      await dispatch(deleteBranchType({ id: branchIdToDelete }));
      dispatch(getBranchType({}));     
    }

    if (deleteId) { 
      onDelete(deleteId);
      setDeleteId(null); 
    }
  };
  
  const handleDeleteBranch = (id: string) => {
    setShowDeleteModal(true);
    setDeleteId(id);

    if(isEditEnable){
      setBranchIdToDelete(id);
    }
  };

  const handleAddBranch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
    if (editMode && editBranchId) {
      const updatedBranch: Branch = {
        ...branchDetails,
        id: editBranchId,
      };

      onUpdate(updatedBranch);
      setEditMode(false);
      setEditBranchId('');
    } else {
      const newBranch: Branch = {
        ...branchDetails,
        id: generateId(), 
      };

      onAdd(newBranch);
    }

    setOpenBranchModal(false);
    setErrors({});
    resetBranchDetails();
    
  }
  };

  const dispatch = useDispatch<AppDispatch>()

  const handleSubmit = () => {
    if (isEditEnable) {
      const payload = {
        id: branchDetails.id 
      };
      const otherFields = Object.entries(branchDetails).reduce((acc, [key, value]) => {
        if (key !== 'id' && value !== "" && value !== null && value !== undefined) {
          (acc as any)[key] = value;
        }
        return acc;
      }, {});
      Object.assign(payload, otherFields);
      
      dispatch(updateBranchType(payload));
      resetBranchDetails();
      setOpenBranchModal(false);
    } 
  
  };
  const {id}= useParams()

  const handleAdd = () => {
    
    if (isEditEnable) {
      const payload = {
        
          "company_id": id,
          "name": branchDetails.name,
          "email": branchDetails.email,
          "phone": branchDetails.phone,
          "kyc_documents_links" : [
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf", 
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf", 
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
          ],
          "gstin": branchDetails.gstin
      
      };

      dispatch(createBranchType(payload));
      resetBranchDetails()
      //setOpenBranchModal(false)
    } 
  
  };


  const validateForm = (): boolean => {
    let formIsValid = true;
    const newErrors: Partial<FormState> = {};

    if (!branchDetails.name.trim()) {
      newErrors.name = 'First Name is required';
      formIsValid = false;
    }

    if (!branchDetails.gstin.trim()) {
      newErrors.gstin = 'Last Name is required';
      formIsValid = false;
    }


    if (!branchDetails.email.trim()) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(branchDetails.email)) {
      newErrors.email = 'Email is not valid';
      formIsValid = false;
    }

    if (!branchDetails.phone.trim()) {
      newErrors.phone = 'Phone Number is required';
      formIsValid = false;
    } else if (!/^\d{10}$/.test(branchDetails.phone)) {
      newErrors.phone = 'Phone Number must be 10 digits';
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleEditBranch = (branch: Branch) => {
    setModalAction('update');
    setEditMode(true);
    setEditBranchId(branch.id);
    setBranchDetails(branch);
    setOpenBranchModal(true);
  };



  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setBranchDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...files]);
      const urls = files.map((file) => URL.createObjectURL(file));
      setPhotoUrls((prevUrls) => [...prevUrls, ...urls]);
    }
  };

  const deleteImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newUrls = [...photoUrls];
    newUrls.splice(index, 1);
    setPhotoUrls(newUrls);
  };

  const generateId = () => {
    
    return Math.random().toString(36).substr(2, 9);
  };

  const resetBranchDetails = () => {
    setBranchDetails({
      id: '',
      name: '',
      gstin: '',
      phone: '',
      email: '',
      kyc_documents_links: [''],
    });
  };

  useEffect(() => {
    if (branches.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [branches]);

  const handlePhoneFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
  
    if (name === "phone" && value.length > 10) {
      return;
    }
  
    setBranchDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem', marginTop: '2rem' }}>Branches</Typography>
      {tableVisible ? null : (
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Please click the button below to add branch details.</Typography>
      )}

      <Button
        variant="contained"
        onClick={() => {
          setEditMode(false);
          setOpenBranchModal(true);
          resetBranchDetails()
        }}
        startIcon={<AddIcon />}
        sx={{ mt: 2, fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '144px', backgroundColor: '#03BBD2', borderRadius: '8px' }}
      >
        Add Branch
      </Button>

      <Modal open={openBranchModal} onClose={() => setOpenBranchModal(false)}>
        <Paper sx={{ position: 'absolute', minWidth: '640px', bgcolor: 'background.paper', boxShadow: 24, p: 4, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem' }}>{modalAction === 'update' ? 'Edit Branch' : 'Add Branch'}</Typography>
            <CloseIcon onClick={() => setOpenBranchModal(false)} sx={{ cursor: 'pointer' }} />
          </div>
          <Divider />
          <form onSubmit={handleAddBranch}>
            <div style={{ display: 'flex', marginTop: '2rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px' }}>Branch Name</Typography>
                <TextField
                  placeholder="Branch Name"
                  variant="outlined"
                  sx={{ width: '296px', marginRight: '10px' }}
                  name="name"
                  size='small'
                  value={branchDetails.name}
                  onChange={handleFieldChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px' }}>GSTIN</Typography>
                <TextField
                  placeholder="eg. 22AAAAA0000A1Z5"
                  variant="outlined"
                  sx={{ width: '296px' }}
                  name="gstin"
                  size='small'
                  value={branchDetails.gstin}
                  onChange={handleFieldChange}
                  error={!!errors.gstin}
                  helperText={errors.gstin}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px', marginTop: '10px' }}>Email ID</Typography>
                <TextField
                  placeholder="Enter email ID"
                  variant="outlined"
                  sx={{ width: '296px', marginRight: '10px' }}
                  name="email"
                  size='small'
                  value={branchDetails.email}
                  onChange={handleFieldChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '10px', marginTop: '10px' }}>Phone Number</Typography>
                <TextField
                  type="tel"
                  autoComplete="tel"
                  placeholder="Enter phone number"
                  name="phone"
                  sx={{ width: '296px'}}
                  value={branchDetails.phone}
                  onChange={handlePhoneFieldChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  size="small"
                  maxLength={10}
                      {...({} as any)}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>KYC Documents</Typography>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  multiple 
                />
                <Button
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '16px',
                      letterSpacing: '0.025em',
                      textAlign: 'center',
                      color: '#03BBD2',
                      border: 'none',
                      textTransform: 'none',
                      mt: '10px',
                      ml:'none',
                      mr:'85%',
                      '&:hover': {
                        border: 'none',
                        backgroundColor: 'none',
                      },
                    }}
                    onClick={() => fileInputRef.current?.click()}
                    variant="outlined"
                    type="button"
                >
                  Upload <FileUploadIcon fontSize='small' />
                </Button>
              
              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop:'20px' }}>
              {photoUrls.map((url, index) => (
                <div key={index} style={{ marginRight: '10px', marginBottom: '10px', position:'relative' }}>
                  <div style={{display:'flex', alignItems:'center', marginRight:'20px'}}>
                  <img src={url} alt="Uploaded File" style={{ width: '50px', height: '50px' }} />
                  <div style={{ position: 'absolute', top: '-10px', left: '35px', backgroundColor: 'black', borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <CloseIcon sx={{ color: 'white' }} fontSize='small' onClick={() => deleteImage(index)} />
                  </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
            </div>

            {modalAction === 'update' ? (
              <>
                {editMode && isEditEnable ? (
              <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px', backgroundColor: '#03BBD2', borderRadius: '8px', textTransform: 'none' }}>
                Update Branch
              </Button>
            ) : (
              editMode && !isEditEnable ? (
                <Button type='submit' variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px', backgroundColor: '#03BBD2', borderRadius: '8px', textTransform: 'none' }}>
                  Update Branch
                </Button>
              ) : (
                <Button type='submit' variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px', backgroundColor: '#03BBD2', borderRadius: '8px', textTransform: 'none' }}>
                  Add Branch
                </Button>
              )
            )}

              </>
            ) : (
              <>
         {isEditEnable  ? (
      <Button onClick={() => { setOpenBranchModal(false); handleAdd()}} variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px', backgroundColor: '#03BBD2', borderRadius: '8px', textTransform: 'none' }}>
        Add Branch
      </Button>
    ) : (
      <Button type='submit' variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px', backgroundColor: '#03BBD2', borderRadius: '8px', textTransform: 'none' }}>
        Add Branch
      </Button>
    )}
  </>
)}

          </form>
        </Paper>
      </Modal>

      {tableVisible  && branches.length > 0 && (
        <Box mt={4}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
              <TableRow>
                <TableCell>Branch Name</TableCell>
                <TableCell>GSTIN</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branches.map((branch: Branch) => (
                <TableRow key={branch.id}>
                  <TableCell>{branch.name}</TableCell>
                  <TableCell>{branch.gstin}</TableCell>
                  <TableCell>{branch.phone}</TableCell>
                  <TableCell>{branch.email}</TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>
                      <EditNoteOutlinedIcon onClick={() => handleEditBranch(branch)}/>
                      <DeleteOutlineOutlinedIcon sx={{ color: '#C00F00' }} onClick={() => handleDeleteBranch(branch.id)}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <DeleteBranchModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
          </Table>

        </Box>
      )}
    </>
  );
};

export default BranchDetails;
