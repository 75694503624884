export interface FormState {
    checklist_id: string;
    selectedChecklistIds: string[]; 
  }
  
  const initialState: FormState = {
    checklist_id: '',
    selectedChecklistIds: [],
  };
  
  const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';
  export const UPDATE_SELECTED_CHECKLIST_IDS = 'UPDATE_SELECTED_CHECKLIST_IDS';
  
  export const updateSelectedChecklistIds = (selectedChecklistIds: string[]) => ({
    type: UPDATE_SELECTED_CHECKLIST_IDS,
    payload: selectedChecklistIds,
  });
  
  export const updateFormState = (ChecklistId: string) => ({
    type: UPDATE_FORM_STATE,
    payload: { checklist_id: ChecklistId },
  });
  
  
  const checklistformReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'UPDATE_FORM_STATE':
        return { ...state, ...action.payload };
      case UPDATE_SELECTED_CHECKLIST_IDS:
        return { ...state, selectedChecklistIds: action.payload }; 
      default:
        return state;
    }
  };
  
  
  export default checklistformReducer;
  