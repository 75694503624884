import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateTruckPayload {
  id: string;
  company_id: string;
  company: {
    id: string;
    name: string;
    role: string;
  };
  truck_type_id: string;
  truck_type_tyre_id: string;
  truck_weight_id: string;
  truck_type: {
    tyres: any;
    id: string;
    name: string;
    photo_url: string;
  };
  truck_type_tyre: {
    id: string;
    truck_type_id: string;
    no_of_tyres: number;
  };
  truck_weight: {
    id: string;
    truck_type_id: string;
    truck_type_tyre_id: string;
    weight: number;
    unit: string;
  };
  truck_number: string;
  registration_date: string;
  registration_type: string;
  fuel_consumption: number;
  chassis: string;
  tank_size: number;
  maintenance_cost: number;
  created_at: string;
  updated_at: string;
}


interface TruckTypeState {
  trucks: {
    count: number | undefined;
    results: CreateTruckPayload[];
  }
  error: string | null;
  createStatus: STATUS;
  deleteStatus: STATUS;
  updateStatus: STATUS;
  getStatus: STATUS;
  
}

const initialState: TruckTypeState = {
  trucks: { 
    count: undefined,
    results: [] },
  error: null,
  createStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  
};

export const createTruck = createAsyncThunk(
  'truck/POST',
  async (payload: any, thunkAPI) => {
    const response = await api.post('truck/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
)
interface GetTruckPayload {
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: string;
  truckTypeId?: string;
  truckTypeTyreId?: string;
  truckWeightId?: string;
}


export const getTruck = createAsyncThunk(
  'truck/get',
  async (payload: GetTruckPayload = {}, thunkAPI) => {
    const { search, page, pageSize, sort, order, truckTypeId, truckTypeTyreId, truckWeightId } = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(truckTypeId && { truck_type_id: truckTypeId }),
      ...(truckTypeTyreId && { truck_type_tyre_id: truckTypeTyreId }),
      ...(truckWeightId && { truck_weight_id: truckWeightId }),
    }).toString();
    const response = await api.get(`truck/?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);



export const updateTruck = createAsyncThunk(
  'truck/patch',
  async (payload: any, thunkAPI) => {
    const response = await api.patch(`truck/`, payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const deleteTruck = createAsyncThunk(
  'truck/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('truck/', { data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload: payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const addtruckSlice = createSlice({
  name: 'trucks',
  initialState,
  reducers: {
    setCreateTrucksStatus(state,action){
      state.createStatus = action.payload
    },
    setUpdateTrucksStatus(state,action){
      state.updateStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTruck.pending, (state) => {
        state.createStatus = STATUS.PENDING;
      })
      .addCase(createTruck.fulfilled, (state, action) => {
        console.log('Create Truck Fulfilled:', action.payload); 
        state.trucks = action.payload; 
        state.createStatus = STATUS.SUCESS; 
      })
      .addCase(createTruck.rejected, (state, action) => {
        console.error('Create Truck Rejected:', action.error.message ?? 'An error occurred'); 
        state.createStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(getTruck.pending, (state) => {
        state.getStatus = STATUS.PENDING;
      })
      .addCase(getTruck.fulfilled, (state, action) => {
        console.log('Get Truck Fulfilled:', action.payload); 
        state.trucks = action.payload; 
        state.getStatus = STATUS.SUCESS; 
      })
      .addCase(getTruck.rejected, (state, action) => {
        console.error('Get Truck Rejected:', action.error.message ?? 'An error occurred'); 
        state.getStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(updateTruck.pending, (state) => {
        state.updateStatus = STATUS.PENDING;
      })
      .addCase(updateTruck.fulfilled, (state, action) => {
        console.log('Update Truck Fulfilled:', action.payload); 
        const updatedTruck = action.payload;
        state.trucks.results = state.trucks.results.map((truck) =>
          truck.id === updatedTruck.id ? updatedTruck : truck
        );
        state.updateStatus = STATUS.SUCESS;
      })
      .addCase(updateTruck.rejected, (state, action) => {
        console.error('Update Truck Rejected:', action.error.message ?? 'An error occurred'); 
        state.updateStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(deleteTruck.pending, (state) => {
        state.deleteStatus = STATUS.PENDING;
      })
      .addCase(deleteTruck.fulfilled, (state, action) => {
        console.log('Delete Truck Fulfilled:', action.payload); 
        const deletedId = action.payload.requestPayload.id;
        state.trucks.results = state.trucks.results.filter((item) => item.id !== deletedId);
        state.deleteStatus = STATUS.SUCESS;
      })
      .addCase(deleteTruck.rejected, (state, action) => {
        console.error('Delete Truck Rejected:', action.error.message ?? 'An error occurred'); 
        state.deleteStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
      
  },
});

export const {setCreateTrucksStatus, setUpdateTrucksStatus} = addtruckSlice.actions
export default addtruckSlice.reducer;
