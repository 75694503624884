import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
interface GoodsDetails {
  id:string,
  goods_name: string;
  hsn_code: string;
  gst_tax_type: 'EXEMPTED' | 'NONEXEMPTED';
}

interface GoodsValue {
  id: string;
  goods_name_id: string;
  value: number;
  value_unit: 'TON' | 'KG' | 'QUINTAL' | 'RS' | 'LITRE';
}

interface ShortAllowance {
  id: string;
  goods_type_id: string;
  allowance_type: 'DEPENDANT' | 'NON_DEPENDANT';
  short_allowance: string|number;
  short_allowance_unit: 'KG' | 'PERCENTAGE';
  weight_range_start: string|number;
  weight_range_end: string|number

}

export interface CreateGoodsTypePayload {
  shortages: any;
  goods_name_id: string;
  id: string;
  goods_details: GoodsDetails;
  goods_value: GoodsValue;
  short_allowances: ShortAllowance[];
}

export interface GoodsState {
  activeGoodsCount: number | null;
  totalGoodsCount: number | null;
  goods: {
    count: number | undefined;
    results: CreateGoodsTypePayload[];
  };
  error: string | null;
  getGoodsStatus: STATUS;
  deleteGoodsStatus: STATUS;
  updateGoodsStatus: STATUS;
  createGoodsStatus: STATUS;
}

const initialState: GoodsState = {
  activeGoodsCount: null,
  totalGoodsCount: null,
  goods: { 
    count: undefined,
    results: [] },
  error: null,
  
  getGoodsStatus: STATUS.NOT_STARTED,
  deleteGoodsStatus: STATUS.NOT_STARTED,
  updateGoodsStatus: STATUS.NOT_STARTED,
  createGoodsStatus: STATUS.NOT_STARTED,
};

export const createGoodsType = createAsyncThunk(
  'goods/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post('goodstype/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);
interface GetGoodsPayload {
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: string;
  goods_name?:string;
}

export const getGoodsType = createAsyncThunk(
  'goods/get',
  async (payload: GetGoodsPayload = {}, thunkAPI) => {
    const { search, page, pageSize, sort, order,goods_name} = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(goods_name && { goods_name }),
    }).toString();
    const response = await api.get(`goodstype/?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateGoodsType = createAsyncThunk(
  'goods/put',
  async (payload: any, thunkAPI) => {
   
    const response = await api.put(`goodstype/`, payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const deleteGoodsType = createAsyncThunk(
  'goods/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('goodstype/', { data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload: payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const goodsSlice = createSlice({
  name: 'goods',
  initialState,
  reducers: {
    setCreateGoodsStatus(state,action){
      state.createGoodsStatus = action.payload
    },
    setUpdateGoodsStatus(state,action){
      state.updateGoodsStatus = action.payload
    },
    setDeleteGoodsStatus(state,action){
      state.deleteGoodsStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGoodsType.pending, (state, action) => {
        state.createGoodsStatus = STATUS.PENDING;
      })
      .addCase(createGoodsType.fulfilled, (state, action) => {
        state.goods.results.push(action.payload);
        state.createGoodsStatus = STATUS.SUCESS;
      })
      .addCase(createGoodsType.rejected, (state, action) => {
        state.createGoodsStatus = STATUS.FAILED;
      })
      .addCase(getGoodsType.pending, (state, action) => {
        state.getGoodsStatus = STATUS.PENDING;
      })
      .addCase(getGoodsType.fulfilled, (state, action) => {
        state.goods = action.payload;
        state.getGoodsStatus = STATUS.SUCESS;
        console.log('GET GOODS TYPES SUCCESS');
      })
      .addCase(getGoodsType.rejected, (state, action) => {
        state.getGoodsStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
      .addCase(deleteGoodsType.pending, (state, action) => {
        state.deleteGoodsStatus = STATUS.PENDING;
        console.log('DELETE GOODS TYPE PENDING');
      })
      .addCase(deleteGoodsType.fulfilled, (state, action) => {
        const deletedId = action.payload.requestPayload.id;
        state.goods.results = state.goods.results.filter((item) => item.id !== deletedId);
        state.deleteGoodsStatus = STATUS.SUCESS;
        console.log('DELETE GOODS TYPE SUCCESS');
      })
      .addCase(deleteGoodsType.rejected, (state, action) => {
        state.deleteGoodsStatus = STATUS.FAILED;
        console.log('DELETE GOODS TYPE FAILED', action.payload);
      })
      .addCase(updateGoodsType.pending, (state, action) => {
        state.updateGoodsStatus = STATUS.PENDING;
        console.log('UPDATE GOODS TYPE PENDING');
      })
      .addCase(updateGoodsType.fulfilled, (state, action) => {
        state.goods = action.payload;
        state.updateGoodsStatus = STATUS.SUCESS;
      })
      .addCase(updateGoodsType.rejected, (state, action) => {
        state.updateGoodsStatus = STATUS.FAILED;
        console.log('UPDATE GOODS TYPE FAILED', action.payload);
      })
  },
});

export const {setCreateGoodsStatus, setUpdateGoodsStatus, setDeleteGoodsStatus} = goodsSlice.actions
export default goodsSlice.reducer;
