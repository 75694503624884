import {  Button, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { createReceiver, getReceiver } from '../redux/receiver/receiver';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';

export interface FormState {
  id:string;
  receiver: string;
  name: string;
  email: string;
  address: string;
  phone: string;
  note: string;
}

const CustomerForm = () => {
  const dispatch = useDispatch<AppDispatch>()

  const companyDetails: FormState = useSelector((state: RootState) => state.receriverFormState);
  const receiver = useSelector((state:RootState)=> state.receiver.receiver)

  useEffect(()=>{
    dispatch(getReceiver({}));
  },[dispatch])
  
  const handleFieldChange = (event: any) => {
    // Dispatch action to update form state in Redux store
    dispatch({ type: 'UPDATE_FORM_STATE', payload: { [event.target.name]: event.target.value } });
  };

  const handleSelectFieldChange = (event: any, fieldName: keyof FormState) => {
    // Dispatch action to update form state in Redux store
    dispatch({ type: 'UPDATE_FORM_STATE', payload: { [fieldName]: event.target.value } });
  };
  
  const isFormFilled = Object.values(companyDetails).every(value => {
    return value !== '';
  });
  

      useEffect(() => {
          const payload = {
            id: companyDetails.id,
            name: companyDetails.name,
            email: companyDetails.email,
            address: companyDetails.address,
            pincode: '321001',
            phone: companyDetails.phone,
          };
          dispatch(createReceiver(payload));
      }, [companyDetails, dispatch]);
    
  return (
    <>
        <div style={{display:'flex'}}>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Receiver</Typography>
          <Select
            value={companyDetails.receiver}
            onChange={(e) => handleSelectFieldChange(e,'receiver')}
            fullWidth
            displayEmpty
            size='small'
            name='id'
          >
            <MenuItem value="" disabled>
              Select receiver
            </MenuItem>
            {receiver.results.map((receiver: any) => (
            <MenuItem key={receiver.id} value={receiver.id}>
              {receiver.name}
            </MenuItem>
          ))}
            {/* <MenuItem value="Narinder">
              Narinder
            </MenuItem> */}
          </Select>
        </div>
        </div>

        <div style={{display:'flex'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Customer/Company Name</Typography>
          <TextField
              placeholder="Enter name"
              name="name"
              value={companyDetails.name}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Email ID</Typography>
        <TextField
              placeholder="Enter email ID"
              name="email"
              value={companyDetails.email}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Customer Address</Typography>
        <TextField
              placeholder="Enter address"
              name="address"
              value={companyDetails.address}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
      </div>
        </div>

        <div style={{display:'flex'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Phone</Typography>
          <TextField
              placeholder="Enter phone number"
              name="phone"
              value={companyDetails.phone}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '65.5aaa%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Note</Typography>
        <TextField
              placeholder="Enter note here"
              name="note"
              value={companyDetails.note}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
        </div>
        </div>
    </>
  )
}

export default CustomerForm