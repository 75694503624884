import React, { useState, ChangeEvent, FormEvent, useEffect} from 'react';
import axios from 'axios';
import { TextField, Typography, MenuItem, Select, SelectChangeEvent, FormControl, FormHelperText } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface FormState {
  address_line_1: string;
  address_line_2: string;
  state: string;
  city: string;
  zipcode: string;
}

interface State {
  name: string;
  iso2: string;
}


interface AddressDetailsProps {
  onUpdate: (formData: FormState) => void; 
  formSubmit: boolean;
  setFormSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddressDetails: React.FC<AddressDetailsProps> = ({  onUpdate, formSubmit, setFormSubmit  }) => {
  const location = useLocation();
  
  const { companyDetails: initialGoodsDetails, id: goodsId } = location.state || { companyDetails: null, id: '' };
  
  const [companyDetails, setCompanyDetails] = useState<FormState>({
    address_line_1: '',
    address_line_2: '',
    state: '',
    city: '',
    zipcode: '',
  });

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [errors, setErrors] = useState<Partial<FormState>>({});
  const [states, setStates] = useState<State[]>([]);

  const [cities, setCities] = useState<string[]>([]);
  useEffect(() => {
    console.log("Running useEffect in AddressDetails component");
    console.log("Initial goods details:", initialGoodsDetails);
    
    if (initialGoodsDetails) {
      setCompanyDetails((prevDetails) => ({
        ...prevDetails,
        ...initialGoodsDetails,
      }));
      
      if (initialGoodsDetails.state) {
        console.log("Initial goods details state:", initialGoodsDetails.state);
        
        const selectedState: any = states.find(state => state.name === initialGoodsDetails.state);
        console.log("Selected state:", selectedState);
        
        if (selectedState) {
          setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            city: initialGoodsDetails.city, 
          }));
          console.log("Fetching cities based on state:", selectedState.iso2);
          fetchCities(selectedState.iso2); 
        }
      }
    }
  }, []);
  
  
  // Other useEffect hooks remain the same
  
  
  
  

  const API_KEY = 'YlBWMTBQdkNuNnVzZWM2S0F2TWtyUmtwT1BvbzFrV0VaY2RoUzVuZw==';

  const fetchStatesData = async () => {
    try {
      const response = await axios.get<State[]>(`https://api.countrystatecity.in/v1/countries/IN/states`, {
        headers: {
          'X-CSCAPI-KEY': API_KEY,
        },
      });
      const statesData: State[] = response.data; 
      return statesData;
    } catch (error) {
      console.error('Error fetching states data:', error);
      return []; 
    }
  };

useEffect(() => {
  fetchStatesData()
    .then(data => setStates(data))
    .catch(error => console.error('Error setting states data:', error));
}, []);

const fetchCities = async (stateIso2: string) => {
  try {
    const response = await axios.get(`https://api.countrystatecity.in/v1/countries/IN/states/${stateIso2}/cities`, {
      headers: {
        'X-CSCAPI-KEY': API_KEY,
      },
    });
    const citiesData = response.data.map((city: { name: string }) => city.name);
    setCities(citiesData);
  } catch (error) {
    console.error('Error fetching cities:', error);
  }
};


  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    onUpdate({
      ...companyDetails,
      [name]: value,
    });
  };
  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
    onUpdate({
      ...companyDetails,
      [fieldName]: value,
    });
    if (fieldName === 'state') {
      const selectedState: any = states.find(state => state.name === value);
      if (selectedState) {
        fetchCities(selectedState.iso2); 
      }
    }
  };
  useEffect(() => {
    if (formSubmit) {
      const isValid = validateForm();
      if (isValid) {
        onUpdate(companyDetails);
      } else {
        setFormSubmitted(false);
      }
    }
  }, [formSubmit]);

  const validateForm = (): boolean => {
    let formIsValid = true;
    const newErrors: Partial<FormState> = {};

    if (!companyDetails.address_line_1.trim()) {
      newErrors.address_line_1 = 'Address Line 1 is required';
      formIsValid = false;
    }

    if (!companyDetails.address_line_2.trim()) {
      newErrors.address_line_2 = 'Address Line 2 is required';
      formIsValid = false;
    }

    if (!companyDetails.state.trim()) {
      newErrors.state = 'State is required';
      formIsValid = false;
    }

    if (!companyDetails.city.trim()) {
      if (window.location.href.endsWith('company-form')) {
        newErrors.city = 'City is required';
      }
      formIsValid = false;
    }

    if (!companyDetails.zipcode.trim()) {
      newErrors.zipcode = 'Pin Code is required';
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };
  
  return (
    <>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem', marginTop:'2rem' }}>Address Details</Typography>
      <div>
        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width:'55%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Address Line 1</Typography>
            <TextField
              placeholder="Enter Address Line 1"
              name="address_line_1"
              value={companyDetails.address_line_1}
              onChange={handleFieldChange}
              error={!!errors.address_line_1}
              helperText={errors.address_line_1}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' , width:'55%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Address Line 2</Typography>
            <TextField
              placeholder="Enter Address Line 2"
              name="address_line_2"
              value={companyDetails.address_line_2}
              onChange={handleFieldChange}
              error={!!errors.address_line_2}
              helperText={errors.address_line_2}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>
        </div>


        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' , width:'36%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>State</Typography>
            
            <Select
              value={companyDetails.state}
              onChange={(e) => handleSelectFieldChange(e, 'state')}
              fullWidth
              displayEmpty
              size='small'
              error={!!errors.state}
            >
              <MenuItem value="" disabled>
                Select State
              </MenuItem>
              {states.map((state, index) => (
                <MenuItem key={index} value={state.name}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
            {errors.state && ( 
              <FormHelperText error>{errors.state}</FormHelperText>
            )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width:'36%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>City</Typography>
            
            <Select
              value={companyDetails.city}
              onChange={(e) => handleSelectFieldChange(e, 'city')}
              fullWidth
              displayEmpty
              size='small'
              disabled = {!companyDetails.state}
              error={!!errors.city}
            >
              <MenuItem value="" disabled>
                Select City
              </MenuItem>
              {cities.map((city, index) => (
                <MenuItem key={index} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
            {errors.city && ( 
            <FormHelperText error>{errors.city}</FormHelperText>
          )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width:'36%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Pin Code</Typography>
            <TextField
              placeholder="Enter Pin Code"
              name="zipcode"
              value={companyDetails.zipcode}
              onChange={handleFieldChange}
              error={!!errors.zipcode}
              helperText={errors.zipcode}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressDetails;
