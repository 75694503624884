import React, { useState, ChangeEvent, FormEvent, useEffect, useRef, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Box, MenuItem, Select, SelectChangeEvent,IconButton, SnackbarContent, FormHelperText, FormControl } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { createCompanies, fetchCompanies, fetchCompaniesSingle, setCreateCompanyStatus, setUpdateCompanyStatus, updateCompanies } from '../redux/company and model slice/companySlice'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import BranchDetails, { Branch } from './branchDetails';
import UserDetails, { User } from './userDetails';
import AddressDetails from './addressDetails';
import { Alert, Snackbar } from '@mui/material';
import SuccessIcon from '../../assets/img/success.png'
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FailIcon from '../../assets/img/error.png'
import { STATUS } from '../../utils/status';
import { updateUserCount } from '../redux/company and model slice/userCountReducer';


interface FormState {
  id:string;
  name:string;
  role:string;
  allowed_no_of_users: string;
  incorporation_date: string; 
  email: string;
  phone: string;
  kyc_documents_link: string[];
  logo_link: string;
  gstin: string;
  company_type_legal : string;
  company_size: string;
  transporter_id: string;
}

interface UserDetails {
  id: string;
  primary_phone: string;
  first_name: string;
  last_name: string;
}

interface Userdetail {
  company_id: string;
  doj: string;
  email: string;
  id: string;
  kyc_documents_links: string[];
  photourl: string;
  user_details: UserDetails;
  user_id: string;
  user_role: string;
}

interface IProps{
  isEditEnabled: boolean;
}


const CompanyForm : FC<IProps> = ({isEditEnabled}) => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  

  const { companyDetails: initialGoodsDetails, id: companyId } = location.state || { companyDetails: null, id: '' };
  const [companyDetails, setCompanyDetails] = useState<FormState>({
    id:'',
    name:'',
    role:'',
    allowed_no_of_users: '',
    incorporation_date: '',
    email: '',
    phone: '',
    kyc_documents_link: [''],
    logo_link:'',
    gstin:'',
    company_type_legal : '',
    company_size: '',
    transporter_id: '',
  });

  const [formData, setFormData] = useState({
    address_line_1: '',
    address_line_2: '',
    state: '',
    city: '',
    zipcode: '',
  });

  const [role, setRole] = useState('');
  const [branches, setBranches] = useState<Branch[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [kycUrl, setKycUrl] = useState<string>('');
  const [imageKyc, setImageKyc] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<File[]>([]); 
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);
  const [imagePhoto, setImagePhoto] = useState<File | null>(null);
  const fileInputRefKyc = useRef<HTMLInputElement>(null); 
  const fileInputRefPhoto = useRef<HTMLInputElement>(null);  
  const [isEditEnable, setIsEditEnable] = useState(false);
  const [isEdit, setIsEdit] = useState(false); 
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmit, setFormSubmit] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

  const createCompaniesStatus = useSelector((state:RootState)=> state.companies.createCompaniesStatus)
  const updateCompaniesStatus = useSelector((state:RootState)=> state.companies.updateCompaniesStatus)

  useEffect(()=>{
    if(window.location.href.includes(`company-form/${id}`)){
      setIsEdit(true);
    }
  },[])

  useEffect(()=>{
    if(window.location.href.includes(`company-form/${id}`)){
      setIsEditEnable(true);
    }
  },[])

  const [errors, setErrors] = useState<Partial<FormState>>({});

  const companies = useSelector((state: RootState) => state.companies.companies);
  const fetchCompaniesSingleStatus = useSelector((state: RootState) => state.companies.fetchCompaniesSingleStatus);


  useEffect(() => {
    if (fetchCompaniesSingleStatus === STATUS.SUCESS) {
      const updated_company_users = companies.results.filter((c) => c.id === companyId)[0]['users'];
      const transformedUsers = updated_company_users.map((companyUser: any) => ({
        id: companyUser.user_details.id,
        primary_phone: companyUser.user_details.primary_phone,
        first_name: companyUser.user_details.first_name,
        last_name: companyUser.user_details.last_name,
        email: companyUser.email,
        kyc_documents_links: companyUser.kyc_documents_links,
        photourl: companyUser.photourl,
        doj: companyUser.doj,
        role: companyUser.user_role
      }));
  
      setUsers(transformedUsers);
    } else {
      if (initialGoodsDetails) {
        setCompanyDetails(initialGoodsDetails);
  
        if (Object.keys(initialGoodsDetails).includes('branches')) {
          setBranches(initialGoodsDetails.branches);
        } else {
          setBranches([]);
        }
  
        if (Object.keys(initialGoodsDetails).includes('users')) {
          const mergedUsersData = initialGoodsDetails.users.map((user: Userdetail) => {
            const { user_details, email, doj } = user;
            const formattedDoj = new Date(doj).toLocaleDateString();
            return {
              ...user_details,
              email,
              doj: formattedDoj,
            };
          });
  
          setUsers(mergedUsersData);
        } else {
          setUsers([]);
        }
        handleSnackbar('You can make your changes now.', 'info');
      }
    }
  }, [fetchCompaniesSingleStatus, initialGoodsDetails, companies, companyId]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  
    if (name === 'allowed_no_of_users') {
      dispatch(updateUserCount(value)); 
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setRole(event.target.value);
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      role: event.target.value, 
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...files]);
      const urls = files.map((file) => URL.createObjectURL(file));
      setPhotoUrls((prevUrls) => [...prevUrls, ...urls]);
    }
  };

  const deleteImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newUrls = [...photoUrls];
    newUrls.splice(index, 1);
    setPhotoUrls(newUrls);
  };

  const triggerFileInputPhoto = () => {
    if (fileInputRefPhoto.current) {
      fileInputRefPhoto.current.click();
    }
  };

  const handleFileChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImagePhoto(file);
      const url = URL.createObjectURL(file);
      setPhotoUrl(url); 
    }
  };

  const deleteImagePhoto = () => {
    
    setPhotoUrl('');
  };

  const deleteKyc = () => {
    setImageKyc(null);
    setKycUrl('');
  };
  
  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  


  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
   
  };

  const handleSaveCompany = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);
    setFormSubmit(true);
    const errors: { [key: string]: string } = {};
    if (!companyDetails.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!companyDetails.role.trim()) {
      errors.role = 'Role is required';
    }

    if (!companyDetails.allowed_no_of_users.toString().trim()) {
      errors.allowed_no_of_users = 'Alloted User is required';
    }    

    if (!companyDetails.incorporation_date.trim()) {
      errors.incorporation_date = 'Date is required';
    }

    if (!companyDetails.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(companyDetails.email)) {
      errors.email = 'Email is not valid';
    }

    if (!companyDetails.phone.trim()) {
      errors.phone = 'Field is required';
    } else if (!/^(?:\+?91)?[789]\d{9}$/.test(companyDetails.phone)) {
      errors.phone = 'Please enter a valid phone number';
    }

    if (!companyDetails.gstin || !companyDetails.gstin.trim()) {
      errors.gstin = 'GST is required';
    }    

    if (!companyDetails.company_size.trim()) {
      errors.company_size = 'Size is required';
    }
    if (!companyDetails.company_type_legal.trim()) {
      errors.company_type_legal = 'Legal Type is required';
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
        handleSnackbar('Make sure you fill all required fields.', 'warning');
        
      return;
    }
    if (companyId) {
    const payload = {
      id: id
  };
  
  const otherFields = Object.entries(companyDetails).reduce((acc, [key, value]) => {
      if (key !== 'id' && value !== "" && value !== null && value !== undefined) {
          (acc as any)[key] = value;
      }
      return acc;
  }, {});
  
  Object.assign(payload, otherFields);
    dispatch(updateCompanies(payload));
  }
   else {
    const payload = {
      company_details: {
        name: companyDetails.name,
        role: companyDetails.role,
        allowed_no_of_users: companyDetails.allowed_no_of_users,
        incorporation_date: companyDetails.incorporation_date,
        email: companyDetails.email,
        phone: companyDetails.phone,
        logo_link: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        kyc_documents_links: [
            "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
          ],
        address_line_1: formData.address_line_1,
        address_line_2: formData.address_line_2,
        country: 'India',
        state: formData.state,
        city: formData.city,
        zipcode: formData.zipcode,
        gstin: companyDetails.gstin,
        company_type_legal: companyDetails.company_type_legal,
        company_size: companyDetails.company_size,
        transporter_id: companyDetails.transporter_id
      },
      branches: branches,
      users: users,
    };
      dispatch(createCompanies(payload));
    }
  
  };

  useEffect(()=>{
    if(updateCompaniesStatus == STATUS.SUCESS){
      handleSnackbar('Company Updated Successfully', 'success');
      setTimeout(() => {
          navigate('/company');
        }, 1000);
      dispatch(setUpdateCompanyStatus(STATUS.NOT_STARTED))
      }

      if(updateCompaniesStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
      }
  },[updateCompaniesStatus])

  useEffect(()=>{
    if(createCompaniesStatus == STATUS.SUCESS){
      handleSnackbar('Company Added Successfully', 'success');
        setTimeout(() => {
          navigate('/company');
        }, 1000);
        dispatch(setCreateCompanyStatus(STATUS.NOT_STARTED))
      }

      if(createCompaniesStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either this company name already exist or you entered wrong information', 'warning');
        }, 7000);
      }
  },[createCompaniesStatus])

  const handleCloseSnackbar = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  const handleUpdate = (updatedData: React.SetStateAction<{ address_line_1: string; address_line_2: string; state: string; city: string; zipcode: string; }>) => {
    setFormData(updatedData);
  };

  const handleBranchUpdate = (updatedData: Branch) => {
    setBranches((prevBranches) => {
      if (prevBranches) {
        return [...prevBranches, updatedData];
      } else {
        return [updatedData];
      }
    });
  };
  
  const handleDeleteBranch = (id: string) => {
    setBranches((prevBranches) => {
      if (!prevBranches) return [];
      const updatedBranches = prevBranches.filter((branch) => branch.id !== id);
      return updatedBranches;
    });
  };

  const handleDeleteUser = (id: string) => {
    setUsers((prevUsers) => {
      if (!prevUsers) return [];
      const updatedUsers = prevUsers.filter((user) => user.id !== id);
      return updatedUsers;
    });
  };
  
  
  const handleAddBranch = (branchData: Branch) => {
    setBranches((prevBranches) => {
      if (prevBranches) {
        return [...prevBranches, branchData];
      } else {
        return [branchData];
      }
    });
  };

  const handleAddUser = (userData: User) => {
    userData.photourl = "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg";
  
    setUsers((prevUsers) => {
      if (prevUsers) {
        return [...prevUsers, userData];
      } else {
        return [userData];
      }
    });
  };
  
  const handleUserUpdate = (updatedData: User) => {
    updatedData.photourl = "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg";
  
    setUsers((prevUsers) => {
      if (prevUsers) {
        return [...prevUsers, updatedData];
      } else {
        return [updatedData];
      }
    });
  };
  

  const handlePhoneFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "phone" && value.length > 10) {
      const truncatedValue = value.slice(0, 10);
      setCompanyDetails((prevDetails) => ({
        ...prevDetails,
        [name]: truncatedValue,
      }));
      return;
    }
  
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleTransporterFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "transporter_id" && value.length > 15) {
      const truncatedValue = value.slice(0, 15);
      setCompanyDetails((prevDetails) => ({
        ...prevDetails,
        [name]: truncatedValue,
      }));
      return;
    }
  
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem' }}>Company Details</Typography>
      <div>
        <div style={{ display: 'flex' , justifyContent:'space-between',flexWrap: 'wrap'}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Company Name</Typography>
            <TextField
                  placeholder="Enter company name"
                  variant="outlined"
                  name="name"
                  size='small'
                  defaultValue={initialGoodsDetails && initialGoodsDetails.name ? initialGoodsDetails.name : ''}
                  //value={companyDetails.name}
                  onChange={handleFieldChange}
                  error={formSubmitted && formErrors.name ? true : false}
                  helperText={formSubmitted && formErrors.name}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Role</Typography>
            <FormControl  error={formSubmitted && formErrors.role ? true : false}>
            <Select
              //value={companyDetails.role}
              defaultValue={ initialGoodsDetails && initialGoodsDetails.role? initialGoodsDetails.role : '' }
              onChange={(e) => handleSelectFieldChange(e,'role')}
              fullWidth
              displayEmpty
              size='small'
              >
              <MenuItem value="" disabled>
                  Select role
              </MenuItem>
              <MenuItem value="ADMIN">Admin</MenuItem>
              <MenuItem value="CORPORATE">Corporate</MenuItem>
              <MenuItem value="MOTOR OWNER">Motor Owner</MenuItem>
              <MenuItem value="TRANSPORTER">Transporter</MenuItem>
            </Select>
            {formSubmitted && formErrors.role && (
                <FormHelperText>{formErrors.role}</FormHelperText>
              )}
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Incorporation Date</Typography>
            <input type='date' 
            name='incorporation_date' 
            onChange={handleFieldChange} 
            defaultValue={initialGoodsDetails && initialGoodsDetails.incorporation_date? initialGoodsDetails.incorporation_date : ''}
            style={{minHeight:'40px', borderRadius:'4px', border:'1px solid', borderColor:'#939ba2', padding:'8px, 16px, 8px, 16px', gap:'12px'}} 
            // error={formSubmitted && formErrors.value ? true : false}
            // helperText={formSubmitted && formErrors.value}
            />
          
          </div>
        </div>

        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Email ID</Typography>
            <TextField
              placeholder="Enter Email ID"
              name="email"
              defaultValue={ initialGoodsDetails && initialGoodsDetails.email? initialGoodsDetails.email : '' }
              onChange={handleFieldChange}
              error={formSubmitted && formErrors.email ? true : false}
              helperText={formSubmitted && formErrors.email}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Phone Number</Typography>
            <TextField
                  defaultValue={initialGoodsDetails && initialGoodsDetails.phone ? initialGoodsDetails.phone : ''}
                  type="tel"
                    autoComplete="tel"
                    placeholder="Enter phone number"
                    name="phone"
                    onChange={handlePhoneFieldChange}
                    error={formSubmitted && formErrors.phone ? true : false}
                    helperText={formSubmitted && formErrors.phone}
                    size="small"
                    inputProps={{ maxLength: 10 }} 
                    InputLabelProps={{
                      shrink: false,
                    }}
                  />`

          </div>

          <div style={{ display: 'flex', flexDirection: 'column' , width: '32%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Alloted No. of Users</Typography>
            <TextField
              placeholder="Enter User Count"
              name="allowed_no_of_users"
              defaultValue={ initialGoodsDetails && initialGoodsDetails.allowed_no_of_users? initialGoodsDetails.allowed_no_of_users : '' }
              //value={companyDetails.allowed_no_of_users}
              onChange={handleFieldChange}
              error={formSubmitted && formErrors.allowed_no_of_users ? true : false}
              helperText={formSubmitted && formErrors.allowed_no_of_users}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>
          </div>


          <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Company Type</Typography>
            <FormControl  error={formSubmitted && formErrors.company_type_legal ? true : false}>
            <Select
              //value={companyDetails.role}
              defaultValue={ initialGoodsDetails && initialGoodsDetails.company_type_legal? initialGoodsDetails.company_type_legal : '' }
              onChange={(e) => handleSelectFieldChange(e,'company_type_legal')}
              fullWidth
              displayEmpty
              size='small'
              >
              <MenuItem value="" disabled>
                  Select type
              </MenuItem>
              <MenuItem value="PRIVATE LIMITED">Pvt. Ltd.</MenuItem>
              <MenuItem value="LLP">LLP</MenuItem>
              <MenuItem value="SOLE PROPRIETORSHIP">Sole Proprietorship</MenuItem>
              <MenuItem value="PARTNERSHIP">Partnership</MenuItem>
            </Select>
            {formSubmitted && formErrors.company_type_legal && (
                <FormHelperText>{formErrors.company_type_legal}</FormHelperText>
              )}
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Company Size</Typography>
            <FormControl  error={formSubmitted && formErrors.company_size ? true : false}>
            <Select
              //value={companyDetails.role}
              defaultValue={ initialGoodsDetails && initialGoodsDetails.company_size? initialGoodsDetails.company_size : '' }
              onChange={(e) => handleSelectFieldChange(e,'company_size')}
              fullWidth
              displayEmpty
              size='small'
              >
              <MenuItem value="" disabled>
                  Select size
              </MenuItem>
              <MenuItem value="SMALL">Small</MenuItem>
              <MenuItem value="MEDIUM">Medium</MenuItem>
              <MenuItem value="LARGE">Large</MenuItem>
            </Select>
            {formSubmitted && formErrors.company_size && (
                <FormHelperText>{formErrors.company_size}</FormHelperText>
              )}
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' , width: '32%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D'}}>Transporter Id</Typography>
            <TextField
              placeholder="Enter Id"
              name="transporter_id"
              defaultValue={ initialGoodsDetails && initialGoodsDetails.transporter_id? initialGoodsDetails.transporter_id : '' }
              //value={companyDetails.allowed_no_of_users}
              onChange={handleTransporterFieldChange}
              inputProps={{ maxLength: 15}}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>
          </div>


          <div style={{ display: 'flex' , justifyContent:'flex-start'}}>
          <div style={{ display: 'flex', flexDirection: 'column' , marginRight:'20px', width: '32%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '10px' }}>GSTIN</Typography>
            <TextField
              placeholder="Enter GST"
              name="gstin"
              defaultValue={ initialGoodsDetails && initialGoodsDetails.gstin? initialGoodsDetails.gstin : '' }
              value={companyDetails.gstin}
              onChange={handleFieldChange}
              error={formSubmitted && formErrors.gstin ? true : false}
              helperText={formSubmitted && formErrors.gstin}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '18.8%' }}>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>
        KYC Documents
      </Typography>
      <input
       type="file"
       ref={fileInputRef}
       style={{ display: 'none' }}
       onChange={handleFileChange}
       multiple 
       />
      <Button
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '0.025em',
          textAlign: 'center',
          color: '#03BBD2',
          border: 'none',
          textTransform: 'none',
          mt: '10px',
          ml:'none',
          mr:'85%',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
        }}
        onClick={() => fileInputRef.current?.click()}
        variant="outlined"
        type="button"
      >
        Upload<FileUploadIcon fontSize="small" />
      </Button>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop:'20px' }}>
              {photoUrls.map((url, index) => (
                <div key={index} style={{ marginRight: '10px', marginBottom: '10px', position:'relative' }}>
                  <div style={{display:'flex', alignItems:'center', marginRight:'20px', marginBottom:'10px'}}>
                  <img src={url} alt="Uploaded File" style={{ width: '50px', height: '50px' }} />
                  <div style={{ position: 'absolute', top: '-10px', left: '35px', backgroundColor: 'black', borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <CloseIcon sx={{ color: 'white' }} fontSize='small' onClick={() => deleteImage(index)} />
                  </div>
                  </div>
                </div>
              ))}
            </div>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column' , marginLeft:'10rem', width: '18.8%' }}>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', marginLeft:'12px' }}>
        Logo Image
      </Typography>
      <input
        type="file"
        ref={fileInputRefPhoto}
        style={{ position: 'absolute', left: '-9999px' }}
        onChange={handleFileChangePhoto}
      />
      <Button
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '0.025em',
          textAlign: 'center',
          color: '#03BBD2',
          border: 'none',
          textTransform: 'none',
          mt: '10px',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
        }}
        onClick={triggerFileInputPhoto}
        variant="outlined"
        type="button"
      >
        Upload Logo Image <FileUploadIcon fontSize="small" />
      </Button>
      {imagePhoto && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={photoUrl}
            alt="Uploaded File"
            style={{ width: '50px', height: '50px', marginLeft: '10px' }}
          />
          <Typography variant="body2" sx={{ marginLeft: '10px' }}>
            {imagePhoto?.name}
          </Typography>
          <IconButton onClick={deleteImagePhoto}>
            <DeleteIcon sx={{ color: '#C00F00', width: '20.67px' }} />
          </IconButton>
        </div>
      )}
      </div>
        </div>    


        <AddressDetails onUpdate={handleUpdate} formSubmit={formSubmit} setFormSubmit={setFormSubmit} />
         <BranchDetails onUpdate={handleBranchUpdate} branches={branches || []} onAdd={handleAddBranch} onDelete={handleDeleteBranch} isEditEnable={isEditEnable}/>

         <UserDetails onUpdate={handleUserUpdate} users={users || []} onAdd={handleAddUser} onDelete={handleDeleteUser} isEdit={isEdit}/>

        <Button
        onClick={(e:any) => handleSaveCompany(e)}
          variant="contained" sx={{ mt: 2, fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '100%', backgroundColor: '#03BBD2', borderRadius: '8px' }}>{companyId ? 'Update Comapny' : 'Save Company'}</Button>
      </div>

      <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Box>
  );
};

export default CompanyForm;