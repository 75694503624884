import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
interface GoodsDetails {
  id:string,
  goods_name: string;
  hsn_code: string;
  gst_tax_type: 'EXEMPTED' | 'NONEXEMPTED';
}

interface GoodsValue {
  id: string;
  goods_name_id: string;
  value: number;
  value_unit: 'TON' | 'KG' | 'QUINTAL' | 'RS' | 'LITRE';
}

interface ShortAllowance {
  id: string;
  goods_type_id: string;
  allowance_type: 'DEPENDANT' | 'NON_DEPENDANT';
  short_allowance: string|number;
  short_allowance_unit: 'KG' | 'PERCENTAGE';
  weight_range_start: string|number;
  weight_range_end: string|number

}

export interface CreateGoodsTypePayload {
  goods_name: string;
  shortages: any;
  goods_name_id: string;
  id: string;
  goods_details: GoodsDetails;
  goods_value: GoodsValue;
  short_allowances: ShortAllowance[];
}

export interface GoodsState {
  goodsName: {
    count: number | undefined;
    results: CreateGoodsTypePayload[];
  };
  error: string | null;
  getGoodsNameStatus: STATUS;
}

const initialState: GoodsState = {
  goodsName: { 
    count: undefined,
    results: [] },
  error: null,
  
  getGoodsNameStatus: STATUS.NOT_STARTED,
};

export const getGoodsName = createAsyncThunk(
    'goods/name/get',
    async ({ goods_name }: { goods_name:string }, thunkAPI) => {
      const response = await api.get(`goodstype/name?goods_name=${goods_name}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  )

const goodsNameSlice = createSlice({
  name: 'goodsName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGoodsName.pending, (state, action) => {
        state.getGoodsNameStatus = STATUS.PENDING;
      })
      .addCase(getGoodsName.fulfilled, (state, action) => {
        state.goodsName = action.payload;
        state.getGoodsNameStatus = STATUS.SUCESS;
        console.log('GET GOODS TYPES SUCCESS');
      })
      .addCase(getGoodsName.rejected, (state, action) => {
        state.getGoodsNameStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
  },
});

export default goodsNameSlice.reducer;
