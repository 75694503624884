import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { RootState } from '../store/appStore';

interface CreateTruckCountPayload {
  id: string;
  total_trucks: number
}

interface TruckTypeState {
  truckCount: {
    data: CreateTruckCountPayload [];
  }
  error: string | null;
  getTruckCountStatus: STATUS;
}

const initialState: TruckTypeState = {
  truckCount: { data: [] },
  error: null,
  getTruckCountStatus: STATUS.NOT_STARTED,
};

export const getTruckCount = createAsyncThunk(
  'truck/count/get',
  async ( payload:any, thunkAPI) => {
      const response = await api.get(`truck/count`, payload);
      const { status, data } = response;
      
        if (status === 200) {
            return data;
          } else {
            return thunkAPI.rejectWithValue({ response, data });
          } 
}
);



const truckCountSlice = createSlice({
  name: 'truckCount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getTruckCount.pending, (state) => {
        state.getTruckCountStatus = STATUS.PENDING;
      })
      .addCase(getTruckCount.fulfilled, (state, action) => {
        console.log('Get Truck Fulfilled:', action.payload); 
        state.truckCount = action.payload; 
        state.getTruckCountStatus = STATUS.SUCESS; 
      })
      .addCase(getTruckCount.rejected, (state, action) => {
        console.error('Get Truck Rejected:', action.error.message ?? 'An error occurred'); 
        state.getTruckCountStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
  },
});
export const selectTruckCount = (state:RootState) => state.truckCount.truckCount;

export default truckCountSlice.reducer;
