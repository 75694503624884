const initialState = {
    companyDetails: [
      {
        user_type: '',
        unit: '',
        value: '',
      }
    ],
  };
  
  const ADD_COMPANY_DETAILS = 'ADD_COMPANY_DETAILS';

  export const addCompanyDetails = (details:any) => ({
    type: ADD_COMPANY_DETAILS,
    payload: details,
  });

  
  const commissionformReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case ADD_COMPANY_DETAILS:
        const { index, ...newDetails } = action.payload;
        const updatedDetails = [...state.companyDetails];
        if (index >= 0 && index < updatedDetails.length) {
          // If the index is within range, update the details at the specified index
          updatedDetails[index] = { ...updatedDetails[index], ...newDetails };
        } else {
          // If the index is out of range, add the new details as a new row
          updatedDetails.push(newDetails);
        }
        return {
          ...state,
          companyDetails: updatedDetails,
        };
      default:
        return state;
    }
  };
  
  
  
  
  export default commissionformReducer;
  