import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, MenuItem, Select, FormControl, Divider, TextField, Grid, CircularProgress,Snackbar, SnackbarContent } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import GoodsListHeading from './goodsListHeading';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { CreateGoodsTypePayload, deleteGoodsType, getGoodsType, setDeleteGoodsStatus } from '../redux/goodsSlice/goodsSlice';
import { STATUS } from '../../utils/status';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getActiveGoods } from '../redux/goodsSlice/activeGoodsSlice';
import { getTotalGoods } from '../redux/goodsSlice/totalGoodsSlice';
import { getHotGoods } from '../redux/goodsSlice/hotGoodsSlice';
import Error from '../error';
import { AddButton } from '../../assets/button';
import DeleteModal from './deleteModal';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import ClearIcon from '@mui/icons-material/Clear';

const GoodsMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState<string>('goods_name');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [topGoods, setTopGoods] = useState<string[]>([]); 
  const [loading, setLoading] = useState(false); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

  useEffect(() => {
    dispatch(getActiveGoods());
    dispatch(getTotalGoods());
    dispatch(getHotGoods());
  }, [dispatch]);

  const actives = useSelector((state: RootState) => state.actives.activeGoodsCount);
  const totals = useSelector((state: RootState) => state.totals.totalGoodsCount);
  const goods = useSelector((state: RootState) => state.goods.goods);
  const getGoodsStatus = useSelector((state: RootState) => state.goods.getGoodsStatus);
  const deleteGoodsStatus = useSelector((state: RootState) => state.goods.deleteGoodsStatus);
  const hotGoods = useSelector((state: RootState) => state.hots.hotGoods);



  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [goodsIdToDelete, setGoodsIdToDelete] = useState(""); 
  
  const handleDelete = (id: string) => {
    setGoodsIdToDelete(id);
    setShowDeleteModal(true);
    handleSnackbar('Deleting this item cannot be undone.', 'warning');
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    if (goodsIdToDelete) {
      await dispatch(deleteGoodsType({ id: goodsIdToDelete }));
      
      dispatch(getGoodsType({ search: searchTerm, page: currentPage, sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }));
      
    }
  };

  useEffect(()=>{
    if(deleteGoodsStatus == STATUS.SUCESS){
      handleSnackbar('Good Type Deleted Successfully.', 'success');
      dispatch(setDeleteGoodsStatus(STATUS.NOT_STARTED))
    }
    if(deleteGoodsStatus == STATUS.FAILED){
      handleSnackbar('Error Occured, Please Try Again!', 'error');
    }
  },[deleteGoodsStatus])
  
  const cancelDelete = () => {
    setShowDeleteModal(false); 
    
  };

  useEffect(() => {
    setLoading(true); 
    dispatch(getGoodsType({search: searchTerm, page: currentPage, sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }))
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => setLoading(false)); 
  }, [dispatch, searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);


  useEffect(() => {
    if (hotGoods.length > 0) {
      const top3Goods = hotGoods.slice(0, 3).map((item: { goods_name: string }) => item.goods_name);
      setTopGoods(top3Goods);
    }
  }, [hotGoods]);

  const handleSort = (column: string) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleEditClick = (row: any) => {
    handleSnackbar('We are redirecting you in editing mode.', 'info');
    setTimeout(() => {
      navigate(`/goods-type/${row.id}`, { state: { goodsDetails: row, id: row.id } });
    }, 2000);
    
  };

  const handleAddGoodsClick = () => {
    navigate('/goods-type');
  };

  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handlePaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  const stats = [
    { title: 'TOTAL GOODS TYPES', value: totals, change: -1, color: '#FFFFFF' },
    { title: 'ACTIVE GOODS TYPES', value: actives, change: 1, color: '#FFFFFF' },
    { title: 'TOP GOODS TYPES', value: topGoods.join('\n'), change: 2, color: '#FFFFFF' },
  ];

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  return (
    <>
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <GoodsListHeading />
      </Box>
    
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        <Paper elevation={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          padding: '24px',
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          mb: '2rem',
        }}>
          {stats.map((stat, index) => (
            <React.Fragment key={index}>
              <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '8px 16px' }}>
              <Typography variant="subtitle2" component="p" sx={{ fontSize: '10px', fontWeight: '600', fontFamily: 'Montserrat' , color:'#10383D', lineHeight:'20px'}}>
        {stat.title}
      </Typography>
     
      {stat.title === 'TOP GOODS TYPES' && stat.value ? (
            typeof stat.value === 'string' ? (
              <Typography variant="h6" component="p" sx={{ fontSize: '10px', fontWeight: '700', fontFamily: 'Montserrat', textAlign: 'end', color: '#939393',}}>
                {stat.value.split('\n').map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            ) : (
              <Typography variant="h6" component="p" sx={{ fontSize: '40px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D', lineHeight:'32px' }}>
                {stat.value}
              </Typography>
            )
          ) : stat.title === 'TOTAL GOODS TYPES' ? (
            <Typography variant="h6" component="p" sx={{ fontSize: '40px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D', lineHeight:'32px' }}>
              {stat.value}
            </Typography>
          ) : (
            <Typography variant="h6" component="p" sx={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D' , lineHeight:'24px'}}>
              {stat.value}
            </Typography>
          )}
              </Grid>
              {index !== stats.length - 1 && (
                <Divider orientation="vertical" flexItem sx={{ width: '1px', bgcolor: '#DDDDDD', mx: 2 }} />
              )}
            </React.Fragment>
          ))}
        </Paper>
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
              value={searchTerm}
              size="small"
              onChange={handleSearchChange}
              sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
              InputProps={{
                startAdornment: (
                  <SearchIcon color="action" />
                )
              }}
              InputLabelProps={{
                shrink: false
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', marginRight: '20px' }}>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>SHOW</Typography>
                <FormControl sx={{ m: 1 , minWidth:'40'}}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    IconComponent={ArrowDropDownIcon}
                    value={itemsPerPage} 
                    sx={{  height: '40px' }}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>ENTRIES</Typography>
              </div>
              <AddButton onClick={handleAddGoodsClick}>Add New Goods Type</AddButton>
            </div>
          </div>

          <TableContainer>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                <TableRow>
                  <TableCell align="left" onClick={() => handleSort('goods_name')} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                  <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>GOODS NAME</span>
                     
                        <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                          <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'asc' ? 'visible' : 'visible' }} />
                          <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === 'desc' ? 'visible' : 'visible' }} />
                        </div>
                      
                    </div>
                  </TableCell>
                  <TableCell align="left" onClick={() => handleSort('value')} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                  <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>VALUE</span>
                     
                        <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                          <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'asc' ? 'visible' : 'visible' }} />
                          <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === 'desc' ? 'visible' : 'visible' }} />
                        </div>
                      
                    </div>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                  <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>UNITS</span> 
                    </div>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                  <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>HSN CODE</span> 
                    </div>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                  <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>SHORTAGE<br/>ALLOWANCE</span>
                    </div>
                    </TableCell>
                  <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                  <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>GST TAX TYPE</span>
                    </div>
                  </TableCell>
                  <TableCell align="left"onClick={() => handleSort('user_count')} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                  <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>TOTAL USER<br/>  COUNT</span>
                     
                        <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                          <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'asc' ? 'visible' : 'visible' }} />
                          <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === 'desc' ? 'visible' : 'visible' }} />
                        </div>
                      
                    </div>
                  </TableCell>
                  {/* <TableCell align="right"></TableCell> */}
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              
  
              {getGoodsStatus === STATUS.SUCESS && goods && goods.results && goods.results.length > 0 ? (
                  goods.results.map((row: any, index: number) => (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                    <TableCell component="th" scope="row" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
                      {row.goods_name}
                    </TableCell>
                    <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.value}</TableCell>
                    <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.value_unit}</TableCell>
                    <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.hsn_code}</TableCell>

                    <TableCell align="center" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
                      {row.shortages?.length > 0 ? (
                        <>
                          {row.shortages[0].short_allowance}{' '}
                          {row.shortages[0].short_allowance_unit === 'PERCENTAGE' ? '%' : row.shortages[0].short_allowance_unit === 'KG' ? 'KG' : 'per box'}
                        </>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.gst_tax_type}</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: 'Montserrat',
                        color: '#10383D',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '24px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{cursor:'pointer'}}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {row.user_count}
                      </div>
                      {hoveredRowIndex === index && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '70%',
                            right: '1rem',
                            zIndex: 999,
                            padding: '5px',
                            backgroundColor: 'white',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            borderRadius: '5px',
                            width: '100px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: '600',
                              color: '#13282A',
                              fontFamily: 'Montserrat',
                              fontSize: '12px',
                              lineHeight: '14px',
                              padding: '5px',
                              textAlign: 'start',
                            }}
                          >
                            Users
                          </Typography>
                          {row.users.map((user: string, idx: number) => (
                            <Typography
                              key={idx}
                              sx={{
                                fontWeight: '100',
                                color: '#13282A',
                                fontSize: '12px',
                                lineHeight: '20px',
                                padding: '5px',
                                textAlign: 'start'
                              }}
                            >
                              {user}
                            </Typography>
                          ))}
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#13282A', fontWeight:'400',fontSize:'14px', lineHeight:'24px', cursor:'pointer'}}>
                      <EditNoteOutlinedIcon onClick={() => handleEditClick(row)}/>
                      <DeleteOutlineOutlinedIcon
                        sx={{ color: '#C00F00', cursor: 'pointer' }}
                        onClick={() => handleDelete(row.id)}
                      />
                    </TableCell>
                  </TableRow>
                  ))
                  ) : (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                      <TableCell colSpan={8} >
                        <Error message="There are no goods added as of yet. You can add a good to see it here."/>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
          </TableContainer>

        

          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}> 
            {getGoodsStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getGoodsStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, goods.count || 0) 
              : '-'}{' '}
            of {getGoodsStatus === STATUS.SUCESS ? (goods.count || 0) : '-'}
            </Typography>
            </div>
        <Button
          onClick={() => handlePaginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeftIcon
              sx={{
                color: 'black',
                backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
        <Button
          onClick={() => handlePaginate(currentPage + 1)}
          disabled={!goods.results || goods.results.length < itemsPerPage}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronRightIcon
              sx={{
                color: 'black',
                backgroundColor: !goods.results || goods.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
      </div>
        </Paper>

      </Box>
      
    </div>

    <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',

            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
      </>
  );
};

export default GoodsMain;
