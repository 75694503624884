import React, { useEffect, useRef } from 'react';

interface MapProps {
  sourceCoordinates: google.maps.LatLng;
  destinationCoordinates: google.maps.LatLng;
}

const Map: React.FC<MapProps> = ({ sourceCoordinates, destinationCoordinates }) => {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapRef.current || !sourceCoordinates || !destinationCoordinates) return;

    const map = new google.maps.Map(mapRef.current, {
      zoom: 8,
      center: sourceCoordinates,
    });

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({ map });

    const request = {
      origin: sourceCoordinates,
      destination: destinationCoordinates,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      
        directionsRenderer.setDirections(result);
      
    });

    const sourceMarker = new google.maps.Marker({
      position: sourceCoordinates,
      map,
      title: 'Source',
    });

    const destinationMarker = new google.maps.Marker({
      position: destinationCoordinates,
      map,
      title: 'Destination',
    });

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(sourceCoordinates);
    bounds.extend(destinationCoordinates);
    map.fitBounds(bounds);

    return () => {
      sourceMarker.setMap(null);
      destinationMarker.setMap(null);
    };
  }, [sourceCoordinates, destinationCoordinates]);

  return (
    <div style={{ width: '100%', height: '400px', marginBottom: '1rem' }}>
      <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};

export default Map;
