import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, Typography, Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AppDispatch } from '../redux/store/appStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { createTruck, updateTruck } from '../redux/truck slice/addTruckSlice';
import ContractTruckForm from './contractTruckForm';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface FormState {
  corporate: string;
  transporter: string;
  motor_owner: string;
  route: string;
  goods_type: string;
  start_date: string;
  end_date: string;
}

const ContractForm = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { companyDetails: initialGoodsDetails, id: goodsId } = location.state || { companyDetails: null, id: '' };

  const [companyDetails, setCompanyDetails] = useState<FormState>({
  start_date: '',
  end_date: '',
  corporate: '',
  transporter: '',
  motor_owner: '',
  route: '',
  goods_type: '',
  });


  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (initialGoodsDetails) {
      setCompanyDetails(initialGoodsDetails);
    }
  }, [initialGoodsDetails]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    const errors: { [key: string]: string } = {};
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const payload = {
        
      };

      if (goodsId) {
        dispatch(updateTruck(payload));
      } else {
        dispatch(createTruck(payload));
      }

      setSuccessMessage('Truck Type saved successfully!');
      setErrorMessage(null);
     
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem' }}>Contract Details</Typography>
      <div>
        <div style={{ display: 'flex' , justifyContent:'space-between',flexWrap: 'wrap'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Corporate</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
         
            <Select
                value={companyDetails.corporate}
                onChange={(e) => handleSelectFieldChange(e, 'corporate')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select Corporate
                </MenuItem>
                <MenuItem value="Mahe">
                    Mahe
                </MenuItem>
                
                
            </Select>
           
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>


          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Transporter</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.transporter}
                onChange={(e) => handleSelectFieldChange(e, 'transporter')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select Transporter
                </MenuItem>
                <MenuItem value="Sahu">
                    Sahu
                </MenuItem>
                
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column',width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Motor Owner</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.motor_owner}
                onChange={(e) => handleSelectFieldChange(e, 'motor_owner')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select Motor Owner
                </MenuItem>
                <MenuItem value="Narinder Moody">
                    Narinder Moody
                </MenuItem>
                
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>

        </div>

        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Route</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.route}
                onChange={(e) => handleSelectFieldChange(e, 'route')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
                
            >
                <MenuItem value="" disabled>
                    Select Route
                </MenuItem>
                <MenuItem value="jaipur to mumbai">
                    Jaipur to Mumbai
                </MenuItem>
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>


          <div style={{ display: 'flex', flexDirection: 'column', marginRight:'20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Contract Start Date</Typography>
            {/* <TextField
              placeholder="Select Date"
              name="start_date"
              value={companyDetails.start_date}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              // error={formSubmitted && formErrors.incorporation_date ? true : false}
              // helperText={formSubmitted && formErrors.incorporation_date}
            /> */}
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker sx={{width:'25rem', height:'40px',  borderBottom:'1px solid', borderColor:'#A8A8A8', borderRadius:'4px'}}/>
      </DemoContainer>
    </LocalizationProvider>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Contract End Date</Typography>
            {/* <TextField
              placeholder="Select Date"
              name="end_date"
              value={companyDetails.end_date}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              // error={formSubmitted && formErrors.incorporation_date ? true : false}
              // helperText={formSubmitted && formErrors.incorporation_date}
            /> */}

<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker sx={{width:'25rem',height:'40px',  borderBottom:'1px solid', borderColor:'#A8A8A8', borderRadius:'4px'}}/>
      </DemoContainer>
    </LocalizationProvider>
          </div>
          </div>

          <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column',  width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Goods Type</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.goods_type}
                onChange={(e) => handleSelectFieldChange(e, 'goods_type')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select Goods Type
                </MenuItem>
                
                <MenuItem value="rice">
                    rice
                </MenuItem>
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>
          </div>
          
          <ContractTruckForm/>

        <Button type="submit" variant="contained" sx={{ mt: 2, fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '100%', backgroundColor: '#03BBD2', borderRadius: '8px' }}>{goodsId ? 'Update Contract' : 'Save Contract'}</Button>
      </div>
    </Box>
  );
};

export default ContractForm;