import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import EnquiryHeading from './enquiryHeading';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button } from '@mui/material';
import { Forms } from './const';
import { NextButtonEnquiry, PreviousButtonEnquiry, SubmitEnquiry } from '../../assets/button';
import { createEnquiries } from '../redux/enquiries/enquiries';
import { createReceiver, getReceiver } from '../redux/receiver/receiver';
import { FormState } from './truckEnquiryForm';

const EnquiryMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [alignment, setAlignment] = useState('route');
  const [currentForm, setCurrentForm] = useState<any>(Forms[0]);
  const [currentIdx, setcurrentIdx] = useState(0);

  useEffect(() => {
    setCurrentForm(Forms[currentIdx])
  }, [currentIdx]);

  const handleNext = () => {
    if (currentIdx < Forms.length - 1) {
      setcurrentIdx(currentIdx + 1);
      setAlignment(Forms[currentIdx + 1].key);
    }
  };
  
  const handlePrevious = () => {
    if (currentIdx > 0) {
      setcurrentIdx(currentIdx - 1);
      setAlignment(Forms[currentIdx - 1].key);
    }
  };

  const truckDetails: FormState = useSelector((state: RootState) => state.truckformState);
  const receiverDetails = useSelector((state:RootState)=> state.receriverFormState )
  const routeDetails = useSelector((state:RootState)=> state.routeForm.companyDetails )
  const goodsDetails = useSelector((state:RootState)=> state.goodsForm.companyDetails)
  const selectedTruckIds = useSelector((state: RootState) => state.truckListForm.selectedTruckIds);
  const biddingDetails = useSelector((state:RootState) => state.bidForm.companyDetails);
  const payment = useSelector((state:RootState) => state.bidForm.payment);
  const bidding_types = useSelector((state:RootState) => state.bidForm.bidding_types);
  const selectedExpenseIds = useSelector((state: RootState) => state.expenseForm.selectedExpenseIds);
  const selectedChecklistIds = useSelector((state: RootState) => state.checklistForm.selectedChecklistIds);
  const commissionDetails = useSelector((state: RootState) => state.commisionForm.companyDetails);
  const receiver = useSelector((state:RootState)=> state.receiver.receiver)
useEffect(()=>{
  dispatch(getReceiver({}));
},[dispatch])
  
  const handleSubmitBooking = () => {
    
    const formData = {
      routes: ["021a36ac-05fc-4ed9-b6b1-b68b85e14e93"],
      // routeDetails.map(detail => (
      //   detail.source_name,
      // )),
      goods_types: goodsDetails.map(detail => ({ 
        id: "ab355cad-4d44-4953-aa4b-9af8b77ac8a6",
        short_allowance: detail.short_allowance
      })),
      truck_type_id: '6fad42da-9b22-455c-bca8-ae6899177b69', //truckDetails.truck_type, 
      truck_tyre_id: truckDetails.tyre_count, 
      truck_tyre_weight_id: 300, 
      no_of_trucks: truckDetails.number_of_trucks, 
      pickup_date: truckDetails.pickup_date, 
      pickup_location: null, 
      drop_location: null, 
      truck: [
        ...selectedTruckIds
      ], 
      bidding_types: bidding_types, 
      payment_mode: payment, 
      advance_percentage: 0, 
      amount_paid: 0, 
      balance: 0, 
      enquiry_type: 'ADHOC', 
      freight_rates:{
        all:{
          unit: biddingDetails[0].unit, 
          rate: biddingDetails[0].freight,
          total_freight: biddingDetails[0].total_freight, 
        }
      },
      expenses: [
        {
            id: "ef0f9a9a-da91-47f0-928a-1305f1bad728",
            rate: 50
        }
    ],
      //expenses: [ ...selectedExpenseIds ], 
      expense_amount_total: 0, 
      checklist: [ ...selectedChecklistIds ], 
      users : [
        "faf5b6d6-f687-4e58-89e0-f2b07589be58",
        "b63d0892-2f37-462f-93f6-4b1e80ff8670",
        "067b4b82-9e64-4cb7-ab00-8d934f61d46f"
      ] ,
      receiver: {
        id: 'de9c2df3-1d74-4aa0-9700-c68715706524',
        name: receiverDetails.name,
        email: receiverDetails.email,
      }, 
      commissions: {
        transporter: {
            type: "FIXED",
            value: "1000"
        },
        all: {
            type: "FIXED",
            value: "1000"
        }
    },
    expense_in_invoice: true
      //   commissionDetails.map(detail => ({
      //     role: detail.user_type.toLowerCase(), 
      //     commission_unit: detail.unit.toLowerCase(), 
      //     value: detail.value
      // }))
    };

    dispatch(createEnquiries(formData));
  };


  
  

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
    const newIndex = Forms.findIndex((form) => form.key === newAlignment);
    if (newIndex !== -1) {
      setcurrentIdx(newIndex);
    }
  };

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <EnquiryHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        <Paper elevation={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          padding: '24px',
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          mb: '2rem',
        }}>
          {/* Stats */}
          <FormControl>
            <FormLabel sx={{ marginBottom: '15px', color: '#10383D', fontWeight:'600' }} id="demo-row-radio-buttons-group-label">Booking Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel sx={{ color: '#13282A', marginRight: '3rem' }} value="adhoc" control={<Radio />} label="Adhoc" />
              <FormControlLabel sx={{ color: '#13282A', marginRight: '3rem' }} value="lot" control={<Radio />} label="Lot" />
              <FormControlLabel sx={{ color: '#13282A', marginRight: '3rem' }} value="partial" control={<Radio />} label="Partial Booking" />
              <FormControlLabel sx={{ color: '#13282A', marginRight: '3rem' }} value="contract" control={<Radio />} label="Contract Basis" />
            </RadioGroup>
          </FormControl>

        </Paper>
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin:2,
              marginLeft:'none',
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <ToggleButtonGroup
              sx={{ color: "#2C3556" }}
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
            >
              {Forms.map((form) => (
                <ToggleButton
                  key={form.key}
                  value={form.key}
                  style={{
                    border: 'none',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    color: alignment === form.key ? '#03BBD2' : '#2C3556',
                    borderBottom: alignment === form.key ? '3px solid #03BBD2' : 'none',
                    backgroundColor: 'inherit',
                    textTransform: 'none'
                  }}
                >
                  {form.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>

          <currentForm.form />
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            {currentIdx !== 0 && (
              <PreviousButtonEnquiry onClick={handlePrevious}>Previous</PreviousButtonEnquiry>
            )}

            {currentIdx !== Forms.length - 1 && (
              <NextButtonEnquiry onClick={handleNext}>Next</NextButtonEnquiry>
            )}

            {currentIdx === Forms.length - 1 && (
              <SubmitEnquiry onClick={handleSubmitBooking}>Submit Booking</SubmitEnquiry>
            )}
          </div>
        </Paper>
      </Box>
    </div>
  );
}
export default EnquiryMain;
