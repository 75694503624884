import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

type MapSelectProps = {
  value: string;
  placeholder: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  error?: boolean;
  options: { id: string; value: string; label: string }[];
  sx?: React.CSSProperties;
};

export const MapSelect = ({ value, placeholder, onChange, error, options, sx }: MapSelectProps) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      error={error}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      size="small"
      sx={sx} 
    >
      <MenuItem value="" disabled>
        {placeholder}
      </MenuItem>

      {options &&
        options.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  );
};
