import React, { useState, ChangeEvent, FormEvent, useEffect, InputHTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGoodsType, updateGoodsType } from '../redux/goodsSlice/goodsSlice';
import { TextField, Button, Typography, Box, MenuItem, Select, SelectChangeEvent, FormControl, FormHelperText, InputAdornment, SnackbarContent, Snackbar, Chip, Autocomplete } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getTruckTypes } from '../redux/truck type slice/truckTypeSlice';
import { createTruck, setCreateTrucksStatus, updateTruck } from '../redux/truck slice/addTruckSlice';
import { fetchCompanies, setUpdateCompanyStatus } from '../redux/company and model slice/companySlice'
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import FailIcon from '../../assets/img/error.png'
import SuccessIcon from '../../assets/img/success.png'
import ClearIcon from '@mui/icons-material/Clear';
import { getUserModalType } from '../redux/company and model slice/userModelSlice';
import CloseIcon from '@mui/icons-material/Close';
import { STATUS } from '../../utils/status';

interface FormState {
  id?: string;
  company_id: string;
  truck_type_id: string;
  truck_type_tyre_id: string;
  truck_weight_id: string;
  truck_type: string;
  tyre_count: string;
  truck_weight: string;
  truck_number: string;
  chassis_number: string;
  registration_date: string;
  maintenance_cost: string;
  tank_size: string;
  truck_average: string;
  fuel_consumption: string; 
  registration_type: string;
  drivers: string[];
}

interface CreateCompanyTypePayload {
  id: string;
  name: string;
  role: string;
}

interface CompanyState {
  companies: {
    results: CreateCompanyTypePayload[];
    fetchCompaniesStatus: string;
  };
}

interface TruckType {
  id: string;
  name: string;
  truck_tyres: {
    id: string;
    truck_type_id: string;
    no_of_tyres: number;
    weights: {
      id: string;
      truck_type_id: string;
      weight: number;
      unit: string;
    }[];
  }[];
}

const TruckForm = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { truckDetails: initialGoodsDetails, id: goodsId } = location.state || { truckDetails: null, id: '' };
  const truckTypes = useSelector((state: RootState) => state.trucktypes.truckTypes);
  const companies = useSelector((state: RootState) => state.companies.companies.results);
  const createStatus = useSelector((state: RootState)=> state.trucks.createStatus)
  const updateStatus = useSelector((state: RootState)=> state.trucks.updateStatus)

  const [truckDetails, setTruckDetails] = useState<FormState>({
    company_id: '',
    truck_type_id: '',
    truck_type_tyre_id: '',
    truck_weight_id: '',
    truck_type: '',
    tyre_count: '',
    truck_weight: '',
    truck_number: '',
    chassis_number: '',
    registration_date: '',
    maintenance_cost: '',
    tank_size: '',
    fuel_consumption: '',
    registration_type: '',
  drivers: [],
  truck_average: '',
  });

  const [selectedIds, setSelectedIds] = useState({
    truck_type: '',
    tyre_count: '',
    truck_weight: '',
    company_id:''
  });

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<{ label: string; value: string; } | null>(null);
  const [splitMaintenanceCost, setSplitMaintenanceCost] = useState<{ value: string; amount: string }[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const userModal = useSelector((state: RootState) => state.userModal.userModal)
  const filteredUsers = userModal.results.filter(user => user.user_role === "DRIVER");
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<{ label: string; value: string; }[]>([]);



  useEffect(() => {
    dispatch(getTruckTypes({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCompanies({}))
  }, [dispatch]);

  useEffect(() => {
    setOptions(companies.map((company) => ({ label: company.name, value: company.id })));
  }, [companies]);

  useEffect(() => {
    dispatch(getUserModalType({}));
  }, [dispatch]);

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const typeId = event.target.value as string;
    const selectedType = truckTypes.find((type) => type.truck_type_id === typeId);

    setTruckDetails({
      ...truckDetails,
      truck_type: typeId,
      tyre_count: '',
      truck_weight: ''
    });

    setSelectedIds({
      ...selectedIds,
      truck_type: typeId,
      tyre_count: '',
      truck_weight: ''
    });

  };

  const handleCountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const countId = event.target.value as string;
    setTruckDetails({
      ...truckDetails,
      tyre_count: countId,
      truck_weight: ''
    });
    setSelectedIds({
      ...selectedIds,
      tyre_count: countId,
      truck_weight: ''
    });
  };

  const handleWeightChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const weightId = event.target.value as string;
    setTruckDetails({
      ...truckDetails,
      truck_weight: weightId
    });
    setSelectedIds({
      ...selectedIds,
      truck_weight: weightId
    });
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTruckDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddMore = () => {
    setSplitMaintenanceCost((prev) => [...prev, { value: '', amount: '' }]);
  };

  const handleRemove = (index: number) => {
    setSplitMaintenanceCost((prev) =>
      prev.filter((_, i) => i !== index)
    );
  };

  const handleSplitFieldChange = (
    index: number,
    field: 'value' | 'amount',
    value: string
  ) => {
    setSplitMaintenanceCost((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  const navigate = useNavigate();
  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    const errors: { [key: string]: string } = {};
    if (!truckDetails?.truck_type?.trim()) {
      errors.truck_type = 'Truck Type is Required';
    }
    if (!truckDetails?.chassis_number?.trim()) {
      errors.chassis_number = 'Chassis Number is Required';
    }
    if (!truckDetails?.truck_number?.trim()) {
      errors.truck_number = 'Truck Number is Required';
    }
    if (!truckDetails?.tyre_count?.trim()) {
      errors.tyre_count = 'Tyre Count is Required';
    }
    if (!truckDetails?.truck_weight?.trim()) {
      errors.truck_weight = 'Truck Weight is Required';
    }
    if (!truckDetails?.maintenance_cost?.trim()) {
      errors.maintenance_cost = 'Maintenance Cost is Required';
    }
    if (!truckDetails?.fuel_consumption?.trim()) {
      errors.fuel_consumption = 'Fuel Consumption is Required';
    }
    if (!truckDetails?.tank_size?.trim()) {
      errors.tank_size = 'Tank Size is Required';
    }
    if (!truckDetails?.truck_average?.trim()) {
      errors.truck_average = 'Truck Average is Required';
    }
    if (!truckDetails?.registration_type?.trim()) {
      errors.registration_type = 'Registration Type is Required';
    }
    if (!truckDetails?.registration_date?.trim()) {
      errors.registration_date = 'Registration Date is Required';
    }
    if (!truckDetails?.drivers || truckDetails?.drivers.length === 0) {
      errors.drivers = 'Driver is Required';
    }
    
    if (!selectedCompany) {
      errors.selectedCompany = 'Company is Required';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields.', 'warning');
      return;
    }

    if (Object.keys(errors).length === 0) {
      const breakdown = splitMaintenanceCost.reduce((acc: { tyres?: string; cleaning?: string }, item) => {
        acc.tyres = item.value;
        acc.cleaning = item.amount;
        return acc;
      }, {});
      
      
      const payload = {
        company_id: selectedCompany ? selectedCompany.value : '',
        truck_type_id: selectedIds.truck_type,
        truck_type_tyre_id: selectedIds.tyre_count,
        truck_weight_id: selectedIds.truck_weight,
        truck_number: truckDetails.truck_number,
        chassis: truckDetails.chassis_number,
        registration_date: truckDetails.registration_date,
        fuel_consumption: truckDetails.fuel_consumption,
        tank_size: truckDetails.tank_size,
        truck_average: truckDetails.truck_average,
        maintenance_cost: {
          total: truckDetails.maintenance_cost,
          breakdown: breakdown,
        },
        registration_type: truckDetails.registration_type,
        drivers: truckDetails.drivers,
      };

      if (goodsId) {
        dispatch(updateTruck(payload));
      } else {
        dispatch(createTruck(payload));
      }
    }
  };

  useEffect(()=>{
    if(updateStatus == STATUS.SUCESS){
      handleSnackbar('Truck Updated Successfully', 'success');
      setTimeout(() => {
          navigate('/truck');
        }, 1000);
      dispatch(setUpdateCompanyStatus(STATUS.NOT_STARTED))
      }

      if(updateStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
      }
  },[updateStatus])

  useEffect(()=>{
    if(createStatus == STATUS.SUCESS){
      handleSnackbar('Truck Added Successfully', 'success');
        setTimeout(() => {
          navigate('/truck');
        }, 1000);
        dispatch(setCreateTrucksStatus(STATUS.NOT_STARTED))
      }

      if(createStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either this truck already exist or you entered wrong information', 'warning');
        }, 7000);
      }
  },[createStatus])

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCompanyChange = (event: ChangeEvent<{}>, newCompany: any | null) => {
    setSelectedCompany(newCompany);
  };

  const handleInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
    dispatch(fetchCompanies({ search: newInputValue }));
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setTruckDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const handleSelectDriverChange = (event: ChangeEvent<{}>, newDrivers: any[]) => {
    setTruckDetails((prevDetails) => ({
      ...prevDetails,
      drivers: newDrivers.map((driver) => driver.value),
    }));
  };
  

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      
    <div>
    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem' }}>Truck Type Details</Typography>
      
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width:'32.3%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Company</Typography>
            <FormControl error={formSubmitted && !!formErrors.selectedCompany}>
            <Autocomplete
              value={selectedCompany}
              onChange={handleCompanyChange}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              options={options}
              
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} size='small' placeholder='Select Company'/>}
            />
            {formSubmitted && formErrors.selectedCompany && (
              <FormHelperText>{formErrors.selectedCompany}</FormHelperText>
            )}
          </FormControl>
          </div>


        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width:'35%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Truck Type</Typography>
            <FormControl error={formSubmitted && !!formErrors.truck_type}>
            <Select
                value={truckDetails.truck_type}
                onChange={(e:any) => {handleTypeChange(e)}}    
                size='small'
                displayEmpty
                placeholder='Select Truck Type'
              >
                <MenuItem value="">
                  <Typography>Select Truck Type</Typography>
                </MenuItem>
                {truckTypes.map((truckType) => (
                  <MenuItem key={truckType.truck_type_id} value={truckType.truck_type_id}>
                    {truckType.name}
                  </MenuItem>
                ))}
              </Select>
              {formSubmitted && formErrors.truck_type && (
                <FormHelperText>{formErrors.truck_type}</FormHelperText>
              )}
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' , width:'35%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Tyre Count</Typography>
            <FormControl error={formSubmitted && !!formErrors.tyre_count}>
            <Select
              value={truckDetails.tyre_count}
              onChange={(e:any) => {handleCountChange(e)}}
              size='small'
              disabled={!truckDetails.truck_type}
              displayEmpty
              placeholder='Select Tyre Count'
          >
            <MenuItem value="">
                  <Typography>Select Tyre Count</Typography>
                </MenuItem>
            {truckTypes.find((type) => type.truck_type_id === truckDetails.truck_type)?.truck_tyres?.map((tyre) => (
                <MenuItem key={tyre.id} value={tyre.id}>
                    {tyre.no_of_tyres}
                </MenuItem>
            ))}
        </Select>
        {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width:'35%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Truck Weight</Typography>
            <FormControl error={formSubmitted && !!formErrors.truck_weight}>
            <Select
            value={truckDetails.truck_weight}
            onChange={(e:any) => {handleWeightChange(e)}}
            displayEmpty
            placeholder="Select truck weight"
            disabled={!truckDetails.tyre_count} 
            size='small'
              >
                  <MenuItem value="">
                      Select truck weight
                  </MenuItem>
                  {truckTypes.find((type) => type.truck_type_id === truckDetails.truck_type)?.truck_tyres?.map((tyre) => (
                      tyre.weights.map((weight) => (
                          <MenuItem key={weight.id} value={weight.id}>
                              {weight.weight}
                          </MenuItem>
                      ))
                  ))}
              </Select>
              {formSubmitted && formErrors.truck_weight && (
                <FormHelperText>{formErrors.truck_weight}</FormHelperText>
              )}
            </FormControl>
          </div>
        </div>

        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column', width:'32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Truck Number</Typography>
            <TextField
              placeholder="Enter Truck Number"
              name="truck_number"
              value={truckDetails.truck_number}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.truck_number ? true : false}
              helperText={formSubmitted && formErrors.truck_number}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width:'32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Chassis Number</Typography>
            <TextField
              placeholder="Enter Chassis Number"
              name="chassis_number"
              value={truckDetails.chassis_number}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.chassis_number ? true : false}
              helperText={formSubmitted && formErrors.chassis_number}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width:'32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Registration Date</Typography>
            <input type='date' 
            name='registration_date' 
            onChange={handleFieldChange} 
            value={truckDetails.registration_date}
            style={{minHeight:'40px', borderRadius:'4px', border:'1px solid', borderColor:'#939ba2', padding:'8px, 16px, 8px, 16px', gap:'12px'}} 
            // error={formSubmitted && formErrors.truck_type ? true : false}
            // helperText={formSubmitted && formErrors.truck_type}
            />
          </div>
        </div>
         <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem', marginTop: '3rem' }} variant="h6" gutterBottom>
        Previous Maintenance Report
      </Typography>
      
        <div style={{display:'flex'}}>
        <div style={{ display: 'flex', flexDirection: 'column', width:'35%',marginRight: '20px' }}>
          <Typography sx={{ backgroundColor: '#F7F9FA', fontFamily: 'Montserrat', fontSize: '10px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }} variant="subtitle1">Maintenance Cost</Typography>
          <TextField
            placeholder="Enter Maintenance Cost"
            type='number'
            value={truckDetails.maintenance_cost}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTruckDetails({ ...truckDetails, maintenance_cost: e.target.value })
            }
            size="small"
            required
            fullWidth
            InputLabelProps={{
                shrink: false,
              }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeRoundedIcon />
                </InputAdornment>
              ),
            }}
            error={formSubmitted && formErrors.maintenance_cost ? true : false}
              helperText={formSubmitted && formErrors.maintenance_cost}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width:'35%',marginRight: '20px' }}>
          <Typography sx={{ backgroundColor: '#F7F9FA', fontFamily: 'Montserrat', fontSize: '10px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }} variant="subtitle1">Fuel Consumption (LITRES)</Typography>
          <TextField
            placeholder="Enter Fuel Consumed"
            type='number'
            value={truckDetails.fuel_consumption}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTruckDetails({ ...truckDetails, fuel_consumption: e.target.value })
            }
            size="small"
            required
            fullWidth
            InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.fuel_consumption ? true : false}
              helperText={formSubmitted && formErrors.fuel_consumption}
          />
        </div>
        
        <div style={{ display: 'flex', flexDirection: 'column' , width:'35%', marginRight:'20px'}}>
          <Typography  sx={{ backgroundColor: '#F7F9FA', fontFamily: 'Montserrat', fontSize: '10px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }} variant="subtitle1">Tank Size (LITRES)</Typography>
          <TextField
            placeholder="Enter Fuel Tank Size"
            type='number'
            value={truckDetails.tank_size}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTruckDetails({ ...truckDetails, tank_size: e.target.value })
            }
            size="small"
            required
            fullWidth
            InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.tank_size ? true : false}
              helperText={formSubmitted && formErrors.tank_size}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' , width:'35%'}}>
          <Typography  sx={{ backgroundColor: '#F7F9FA', fontFamily: 'Montserrat', fontSize: '10px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }} variant="subtitle1">Truck Average</Typography>
          <TextField
            placeholder="Enter Truck Average"
            value={truckDetails.truck_average}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTruckDetails({ ...truckDetails, truck_average: e.target.value })
            }
            size="small"
            required
            fullWidth
            InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.truck_average ? true : false}
              helperText={formSubmitted && formErrors.truck_average}
          />
        </div>
        </div>
        {splitMaintenanceCost.map((item, index) => (
          <div key={index} style={{ display: 'flex', marginTop: '20px' }}>
            <TextField
              placeholder="Service Name"
              value={item.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleSplitFieldChange(index, 'value', e.target.value)
              }
              size="small"
              required
              fullWidth
            />
            <TextField
              placeholder="Amount"
              type='number'
              value={item.amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleSplitFieldChange(index, 'amount', e.target.value)
              }
              size="small"
              required
              fullWidth
              sx={{ marginLeft: '20px' }}
            />
           
      <CloseIcon onClick={() => handleRemove(index)} sx={{marginTop:'7px', marginLeft:'5px', cursor:'pointer'}}/>
  
          </div>
          
        ))}

        <Button
          onClick={handleAddMore}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
            letterSpacing: '0.025em',
            textAlign: 'center',
            color: 'inherit',
            marginRight: '18rem',
            marginTop:'15px',
            marginBottom:'25px',
            border: 'none',
            textTransform: 'none',
            '&:hover': {
              border: 'none',
              backgroundColor: 'none',
            },
            
          }}
        >
          Split Maintenance Cost
        </Button>


        <div style={{display:'flex'}}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D',marginBottom: '0.5rem', marginTop: '2rem', marginRight:'36%' }}>Registration Details</Typography>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D',marginBottom: '0.5rem', marginTop: '2rem' }}>Driver Details</Typography>
        </div>
        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column', width:'49%', marginRight:'20px' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Type of Registration</Typography>
            <FormControl  error={formSubmitted && formErrors.registration_type ? true : false}>
              <Select
                value={truckDetails.registration_type}
                onChange={(e: SelectChangeEvent<string>) => handleSelectFieldChange(e, 'registration_type')}
                name="value_unit"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size="small"
                
              >
                <MenuItem value="">
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '400', color: '#A8A8A8' }}>Select Unit</Typography>
                </MenuItem>
                <MenuItem value="OPEN">Open for All</MenuItem>
                <MenuItem value="CONTRACT">Contract Basis</MenuItem>
              </Select>
              {formSubmitted && formErrors.registration_type && (
                <FormHelperText>{formErrors.registration_type}</FormHelperText>
              )}
            </FormControl>
          </div>
        

          <div style={{ display: 'flex', flexDirection: 'column', width:'55%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Link Driver</Typography>
            <FormControl error={formSubmitted && formErrors.drivers ? true : false}>
        <Autocomplete
          multiple
          size="small"
          options={filteredUsers.map((driver) => ({ label: `${driver.user_details.first_name} ${driver.user_details.last_name}`, value: driver.user_id }))}
          value={truckDetails.drivers.map((driverId) => {
            const foundDriver = filteredUsers.find((driver) => driver.user_id === driverId);
            return foundDriver ? { label: `${foundDriver.user_details.first_name} ${foundDriver.user_details.last_name}`, value: driverId } : undefined;
          }).filter(Boolean)}
          onChange={handleSelectDriverChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Drivers"
              placeholder="Search and select drivers"
              fullWidth
              error={formSubmitted && !!formErrors.drivers}
              helperText={formSubmitted && formErrors.drivers ? formErrors.drivers : ''}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              option && (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                />
              )
            ))
          }
        />
      </FormControl>
          </div>  
        </div>
        
        <Button type="submit" variant="contained" sx={{ mt: 2, fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '100%', backgroundColor: '#03BBD2', borderRadius: '8px' }}> Save Truck</Button>
      </div>

      <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Box>
  );
};
  
export default TruckForm;