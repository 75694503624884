import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from './shared/theme/ThemeContext';
import AppRoutes from './routes/routes'
import React from 'react';


const App: React.FC = () => {

  return (
  <ThemeProvider>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </ThemeProvider>
  )
};

export default App;
