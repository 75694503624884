const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZV9udW1iZXIiOiI5ODc2NTQzMjEwIiwib3RwIjoiODY2NjgyIiwidXNlcl9pZCI6IjAzZGVlNDhkLTJiYTEtNDMzMy1hY2Y5LTZjZjVjZWM0MmY0MSJ9.Sz6HrX_OPmiUML5PXN6kBMmdkO3dx0V9mU5PDNSgmb0';

// Function to set a cookie with a specific name, value, and expiration time in days
export const setAuthTokenInLocalStorage = (value:any) => {
    localStorage.setItem('authToken', value);
};

// Function to remove the auth token from localStorage (Logout)
export const removeAuthTokenFromLocalStorage = () => {
    localStorage.removeItem('authToken');
};
