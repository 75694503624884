import React from 'react';
import { Modal, Typography, Divider, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  selectedUser: any;
  expenditures: any[];
}

const ExpendituresModal: React.FC<ModalProps> = ({ open, handleClose, selectedUser, expenditures }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '16px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '18px', display: 'flex', alignItems: 'center' }}>
              All Expenditures <CloseIcon onClick={handleClose} fontSize='small' style={{ color: '#1E2C38', cursor: 'pointer', marginLeft: '150px' }} />
            </Typography>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '500', color: '#404040', fontSize: '12px', lineHeight: '18px' }}>
              {selectedUser ? selectedUser.name : ''}
            </Typography>
          </div>
        </div>
        <Divider style={{ marginBottom: '10px' }} />
        {expenditures.length > 0 ? (
          <Table style={{ minWidth: 200, padding: 16 }} aria-label="simple table" size='small'>
            <TableHead sx={{ backgroundColor: '#F7F9FA', height:'22px', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
              <TableRow>
                <TableCell style={{ fontWeight: '600', fontSize: '10px', color: '#13282A', lineHeight: '14px', letterSpacing: '1px', fontFamily: 'Montserrat' }}>NAME</TableCell>
                <TableCell style={{ fontWeight: '600', fontSize: '10px', color: '#13282A', lineHeight: '14px', letterSpacing: '1px', fontFamily: 'Montserrat' }}>RATE</TableCell>
                <TableCell style={{ fontWeight: '600', fontSize: '10px', color: '#13282A', lineHeight: '14px', letterSpacing: '1px', fontFamily: 'Montserrat' }}>UNIT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenditures.map((expenditure: any, index: number) => (
                <TableRow key={index} sx={{'&:last-child td, &:last-child th': { borderBottom: 0 }}}> 
                  <TableCell style={{ fontFamily: 'Montserrat', color: '#13282A', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}>{expenditure.name}</TableCell>
                  <TableCell style={{ fontFamily: 'Montserrat', color: '#13282A', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}>{expenditure.rate}</TableCell>
                  <TableCell style={{ fontFamily: 'Montserrat', color: '#13282A', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}>{expenditure.unit}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1" gutterBottom style={{ fontFamily: 'Montserrat' }}>
            No expenditures for this user.
          </Typography>
        )}
      </div>
    </Modal>
  );
};

export default ExpendituresModal;
