import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateTruckPayload {
  id: string;
  company_id: string;
  company: {
    id: string;
    name: string;
    role: string;
  };
  truck_type_id: string;
  truck_type_tyre_id: string;
  truck_weight_id: string;
  truck_type: {
    id: string;
    name: string;
    photo_url: string;
  };
  truck_type_tyre: {
    id: string;
    truck_type_id: string;
    no_of_tyres: number;
  };
  truck_weight: {
    id: string;
    truck_type_id: string;
    truck_type_tyre_id: string;
    weight: number;
    unit: string;
  };
  truck_number: string;
  registration_date: string;
  registration_type: string;
  fuel_consumption: number;
  chassis: string;
  tank_size: number;
  maintenance_cost: number;
  created_at: string;
  updated_at: string;
}


interface TruckTypeState {
    admins: {
      count: number | undefined;
    results: CreateTruckPayload[];
  }
  error: string | null;
  getAdminStatus: STATUS;
}

const initialState: TruckTypeState = {
    admins: { 
      count: undefined,
      results: [] },
  error: null,
  getAdminStatus: STATUS.NOT_STARTED,
};



export const getTruckAdmin = createAsyncThunk(
  'truck/get',
  async ({search,page ,sort, order , pageSize}: {search: string ,page:number, sort: string, order: string, pageSize: number }, thunkAPI) => {
    const response = await api.get(`truck/?role=ADMIN&search=${search}&page=${page}&page_size=${pageSize}&sort=${sort}&order=${order}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);


const admintruckSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTruckAdmin.pending, (state) => {
        state.getAdminStatus = STATUS.PENDING;
      })
      .addCase(getTruckAdmin.fulfilled, (state, action) => {
        console.log('Get Truck Fulfilled:', action.payload); 
        state.admins = action.payload; 
        state.getAdminStatus = STATUS.SUCESS; 
      })
      .addCase(getTruckAdmin.rejected, (state, action) => {
        console.error('Get Truck Rejected:', action.error.message ?? 'An error occurred'); 
        state.getAdminStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
  },
});

export default admintruckSlice.reducer;
