import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTruckType,
  getTruckTypes,
  setUpdateTruckTypeStatus,
  updateTruckType,
} from '../redux/truck type slice/truckTypeSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Checkbox from '@mui/material/Checkbox';
import { Button, Typography,TextField, SelectChangeEvent, MenuItem, FormControl, Select, CircularProgress, Snackbar, SnackbarContent } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import Error from '../error';
import SearchIcon from '@mui/icons-material/Search';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddButton } from '../../assets/button';
import DeleteModal from './deleteModal';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface TruckType {
  id?: string;
  name: string;
  photourl?: string;
  truck_tyres: {
    id?: string;
    no_of_tyres: number;
    truck_weights: {
      id?: string;
      weight: number;
      unit: 'KG' | 'TON';
    }[];
  }[];
}

export default function AdminTable() {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedRow, setEditedRow] = useState<any>({});
  const [hoveredWeight, setHoveredWeight] = useState<string | null>(null); 
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

  const truckTypes = useSelector((state: RootState) => state.trucktypes.truckTypes);
  const getTruckTypesStatus = useSelector((state: RootState) => state.trucktypes.getStatus);
  const updateTruckTypesStatus = useSelector((state: RootState) => state.trucktypes.updateStatus);
  const deleteTruckTypesStatus = useSelector((state: RootState) => state.trucktypes.deleteStatus);

  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [truckTypeIdToDelete, setTruckTypeIdToDelete] = useState(""); 
  
  const handleDelete = (id: string) => {
    setTruckTypeIdToDelete(id);
    setShowDeleteModal(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    if (truckTypeIdToDelete) {
      await dispatch(deleteTruckType({ id: truckTypeIdToDelete }));
      dispatch(getTruckTypes({ search: searchTerm, page: currentPage, sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }));
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false); 
  };

  useEffect(() => {
    setLoading(true)
    dispatch(getTruckTypes({search: searchTerm, page: currentPage, sort: sortColumn, order: sortOrder , pageSize: itemsPerPage}))
    .then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch(() => setLoading(false));
  }, [dispatch,searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);

  const handleSort = (column: string) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAddTruckTypeClick = () => {
    navigate('/add-trucktype');
  };

  const handleClick = (weight: string) => {
    setHoveredWeight(weight === hoveredWeight ? null : weight);
  };
  
  useEffect(()=>{
    if(deleteTruckTypesStatus == STATUS.SUCESS){
      handleSnackbar('Truck Type Deleted Successfully.', 'success');
    }
    if(deleteTruckTypesStatus == STATUS.FAILED){
      handleSnackbar('Error Occured, Please Try Again!', 'error');
    }
  },[deleteTruckTypesStatus])

  const handlePaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (row: any) => {
    handleSnackbar('We are redirecting you in editing mode.', 'info');
    setTimeout(() => {
      navigate(`/edit-trucktype/${row.id}`, { state: { truckTypes : row, id: row.id } });
    }, 2000);   
  };

  const handleSave = (editedTruckType: any) => {
    dispatch(updateTruckType({ truckId: editedTruckType.id, payload: editedTruckType }));
    setEditingId(null);
    setEditedRow({});
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value;
    setEditedRow((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const [showInBooking, setShowInBooking] = useState<boolean>(false);

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setShowInBooking(checked);
    const payload = {
      id: id,
      show_in_booking: checked,
    }
    dispatch(updateTruckType(payload))
  };

  React.useEffect(()=>{
    if(updateTruckTypesStatus == STATUS.SUCESS){
      if (showInBooking) {
        handleSnackbar('You added this item as active.', 'success');
      } else {
        handleSnackbar('You added this item as inactive.', 'error');
      }
      dispatch(setUpdateTruckTypeStatus(STATUS.NOT_STARTED))
      }

      if(updateTruckTypesStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
      }
  }, [updateTruckTypesStatus])
  

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  return (
    <>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '1rem'  }}>
    <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
              value={searchTerm}
              size="small"
              onChange={handleSearchChange}
              sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
              InputProps={{
                startAdornment: (
                  <SearchIcon color="action" />
                )
              }}
              InputLabelProps={{
                shrink: false
              }}
            />    
               <div style={{display:'flex', justifyContent:'flex-end'}}>
        <div style={{display:'flex', marginRight:'20px'}}>
          <Typography sx={{paddingTop:'25px', fontSize:'12px',lineHeight:'18px', fontWeight:'600', color:'#10383D'}}>SHOW</Typography>
              <FormControl sx={{ m: 1, minWidth: 40 }}>
              <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    IconComponent={ArrowDropDownIcon}
                    value={itemsPerPage} 
                    sx={{  height: '40px' }}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
              </FormControl>
              <Typography sx={{paddingTop:'25px', fontSize:'12px',lineHeight:'18px', fontWeight:'600', color:'#10383D'}}>ENTRIES</Typography>
              </div>
              <AddButton onClick={handleAddTruckTypeClick}>Add Truck Type</AddButton>

                </div>
              </div>
              <TableContainer>
              {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell onClick={() => handleSort('name')}>
              <Typography sx={{ marginTop: '10px', fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>Image</Typography>
            </TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>Truck Type</TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>NO. OF TYRES</TableCell>
            <TableCell align="center" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>ACTIONS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getTruckTypesStatus === STATUS.SUCESS && truckTypes && truckTypes.length > 0 ? (
            truckTypes.map((row: any) => {
              console.log({row})
              return (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                <TableCell align="right" sx={{ width: '50px' }}>
                  <Checkbox
                    {...label}
                    value={row.id}
                    checked={row.show_in_booking}
                    onChange={(e) => {
                      handleCheckboxChange(row.id, e.target.checked);
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#03BBD2',
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="left" sx={{ padding: '8px 0' }}>
                  <img
                    src={row.photo_url}
                    alt="Truck Image"
                    style={{
                      width: '100px',
                      height: 'auto',
                      marginRight: '10px',
                      borderRadius:'4px'
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.name}
                </TableCell>
                <TableCell align="left">
                  {row.truck_tyres && row.truck_tyres.map((tyre: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: 'inline-block',
                        marginRight: '10px',
                        backgroundColor: '#E7F0F1',
                        width: 'auto',
                        height: 'auto',
                        padding: '5px',
                        position: 'relative',
                      }}
                      onClick={() => handleClick(tyre.weights && tyre.weights.length > 0 ? `${tyre.weights[0].weight} ${tyre.weights[0].unit}` : 'N/A')}
                    >
                      {tyre.no_of_tyres}
                      {hoveredWeight === `${tyre.weights && tyre.weights.length > 0 ? tyre.weights[0].weight : 'N/A'} ${tyre.weights && tyre.weights.length > 0 ? tyre.weights[0].unit : 'N/A'}` && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: '#FFFFFF',
                            color: '#13282A',
                            borderRadius: '4px',
                            minWidth:'82px',
                            minHeight:'auto',
                            zIndex: '999', 
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          <Typography sx={{padding:'8px, 12px, 8px, 12px',fontSize: '10px', fontWeight:'600', textAlign:'center'}}> Weight</Typography>
                          <Typography sx={{padding:'8px, 12px, 8px, 12px',fontSize: '10px', textAlign:'center', marginTop:'10px'}}> {`${tyre.weights && tyre.weights.length > 0 ? tyre.weights[0].weight : 'N/A'} ${tyre.weights && tyre.weights.length > 0 ? tyre.weights[0].unit : 'N/A'}`} </Typography>
                        </div>
                      )}
                  </div>
                  ))}
                </TableCell>
                {/* <TableCell align="left">
                  {row.truck_tyres && row.truck_tyres.map((tyre: any, index: number) => (
                    <div key={index}>
                      {tyre.weights && tyre.weights.length > 0 ? `Weight: ${tyre.weights[0].weight} ${tyre.weights[0].unit}` : 'Weight: N/A'}
                    </div>
                  ))}
                </TableCell> */}
                <TableCell align="center">
                  <EditNoteOutlinedIcon onClick={() => handleEdit(row)} />
                  <DeleteOutlineOutlinedIcon
                    onClick={() => handleDelete(row.id)}
                    sx={{ color: '#C00F00', cursor: 'pointer', marginLeft: '8px' }}
                  />
                </TableCell>
              </TableRow>
              )

            })
          ) : (
              <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                <TableCell colSpan={8} >
                  <Error message="There are no truck types added as of yet. You can add a truck type to see it here."/>
                </TableCell>
              </TableRow>
          )}
        </TableBody>
      </Table>
  )}
  <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
    </TableContainer>
    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}> 
      <div style={{ display: 'flex', alignItems: 'center' }}>
    <Typography sx={{ color: '#10383D', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
      {getTruckTypesStatus === STATUS.SUCESS ? `${totalPages} to 
      ${Math.min(totalPages + (itemsPerPage || 10) - 1, truckTypes && truckTypes.length)} of 
      ${truckTypes && truckTypes.length}` : '-'}
    </Typography>
  </div>
            </Typography>
          

            </div>
        <Button
          onClick={() => handlePaginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeftIcon
              sx={{
                color: 'black',
                backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
        <Button
          onClick={() => handlePaginate(currentPage + 1)}
          disabled={!truckTypes || truckTypes.length < itemsPerPage}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronRightIcon
              sx={{
                color: 'black',
                backgroundColor: !truckTypes || truckTypes.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
      </div>
      <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',

            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </>
  );
}
