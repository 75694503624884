import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, setSelectedUser} from '../redux/expenses/userSlice'; 
import { getExpenditures} from '../redux/expenses/expendituesSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Divider} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Error from '../error';
import Backdrop from './backdrop';
import ExpendituresModal from './expenditureModal';

const UsersTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: RootState) => state.users.users);
  const getUsersStatus = useSelector((state: RootState) => state.users.getUsersStatus);
  const expenditures = useSelector((state: RootState) => state.expenditures.expenditures);
  const getExpenditureStatus = useSelector((state: RootState) => state.expenditures.getExpenditureStatus);
  const selectedUser = useSelector((state: RootState) => state.users.selectedUser);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false); 
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(getUsers({ page: currentPage, pageSize: itemsPerPage }))
    .then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch(() => setLoading(false));
  }, [dispatch, currentPage, itemsPerPage]);

  const handleViewExpenditures = (user: any) => {
    dispatch(setSelectedUser(user));
    dispatch(getExpenditures(user.id));
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const handlePaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  return (
    <>
      {/* <Backdrop open={popoverOpen} onClick={handleClosePopover} /> */}
      <TableContainer sx={{ marginTop: 4 }}>
      {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
              <TableRow>
                <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>USER NAME</TableCell>
                <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>USER TYPE</TableCell>
                <TableCell align="right" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }}>NO. OF EXPENDITURES</TableCell>
                <TableCell sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px', textAlign:'center' }}>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getUsersStatus === STATUS.SUCESS && users && users.results.length > 0 ? ( users.results.map((row: any) => (
                  <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': { borderBottom: 0 }}}>
                    <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left' }}>{row.name}</TableCell>
                    <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign:'left' }}>{row.role}</TableCell>
                    <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'right' }}>{row.num_expense}</TableCell>
                    <TableCell style={{ color: '#03BBD2',fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign:'center'  }}>
                      {row.num_expense > 0 ? (
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent:'center', marginLeft:'37%' }} onClick={() => handleViewExpenditures(row)}>
                          View All Expenditures <KeyboardArrowRightIcon />
                        </div>
                      ) : (
                        <div style={{ cursor: 'pointer',  marginLeft:'20%' }}>No Expenditures</div>
                      )}
                    </TableCell>
                  </TableRow>
                 ))
                 ) : (
                   <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                     <TableCell colSpan={8} >
                       <Error message="There are no users added as of yet. You can add a user to see it here."/>
                     </TableCell>
                   </TableRow>
                 )}
            </TableBody>
          </Table>
        )}
        
        
      </TableContainer>
      <ExpendituresModal open={modalOpen} handleClose={handleCloseModal} selectedUser={selectedUser} expenditures={expenditures} />
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}>  
              <Typography sx={{ color: '#10383D', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
                
            {getUsersStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getUsersStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, users.count || 0) 
              : '-'}{' '}
            of {getUsersStatus === STATUS.SUCESS ? (users.count || 0) : '-'}
          </Typography>
              </Typography>
          </div>
          <Button
            onClick={() => handlePaginate(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ChevronLeftIcon
                sx={{
                  color: 'black',
                  backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                  width: '32px',
                  height: '32px',
                }}
              />
            </div>
          </Button>
          <Button
            onClick={() => handlePaginate(currentPage + 1)}
            disabled={currentPage >= totalPages}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ChevronRightIcon
                sx={{
                  color: 'black',
                  backgroundColor: currentPage >= totalPages ? '#F3F5F7' : '#E8EBEE',
                  width: '32px',
                  height: '32px',
                }}
              />
            </div>
          </Button>
        </div>
    </>
  );
};

export default UsersTable;
