import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface dashboardState {
  dashboard: {
    stats: {
        enquires: string,
        bookings: string,
        current_trips: string,
        trips_in_progress: string,
        transport_count: string,
        motor_owner_count: string
        routes_count: string
        assigned_trucks: string
    }
  };
  error: string | null;
  getDashboardStatus: STATUS,
}


const initialState: dashboardState = {
  dashboard: { 
    stats:{
        enquires: '',
        bookings: '',
        current_trips: '',
        trips_in_progress: '',
        transport_count: '',
        motor_owner_count: '',
        routes_count: '',
        assigned_trucks: '',
    }
   },
  error: null,
  getDashboardStatus: STATUS.NOT_STARTED,
};

export const getDashboard = createAsyncThunk(
  'enquiry/stats/get',
  async (_, thunkAPI) => {
    const response = await api.get('enquiry/stats');
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state, action) => {
        state.getDashboardStatus = STATUS.PENDING
        console.log('GET ROUTES PENDING');
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.dashboard = action.payload;
        state.getDashboardStatus = STATUS.SUCESS; 
      })
      .addCase(getDashboard.rejected, (state, action) => {
        console.log('GET ROUTES REJECTED');
        state.getDashboardStatus = STATUS.FAILED;
      })
  },
});

export default dashboardReducer.reducer;
