import { Button, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { addCompanyDetails, setBiddingType, setPayment } from '../redux/reducers/biddingForm';

export interface FormState {
  type: string;
  unit: string;
  freight: string;
  total_freight: string;
  freight_amount: string;
  number_of_trucks: string;
}

const BiddingForm = () => {
  const companyDetails = useSelector((state:RootState) => state.bidForm.companyDetails);
  const payment = useSelector((state:RootState) => state.bidForm.payment);
  const bidding_types = useSelector((state: RootState) => state.bidForm.bidding_types);

  const [firstRowFilled, setFirstRowFilled] = useState(false); 




  const dispatch = useDispatch<AppDispatch>();

  const handleFieldChange = (index: number, fieldName: keyof FormState, value: string) => {
    dispatch(addCompanyDetails({ index, [fieldName]: value }));
    
  };

  const handleSelectPaymentChange = (event: SelectChangeEvent<string>) => {
    dispatch(setPayment(event.target.value));
  };

  const handleAddMore = () => {
    dispatch(addCompanyDetails({ index: companyDetails.length, ...companyDetails[companyDetails.length - 1] }));
  };
  
  const handleBiddingTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setBiddingType(event.target.value));
  };

  return (
    <>
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" sx={{color:'#10383D', marginTop:'2rem', marginBottom:'5px'}}>Biding Type</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={bidding_types}
        onChange={handleBiddingTypeChange}
      >
        <FormControlLabel value="OPEN" control={<Radio />} sx={{color:'#10383D'}} label="Open for Bid"/>

        <FormControlLabel value="FIXED" control={<Radio />} sx={{color:'#10383D'}} label="Fixed Price Contract" />

      </RadioGroup>
    </FormControl>

    <div style={{display:'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Payment Condition</Typography>
          <Select
            value={payment}
            onChange={handleSelectPaymentChange}
            fullWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="" disabled>
              Select payment condition
            </MenuItem>
            <MenuItem value="TO PAY">To Pay</MenuItem>
            <MenuItem value="ADVANCED">Advanced</MenuItem>
          </Select>
        </div>
        </div>

        {companyDetails.map((details, index: number) => (
  <div key={`companyDetails-${index}`}>
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select User</Typography>
        {index === 0 ? (
          <Select
            value="All"
            disabled
            fullWidth
            displayEmpty
            size='small'
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="All">All</MenuItem>
          </Select>
        ) : (
          <Select
            value={details.type}
            onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
            fullWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="Corporate">Corporate</MenuItem>
            <MenuItem value="Motor Owner">Motor Owner</MenuItem>
            <MenuItem value="Transporter">Transporter</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Unit</Typography>
          <Select
            value={details.unit}
            onChange={(e) => handleFieldChange(index, 'unit', e.target.value)}
            fullWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="" disabled>
              Select unit
            </MenuItem>
            <MenuItem value="KG">
              Kg
            </MenuItem>
          </Select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Freight (Rate)</Typography>
        <TextField
              placeholder="Enter freight rate"
              name="freight"
              value={details.freight}
              onChange={(e) => handleFieldChange(index, 'freight', e.target.value)}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Total Freight</Typography>
        <TextField
              placeholder="Enter total freight"
              name="total_freight"
                value={details.total_freight}
                onChange={(e) => handleFieldChange(index, 'total_freight', e.target.value)} 
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Freight Amount</Typography>
        <TextField
              placeholder='Enter freight amount'
              name="freight_amount"
              value={details.freight_amount}
              onChange={(e) => handleFieldChange(index, 'freight_amount', e.target.value)}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
      </div>


        </div>
        </div>
      ))}
        <Button
          onClick={handleAddMore}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '0.025em',
          textAlign: 'center',
          color: 'inherit',
          marginRight: '18rem',
          marginTop:'1rem',
          border: 'none',
          textTransform: 'none',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
          }}
          >
            Add More
          </Button>
    

       
    </>
  )
}

export default BiddingForm