import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { useNavigate } from 'react-router-dom';

interface CustomCardProps {
  subtitle: string;
  count: string;
  sx?: React.CSSProperties;
  navigation: string;
  onClick?: () => void;
}

const CustomCard: React.FC<CustomCardProps> = ({ subtitle, count, sx, navigation }) => {
    const navigate = useNavigate(); 

    const handleClick = () => {
        navigate(navigation); 
      };

  return (
    <Card sx={{ width: '24%', ...sx }} onClick={handleClick}>
      <CardContent>
        <Typography sx={{mb: 1.5, fontSize: 14, color:'#FFFFFF' }} gutterBottom>
          <TripOriginIcon/>
        </Typography>

        <Typography sx={{ color:'#FFFFFF'  }}>
          {subtitle}
        </Typography>
        <Typography sx={{ color:'#FFFFFF', fontSize:32 }}>
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
