import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';
import { setAuthTokenInLocalStorage } from '../../localstorage';
import { getAuthTokenFromLocalStorage } from '../../utils/api'; 
import axios from 'axios';
import { BASE_URL } from '../../utils/constants';
import LoginImage from '../../assets/img/logo.png';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SuccessIcon from '../../assets/img/Vector.png';

const defaultTheme = createTheme();

export default function SignInSide() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [showOTPField, setShowOTPField] = useState(false);
  const [resentOTP, setResentOTP] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [otpError, setOtpError] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // const handleVerifyPhoneNumber = () => {
  //   if (phoneNumber.length !== 10) {
  //     setPhoneNumberError('Phone number must be of 10 digits');
  //     return;
  //   }
    
  //   setShowOTPField(true);
  // };

  const handleVerifyPhoneNumber = async () => {
    if (phoneNumber.length !== 10) {
      setPhoneNumberError('Phone number must be of 10 digits');
      return;
    }
  
    try {
      const response = await axios.post(BASE_URL + 'auth/otp-request/', { phone_number: phoneNumber });
      if (response.status === 200) {
        setShowOTPField(true);
        setResentOTP(true);
        handleSnackbar('OTP sent successfully!', 'success');
      }
    } catch (error) {
      handleSnackbar('Failed to send OTP. Please try again.', 'error');
    }
  };
  

  const handleResendOTP = async () => {
    setResentOTP(true);
    setOtp('');
    setOtpError('');
    try {
      const response = await axios.post(BASE_URL + 'auth/otp-request/', { phone_number: phoneNumber });
      if (response.status === 200) {
        handleSnackbar('OTP resent successfully!', 'success');
      }
    } catch (error) {
      handleSnackbar('Failed to resend OTP. Please try again.', 'error');
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (showOTPField) {
      if (otp.length !== 6) {
        handleSnackbar('OTP must be of 6 digits', 'info')
        return;
      } else {
        setOtpError('');
      }

      try {
        // Make HTTP POST request to validate OTP
        const response = await axios.post(BASE_URL + 'auth/otp-verification-login/', { phone_number: phoneNumber, otp });

        // Handle response from the backend
        if (response.status === 200) {
          const authToken = response.data['authentication-token'];
          if (authToken) {
            // Set authentication status to true in local storage
            localStorage.setItem('authenticated', 'true');
            setAuthTokenInLocalStorage(authToken);
            // Display success message
            handleSnackbar('Login Successful', 'success');
            // Redirect to the dashboard after successful login
            setTimeout(() => {
              window.location.href = '/dashboard';
            }, 1000);
          }
        }
      } catch (error) {
        // Handle request failure
        handleSnackbar('Invalid OTP. Please try again.', 'error');
        setOtpError('You’ve entered an incorrect OTP. Please try again or resend another OTP.');
      }
    }
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, '');
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setPhoneNumber(value);
    if (value.length !== 10) {
      setPhoneNumberError('Phone number must be of 10 digits');
    } else {
      setPhoneNumberError('');
    }
  };
  

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
    setOtpError('');
  };

  useEffect(() => {
    const isAuthenticated = getAuthTokenFromLocalStorage() !== null;
    if (isAuthenticated) {
      window.location.href = '/dashboard';
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={0}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://truckexpress.in/Corporate/index/images/business-man.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={LoginImage} alt="Login" style={{ marginBottom: '4rem', marginRight: '21rem', width: '140px', height: '117.6px' }} />
            <Container maxWidth="lg">
              <Typography
                component="div"
                variant="h6"
                sx={{
                  mt: 1,
                  mb: 5,
                  fontSize: 20,
                  fontWeight: 700,
                }}
              >
                Nice to see you again!
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <label htmlFor="phoneNumber" style={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '400', lineHeight: '20px' }}>Mobile Number</label>
                <TextField
                  required
                  fullWidth
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="eg. 9123456789"
                  type="tel"
                  autoComplete="tel"
                  size="small"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                  maxLength={10}
                  {...({} as any)}
                />


                <br /><br />
                {showOTPField && (
                  <>
                    <label htmlFor="otp" style={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '400', lineHeight: '20px' }}>OTP</label>
                    <TextField
                      required
                      fullWidth
                      name="otp"
                      placeholder="Enter OTP"
                      type="text"
                      id="otp"
                      autoComplete="one-time-code"
                      size="small"
                      value={otp}
                      onChange={handleOtpChange}
                      error={!!otpError}
                      sx={{ borderColor: otpError ? 'red' : '#DDDDDD', border: '1px', marginBottom: '10px' }}
                    />
                  </>
                )}

                {showOTPField &&  (
                  <>
                    <div style={{display:'flex'}}>
                      <Typography sx={{fontFamily:'Montserrat',fontSize:'12px',fontWeight:'600', lineHeight:'20px',letterSpacing:'0.3px', minWidth:'9rem'}}>Didn't receive code?</Typography>
                      <Button
                        fullWidth
                        variant="text"
                        onClick={handleResendOTP}
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          textAlign: 'center',
                          border: 'none',
                          textTransform: 'none',
                          '&:hover': {
                            border: 'none',
                            backgroundColor: 'none',
                          },
                          width: '5rem', 
                          color: '#03BBD2',
                          p: 0, 
                          m: 0, 
                        }}
                      >
                        Resend OTP
                      </Button>
                    </div>
                    <div style={{color:'red', marginTop:'2rem'}}>
                  {/* <ReportProblemOutlinedIcon fontSize='small' sx={{color:'red'}}/>  */}
                  {otpError}
                </div>
                  </>
                  
                )}

                

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={showOTPField ? () => {} : handleVerifyPhoneNumber}
                  sx={{
                    mt:'1rem',
                    minWidth:'384px',
                    minHeight:'40px',
                    fontFamily:'Montserrat',
                    fontSize:'14px',
                    fontWeight:'600px',
                    lineHeight:'24px',
                    padding:'8px, 24px, 8px, 24px',
                    backgroundColor: '#03BBD2',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#03BBD2',
                    },
                  }}
                >
                  {showOTPField ? 'Verify OTP' : 'Verify Phone Number'}
                </Button>
              </Box>
            </Container>
            
          </Box>
          <Typography sx={{marginTop:'10rem', textAlign:'center', fontFamily:'Montserrat', fontWeight:'400', fontSize:'12px',lineHeight:'16px', color:'#666666'}}>truckexpress.in2024</Typography>
        </Grid>
        
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={9000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ borderRadius:'8px' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity || 'success'} sx={{ width: '100%' }}>
        <div style={{display:'flex'}}>   
              <div style={{fontFamily:'Montserrat', fontWeight:'600'}}>{snackbarMessage}</div>
            </div>
        </Alert>
      </Snackbar>

    </ThemeProvider>
  );
}
