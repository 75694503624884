import React from 'react';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DeleteModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DeleteBranchModal: React.FC<DeleteModalProps> = ({ open, onCancel, onConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
    >
        <>
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '16px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '18px', display: 'flex', alignItems: 'center', color:'#1E2C38' }}>
              Delete Branch? <CloseIcon onClick={onCancel} fontSize='small' style={{ color: '#1E2C38', cursor: 'pointer', marginLeft: '200px' }} />
            </Typography>
          </div>
        </div>
        <Divider style={{ marginBottom: '10px' }} />

        <Typography sx={{fontFamily:'Montserrat', fontWeight:'500', fontSize:'14px', lineHeight:'18px', color:'#1E2C38', marginTop:'30px'}} id="delete-modal-description">Are you sure you want to delete this branch?</Typography>
        <Typography sx={{fontFamily:'Montserrat', fontWeight:'500', fontSize:'14px', lineHeight:'18px', color:'#1E2C38'}} id="delete-modal-description">You cannot undo this change later.</Typography>
        <div style={{marginTop:'30px', marginLeft:'18%'}}>

        <Button sx={{minWidth:'81px', minHeight:'40px',backgroundColor:'#F7F9FA', padding:'8px, 16px, 8px, 16px', gap:'4px', borderRadius:'8px', marginRight:'10px'}} onClick={onCancel}>
        <Typography sx={{fontFamily:'Montserrat', fontWeight:'600', fontSize:'14px', lineHeight:'24px', textAlign:'center', color:'#10383D', textTransform:'none'}}>Cancel</Typography> 
        </Button>

        <Button sx={{minWidth:'166px', minHeight:'40px',backgroundColor:'#C00F00', padding:'8px, 16px, 8px, 16px', gap:'4px', borderRadius:'8px', '&:hover': {backgroundColor: '#C00F00'}}} onClick={onConfirm}>
            <Typography sx={{fontFamily:'Montserrat', fontWeight:'600', fontSize:'14px', lineHeight:'24px', textAlign:'center', color:'#FFFFFF', textTransform:'none'}}><div style={{display:'flex', alignItems:'center'}}><DeleteIcon/> Confirm Delete</div></Typography> 
        </Button>

        </div>
        </div>
      </>
    </Modal>
  );
}

export default DeleteBranchModal;
