import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PhoneIcon from '@mui/icons-material/Phone';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Chip, Typography, IconButton, CircularProgress } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useDispatch, useSelector } from 'react-redux';
import { getTdsType } from '../redux/tds/tdsSlice';
import { STATUS } from '../../utils/status';
import Button from '@mui/material/Button';
import { FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search'; 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Error from '../error';
import { AddButton } from '../../assets/button';

export default function DeductTDS() {
  const [searchTerm, setSearchTerm] = React.useState('');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(10);
  const [loading, setLoading] = React.useState(false); 
  const tds = useSelector((state: RootState) => state.tds.tds);
  const getTdsStatus = useSelector((state: RootState) => state.tds.getTdsStatus);

  React.useEffect(() => {
    setLoading(true)
    dispatch(getTdsType({search: searchTerm, page: currentPage, tds: false , pageSize: itemsPerPage}))
    .then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch(() => setLoading(false));
  }, [dispatch, searchTerm, currentPage, itemsPerPage]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const getChipColor = (status: string) => {
    switch (status) {
      case 'Verified':
        return '#44936B'; // Green
      case 'Uploaded':
        return '#D0A91F'; // Yellow
      case 'Not Uploaded':
        return '#939393'; // Grey
      default:
        return ''; // Default color
    }
  };

  const getChipBg = (status: string) => {
    switch (status) {
      case 'Verified':
        return '#E7F0F1'; // Green
      case 'Uploaded':
        return '#F7F2DD'; // Yellow
      case 'Not Uploaded':
        return '#F7F9FA'; // Grey
      default:
        return ''; // Default color
    }
  };

  const [selectedFiles, setSelectedFiles] = React.useState<{ [key: string]: File[] }>({});
  const [selectedFileNames, setSelectedFileNames] = React.useState<{ [key: string]: string[] }>({});

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, rowId: string) => {
    if (e.target.files) {
      const files: FileList = e.target.files;
      const newSelectedFiles: File[] = Array.from(files);
      const newSelectedFileNames: string[] = newSelectedFiles.map(file => file.name);
      setSelectedFiles(prevState => ({
        ...prevState,
        [rowId]: [...(prevState[rowId] || []), ...newSelectedFiles],
      }));
      setSelectedFileNames(prevState => ({
        ...prevState,
        [rowId]: [...(prevState[rowId] || []), ...newSelectedFileNames],
      }));
    }
  };
  
  const handleDeleteFile = (index: number, rowId: string) => {
    const updatedFiles = [...(selectedFiles[rowId] || [])];
    const updatedFileNames = [...(selectedFileNames[rowId] || [])];
    updatedFiles.splice(index, 1);
    updatedFileNames.splice(index, 1);
    setSelectedFiles(prevState => ({
      ...prevState,
      [rowId]: updatedFiles,
    }));
    setSelectedFileNames(prevState => ({
      ...prevState,
      [rowId]: updatedFileNames,
    }));
  };

  const handlePaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  const handleAddTdsClick = ()=>{
    navigate('/slab')
  }

  return (
    <>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'5px'}}>
    <TextField 
      id="outlined-basic" 
      variant="outlined" 
      placeholder="Search"
      value={searchTerm}
      size='small'
      onChange={handleSearchChange}
      sx={{padding:'8px, 16px, 8px, 16px', border:'1px', borderRadius:'4px', gap:'12px', width:'320px'}}
      InputProps={{
        startAdornment: (
          <SearchIcon color="action" />
        )
      }}
      InputLabelProps={{ 
        shrink: false
      }}
    />
    <div style={{display:'flex', justifyContent:'flex-end'}}>
    <div style={{display:'flex', marginRight:'20px'}}>
      <Typography sx={{paddingTop:'25px', fontSize:'12px',lineHeight:'18px', fontWeight:'600', color:'#10383D'}}>SHOW</Typography>
                  <FormControl sx={{ m: 1, minWidth: 40 }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    IconComponent={ArrowDropDownIcon}
                    value={itemsPerPage} 
                    sx={{  height: '40px' }}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                  </FormControl>
                  <Typography sx={{paddingTop:'25px', fontSize:'12px',lineHeight:'18px', fontWeight:'600', color:'#10383D'}}>ENTRIES</Typography>
                  </div>
                  <AddButton onClick={handleAddTdsClick}>Create TDS</AddButton>
      
            </div>
            </div>
            <TableContainer>
            {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 }}}>
        <TableRow>
          <TableCell align="left" style={{color:'#10383D', fontSize:'10px', fontWeight: 700, lineHeight:'14px', letterSpacing:'1px'}}>NAME</TableCell>
          <TableCell align="left" style={{color:'#10383D', fontSize:'10px', fontWeight: 700, lineHeight:'14px', letterSpacing:'1px'}}>USER TYPE</TableCell>
          <TableCell align="left" style={{color:'#10383D', fontSize:'10px', fontWeight: 700, lineHeight:'14px', letterSpacing:'1px'}}>OWNER DETAILS</TableCell>
          <TableCell align="left" style={{color:'#10383D', fontSize:'10px', fontWeight: 700, lineHeight:'14px', letterSpacing:'1px'}}><span style={{display:'flex' , alignItems:'center'}}>UPLOAD<InfoOutlinedIcon sx={{marginLeft:'5px'}} fontSize='small'/></span></TableCell>
          <TableCell align="left" style={{color:'#10383D', fontSize:'10px', fontWeight: 700, lineHeight:'14px', letterSpacing:'1px'}}>UPLOAD STATUS</TableCell>
          <TableCell align="left" style={{color:'#10383D', fontSize:'10px', fontWeight: 700, lineHeight:'14px', letterSpacing:'1px'}}>COUNT ADDED</TableCell>
          <TableCell align="left" style={{color:'#10383D', fontSize:'10px', fontWeight: 700, lineHeight:'14px', letterSpacing:'1px'}}>ACTIONS</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {getTdsStatus === STATUS.SUCESS && tds && tds.results.length > 0 ? ( tds.results.map((row: any) => (
          <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
            <TableCell component="th" scope="row" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
              <div>
                {row.name}
              </div>
            </TableCell>
            <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
              <div>
                {row.role}
              </div>
            </TableCell>
            <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
              Shakti<br />
                <span style={{ display: 'flex', alignItems: 'right' ,color: '#939393',}}>
                <PhoneIcon style={{ fontSize: '9px', marginTop:'4px' , marginRight:'1px'}} />
                <Typography style={{fontSize:'10px'}}>{row.phone}</Typography>
                </span>
            </TableCell>
            <TableCell align="left">

              <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                <label htmlFor={`file-input-${row.id}`}>
                  <input
                    id={`file-input-${row.id}`}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, row.id)}
                    multiple
                  />
              <span style={{display:'flex' , alignItems:'center', cursor:'pointer'}}>
              Choose Files <KeyboardArrowRightOutlinedIcon style={{ fontSize: '16px' }} />
              </span>
                 
          </label>
         
          <Typography variant="caption">
          {selectedFileNames[row.id]?.map((name, index) => {
            const [fileName, fileType] = name.split('.');
            let truncatedName = fileName;
            if (truncatedName.length > 15) {
              truncatedName = `${truncatedName.substring(0, 15)}...`;
            }
            const displayText = `${truncatedName}.${fileType}`;

            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop:'10px' }}>
                 <InsertDriveFileOutlinedIcon sx={{fontSize:'15px', marginRight:'1px'}}/> {displayText}
                  <IconButton onClick={() => handleDeleteFile(index, row.id)} >
                    <DeleteIcon sx={{ color: '#C00F00' , fontSize:'15px'}} />
                  </IconButton>
                </div>
            );
          })}
          </Typography>

            </div>
          </TableCell>
          <TableCell align="left" sx={{ fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}>
            <Chip
              label="Verified"
              variant="outlined"
              sx={{ backgroundColor: getChipBg(row.uploadStatus), color: getChipColor(row.uploadStatus) }}
              icon={row.uploadStatus === 'Verified' ? <CheckIcon style={{ color: '#44936B', fontSize: '12.57px' }} /> : undefined}
            />
          </TableCell>
          <TableCell align="left" sx={{ fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}>5 in 2 Corporates</TableCell>
          <TableCell align="left">
            <span style={{ display: 'flex', alignItems: 'right' }}>
              <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Verify Document</Typography>
              <KeyboardArrowRightOutlinedIcon style={{ fontSize: '16px' }} />
              <CampaignIcon style={{ color: '#03BBD2', fontSize: '22px', marginTop: 0 }} />
            </span>
          </TableCell>
        </TableRow>
       ))
       ) : (
         <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
           <TableCell colSpan={8} >
             <Error message="There are no tds added as of yet. You can add a tds to see it here."/>
           </TableCell>
         </TableRow>
       )}
      </TableBody>
      </Table>
  )}
      </TableContainer>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}> 
            {getTdsStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getTdsStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, tds.count || 0) 
              : '-'}{' '}
            of {getTdsStatus === STATUS.SUCESS ? (tds.count || 0) : '-'}
            </Typography>
            </div>
        <Button
          onClick={() => handlePaginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeftIcon
              sx={{
                color: 'black',
                backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
        <Button
          onClick={() => handlePaginate(currentPage + 1)}
          disabled={!tds.results || tds.results.length < itemsPerPage}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronRightIcon
              sx={{
                color: 'black',
                backgroundColor: !tds.results || tds.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
      </div>
      </>
      );
      
      }