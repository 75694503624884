import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
  
  export interface Receiver {
    id: string
    name:string
    email:string 
    address:string 
    pincode:string 
    phone:string    
  }
  
  interface UpdateFormDataPayload {
    field: keyof Receiver;
    value: string;
  }

export interface EnquiryState {
  receiver: {
    count: number | undefined;
    results: Receiver[];
  };
  error: string | null;
  getReceiverStatus: STATUS;
  createReceiverStatus: STATUS,
  updateReceiverStatus: STATUS,
}

const initialState: EnquiryState = {
  receiver: { 
    count: undefined,
    results: [] },
  error: null,
  
  getReceiverStatus: STATUS.NOT_STARTED,
  createReceiverStatus: STATUS.NOT_STARTED,
  updateReceiverStatus: STATUS.NOT_STARTED,
};

export const createReceiver = createAsyncThunk(
    'receiver/post',
    async (payload: any, thunkAPI) => {
      const response = await api.post('user/receiver', payload);
      const { status, data } = response;
      if (status === 201) {
        return data;
      } 
      else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

export const updateReceiver = createAsyncThunk(
    'receiver/patch',
    async (payload: any, thunkAPI) => {
     
      const response = await api.patch(`user/receiver`, payload);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  interface GetReceiverPayload {
    search?: string;
    page?: number;
    pageSize?: number;
    sort?: string;
    order?: string;
  }

export const getReceiver = createAsyncThunk(
  'receiver/get',
  async (payload: GetReceiverPayload = {}, thunkAPI) => {
    const { search, page, pageSize, sort, order} = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
    }).toString();
    const response = await api.get(`user/receiver?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const receiverSlice = createSlice({
  name: 'receiver',
  initialState,
  reducers: {
    setCreateReceiverStatus(state,action){
      state.createReceiverStatus = action.payload
    },
    setUpdateReceiverStatus(state,action){
      state.updateReceiverStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(createReceiver.pending, (state, action) => {
      state.createReceiverStatus = STATUS.PENDING;
      console.log('PENDING');
    })
    .addCase(createReceiver.fulfilled, (state, action) => {
        state.receiver.results.push(action.payload);
        state.createReceiverStatus = STATUS.SUCESS;
        console.log('SUCCESS');
      })
      .addCase(createReceiver.rejected, (state, action) => {
        state.createReceiverStatus = STATUS.FAILED;
        console.log('FAILED');
      })
      .addCase(getReceiver.pending, (state, action) => {
        state.getReceiverStatus = STATUS.PENDING;

      })
      .addCase(getReceiver.fulfilled, (state, action) => {
        state.receiver = action.payload;
        state.getReceiverStatus = STATUS.SUCESS;
        console.log('GET ENQUIRY TYPES SUCCESS');
      })
      .addCase(getReceiver.rejected, (state, action) => {
        state.getReceiverStatus = STATUS.FAILED;
        console.log('GET ENQUIRY TYPES FAILED');
      })
      .addCase(updateReceiver.pending, (state, action) => {
        state.updateReceiverStatus = STATUS.PENDING;
        console.log('UPDATE RECEIVER PENDING');
      })
      .addCase(updateReceiver.fulfilled, (state, action) => {
        state.receiver = action.payload;
        state.updateReceiverStatus = STATUS.SUCESS;
        console.log('UPDATE RECEIVER Fullfilled', action.payload);
      })
      .addCase(updateReceiver.rejected, (state, action) => {
        state.updateReceiverStatus = STATUS.FAILED;
        console.log('UPDATE RECEIVER FAILED');
      })
  },
});

export const {setCreateReceiverStatus, setUpdateReceiverStatus} = receiverSlice.actions
export default receiverSlice.reducer;
