import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import CompanyForm from './companyForm';
import CompanyHeading from './companyHeading';

const Company = () => {
  const navigate = useNavigate(); 
  const {id} = useParams()
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  useEffect(()=>{
    if(window.location.href.includes(`company-form/${id}`)){
      setIsEditEnabled(true);
    }
  },[])

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
      <CompanyHeading isEditEnabled={isEditEnabled}/>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>   

        {/* Table Layout */}
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          
          <CompanyForm isEditEnabled={isEditEnabled}/>
        </Paper>
      </Box>
    </div>
  );
};

export default Company;
