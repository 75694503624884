import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateTruckTypePayload {
  id?: string;
}


interface TruckTypeState {
  trucktypeusers: {
    count: number | undefined;
    results: CreateTruckTypePayload[];
  };
  error: string | null;
  getStatus: STATUS;
}

const initialState: TruckTypeState = {
  trucktypeusers:{
    count: undefined,
    results: [] },
  error: null,
  getStatus: STATUS.NOT_STARTED,
};



// export const getTruckTypesUsers = createAsyncThunk(
//   'trucktype/get',
//   async ( { search,page, sort, order, companyId }: { search:string ,page:number, sort: string, order: string, companyId?: string },  thunkAPI) => {
//     const response = await api.get(`trucktype/?company_id=${companyId}&search=${search}&page=${page}&sort=${sort}&order=${order}`);
//     const { status, data } = response;
//     if (status === 200) {
//       return data;
//     } else {
//       return thunkAPI.rejectWithValue({ response, data });
//     }
//   }
// );

export const getTruckTypesUsers = createAsyncThunk(
  'trucktype/get',
  async ( { search,page, sort, order, exclude_admin}: { search:string ,page:number, sort: string, order: string, exclude_admin: boolean},  thunkAPI) => {
    const response = await api.get(`trucktype/?search=${search}&page=${page}&sort=${sort}&order=${order}&exclude_admin=${exclude_admin}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);


const truckUserSlice = createSlice({
  name: 'trucktypeuser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTruckTypesUsers.pending, (state) => {
        state.getStatus = STATUS.PENDING;
      })
      .addCase(getTruckTypesUsers.fulfilled, (state, action) => {
        state.trucktypeusers = action.payload;
        state.getStatus = STATUS.SUCESS;
      })
      
      .addCase(getTruckTypesUsers.rejected, (state) => {
        state.getStatus = STATUS.FAILED;
        state.error = 'Failed to fetch truck types';
      })
  },
});

export default truckUserSlice.reducer;
