import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getTruck } from '../redux/truck slice/addTruckSlice';
import { Checkbox,Typography } from '@mui/material';
import Error from '../error';
import { FormState, updateFormState, updateSelectedTruckIds } from '../redux/reducers/truckListForm';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const TruckList = () => {
    const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const companyDetails: FormState = useSelector((state: RootState) => state.truckListForm);
  const selectedTruckIds = useSelector((state: RootState) => state.truckListForm.selectedTruckIds);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, truckId: string) => {
    const isChecked = event.target.checked;
    dispatch(updateFormState(truckId)); 
    if (isChecked) {
      dispatch(updateSelectedTruckIds([...selectedTruckIds, truckId])); 
    } else {
      dispatch(updateSelectedTruckIds(selectedTruckIds.filter((id:any) => id !== truckId))); 
    }
  };
  
  const handleSort = (column: string) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    dispatch(getTruck({search: searchTerm,page:currentPage, sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }))
  }, [dispatch,searchTerm,currentPage, sortColumn, sortOrder, itemsPerPage]);


  function formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const  trucks  = useSelector((state: RootState) => state.trucks.trucks);
  const getTruckStatus = useSelector((state: RootState) => state.trucks.getStatus);
 
  return (
    <>
    
    <TableContainer sx={{marginTop:'2rem'}}>
    <Typography sx={{marginBottom:'20px', fontFamily:'Montserrat', fontWeight:'500', fontSize:'14px', lineHeight:'24px'}}>Select Truck</Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    
                  <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                  <TableRow>
                  <TableCell align="right"></TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                          TRUCK NUMBER
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>MODEL NUMBER</TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                       REGISTRATION ON
                     
                      </TableCell>
                      <TableCell align="center" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} onClick={() => handleSort('name')}>
                      <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>DRIVER NAME</span>
                     
                        <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                          <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'asc' ? 'visible' : 'visible' }} />
                          <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === 'desc' ? 'visible' : 'visible' }} />
                        </div>
                      
                    </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                       DRIVER CONTACT NUMBER
                     
                      </TableCell>
                      
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} onClick={() => handleSort('company.role')}>
                     TRUCK DOCUMENTS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getTruckStatus === STATUS.SUCESS && trucks && trucks.results.length > 0 ? ( trucks.results.map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell align="right" sx={{ width: '50px', borderBottom: 0 }}>
                        <Checkbox
                        {...label}
                        onChange={(e) => handleCheckboxChange(e, row.id)}
                        checked={selectedTruckIds.includes(row.id)}
                        sx={{
                            '&.Mui-checked': {
                            color: '#03BBD2',
                            },
                        }}
                        />
                    </TableCell>
                      <TableCell component="th" scope="row" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px', borderBottom: 0 }}>
                        {row.truck_number}
                      </TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px', borderBottom: 0 }}>{row.chassis.toUpperCase()}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px', borderBottom: 0 }}>{formatDate(row.registration_date)}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px', borderBottom: 0 }}>{row.truck_type.name}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px', borderBottom: 0 }}>{row.truck_type.name}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px', borderBottom: 0 }}>{row.company && row.company.role}</TableCell>
                    </TableRow>
                     ))
                     ) : (
                       <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                         <TableCell colSpan={8} >
                           <Error message="There are no trucks added as of yet. You can add a truck to see it here."/>
                         </TableCell>
                       </TableRow>
                     )}
                  </TableBody>
                </Table>
  
              </TableContainer>
    </>
  )
}

export default TruckList