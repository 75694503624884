import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HomePage from '../components/homePage';
import AddNewRoute from '../components/routesForm/addNewRoute';
import RouteList from '../components/routesList/routeList';
import GoodsList from '../components/goodsList/goodsMain';
import GoodsTypeMain from '../components/goodsType/goodsTypeMain';
import ChecklistMain from '../components/checklist/checklistMain';
import ExpensesMain from '../components/expenses/expensesMain';
import SignInSide from '../components/login/signIn';
import SlabMain from '../components/tdsSlab/slabMain';
import TruckType from '../components/truckType/truckTypeMain';
import TruckTypeMain from '../components/truckTypeList/truckTypeListMain';
import TruckListMain from '../components/truckList/truckListMain';
import Truck from '../components/truck/truckMain';
import TdsForm from '../components/tdsSlab/tdsForm';
import FormMain from '../components/tdsSlab/formMain';
import Company from '../components/company/companyMain';
import TruckForm from '../components/truckType/truckTypeMain';
import TruckList from '../components/truckTypeList/truckTypeListMain';
import TruckMain from '../components/truckList/truckListMain';
import TDSForm from '../components/tdsSlab/formMain';
import DashboardMain from '../components/dashboard/dashboardMain';
import Contract from '../components/contract/contractMain';
import EnquiryMain from '../components/enquiry/enquiryMain';
import ContractListMain from '../components/contractList/contractListMain';
import CompanyListMain from '../components/companyList/companyListMain';

import { getAuthTokenFromLocalStorage } from '../utils/api';
import BidingMain from '../components/biding/bidingMain';
import EnquiryListMain from '../components/enquiry list/enquiryListMain';
import PaymentMain from '../components/paymentList/paymentListMain';
import Receiver from '../components/receiver/receiverMain';
import ReceiverListMain from '../components/receiverlist/receiverListMain';

const AppRoutes: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // navigate('/login')
    const authToken = getAuthTokenFromLocalStorage();
    if (!authToken) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/login" element={<SignInSide />} />
      <Route path="/" element={<HomePage />}>
        <Route path="routes" element={<RouteList />} />
        <Route path="add-route" element={<AddNewRoute />} />
        <Route path="edit-route" element={<AddNewRoute />} />
        <Route path="goods" element={<GoodsList />} />
        <Route path="goods-type" element={<GoodsTypeMain />} />
        <Route path="goods-type/:id" element={<GoodsTypeMain />} />
        <Route path="checklist" element={<ChecklistMain />} />
        <Route path="expenses" element={<ExpensesMain />} />
        <Route path="truck type" element={<TruckList />} />
        <Route path="edit-trucktype/:id" element={<TruckForm />} />
        <Route path="add-trucktype" element={<TruckForm />} />
        <Route path="truck" element={<TruckMain />} />
        <Route path="truck/:id" element={<TruckMain />} />
        <Route path="add-truck" element={<Truck />} />
        <Route path="slab" element={<FormMain />} />
        <Route path="tds slab" element={<SlabMain />} />
        <Route path="company" element={<CompanyListMain />} />
        <Route path="company-form/:id" element={<Company />} />
        <Route path="company-form" element={<Company />} />
        <Route path="dashboard" element={<DashboardMain />} />
        <Route path="contract" element={<ContractListMain />} />
        <Route path="add-contract" element={<Contract />} />
        <Route path="enquiry" element={<EnquiryListMain />} />
        <Route path="add-enquiry" element={<EnquiryMain />} />
        <Route path="biding" element={<BidingMain />} />
        <Route path="biding/:id" element={<BidingMain />} />
        <Route path="payment" element={<PaymentMain />} />
        <Route path="receiver-form" element={<Receiver/>} />
        <Route path="receiver-form/:id" element={<Receiver/>} />
        <Route path="receiver" element={<ReceiverListMain/>} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
