import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateUsersTypePayload {
  user_role: string;
  id: string;
  name: string;
  role: string;
  allowed_no_of_users: number;
}

interface ExpenditureType {
  name: string;
  rate: number;
  unit: string;
}

interface UserState {
  users: {
    count: number | undefined;
    results: CreateUsersTypePayload[];
  };
  expenditures: ExpenditureType[];
  error: string | null;
  getUsersStatus: STATUS;
  getExpenditureStatus: STATUS;
  selectedUser: CreateUsersTypePayload | null; 
}

const initialState: UserState = {
  users: { 
    count: undefined,
    results: [] 
  },
  expenditures: [],
  error: null,
  getUsersStatus: STATUS.NOT_STARTED,
  getExpenditureStatus: STATUS.NOT_STARTED,
  selectedUser: null, 
};

export const getUsers = createAsyncThunk(
  'users/get',
  async ({page, pageSize}:{page:number, pageSize: number } , thunkAPI) => {
    const response = await api.get(`company/?expense_count=true&page=${page}&page_size=${pageSize}`);
    const { status, data } = response;
    if (status === 200) {
      return data.results;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.getUsersStatus = STATUS.PENDING;
        console.log('GET USERS PENDING');
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users.results = action.payload;
        state.getUsersStatus = STATUS.SUCESS;
        console.log('GET USERS SUCCESS');
      })
      .addCase(getUsers.rejected, (state) => {
        state.getUsersStatus = STATUS.FAILED;
        console.log('GET USERS FAILED');
      })
  },
});

export const { setSelectedUser } = userSlice.actions;

export default userSlice.reducer;
