import { Box, Typography } from '@mui/material'
import React from 'react'

const PaymentListHeading = () => {
  return (
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '12px, 0px, 12px, 0px',
        color: '#13282A',
        mb: '2rem',
        mt:'4rem',
        height:'54px',
      }}>
        <Typography paragraph sx={{fontWeight: 600, fontSize: '24px', lineHeight: '36px', color:'#10383D', padding:'24px'}}>
          Payments
        </Typography>
      </Box>
    </div>
  )
}

export default PaymentListHeading