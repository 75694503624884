import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { RootState } from '../store/appStore';

interface CreateTdsCountPayload {
  id: string;
}

interface TruckTypeState {
  tdsCount: {
    results: CreateTdsCountPayload [];
    total_transporters: number;
    total_uploaded_documents: number;
    total_not_uploaded_documents: number;
    total_verified_documents: number;

  }
  error: string | null;
  getTdsCountStatus: STATUS;
}

const initialState: TruckTypeState = {
  tdsCount: {
      results: [],
      total_transporters: 0,
      total_uploaded_documents: 0, 
      total_not_uploaded_documents: 0, 
      total_verified_documents: 0,
  },
  error: null,
  getTdsCountStatus: STATUS.NOT_STARTED,
};

export const getTdsCount = createAsyncThunk(
  'tds/stats/get',
  async ( payload:any, thunkAPI) => {
      const response = await api.get(`tds/stats`, payload);
      const { status, data } = response;
      
        if (status === 200) {
            return data;
          } else {
            return thunkAPI.rejectWithValue({ response, data });
          } 
}
);



const tdsCountSlice = createSlice({
  name: 'tdsCount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getTdsCount.pending, (state) => {
        state.getTdsCountStatus = STATUS.PENDING;
      })
      .addCase(getTdsCount.fulfilled, (state, action) => {
        console.log('Get Tds Fulfilled:', action.payload); 
        state.tdsCount = action.payload; 
        state.getTdsCountStatus = STATUS.SUCESS; 
      })
      .addCase(getTdsCount.rejected, (state, action) => {
        console.error('Get Tds Rejected:', action.error.message ?? 'An error occurred'); 
        state.getTdsCountStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
  },
});
export const selectTruckCount = (state:RootState) => state.truckCount.truckCount;

export default tdsCountSlice.reducer;
