import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface Enquiry 
{
    id:string;
}
  

export interface EnquiryState {
  bids: {
    count: number | undefined;
    results: Enquiry[];
  };
  error: string | null;
  getBidStatus: STATUS;
  createBidStatus: STATUS.NOT_STARTED,
  createActionStatus: STATUS.NOT_STARTED,
}

const initialState: EnquiryState = {
  bids: { 
    count: undefined,
    results: [] },
  error: null,
  
  getBidStatus: STATUS.NOT_STARTED,
  createBidStatus: STATUS.NOT_STARTED,
  createActionStatus: STATUS.NOT_STARTED,
};

export const createBids = createAsyncThunk(
    'bids/post',
    async (payload: any, thunkAPI) => {
      const response = await api.post('enquiry/bid/', payload);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } 
      else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  export const createActions = createAsyncThunk(
    'bids/action/post',
    async (payload: any, thunkAPI) => {
      const response = await api.post('enquiry/bid/action/', payload);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } 
      else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

export const getBids = createAsyncThunk(
  'bids/get',
  async (_, thunkAPI) => {
    const response = await api.get('enquiry/bid/');
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const bidSlice = createSlice({
  name: 'bids',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createBids.fulfilled, (state, action) => {
        state.bids.results.push(action.payload);
      })
      .addCase(createActions.fulfilled, (state, action) => {
        state.bids.results.push(action.payload);
      })
      .addCase(getBids.pending, (state, action) => {
        state.getBidStatus = STATUS.PENDING;
        console.log('GET BIDS TYPES PENDING');
      })
      .addCase(getBids.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.getBidStatus = STATUS.SUCESS;
        console.log('GET BIDS TYPES SUCCESS');
      })
      .addCase(getBids.rejected, (state, action) => {
        state.getBidStatus = STATUS.FAILED;
        console.log('GET BIDS TYPES FAILED');
      })
  },
});

export default bidSlice.reducer;
