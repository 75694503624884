import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';


export interface CreateGoodsTypePayload {
  item_name: string;
  id: string;
}

export interface GoodsState {
  checklistName: {
    count: number | undefined;
    results: CreateGoodsTypePayload[];
  };
  error: string | null;
  getChecklistNameStatus: STATUS;
}

const initialState: GoodsState = {
  checklistName: { 
    count: undefined,
    results: [] },
  error: null,
  
  getChecklistNameStatus: STATUS.NOT_STARTED,
};

interface GetGoodsPayload {
    search?: string;
    page?: number;
    pageSize?: number;
    sort?: string;
    order?: string;
  }
  
  export const getGlobalChecklist = createAsyncThunk(
    'checklist/global/get',
    async (payload: GetGoodsPayload = {}, thunkAPI) => {
      const { search, page, pageSize, sort, order} = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort && { sort }),
        ...(order && { order }),
      }).toString();
      const response = await api.get(`checklist/global?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

const checklistNameSlice = createSlice({
  name: 'checklistName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalChecklist.pending, (state, action) => {
        state.getChecklistNameStatus = STATUS.PENDING;
      })
      .addCase(getGlobalChecklist.fulfilled, (state, action) => {
        state.checklistName = action.payload;
        state.getChecklistNameStatus = STATUS.SUCESS;
        console.log('GET GOODS TYPES SUCCESS');
      })
      .addCase(getGlobalChecklist.rejected, (state, action) => {
        state.getChecklistNameStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
  },
});

export default checklistNameSlice.reducer;
