import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { deleteGoodsType, getGoodsType } from '../redux/goodsSlice/goodsSlice';
import { STATUS } from '../../utils/status';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { getTruckAdmin } from '../redux/truck slice/truckAdminSlice';
import { useNavigate } from 'react-router-dom';
import Error from '../error';
import { AddButton } from '../../assets/button';
import BidIcon from '../../assets/img/bid.png'
import ReplyIcon from '../../assets/img/Reply.png'
import { getEnquiries } from '../redux/enquiries/enquiries';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const EnquiryListTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('truck_number');
  const [sortOrder, setSortOrder] = useState('asc');
  const [alignment, setAlignment] = React.useState('web');
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const enquiries = useSelector((state: RootState) => state.enquiries);
  const getEnquiryStatus = useSelector((state: RootState)=> state.enquiries.getEnquiryStatus);


  useEffect(() => {
    dispatch(getEnquiries());
  }, [dispatch]);

  const handleAddEnquiryClick =()=>{
    navigate('/add-enquiry')
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setLoading(true)
    dispatch(getTruckAdmin({search: searchTerm,page:currentPage, sort: sortColumn, order: sortOrder , pageSize: itemsPerPage}))
    .then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch(() => setLoading(false));
  }, [dispatch,searchTerm,currentPage, sortColumn, sortOrder, itemsPerPage]);

  const  admins  = useSelector((state: RootState) => state.admins.admins);
  const getTruckAdminStatus = useSelector((state: RootState) => state.admins.getAdminStatus);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  const handleBidClick = (row:any)=>{
    navigate(`/biding/${row.id}`,{ state: { enquiryDetails: row, id: row.id } })
  }

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);
  const headers = ["BOOKING DETAILS", "CUSTOMER DETAILS", "ROUTES", "TRUCK DETAILS", "WEIGHT", "RATE", "UNIT", "FREIGHT", "PAYMENTS", "ACTIONS"];

  return (
    <>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '1rem' }}>
    <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
              value={searchTerm}
              size="small"
              onChange={handleSearchChange}
              sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
              InputProps={{
                startAdornment: (
                  <SearchIcon color="action" />
                )
              }}
              InputLabelProps={{
                shrink: false
              }}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', marginRight: '20px' }}>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>SHOW</Typography>
                <FormControl sx={{ m: 1 }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    size='small'
                    IconComponent={ArrowDropDownIcon}
                    value={itemsPerPage} 
                    sx={{ height: '40px' }}
                    onChange={handleChangeRowsPerPage}
                  >
                    
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>ENTRIES</Typography>
              </div>
              <AddButton onClick={handleAddEnquiryClick}>New Enquiry</AddButton>
          </div>
          </div>
          
    <TableContainer>
    {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                  <TableRow>
                  {headers.map((header, index) => (
                    <TableCell key={index} align="left" sx={{fontFamily:'Montserrat', fontSize: '10px', fontWeight: 700, lineHeight: '14px', letterSpacing:'1px', color:'#10383D'}}>
                      {header}
                    </TableCell>
                  ))}
                  </TableRow>
                  </TableHead>
                  <TableBody>
                    {getEnquiryStatus === STATUS.SUCESS && enquiries && enquiries.enquiries.results.length > 0 ? ( enquiries.enquiries.results.map((row: any) => (
                      <TableRow key={row.id}>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>ID: {row.id.substr(-4)}</TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}> {row.receiver_details && row.receiver_details.name} <br/><div style={{fontFamily:'Montserrat', color:'#939393', fontWeight:'600',fontSize:'10px', lineHeight:'13px'}}> Goods Type: </div> </TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}> {row.no_of_trucks} trucks</TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}> 
                        {row.truck_type_details && row.truck_type_details.name} <br/>
                        {row.truck_type_details && row.truck_type_details.truck_tyres.map((tyre: any) => (
                            <div key={tyre.id}>
                              {tyre.weights.map((weight: any) => (
                                    <div key={weight.id} style={{fontFamily:'Montserrat', color:'#939393', fontWeight:'600',fontSize:'10px', lineHeight:'13px'}}>
                                        {weight.weight} {weight.unit} | {row.no_of_trucks} trucks
                                    </div>
                                ))}
                            </div>
                        ))}
                    </TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>null</TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}> null</TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}> null</TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>null</TableCell>
                      <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}> {row.payment_mode} {row.expenses_list && row.expenses_list.map((expense: any) => (
                        <div key={expense.id} style={{fontFamily:'Montserrat', color:'#939393', fontWeight:'600',fontSize:'10px', lineHeight:'13px'}}>
                            Expense: {expense.rate}
                        </div>
                       ))}
                    </TableCell>
                      <TableCell > 
                        <img src={ReplyIcon} style={{marginRight: '12px'}}/>  
                        <img src={BidIcon} style={{marginRight: '12px'}} onClick={() => handleBidClick(row)}/> 
                        <MoreVertIcon fontSize='small'/> 
                        </TableCell>
                    </TableRow>
                     ))
                     ) : (
                       <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                         <TableCell colSpan={8} >
                           <Error message="There are no enquiries added as of yet. You can add a enquiry to see it here."/>
                         </TableCell>
                       </TableRow>
                     )}
                  </TableBody>
                </Table>
                )}
              </TableContainer>

              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}> 
            {getTruckAdminStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getTruckAdminStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, admins.count || 0) 
              : '-'}{' '}
            of {getTruckAdminStatus === STATUS.SUCESS ? (admins.count || 0) : '-'}
            </Typography>
            </div>
                <Button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronLeftIcon
                      sx={{
                        color: 'black',
                        backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
                <Button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={!admins.results || admins.results.length < itemsPerPage}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronRightIcon
                      sx={{
                        color: 'black',
                        backgroundColor: !admins.results || admins.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
              </div>
    </>
  )
}

export default EnquiryListTable