import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store/appStore';
import { getTruckCount, selectTruckCount } from '../redux/truck slice/truckKpi';
import PaymentListHeading from './paymentListHeading';
import PaymentListTable from './paymentListTable';

const PaymentMain = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [alignment, setAlignment] = useState('mac');

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const truckCount = useSelector(selectTruckCount);

  useEffect(() => {
    dispatch(getTruckCount({}));
  }, [dispatch]);

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <PaymentListHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        
       
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <ToggleButtonGroup
              color="standard"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
            >
              <ToggleButton
                value="mac"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'mac' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'mac' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Bills to be paid 
              </ToggleButton>
              <ToggleButton
                value="advance"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'advance' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'advance' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Advance
              </ToggleButton>
              <ToggleButton
                value="slip"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'slip' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'slip' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Advance slip
              </ToggleButton>
              <ToggleButton
                value="web"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'web' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'web' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Bills paid
              </ToggleButton>
              <ToggleButton
                value="android"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'android' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'android' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Bills to be received
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          
          {alignment === 'mac' && (
            <div>
              <PaymentListTable/>
              
            </div>
          )}

          {alignment === 'web' && (
            <div>
             
              
            </div>
          )}

          {alignment === 'android' && (
            <div>
             
              
            </div>
          )}
        </Paper>
      </Box>
    </div>
  );
                    }
  export default PaymentMain;   
