import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateTruckPayload {
  id: string;
  company_id: string;
}


interface TruckTypeState {
    usertrucks: {
      count: number | undefined,
    results: CreateTruckPayload[];
  }
  error: string | null;
  getUserTableStatus: STATUS;
}

const initialState: TruckTypeState = {
    usertrucks: {
      count: undefined,
       results: [],
       },
  error: null,
  getUserTableStatus: STATUS.NOT_STARTED,
};



export const getTruckUsers = createAsyncThunk(
  'truck/get',
  async ({search, page, sort, order , pageSize}: {search:string,page:number, sort: string, order: string, pageSize: number }, thunkAPI) => {
    const response = await api.get(`truck/?exclude_admin=True&search=${search}&page=${page}&page_size=${pageSize}&sort=${sort}&order=${order}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);


const usertruckSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTruckUsers.pending, (state) => {
        state.getUserTableStatus = STATUS.PENDING;
      })
      .addCase(getTruckUsers.fulfilled, (state, action) => {
        console.log('Get Truck Fulfilled:', action.payload); 
        state.usertrucks = action.payload; 
        state.getUserTableStatus = STATUS.SUCESS; 
      })
      .addCase(getTruckUsers.rejected, (state, action) => {
        console.error('Get Truck Rejected:', action.error.message ?? 'An error occurred'); 
        state.getUserTableStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
  },
});

export default usertruckSlice.reducer;
