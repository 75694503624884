import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface Weight {
  id: string;
  truck_type_id: string;
  truck_type_tyre_id: string;
  weight: number;
  unit: string;
}

interface Tyre {
  id: string;
  truck_type_id: string;
  no_of_tyres: number;
  weights: Weight[];
}

export interface TruckType {
  count: undefined
  id: string;
  company_id: string;
  truck_type_id: string;
  show_in_booking: boolean;
  name: string;
  truck_tyres?: Tyre[];
}

interface TruckTypeState {
  count: number | undefined;
  truckTypes: TruckType[];
  tyreCounts: Tyre[];
  truckWeights: Weight[];
  error: string | null;
  getStatus: STATUS;
  createStatus: STATUS;
  updateStatus: STATUS;
  deleteStatus: STATUS;
}

const initialState: TruckTypeState = {
  count: undefined,
  truckTypes: [],
  tyreCounts: [],
  truckWeights: [],
  error: null,
  getStatus: STATUS.NOT_STARTED,
  createStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
};

export const createTruckType = createAsyncThunk(
  'trucktype/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post('trucktype/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

interface GetTruckTypePayload {
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: string;
  truckTypeId?: string;
  truckTypeTyreId?: string;
  truckWeightId?: string;
}


export const getTruckTypes = createAsyncThunk(
  'trucktype/get',
  async (payload: GetTruckTypePayload = {}, thunkAPI) => {
    const { search, page, pageSize, sort, order, truckTypeId, truckTypeTyreId, truckWeightId } = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(truckTypeId && { truck_type_id: truckTypeId }),
      ...(truckTypeTyreId && { truck_type_tyre_id: truckTypeTyreId }),
      ...(truckWeightId && { truck_weight_id: truckWeightId }),
    }).toString();
    const response = await api.get(`trucktype/?${queryString}`);
    const { results, count } = response.data;

    const truckTypes: TruckType[] = results.map((truck: any) => truck);

    const tyreCounts: Tyre[] = truckTypes
      .flatMap((truckType: TruckType) => truckType.truck_tyres ?? [])
      .map((truckTyre) => ({
        id: truckTyre.id,
        truck_type_id: truckTyre.truck_type_id,
        no_of_tyres: truckTyre.no_of_tyres,
        weights: truckTyre.weights ?? [],
      }));

    const truckWeights: Weight[] = truckTypes
      .flatMap((truckType: TruckType) => truckType.truck_tyres ?? [])
      .flatMap((truckTyre) => truckTyre.weights ?? [])
      .map((weight) => ({
        id: weight.id,
        truck_type_id: weight.truck_type_id,
        truck_type_tyre_id: weight.truck_type_tyre_id,
        weight: weight.weight,
        unit: weight.unit,
      }));

    return { truckTypes, tyreCounts, truckWeights, count };
  }

);

export const updateTruckType = createAsyncThunk(
  'trucktype/patch',
  async (payload: any, thunkAPI) => {
    const response = await api.patch(`trucktype/`, payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const deleteTruckType = createAsyncThunk(
  'trucktype/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('trucktype/', { data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload: payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const truckSlice = createSlice({
  name: 'trucktype',
  initialState,
  reducers: {
    setCreateTruckTypeStatus(state,action){
      state.createStatus = action.payload
    },
    setUpdateTruckTypeStatus(state,action){
      state.updateStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTruckType.pending, (state) => {
        state.createStatus = STATUS.PENDING;
      })
      .addCase(createTruckType.fulfilled, (state, action) => {
        state.truckTypes = action.payload;
        state.createStatus = STATUS.SUCESS;
      })
      .addCase(createTruckType.rejected, (state, action) => {
        state.createStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(getTruckTypes.pending, (state) => {
        state.getStatus = STATUS.PENDING;
      })
      .addCase(getTruckTypes.fulfilled, (state, action) => {
        state.truckTypes = action.payload.truckTypes;
        state.tyreCounts = action.payload.tyreCounts;
        state.count = action.payload.count;
        state.truckWeights = action.payload.truckWeights;
        state.getStatus = STATUS.SUCESS;
      })
      .addCase(getTruckTypes.rejected, (state, action) => {
        state.getStatus = STATUS.FAILED;
      })
      .addCase(updateTruckType.pending, (state) => {
        state.updateStatus = STATUS.PENDING;
      })
      .addCase(updateTruckType.fulfilled, (state, action) => {
        let index = state.truckTypes.map(tt => tt.id).indexOf(action.payload.id)
        state.truckTypes[index] = action.payload
        state.updateStatus = STATUS.SUCESS;
        console.log(action.payload)
      })
      .addCase(updateTruckType.rejected, (state, action) => {
        state.updateStatus = STATUS.FAILED;
      })
      .addCase(deleteTruckType.pending, (state) => {
        state.deleteStatus = STATUS.PENDING;
      })
      .addCase(deleteTruckType.fulfilled, (state, action) => {
        const deletedId = action.payload.requestPayload.id;
        state.truckTypes = state.truckTypes.filter((item) => item.id !== deletedId);
        state.deleteStatus = STATUS.SUCESS;
      })
      .addCase(deleteTruckType.rejected, (state, action) => {
        state.deleteStatus = STATUS.FAILED;
        state.error = action.error.message ?? 'An error occurred';
      });
  },
});

export const {setUpdateTruckTypeStatus, setCreateTruckTypeStatus} = truckSlice.actions
export default truckSlice.reducer;
