import TruckForm from "./truckEnquiryForm";
import BiddingForm from "./biddingForm";
import CustomerForm from "./customerForm";
import ExpenseEnquiryForm from "./expenseEnquiryForm";
import RouteForm from "./routeForm";
import UserForm from "./userForm";
import TruckList from "./truckList";

export const Forms = [
    {
        key: 'route',
        label: 'Route & Goods',
        form: RouteForm
    },
    {
        key: 'truck',
        label: 'Truck Type',
        form: TruckForm
    },
    {
        key: 'trucklist',
        label: 'Truck List',
        form: TruckList
    },
    {
        key: 'bidding',
        label: 'Bidding Type',
        form: BiddingForm
    },
    {
        key: 'expenses',
        label: 'Expenses',
        form: ExpenseEnquiryForm
    },
    {
        key: 'users',
        label: 'Confirm Booking',
        form: UserForm
    },
    {
        key: 'customer',
        label: 'Receiver Details',
        form: CustomerForm
    }
];
