import { FormState } from "../../enquiry/truckEnquiryForm";

const initialState: FormState = {
  truck_type: '',
  tyre_count: '',
  truck_weight: '',
  pickup_location: '',
  drop_location: '',
  number_of_trucks: '',
  pickup_date: '',
};

const truckformReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_FORM_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default truckformReducer;
