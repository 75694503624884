import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateRouteTypePayload {
  route_details: any;
  id: string;
  source_name: string;
  destination_name: string;
  pickups: Location[];
  drops: Location[];
  corporate: string[];
}

interface RoutesState {
  routes: {
    count: number | undefined;
    results: CreateRouteTypePayload[];
  };
  error: string | null;
  getRoutesStatus: STATUS,
  createRoutesStatus: STATUS,
  updateRoutesStatus: STATUS,
}


const initialState: RoutesState = {
  routes: { 
    count: undefined,
    results: []
   },
  error: null,
  getRoutesStatus: STATUS.NOT_STARTED,
  createRoutesStatus: STATUS.NOT_STARTED,
  updateRoutesStatus: STATUS.NOT_STARTED,
};

interface GetRoutePayload {
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: string;
}

export const getRoutesAction = createAsyncThunk(
  'routes/get',
  async (payload: GetRoutePayload= {}, thunkAPI) => {
    const { search, page, pageSize, sort, order} = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
    }).toString();
    const response = await api.get(`route/?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getRoutes = createAsyncThunk(  
  'routes/get',
  async ({ group }: { group:string }, thunkAPI) => {
    const response = await api.get(`route/?group=${group}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);


export const createRouteAction = createAsyncThunk(  
  'routes/post',
  async (payload: any, thunkAPI) => {
    console.log("hello")
    const response = await api.post('route/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateRouteAction = createAsyncThunk(
  'routes/put',
  async (payload: any, thunkAPI) => {
    console.log("hello")
    const response = await api.put(`route/`, payload); 
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const removeDeletedRoute = createAction<string>('routes/removeDeleted');

const routeSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setCreateRouteStatus(state,action){
      state.createRoutesStatus = action.payload
    },
    setUpdateRouteStatus(state,action){
      state.updateRoutesStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoutesAction.pending, (state, action) => {
        state.getRoutesStatus = STATUS.PENDING
        console.log('GET ROUTES PENDING');
      })
      .addCase(getRoutesAction.fulfilled, (state, action) => {
        state.routes = action.payload;
        state.getRoutesStatus = STATUS.SUCESS; 
      })
      .addCase(getRoutesAction.rejected, (state, action) => {
        console.log('GET ROUTES REJECTED');
        state.getRoutesStatus = STATUS.FAILED;
      })
      .addCase(createRouteAction.pending, (state, action) => {
        console.log('CREATE ROUTE PENDING');
        state.createRoutesStatus = STATUS.PENDING
      })
      .addCase(createRouteAction.fulfilled, (state, action) => {
        state.routes = action.payload;
        state.createRoutesStatus = STATUS.SUCESS
      })
      .addCase(createRouteAction.rejected, (state, action) => {
        console.log('CREATE ROUTE REJECTED', action.payload);
        state.createRoutesStatus = STATUS.FAILED
      })
      .addCase(updateRouteAction.pending, (state, action) => {
        console.log('UPDATE ROUTE PENDING');
        state.updateRoutesStatus = STATUS.PENDING
      })
      .addCase(updateRouteAction.fulfilled, (state, action) => {
        state.routes = action.payload;
        state.updateRoutesStatus = STATUS.SUCESS
      })
      .addCase(updateRouteAction.rejected, (state, action) => {
        console.log('UPDATE ROUTE REJECTED', action.payload);
        state.updateRoutesStatus = STATUS.FAILED
      })
  },
});

export const {setCreateRouteStatus, setUpdateRouteStatus} = routeSlice.actions
export default routeSlice.reducer;
