import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import LoginImage from '../assets/img/logo.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { removeAuthTokenFromLocalStorage } from '../localstorage';
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function HomePage() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mastersOpen, setMastersOpen] = React.useState(false);
  const [transactionOpen, setTransactionOpen] = React.useState(false);
  const [formOpen, setFormOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState<string>('');
  const navigate = useNavigate();

  const handleItemClick = (text: string) => {
    setActiveItem(text);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMastersOpen(false);
    setTransactionOpen(false);
  };

  const handleFormToggle = () => {
    setFormOpen(!formOpen);
  };

  const handleMastersToggle = () => {
    setMastersOpen(!mastersOpen);
    setTransactionOpen(false);
  };

  const handleTransactionsToggle = () => {
    setTransactionOpen(!transactionOpen);
    setMastersOpen(false);
  };

  const handleLogout = () => {
    removeAuthTokenFromLocalStorage();
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <AppBar position="fixed" sx={{ backgroundColor: '#FFFFFF' }}>
          <Toolbar>
            <IconButton
              aria-label="toggle drawer"
              onClick={handleFormToggle}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              {formOpen ? <MenuIcon onClick={handleDrawerOpen} sx={{ color: '#10383D' }} /> : <CloseIcon onClick={handleDrawerClose} sx={{ color: '#10383D' }} />}
            </IconButton>

            <img src={LoginImage} alt="Login" style={{ marginRight: '1rem', width: '47.62px', height: '40px' }} />
            <Typography variant="h6" noWrap component="div" sx={{ color: '#10383D' }}>
              Orient Transportation Organization
            </Typography>

            <Typography
              variant="body1"
              noWrap
              component="div"
              sx={{ color: '#10383D', marginLeft: '60%', cursor: 'pointer' }}
              onClick={handleLogout}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LogoutIcon sx={{ marginRight: '10px' }} /> Logout
              </div>
            </Typography>
          </Toolbar>
        </AppBar>

        <br /><br /> <br /><br />
        <Typography style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '24px', color: '#13282A', margin: '1rem', marginLeft: '2rem', display: 'flex', alignItems: 'center' }}>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
            <GridViewRoundedIcon fontSize='small' sx={{ color: '#03BBD2', marginRight: '13px' }} /> <span>Dashboard </span>
          </Link>
        </Typography>
        <Typography onClick={handleMastersToggle} style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '24px', color: '#13282A', margin: '1rem', marginLeft: '2rem', display: 'flex', alignItems: 'center' }}>
          <ManageAccountsRoundedIcon fontSize='small' sx={{ color: '#03BBD2', marginRight: '13px' }} /> <span>Masters </span> <KeyboardArrowDownIcon fontSize='small' />
        </Typography>
        <List sx={{ padding: '20px' }} style={{ display: mastersOpen ? 'block' : 'none' }}>
          {['Company', 'Goods', 'Truck', 'Truck Type', 'Routes', 'TDS Slab', 'Expenses', 'Checklist', 'Receiver'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block', backgroundColor: '#F7F9FA' }}>
              <Link to={`/${text.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItemButton
                  onClick={() => handleItemClick(text)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    marginLeft: '1.5rem',
                    fontWeight: activeItem === text ? 'bold' : 'normal',
                    color: activeItem === text ? '#03BBD2' : '#000000',
                  }}
                >
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>

        <Typography
          onClick={handleTransactionsToggle}
          style={{
            cursor: 'pointer',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#13282A',
            margin: '1rem',
            marginLeft: '2rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CurrencyRupeeRoundedIcon fontSize='small' sx={{ color: '#03BBD2', marginRight: '13px' }} />
          <span>Transactions </span>
          <KeyboardArrowDownIcon fontSize='small' />
        </Typography>
        <List sx={{ padding: '20px' }} style={{ display: transactionOpen ? 'block' : 'none' }}>
          {['Contract', 'Enquiry', 'Payment'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block', backgroundColor: '#F7F9FA' }}>
              <Link to={`/${text.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItemButton
                  onClick={() => handleItemClick(text)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    marginLeft: '1.5rem',
                    fontWeight: activeItem === text ? 'bold' : 'normal',
                    color: activeItem === text ? '#03BBD2' : '#000000',
                    '&:hover': {
                      minHeight: 'none',
                      backgroundColor: 'none',
                    },
                  }}
                >
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <Typography style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '24px', color: '#13282A', margin: '1rem', marginLeft: '2rem', display: 'flex', alignItems: 'center' }}>
          <AutoGraphRoundedIcon fontSize='small' sx={{ color: '#03BBD2', marginRight: '13px' }} /> <span>Reports </span> <KeyboardArrowDownIcon fontSize='small' />
        </Typography>
        <Typography style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '24px', color: '#13282A', margin: '1rem', marginLeft: '2rem', display: 'flex', alignItems: 'center' }}>
          <TuneRoundedIcon fontSize='small' sx={{ color: '#03BBD2', marginRight: '13px' }} /> <span>Adjustments</span> <KeyboardArrowDownIcon fontSize='small' />
        </Typography>
        <Typography style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '24px', color: '#13282A', margin: '1rem', marginLeft: '2rem', display: 'flex', alignItems: 'center' }}>
          <SettingsSuggestRoundedIcon fontSize='small' sx={{ color: '#03BBD2', marginRight: '13px' }} /> <span>General</span> <KeyboardArrowDownIcon fontSize='small' />
        </Typography>

      </Drawer>
      <Outlet />
    </Box>
  );
}
