import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import DashboardHeading from './dashboardHeading';
import CustomCard from './card';
import { TypographyWithoutMargin } from '../../assets/typography';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { getDashboard } from '../redux/dashboard/dashboard';

const DashboardMain = () => {
  const dispatch = useDispatch<AppDispatch>()
  const dashboard = useSelector((state: RootState) => state.dashboard.dashboard)

  useEffect(()=>{
    dispatch(getDashboard())
  },[])
  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <DashboardHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        {/* Table Layout */}
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <TypographyWithoutMargin sx={{fontSize:'25px', fontWeight:'600', marginBottom:'10px'}} variant="h1">All Statistics</TypographyWithoutMargin>
          <div style={{display:'flex', marginBottom:'40px'}}>
          <CustomCard
            subtitle="BOOKING"
            count= {dashboard.stats.bookings} 
            sx={{marginRight:'20px', backgroundColor:'#766161'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="ENQUIRY STATUS"
            count= {dashboard.stats.enquires} 
            sx={{marginRight:'20px', backgroundColor:'#9400FF'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="LOADING"
            count= {dashboard.stats.bookings}
            sx={{marginRight:'20px', backgroundColor:'#419197'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="UNLOADING"
            count= {dashboard.stats.bookings} 
            sx={{backgroundColor:'#9F73AB'}}
            navigation='/routes'
          />
          </div>

          <div style={{display:'flex'}}>
          <CustomCard
            subtitle="TRANSPORTER"
            count= {dashboard.stats.transport_count} 
            sx={{marginRight:'20px', backgroundColor:'#001C30'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="ROUTES"
            count= {dashboard.stats.routes_count}
            sx={{marginRight:'20px', backgroundColor:'#3E54AC'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="MANAGE PAYMENT"
            count= {dashboard.stats.bookings} 
            sx={{marginRight:'20px', backgroundColor:'#2C74B3'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="MOTOR OWNER"
            count= {dashboard.stats.motor_owner_count} 
            sx={{ backgroundColor:'#A25772'}}
            navigation='/routes'
          />
          </div>


          <TypographyWithoutMargin sx={{fontSize:'25px', fontWeight:'600', marginBottom:'10px', marginTop:'40px'}} variant="h1">Statistics for today</TypographyWithoutMargin>
          <div style={{display:'flex', marginBottom:'40px'}}>
          <CustomCard
            subtitle="BOOKING"
            count= {dashboard.stats.bookings} 
            sx={{marginRight:'20px', backgroundColor:'#766161'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="ENQUIRY STATUS"
            count= {dashboard.stats.enquires} 
            sx={{marginRight:'20px', backgroundColor:'#9400FF'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="LOADING"
            count= {dashboard.stats.bookings}
            sx={{marginRight:'20px', backgroundColor:'#419197'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="UNLOADING"
            count= {dashboard.stats.bookings} 
            sx={{backgroundColor:'#9F73AB'}}
            navigation='/routes'
          />
          </div>

          <div style={{display:'flex'}}>
          <CustomCard
            subtitle="TRANSPORTER"
            count= {dashboard.stats.transport_count} 
            sx={{marginRight:'20px', backgroundColor:'#001C30'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="ROUTES"
            count= {dashboard.stats.routes_count} 
            sx={{marginRight:'20px', backgroundColor:'#3E54AC'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="MANAGE PAYMENT"
            count= {dashboard.stats.bookings} 
            sx={{marginRight:'20px', backgroundColor:'#2C74B3'}}
            navigation='/routes'
          />
          <CustomCard
            subtitle="MOTOR OWNER"
            count= {dashboard.stats.motor_owner_count} 
            sx={{ backgroundColor:'#A25772'}}
            navigation='/routes'
          />
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default DashboardMain;
