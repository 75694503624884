import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateBranchPayload {
    user_id: string ;
    user_details: any;
    user_role: string;
    id: string;
    name: string;
    gstin: string;
    phone: string;
    email: string;
    kyc_documents: string[];
}

export interface GoodsState {
    userModal:{
        results: CreateBranchPayload[];
      }
      getUserModalStatus: STATUS;
      deleteUserModalStatus: STATUS;
      updateUserModalStatus: STATUS;
      createUserModalStatus: STATUS;
}

const initialState: GoodsState = {
    userModal:{
        results: [],
      },
      getUserModalStatus: STATUS.NOT_STARTED,
      deleteUserModalStatus: STATUS.NOT_STARTED,
      updateUserModalStatus: STATUS.NOT_STARTED,
      createUserModalStatus: STATUS.NOT_STARTED
};

export const createUserModalType = createAsyncThunk(
  'user/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post('user/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getUserModalType = createAsyncThunk(
  'user/get',
  async (payload:any, thunkAPI) => {
    const response = await api.get('user/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateUserModalType = createAsyncThunk(
  'user/patch',
  async (payload: any, thunkAPI) => {
   
    const response = await api.patch('user/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const deleteUserModalType = createAsyncThunk(
  'user/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('user/', { data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload: payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const userModal = createSlice({
  name: 'branch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserModalType.pending, (state, action) => {
        state.createUserModalStatus = STATUS.PENDING;
        
      })
      .addCase(createUserModalType.fulfilled, (state, action) => {
        state.userModal.results.push(action.payload);
        state.createUserModalStatus = STATUS.SUCESS;
      })
      .addCase(createUserModalType.rejected, (state, action) => {
        state.createUserModalStatus = STATUS.FAILED;
        
      })
      .addCase(getUserModalType.pending, (state, action) => {
        state.getUserModalStatus = STATUS.PENDING;
        console.log('userModal TYPES PENDING');
      })
      .addCase(getUserModalType.fulfilled, (state, action) => {
        state.userModal = action.payload;
        state.getUserModalStatus = STATUS.SUCESS;
        console.log('GET userModal TYPES SUCCESS');
      })
      .addCase(getUserModalType.rejected, (state, action) => {
        state.getUserModalStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
      .addCase(deleteUserModalType.pending, (state, action) => {
        state.deleteUserModalStatus = STATUS.PENDING;
        console.log('DELETE GOODS TYPE PENDING');
      })
      .addCase(deleteUserModalType.fulfilled, (state, action) => {
        const deletedId = action.payload.requestPayload.id;
        state.userModal.results = state.userModal.results.filter((item) => item.id !== deletedId);
        state.deleteUserModalStatus = STATUS.SUCESS;
        console.log('DELETE GOODS TYPE SUCCESS');
      })
      .addCase(deleteUserModalType.rejected, (state, action) => {
        state.deleteUserModalStatus = STATUS.FAILED;
        console.log('DELETE GOODS TYPE FAILED', action.payload);
      })
      .addCase(updateUserModalType.pending, (state, action) => {
        state.updateUserModalStatus = STATUS.PENDING;
        console.log('UPDATE GOODS TYPE PENDING');
      })
      .addCase(updateUserModalType.fulfilled, (state, action) => {
        const updatedGoodsType = action.payload;
        const index = state.userModal.results.findIndex((item) => item.id === updatedGoodsType.id);
        if (index !== -1) {
          state.userModal.results[index] = updatedGoodsType;
        }
      })
      .addCase(updateUserModalType.rejected, (state, action) => {
        state.updateUserModalStatus = STATUS.FAILED;
        console.log('UPDATE GOODS TYPE FAILED', action.payload);
      })
  },
});

export default userModal.reducer;
