import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import TdsForm from './tdsForm';
import TdsFormHeading from './tdsFormHeading';

const FormMain = () => {
  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
      <TdsFormHeading/>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>   

        {/* Table Layout */}
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <TdsForm />
        </Paper>
      </Box>
    </div>
  );
};

export default FormMain;
