import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateUsersTypePayload {
  id: string;
  name: string;
  role: string;
  allowed_no_of_users: number;
}

interface ExpenditureType {
  name: string;
  rate: number;
  unit: string;
}

interface UserState {
  drivers: {
    results: CreateUsersTypePayload[];
  };
  error: string | null;
  getDriversStatus: STATUS; 
}

const initialState: UserState = {
  drivers: { results: [] },
  error: null,
  getDriversStatus: STATUS.NOT_STARTED,
  
};

export const getDrivers = createAsyncThunk(
  'drivers/get',
  async (_ , thunkAPI) => {
    const response = await api.get(`user/?user_role=DRIVER`);
    const { status, data } = response;
    if (status === 200) {
      return data.results;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const driverSlice= createSlice({
  name: 'drivers',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrivers.pending, (state) => {
        state.getDriversStatus = STATUS.PENDING;
        console.log('GET DRIVERS PENDING');
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.drivers.results = action.payload;
        state.getDriversStatus = STATUS.SUCESS;
        console.log('GET DRIVERS SUCCESS');
      })
      .addCase(getDrivers.rejected, (state) => {
        state.getDriversStatus = STATUS.FAILED;
        console.log('GET DRIVERS FAILED');
      })
  },
});

export default driverSlice.reducer;
