import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateExpensesTypePayload, deleteExpense, getExpenses, setDeleteExpenseStatus, setUpdateExpensesStatus, updateExpense} from '../redux/expenses/expensesSlice'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Checkbox from '@mui/material/Checkbox';
import { Button, Typography, CircularProgress, Select, MenuItem, Snackbar, SnackbarContent } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Error from '../error';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import ClearIcon from '@mui/icons-material/Clear';
import DeleteModal from './deleteModal';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
interface IProps {
  data: CreateExpensesTypePayload[],
  count: number
}


export default function AdminTable({data,count}:IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedRow, setEditedRow] = useState<any>({});
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [loading, setLoading] = useState(false); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [expenseIdToDelete, setExpenseIdToDelete] = useState(""); 
  const [checkboxUpdate, setCheckboxUpdate] = useState(false);
  const deleteExpenseStatus = useSelector((state: RootState) => state.expenses.deleteExpenseStatus);
  const getExpensesStatus = useSelector((state: RootState) => state.expenses.getExpensesStatus);
  const updateExpensesStatus = useSelector((state: RootState) => state.expenses.updateExpenseStatus);
  
  
  useEffect(() => {
    setLoading(true)
    dispatch(getExpenses({ page: currentPage, sort: sortColumn, order: sortOrder }))
    .then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch(() => setLoading(false));
}, [dispatch, currentPage, sortColumn, sortOrder]);

  const handleSort = (column: string) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handlePaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (id: string, row: any) => {
    setEditingId(id);
    setEditedRow({ ...row });
  };

  const [showInBooking, setShowInBooking] = useState<boolean>(false);

const handleCheckboxChange = (id: string, checked: boolean) => {
  setEditedRow((prev: any) => ({
    ...prev,
    show_in_booking: checked,
  }));
  setShowInBooking(checked);
  setCheckboxUpdate(true);

  const payload = {
    id: id,
    show_in_booking: checked,
  };
  dispatch(updateExpense(payload));
};

useEffect(()=>{
  if(updateExpensesStatus === STATUS.SUCESS){
    if (checkboxUpdate) {
      handleSnackbar(showInBooking ? 'You added this item as active.' : 'You added this item as inactive.', 'success');
    } else {
      handleSnackbar('Expense Updated Successfully.', 'success');
    }
    dispatch(setUpdateExpensesStatus(STATUS.NOT_STARTED))
    setCheckboxUpdate(false);
  }

  if(updateExpensesStatus === STATUS.FAILED){
    handleSnackbar('Error Occured, Please try again!', 'error');
  }
}, [updateExpensesStatus, checkboxUpdate, showInBooking]);

  const handleSave = (editedExpense: any) => {
    const updatedExpense = { ...editedExpense };
  if (editedExpense.name !== editedRow.name) {
    updatedExpense.name = editedRow.name;
  }
  if (editedExpense.rate !== editedRow.rate) {
    updatedExpense.rate = editedRow.rate;
  }
  if (editedExpense.unit !== editedRow.unit) {
    updatedExpense.unit = editedRow.unit;
  }
  const payload = { ...updatedExpense };
  
  dispatch(updateExpense(payload))
    setEditingId(null);
    setEditedRow({});
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value;
    setEditedRow((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDelete = (id: string) => {
    setExpenseIdToDelete(id);
    setShowDeleteModal(true);
    handleSnackbar('Deleting this item cannot be undone.', 'warning');
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    if (expenseIdToDelete) {
      await dispatch(deleteExpense({ id: expenseIdToDelete }));     
      dispatch(getExpenses({ page: currentPage, sort: sortColumn, order: sortOrder }));   
    }
  };

  React.useEffect(()=>{
    if(deleteExpenseStatus == STATUS.SUCESS){
      handleSnackbar('Expense Deleted Successfully.', 'success');
      dispatch(setDeleteExpenseStatus(STATUS.NOT_STARTED))
    }
    if(deleteExpenseStatus == STATUS.FAILED){
      handleSnackbar('Error Occured, Please Try Again!', 'error');
    }
  },[deleteExpenseStatus])
  
  const cancelDelete = () => {
    setShowDeleteModal(false); 
    
  };

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <>
    <TableContainer>
       {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
      <Table sx={{ minWidth: 650 }} size='small' aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 }}}>
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} onClick={() => handleSort('name')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>EXPENSE NAME</span>
                <div style={{ display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                  <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'asc' ? 'visible' : 'visible' }} />
                  <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === 'desc' ? 'visible' : 'visible' }} />
                </div>
              </div>
            </TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>RATE</TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>UNIT</TableCell>
            <TableCell align="center" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>ACTIONS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getExpensesStatus === STATUS.SUCESS && data && data.length > 0 ? ( data.map((row: any) => (
            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': { borderBottom: 0 }}}>
              <TableCell align="right" sx={{ width: '50px' }}>
              <Checkbox
                {...label}
                value={row.id}
                checked={(editedRow.id === row.id && editedRow.show_in_booking) || row.show_in_booking}
                onChange={(e) => {
                  handleCheckboxChange(row.id, e.target.checked);
                }}
                sx={{
                  '&.Mui-checked': {
                    color: '#03BBD2',
                  },
                }}
              />
              </TableCell>
              <TableCell component="th" scope="row" sx={{fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px'}}>
               
                  
                    {row.name}
              </TableCell>
              <TableCell align="left" sx={{fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px'}}>
                {editingId === row.id ? (
                  <input
                    type="text"
                    value={editedRow.rate || ''}
                    onChange={(e) => handleFieldChange(e, 'rate')}
                    style={{border: '1px solid', borderColor: '#E8EAEF', height: '32px', borderRadius: '4px', width: 'auto'}}
                  />
                ) : (
                  <div style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', width: '50px' }}>
                    {row.rate}<br />
                  </div>
                )}
              </TableCell>
              <TableCell align="left" sx={{fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px'}}>
                {editingId === row.id ? (
                  <Select
                  value={editedRow.unit || ''}
                  onChange={(e:any) => handleFieldChange(e, 'unit')}
                  style={{ borderColor: '#E8EAEF', height: '32px', borderRadius: '4px'}}
                  >
                    <MenuItem value="" disabled>
                      Select Unit
                    </MenuItem>
                    <MenuItem value="TON">Ton</MenuItem>
                    <MenuItem value="KG">Kg</MenuItem>
                    <MenuItem value="QUINTAL">Quintal</MenuItem>
                    <MenuItem value="RS">Rs</MenuItem>
                    <MenuItem value="LITRE">Litre</MenuItem>
                  </Select>
                ) : (
                  <div style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', width: '50px' }}>
                    {row.unit}<br />
                  </div>
                )}
              </TableCell>
              <TableCell align="center" sx={{fontFamily: 'Montserrat', color: '#13282A', fontWeight: '400', fontSize: '14px', lineHeight: '24px', cursor: 'pointer'}}>
                {editingId === row.id ? (
                  <>
                  <Button
                    sx={{
                      padding: '8px 16px',
                      border: '1px',
                      borderRadius: '8px',
                      width: '85px',
                      height: '32px',
                      marginBottom: '1px',
                      marginLeft: 0,
                      textTransform: 'none',
                      backgroundColor: '#03BBD2',
                      '&:hover': { backgroundColor: '#03BBD2' },
                    }}
                    onClick={() => handleSave({ ...row, ...editedRow })}
                  >
                    <Typography style={{ fontSize: '12px', color: '#FFFFFF'}}>
                      Save 
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      padding: '8px 16px',
                      border: '1px',
                      borderRadius: '8px',
                      width: '85px',
                      height: '32px',
                      marginBottom: '1px',
                      marginLeft: '10px',
                      textTransform: 'none',
                      backgroundColor: '#C00F00',
                      '&:hover': { backgroundColor: '#C00F00' },
                    }}
                    onClick={() => {
                      setEditingId(null);
                      setEditedRow({});
                    }}
                  >
                    <Typography style={{ fontSize: '12px', color: '#FFFFFF'}}>
                      Cancel
                    </Typography>
                  </Button>
                </>
                ) : (
                  <EditNoteOutlinedIcon onClick={() => handleEdit(row.id, row)} />
                )}
                <DeleteOutlineOutlinedIcon
                  onClick={() => handleDelete(row.id)}
                  sx={{ color: '#C00F00', cursor: 'pointer', marginLeft: '8px' }}
                />
              </TableCell>
            </TableRow>
          ))
          ) : (
            <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
              <TableCell colSpan={8} >
                <Error message="There are no expenses added as of yet. You can add a expense to see it here."/>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      )}
      {/* Pagination Buttons */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400', lineHeight:'24px'}}> 
          {getExpensesStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getExpensesStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, count || 0) 
              : '-'}{' '}
            of {getExpensesStatus === STATUS.SUCESS ? (count || 0) : '-'}
          </Typography>
        </div>
        <Button
          onClick={() => handlePaginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeftIcon
              sx={{
                color: 'black',
                backgroundColor: currentPage === 1 ? '#E8EBEE' : '#F3F5F7',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
        <Button
        onClick={() => handlePaginate(currentPage + 1)}
        disabled={currentPage * itemsPerPage >= count!} 
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ChevronRightIcon
            sx={{
              color: 'black',
              backgroundColor: currentPage * itemsPerPage >= count! ? '#E8EBEE' : '#F3F5F7',
              width: '32px',
              height: '32px',
            }}
          />
        </div>
      </Button>
      </div>
      <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
    </TableContainer>


<Snackbar
anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
 open={snackbarOpen}
 autoHideDuration={6000}
 onClose={handleSnackbarClose}
 message={snackbarMessage}
 sx={{ borderRadius:'8px' }} 
>
   <SnackbarContent
   sx={{
     backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
     color: '#FFFFFF',
   }}
   message={
     <>
     <div style={{ display: 'flex' }}>
     {snackbarSeverity === 'success' ? (
       <img src={SuccessIcon} style={{ marginRight: '12px' }} />
     ) : snackbarSeverity === 'warning' ? (
       <img src={FailIcon} style={{ marginRight: '12px' }} />
     ) : snackbarSeverity === 'info' ? (
       <img style={{ marginRight: '12px' }} />
     ) : (
       <img src={FailIcon} style={{ marginRight: '12px' }} />
     )}

     <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
   </div>
     </>
   }
  
   action={
     <React.Fragment>
         <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
     </React.Fragment>
   }
 />
</Snackbar>
</>
  );
}
