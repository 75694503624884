import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, Divider, MenuItem, Select, FormControl, Table, TableBody, TableCell, TableRow, TableHead, TextField, Grid, SelectChangeEvent, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutesAction} from '../redux/route/routeSlice';
import { RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import ListComponent from './list';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search'; 
import CloseIcon from '@mui/icons-material/Close'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; 
import Error from '../error';
import { AddButton } from '../../assets/button';
import { getRoutesKpiAction } from '../redux/route/routeKpi';


const RouteList: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(10); 
  const [searchTerm, setSearchTerm] = React.useState('');
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const [isHoveredSource, setIsHoveredSource] = React.useState(false);
  const [isHoveredDestination, setIsHoveredDestination] = React.useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<number | null>(null);
  const [hoveredMotorIndex, setHoveredMotorIndex] = React.useState(-1);
  const [hoveredCorporateIndex, setHoveredCorporateIndex] = React.useState(-1);
  const [hoveredTransporterIndex, setHoveredTransporterIndex] = React.useState(-1);
  const [hoveredDriverIndex, setHoveredDriverIndex] = React.useState(-1);
  const [loading, setLoading] = React.useState(false); 

  const { routes, getRoutesStatus } = useSelector((state: RootState) => state.routes);
  const routeKpi = useSelector((state: RootState) => state.routeKpi.routeKpi);

  React.useEffect(() => {
    dispatch(getRoutesKpiAction())
   }, []);

  React.useEffect(() => {
    setLoading(true)
      dispatch(getRoutesAction({ search: searchTerm, page: currentPage,  pageSize: itemsPerPage }))
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => setLoading(false));
  }, [dispatch, currentPage, searchTerm, itemsPerPage]);

  const handleAddRouteClick = () => {
    navigate('/add-route');
  };

  const handleEditRoute = (row: any) => {

    navigate(`/edit-route/`, { state: { routeData : row } });
  };
  
  const stats = [
    { title: 'TOTAL ROUTES', value: routeKpi.data?.total_count || 0, change: -1, color: '#FFFFFF' },
    { title: 'CORPORATES', value: routeKpi.data?.CORPORATE || 0, change: 1, color: '#FFFFFF' },
    { title: 'MOTOR OWNERS', value: routeKpi.data['MOTOR OWNER'] || 0, change: 2, color: '#FFFFFF' },
    { title: 'TRANSPORTERS', value: routeKpi.data?.TRANSPORTER || 0, change: 2, color: '#FFFFFF' },
    { title: 'ADMINS', value: routeKpi.data?.ADMIN || 0, change: 2, color: '#FFFFFF' },
  ];
  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
 
  const handlePaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <ListComponent />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        <Paper elevation={1} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '30px',
          paddingTop: '24px',
          paddingBottom: '24px',
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          mb: '2rem',
          width: '100%', 
          overflow: 'hidden',
          paddingRight:'40px'
        }}>
          {/* Stats */}
          <Grid container alignItems="center" justifyContent="flex-start">
            {stats.map((stat, index) => (
              <React.Fragment key={index}>
              <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '8px 16px' }}>
              <Typography variant="subtitle2" component="p" sx={{ fontSize: '10px', fontWeight: '700', fontFamily: 'Montserrat' , color:'#10383D', lineHeight:'20px'}}>
                {stat.title}
              </Typography>
            
            { stat.title === 'TOTAL ROUTES' ? (
            <Typography variant="h6" component="p" sx={{marginTop:'10px', fontSize: '40px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D', lineHeight:'32px' }}>
              {stat.value}
            </Typography>
          ) : (
            <Typography variant="h6" component="p" sx={{ marginTop:'10px', fontSize: '24px', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'end', color: '#10383D' , lineHeight:'24px'}}>
              {stat.value}
            </Typography>
          )}
              </Grid>
              {index !== stats.length - 1 && (
                <Divider orientation="vertical" flexItem sx={{ width: '1px', bgcolor: '#DDDDDD', mx: 2 }} />
              )}
            </React.Fragment>
            ))}
          </Grid>
        </Paper>

        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
            <div>
              <TextField 
                id="outlined-basic" 
                variant="outlined" 
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                size='small'
                sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon color="action" />
                  )
                }}
                InputLabelProps={{ 
                  shrink: false
                }}
              />
            </div>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ paddingTop: '10px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>SHOW</Typography>
              <FormControl sx={{ m: 1 , minWidth:'40'}}>
              <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    size="small"
                    IconComponent={ArrowDropDownIcon}
                    sx={{ height: '40px' }}
                    value={itemsPerPage} 
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{marginRight:'20px', paddingTop: '10px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>ENTRIES</Typography>
            </div>
            <AddButton onClick={handleAddRouteClick}>Add New Route</AddButton>
          </div>
          </div>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
              <TableRow>
                <TableCell align="left" sx={{fontWeight:'600', fontFamily:'Montserrat',fontSize:'11px', lineHeight:'24px'}}>SOURCE</TableCell>
                <TableCell align="left" sx={{fontWeight:'600', fontFamily:'Montserrat',fontSize:'11px', lineHeight:'24px'}}>DESTINATION</TableCell>
                <TableCell align="right" sx={{fontWeight:'600', fontFamily:'Montserrat',fontSize:'11px', lineHeight:'24px'}}>CORPORATE<br/>COUNT</TableCell>
                <TableCell align="right" sx={{fontWeight:'600', fontFamily:'Montserrat',fontSize:'11px', lineHeight:'24px'}}>TRANSPORTERS<br/>COUNT</TableCell>
                <TableCell align="right" sx={{fontWeight:'600', fontFamily:'Montserrat',fontSize:'11px', lineHeight:'24px'}}>MOTOR OWNER<br/>COUNT</TableCell>
                <TableCell align="right" sx={{fontWeight:'600', fontFamily:'Montserrat',fontSize:'11px', lineHeight:'24px'}}>DRIVER<br/>COUNT</TableCell>
                <TableCell align="right" sx={{fontWeight:'600', fontFamily:'Montserrat',fontSize:'11px', lineHeight:'24px'}}></TableCell>
              </TableRow>
            </TableHead>


            <TableBody>
          {getRoutesStatus === STATUS.SUCESS && routes && routes.results && routes.results.length> 0 ? ( routes.results.map((row: any, index:number) => (
      <React.Fragment >
        <TableRow key={index} sx=
        {{ 
          '&:last-child td, &:last-child th': { borderBottom: 0 },
        }}
        onMouseEnter={() => setHoveredRowIndex(index)}
        onMouseLeave={() => setHoveredRowIndex(null)}
        >
          <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
            <div>
              {row.source_name}
              <span
              onMouseEnter={() => setIsHoveredSource(true)}
              onMouseLeave={() => setIsHoveredSource(false)}
              style={{ position: 'relative' }}
            >
              <Typography
                variant="subtitle2"
                component="p"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#939393',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
        {row.pickups.length > 0  && (
          <>
            {isHoveredSource && index === hoveredRowIndex && (
              <div
                style={{
                  position: 'absolute',
                  top: '110%',
                  right: '7rem',
                  zIndex: 1,
                  padding: '5px',
                  backgroundColor: 'white',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                  borderRadius: '5px',
                  width: '150px',
                }}
              >
                <Typography sx={{fontWeight:'600', color:'#13282A', fontFamily:'Montserrat', fontSize:'12px', lineHeight:'14px', padding:'5px', }}> PICKUP </Typography>
                {row.pickups.map((pickup: any, index: number) => (
                  <React.Fragment key={index}>
                   <Typography sx={{ fontWeight:'100',color:'#13282A', fontSize:'12px', lineHeight:'20px', padding:'5px',}}> {pickup.name} </Typography>
                    {index < row.pickups.length - 1 && '\n'}
                  </React.Fragment>
                ))}
                <div
                    onClick={() => setIsHoveredSource(false)}
                    style={{
                      position: 'absolute',
                      top: '4px',
                      right: '5px',
                      color: '#10383D',
                      cursor: 'pointer'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </div>
              </div>
            )}
            {isHoveredSource && ' '}
            {row.pickups.length} pickup location
            <KeyboardArrowRightOutlinedIcon
              style={{
                fontSize: '20px',
                marginLeft: '1px',
                cursor: 'pointer',
                fontWeight: 500,
              }}
            />
          </>
        )}
      </Typography>
    </span>
            </div>
          </TableCell>
          <TableCell sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
            <div >
              {row.destination_name}
              <span onMouseEnter={() => setIsHoveredDestination(true)}
      onMouseLeave={() => setIsHoveredDestination(false)}
      style={{ position: 'relative' }}>
                 <Typography
        variant="subtitle2"
        component="p"
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#939393',
          fontSize: '12px',
          fontWeight: 500,
        }}
      >
        {row.drops.length > 0 && (
          <>
            {isHoveredDestination && index === hoveredRowIndex && (
              <div
                style={{
                  position: 'absolute',
                  top: '110%',
                  right: '7rem',
                  zIndex: 1,
                  padding: '5px',
                  backgroundColor: 'white',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  width: '150px',
                }}
              >
               <Typography sx={{fontWeight:'600', color:'#13282A', fontFamily:'Montserrat', fontSize:'12px', lineHeight:'14px', padding:'5px', }}> DROP </Typography>
                {row.drops.map((drop: any, index: number) => (
                  <React.Fragment key={index}>
                     
                     <Typography sx={{ fontWeight:'100',color:'#13282A', fontSize:'12px', lineHeight:'20px', padding:'5px',}}> {drop.name} </Typography>
                    {index < row.drops.length - 1 && '\n'}
                  </React.Fragment>
                ))}
                <div
                    onClick={() => setIsHoveredDestination(false)}
                    style={{
                      position: 'absolute',
                      top: '4px',
                      right: '5px',
                      color: '#10383D',
                      cursor: 'pointer'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </div>
              </div>
            )}
            {isHoveredDestination && ' '}
            {row.drops.length} drop location
            <KeyboardArrowRightOutlinedIcon
              style={{
                fontSize: '20px',
                marginLeft: '1px',
                cursor: 'pointer',
                fontWeight: 500,
              }}
            />
          </>
        )}
      </Typography>
              </span>
            </div>
          </TableCell>
          <TableCell align="right" sx={{ fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'relative' }}>
        {row.corporate.length > 0 ? (
          <>
            {hoveredCorporateIndex === index && (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  top: '100%',
                  left: '0',
                  zIndex: '999',
                  padding: '5px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  width:'130px',
                }}
              >
                <Typography sx={{ fontWeight: '600', color: '#13282A', fontFamily: 'Montserrat', fontSize: '12px', lineHeight: '14px', padding: '5px', textAlign:'start' }}>CORPORATE</Typography>
                {row.corporate.map((corporate: any, idx:number) => (
                  <Typography
                    key={idx}
                    sx={{ fontWeight: '100', color: '#13282A', fontSize: '12px', lineHeight: '20px', padding: '5px', textAlign: 'start' }}
                  >
                    {corporate.name}
                  </Typography>
                ))}
                <div
                  onClick={() => setHoveredCorporateIndex(-1)}
                  style={{
                    position: 'absolute',
                    top: '4px',
                    right: '5px',
                    color: '#10383D',
                    cursor: 'pointer'
                  }}
                >
                  <CloseIcon fontSize="small" />
                </div>
              </div>
            )}
            <div
              onMouseEnter={() => setHoveredCorporateIndex(index)}
              onMouseLeave={() => setHoveredCorporateIndex(-1)}
              style={{display:'flex', alignItems:'center'}}
            >
              {row.corporate.length}
              <KeyboardArrowRightOutlinedIcon
                style={{
                  fontSize: '20px',
                  marginLeft: '1px',
                  cursor: 'pointer',
                  fontWeight: 500,
                }}
              />
            </div>
          </>
        ) : (
          '0'
        )}
      </div>
    </TableCell>
          <TableCell align="right" sx={{ fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'relative' }}>
        {row.transporter.length > 0 ? (
          <>
            {hoveredTransporterIndex === index && (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  top: '100%',
                  left: '0',
                  zIndex: '999',
                  padding: '5px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  width:'130px',
                }}
              >
                <Typography sx={{ fontWeight: '600', color: '#13282A', fontFamily: 'Montserrat', fontSize: '12px', lineHeight: '14px', padding: '5px', textAlign:'start' }}>Transporter</Typography>
                {row.transporter.map((transporter: any, idx:number) => (
                  <Typography
                    key={idx}
                    sx={{ fontWeight: '100', color: '#13282A', fontSize: '12px', lineHeight: '20px', padding: '5px', textAlign: 'start' }}
                  >
                    {transporter.name}
                  </Typography>
                ))}
                <div
                  onClick={() => setHoveredTransporterIndex(-1)}
                  style={{
                    position: 'absolute',
                    top: '4px',
                    right: '5px',
                    color: '#10383D',
                    cursor: 'pointer'
                  }}
                >
                  <CloseIcon fontSize="small" />
                </div>
              </div>
            )}
            <div
              onMouseEnter={() => setHoveredTransporterIndex(index)}
              onMouseLeave={() => setHoveredTransporterIndex(-1)}
              style={{display:'flex', alignItems:'center'}}
            >
              {row.transporter.length}
              <KeyboardArrowRightOutlinedIcon
                style={{
                  fontSize: '20px',
                  marginLeft: '1px',
                  cursor: 'pointer',
                  fontWeight: 500,
                }}
              />
            </div>
          </>
        ) : (
          '0'
        )}
      </div>
    </TableCell>
    <TableCell align="right" sx={{ fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'relative' }}>
        {row.motor_owner.length > 0 ? (
          <>
            {hoveredMotorIndex === index && (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  top: '100%',
                  left: '0',
                  zIndex: '999',
                  padding: '5px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  width:'130px',
                }}
              >
                <Typography sx={{ fontWeight: '600', color: '#13282A', fontFamily: 'Montserrat', fontSize: '12px', lineHeight: '14px', padding: '5px', textAlign:'start' }}>Motor Owners</Typography>
                {row.motor_owner.map((motorOwner: any, idx:number) => (
                  <Typography
                    key={idx}
                    sx={{ fontWeight: '100', color: '#13282A', fontSize: '12px', lineHeight: '20px', padding: '5px', textAlign: 'start' }}
                  >
                    {motorOwner.name}
                  </Typography>
                ))}
                <div
                  onClick={() => setHoveredMotorIndex(-1)}
                  style={{
                    position: 'absolute',
                    top: '4px',
                    right: '5px',
                    color: '#10383D',
                    cursor: 'pointer'
                  }}
                >
                  <CloseIcon fontSize="small" />
                </div>
              </div>
            )}
            <div
              onMouseEnter={() => setHoveredMotorIndex(index)}
              onMouseLeave={() => setHoveredMotorIndex(-1)}
              style={{display:'flex', alignItems:'center'}}
            >
              {row.motor_owner.length}
              <KeyboardArrowRightOutlinedIcon
                style={{
                  fontSize: '20px',
                  marginLeft: '1px',
                  cursor: 'pointer',
                  fontWeight: 500,
                }}
              />
            </div>
          </>
        ) : (
          '0'
        )}
      </div>
    </TableCell>

        <TableCell align="right" sx={{ fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'relative' }}>
        {row.drivers && row.drivers.length > 0 ? (
          <>
            {hoveredDriverIndex === index && (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  top: '100%',
                  left: '0',
                  zIndex: '999',
                  padding: '5px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  width:'130px',
                }}
              >
                <Typography sx={{ fontWeight: '600', color: '#13282A', fontFamily: 'Montserrat', fontSize: '12px', lineHeight: '14px', padding: '5px', textAlign:'start' }}>Drivers</Typography>
                {row.drivers.map((driver: any, idx:number) => (
                  <Typography
                    key={idx}
                    sx={{ fontWeight: '100', color: '#13282A', fontSize: '12px', lineHeight: '20px', padding: '5px', textAlign: 'start' }}
                  >
                    {driver.name }
                  </Typography>
                ))}
                <div
                  onClick={() => setHoveredDriverIndex(-1)}
                  style={{
                    position: 'absolute',
                    top: '4px',
                    right: '5px',
                    color: '#10383D',
                    cursor: 'pointer'
                  }}
                >
                  <CloseIcon fontSize="small" />
                </div>
              </div>
            )}
            <div
              onMouseEnter={() => setHoveredDriverIndex(index)}
              onMouseLeave={() => setHoveredDriverIndex(-1)}
              style={{display:'flex', alignItems:'center'}}
            >
              {row.drivers.length}
              <KeyboardArrowRightOutlinedIcon
                style={{
                  fontSize: '20px',
                  marginLeft: '1px',
                  cursor: 'pointer',
                  fontWeight: 500,
                }}
              />
            </div>
          </>
        ) : (
          '0'
        )}
      </div>
        </TableCell>

          <TableCell align="right" sx={{fontFamily:'Montserrat', color:'#13282A', fontWeight:'400',fontSize:'14px', lineHeight:'24px', cursor:'pointer'}} >
            <EditNoteOutlinedIcon onClick={() => handleEditRoute(row)} />
            {/* <DeleteOutlineOutlinedIcon sx={{ color: '#C00F00', cursor: 'pointer' }} onClick={() => handleDeleteClick(row.id)} /> */}
          </TableCell>
        </TableRow>
      </React.Fragment>
     ))
     ) : (
       <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
         <TableCell colSpan={8} >
           <Error message="There are no routes added as of yet. You can add a route to see it here."/>
         </TableCell>
       </TableRow>
     )}
</TableBody>


          </Table>
  )}
          
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}> 
            {getRoutesStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getRoutesStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, routes.count || 0) 
              : '-'}{' '}
            of {getRoutesStatus === STATUS.SUCESS ? (routes.count || 0) : '-'}
            </Typography>
            </div>
        <Button
          onClick={() => handlePaginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeftIcon
              sx={{
                color: 'black',
                backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
        <Button
          onClick={() => handlePaginate(currentPage + 1)}
          disabled={!routes.results || routes.results.length < itemsPerPage}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronRightIcon
              sx={{
                color: 'black',
                backgroundColor: !routes.results || routes.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
      </div>
        </Paper>
      </Box>
    </div>
  );
};

export default RouteList;
