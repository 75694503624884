import { FormState } from "../../enquiry/customerForm";


const initialState: FormState = {
    id:'',
    receiver: '',
    name: '',
    email: '',
    address: '',
    phone: '',
    note: '',
};

const receiverFormReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_FORM_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default receiverFormReducer;
