export interface FormState {
  truck_id: string;
  selectedTruckIds: string[]; 
}

const initialState: FormState = {
  truck_id: '',
  selectedTruckIds: [], 
};
// Define action type
const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';
export const UPDATE_SELECTED_TRUCK_IDS = 'UPDATE_SELECTED_TRUCK_IDS';

export const updateSelectedTruckIds = (selectedTruckIds: string[]) => ({
  type: UPDATE_SELECTED_TRUCK_IDS,
  payload: selectedTruckIds,
});

export const updateFormState = (truckId: string) => ({
  type: UPDATE_FORM_STATE,
  payload: { truck_id: truckId },
});


const truckListformReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_FORM_STATE':
      return { ...state, ...action.payload };
    case UPDATE_SELECTED_TRUCK_IDS:
      return { ...state, selectedTruckIds: action.payload }; 
    default:
      return state;
  }
};


export default truckListformReducer;
