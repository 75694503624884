import React, { useState, useEffect, FC } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormState, MultipleSelectPlaceholder } from './MultipleSelectPlaceholder';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createRouteAction, setCreateRouteStatus, setUpdateRouteStatus, updateRouteAction } from '../redux/route/routeSlice';
import { Checkbox, FormControlLabel, FormGroup, Snackbar, SnackbarContent } from '@mui/material';
import Map from './map';
import {TextfieldName, TextfieldNameWithMargin, TypographyWithMargin, TypographyWithoutMargin} from '../../assets/typography';
import DragHandleTwoTone from '@mui/icons-material/DragHandleTwoTone';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import {AddMoreButton} from '../../assets/button';
import SuccessIcon from '../../assets/img/success.png'
import ClearIcon from '@mui/icons-material/Clear';
import FailIcon from '../../assets/img/error.png';
import { RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
declare global {
  interface Window {
    __google_maps_script_loaded__: boolean;
    __google_maps_script_error__: boolean;
    google: { maps: { places: { Autocomplete: any } } };
  }
}
interface routeDetail {
  sourceLocation: string;
  destinationLocation: string;
  pickupLocations: string[];
  dropLocations: string[];
}
interface IProps{
  isEditEnabled: boolean;
}
const RouteDetails: FC<IProps> = ({isEditEnabled}) => {

  const renderSourceLocationField = () => {
    if (isEditEnabled) {
      return (
        <TextField
          id="source-location"
          value={sourceLocation}
          placeholder="eg. Mumbai"
          variant="outlined"
          size="small"
          onChange={(e) => setSourceLocation(e.target.value)}
          disabled
          error={!!errors.sourceLocation}
          helperText={errors.sourceLocation}
        />
      );
    }
    return (
      <TextField
        id="source-location"
        value={sourceLocation}
        placeholder="eg. Mumbai"
        variant="outlined"
        size="small"
        onChange={(e) => setSourceLocation(e.target.value)}
        error={!!errors.sourceLocation}
        helperText={errors.sourceLocation}
      />
    );
  };

  const renderDestinationLocationField = () => {
    if (isEditEnabled) {
      return (
        <TextField
          id="destination-location"
          value={destinationLocation}
          placeholder="eg. Bengaluru"
          variant="outlined"
          size="small"
          onChange={(e) => setDestinationLocation(e.target.value)}
          disabled
          error={!!errors.destinationLocation}
          helperText={errors.destinationLocation}
        />
      );
    }
    return (
      <TextField
        id="destination-location"
        value={destinationLocation}
        placeholder="eg. Bengaluru"
        variant="outlined"
        size="small"
        onChange={(e) => setDestinationLocation(e.target.value)}
        error={!!errors.destinationLocation}
        helperText={errors.destinationLocation}
      />
    );
  };
  const [routeDetail,setRouteDetail] = useState<routeDetail>({
    sourceLocation: '',
    destinationLocation: '',
    pickupLocations: [''],
    dropLocations: [''],
  });

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [returnRoute, setReturnRoute] = useState(false);
  const [sourceLocation, setSourceLocation] = useState('');
  const [destinationLocation, setDestinationLocation] = useState('');
  const [pickupLocations, setPickupLocations] = useState<string[]>(['']);
  const [dropLocations, setDropLocations] = useState<string[]>(['']);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [sourceLatitude, setSourceLatitude] = useState('');
  const [sourceLongitude, setSourceLongitude] = useState('');
  const [destinationLatitude, setDestinationLatitude] = useState('');
  const [destinationLongitude, setDestinationLongitude] = useState('');
  const [sourceName, setSourceName] = useState('');
  const [destinationName, setDestinationName] = useState('');
  const [pickupLatitude, setPickupLatitude] = useState<string[]>(['']);
  const [pickupLongitude, setPickupLongitude] = useState<string[]>(['']);
  const [dropLatitude, setDropLatitude] = useState<string[]>(['']);
  const [dropLongitude, setDropLongitude] = useState<string[]>(['']);
  const [users, setUsers] = useState<string[]>([]); 
  const [sourceGooglePlaceId, setSourceGooglePlaceId] = useState('');
  const [destinationGooglePlaceId, setDestinationGooglePlaceId] = useState('');
  const [pickupGooglePlaceIds, setPickupGooglePlaceIds] = useState<string[]>([]);
  const [dropGooglePlaceIds, setDropGooglePlaceIds] = useState<string[]>([]);
  const [sourceCoordinates, setSourceCoordinates] = useState<google.maps.LatLng | null>(null);
  const [destinationCoordinates, setDestinationCoordinates] = useState<google.maps.LatLng | null>(null);
  const [errors, setErrors] = useState<routeDetail>({
    sourceLocation: '',
    destinationLocation: '',
    pickupLocations: [''],
    dropLocations: [''],
  });

  const location = useLocation();
  const { routeData: initialRouteData } = location.state || { routeData: null };
  const [corporate, setCorporate] = useState<string[]>([]);
  const [motorOwner, setMotorOwner] = useState<string[]>([]);
  const [transporter, setTransporter] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

  const createStatus = useSelector((state: RootState)=> state.routes.createRoutesStatus)
  const updateStatus = useSelector((state: RootState)=> state.routes.updateRoutesStatus)


  useEffect(() => {
    if (initialRouteData) {
      setSourceLocation(initialRouteData.source_name || '');
      setDestinationLocation(initialRouteData.destination_name || '');
      setSourceName(initialRouteData.city_name_source || '');
      setDestinationName(initialRouteData.city_name_destination || '')
      setPickupLocations(initialRouteData.pickups.map((location: any) => location.name) || ['']);
      setDropLocations(initialRouteData.drops.map((location: any) => location.name) || ['']);
      setSourceLatitude(initialRouteData.source_lat || '');
      setSourceLongitude(initialRouteData.source_long || '');
      setDestinationLatitude(initialRouteData.destination_lat || '');
      setDestinationLongitude(initialRouteData.destination_long || '');
      setSourceGooglePlaceId(initialRouteData.google_place_id_source || '');
      setDestinationGooglePlaceId(initialRouteData.google_place_id_destination || '');
      setPickupLatitude(initialRouteData.pickups.map((location: any) => location.lat) || ['']);
      setPickupLongitude(initialRouteData.pickups.map((location: any) => location.long) || ['']);
      setDropLatitude(initialRouteData.drops.map((location: any) => location.lat) || ['']);
      setDropLongitude(initialRouteData.drops.map((location: any) => location.long) || ['']);
      setPickupGooglePlaceIds(initialRouteData.pickups.map((location: any) => location.google_place_id) || []);
      setDropGooglePlaceIds(initialRouteData.drops.map((location: any) => location.google_place_id) || []);
      setCorporate(initialRouteData.corporate.map((item: any) => item.id) || []);
      setMotorOwner(initialRouteData.motor_owner.map((item: any) => item.id) || []);
      setTransporter(initialRouteData.transporter.map((item: any) => item.id) || []);
      handleSnackbar('You can make your changes now.', 'info');
    }
  }, [initialRouteData]);

  const { id } = useParams<{ id: string }>();
  const { routes } = useSelector((state: any) => state.routes);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const loadGooglePlacesScript = async () => {
      const googlePlacesScript = document.createElement('script');
      googlePlacesScript.id = 'google-places-script';
      googlePlacesScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBcGJ7fTKZ-cJwHBwh_XMIVyJzvao3_W2s&libraries=places`;
      googlePlacesScript.async = true;
      googlePlacesScript.defer = true;
      googlePlacesScript.onload = () => setScriptLoaded(true);
      document.body.appendChild(googlePlacesScript);
    };

    loadGooglePlacesScript();

    return () => {
      const scriptElement = document.getElementById('google-places-script');
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      initializeAutocomplete();
      initializePickupAutocomplete('pickup-location-0');
      initializeDropAutocomplete('drop-location-0');
    }
  }, [scriptLoaded]);

  useEffect(() => {
    if (sourceLatitude && sourceLongitude) {
      setSourceCoordinates(new google.maps.LatLng(parseFloat(sourceLatitude), parseFloat(sourceLongitude)));
    }
    if (destinationLatitude && destinationLongitude) {
      setDestinationCoordinates(new google.maps.LatLng(parseFloat(destinationLatitude), parseFloat(destinationLongitude)));
    }
  }, [sourceLatitude, sourceLongitude, destinationLatitude, destinationLongitude]);

  // Inside initializeAutocomplete function
const initializeAutocomplete = () => {
  if (!window.google || !window.google.maps) {
   
    return;
  }

  const indiaBounds = {
    componentRestrictions: { country: 'IN' },
    types: ['(cities)'],
  };

  // Autocomplete for Source Location
  const sourceAutocomplete = new window.google.maps.places.Autocomplete(
    document.getElementById('source-location'),
    {
      ...indiaBounds,
    }
  );
  sourceAutocomplete.addListener('place_changed', () => {
    const place = sourceAutocomplete.getPlace();
    
    if (place && place.formatted_address) {
      setSourceLocation(place.formatted_address);
      setSourceLatitude(place.geometry?.location.lat().toString());
      setSourceLongitude(place.geometry?.location.lng().toString());

      let sourceName = '';
      const typesToSearch = ['locality', 'administrative_area_level_2', 'administrative_area_level_1'];
      typesToSearch.forEach(type => {
        place.address_components.forEach((component: { types: string[]; long_name: string }) => {
          if (component.types.includes(type)) {
            sourceName = component.long_name;
          }
        });
      });
     
      setSourceName(sourceName);
      setSourceGooglePlaceId(place.place_id);
    }
  });

  // Autocomplete for Destination Location
  const destinationAutocomplete = new window.google.maps.places.Autocomplete(
    document.getElementById('destination-location'),
    {
      ...indiaBounds,
    }
  );
  destinationAutocomplete.addListener('place_changed', () => {
    const place = destinationAutocomplete.getPlace();
  
    if (place && place.formatted_address) {
      setDestinationLocation(place.formatted_address);
      setDestinationLatitude(place.geometry?.location.lat().toString());
      setDestinationLongitude(place.geometry?.location.lng().toString());

      let destinationName = '';
      const typesToSearch = ['locality', 'administrative_area_level_2', 'administrative_area_level_1'];
      typesToSearch.forEach(type => {
        place.address_components.forEach((component: { types: string[]; long_name: string }) => {
          if (component.types.includes(type)) {
            destinationName = component.long_name;
          }
        });
      });
    
      setDestinationName(destinationName);
      setDestinationGooglePlaceId(place.place_id);
    }
  });
};


  const initializePickupAutocomplete = (id: string) => {
    if (!window.google || !window.google.maps) {
      console.error('Google Maps script not loaded');
      return;
    }

    const pickupAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById(id));
    pickupAutocomplete.addListener('place_changed', () => {
      const place = pickupAutocomplete.getPlace();
      if (place && place.formatted_address) {
        const newLocations = [...pickupLocations];
        const newLatitude = [...pickupLatitude];
        const newLongitude = [...pickupLongitude];
        newLocations[parseInt(id.split('-')[2])] = place.formatted_address;
        newLatitude[parseInt(id.split('-')[2])] = place.geometry?.location.lng().toString();
        newLongitude[parseInt(id.split('-')[2])] = place.geometry?.location.lat().toString();
        setPickupLocations(newLocations);
        setPickupLatitude(newLatitude);
        setPickupLongitude(newLongitude);
        setPickupGooglePlaceIds(prevIds => [...prevIds, place.place_id]);
      }
    });
  };

  const initializeDropAutocomplete = (id: string) => {
    if (!window.google || !window.google.maps) {
      console.error('Google Maps script not loaded');
      return;
    }

    const dropAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById(id));
    dropAutocomplete.addListener('place_changed', () => {
      const place = dropAutocomplete.getPlace();
      if (place && place.formatted_address) {
        const newLocations = [...dropLocations];
        const newLatitude = [...dropLatitude];
        const newLongitude = [...dropLongitude];
        newLocations[parseInt(id.split('-')[2])] = place.formatted_address;
        newLatitude[parseInt(id.split('-')[2])] = place.geometry?.location.lat().toString();
        newLongitude[parseInt(id.split('-')[2])] = place.geometry?.location.lng().toString();
        setDropLocations(newLocations);
        setDropLatitude(newLatitude);
        setDropLongitude(newLongitude);
        setDropGooglePlaceIds(prevIds => [...prevIds, place.place_id]);
      }
    });
  };

  const handleDeleteLocation = (index: number, setLocation: React.Dispatch<React.SetStateAction<string[]>>) => {
    setLocation(prevLocations => prevLocations.filter((_, i) => i !== index));
  };

  const handleAddLocation = (setLocation: React.Dispatch<React.SetStateAction<string[]>>, initializeAutocomplete: Function) => {
    setLocation(prevLocations => [...prevLocations, '']);
    setTimeout(initializeAutocomplete, 100);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number, type: string) => {
    e.dataTransfer.setData('index', index.toString());
    e.dataTransfer.setData('type', type);
  };
  
  
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  
  
  const handleDrop = (e: React.DragEvent<HTMLDivElement>, setLocation: React.Dispatch<React.SetStateAction<string[]>>, locations: string[], setLocations: React.Dispatch<React.SetStateAction<string[]>>) => {
    e.preventDefault();
    const fromIndex = Number(e.dataTransfer.getData('index'));
    const fromType = e.dataTransfer.getData('type');
    const toIndex = Number(e.currentTarget.dataset.index);
  
    if (fromType === 'pickup' && pickupLocations && dropLocations && fromIndex !== toIndex) {
      const newLocations = [...pickupLocations];
      const temp = newLocations[toIndex];
      newLocations[toIndex] = newLocations[fromIndex];
      newLocations[fromIndex] = temp;
      setLocations(newLocations);
      
      const newPickupIds = [...pickupGooglePlaceIds];
      const tempId = newPickupIds[toIndex];
      newPickupIds[toIndex] = newPickupIds[fromIndex];
      newPickupIds[fromIndex] = tempId;
      setPickupGooglePlaceIds(newPickupIds);
    } else if (fromType === 'drop' && dropLocations && fromIndex !== toIndex) {
      const newLocations = [...dropLocations];
      const temp = newLocations[toIndex];
      newLocations[toIndex] = newLocations[fromIndex];
      newLocations[fromIndex] = temp;
      setLocations(newLocations);
      
      const newDropIds = [...dropGooglePlaceIds];
      const tempId = newDropIds[toIndex];
      newDropIds[toIndex] = newDropIds[fromIndex];
      newDropIds[fromIndex] = tempId;
      setDropGooglePlaceIds(newDropIds);
    }
  };
  
  const handleUpdateForm = (updatedData: string[], fieldName: keyof FormState) => {
    if(fieldName)
    setSelectedUsers(updatedData);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReturnRoute(event.target.checked);
  };
  

  const validateForm = () => {
    let valid = true;
    const newErrors: routeDetail = {
      sourceLocation: '',
      destinationLocation: '',
      pickupLocations: pickupLocations.map(() => ''),
      dropLocations: dropLocations.map(() => ''),
    };

    if (!sourceLocation) {
      newErrors.sourceLocation = 'Source location is required';
      valid = false;
    }

    if (!destinationLocation) {
      newErrors.destinationLocation = 'Destination location is required';
      valid = false;
    }

    const pickupErrors = pickupLocations.map((location, index) => {
      if (!location) {
        return 'Pickup location is required';
      }
      return '';
    });

    const dropErrors = dropLocations.map((location, index) => {
      if (!location) {
        return 'Drop location is required';
      }
      return '';
    });

    if (pickupErrors.some((error) => !!error)) {
      newErrors.pickupLocations = pickupErrors;
      valid = false;
    }

    if (dropErrors.some((error) => !!error)) {
      newErrors.dropLocations = dropErrors;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    const isValid = validateForm();
    if (isValid) {
    let payload : any= {
      route_details: {
        id: id,
        city_name_source:  sourceName ,
        source_name: sourceLocation,
        source_lat: sourceLatitude,
        source_long: sourceLongitude,
        city_name_destination:  destinationName ,
        destination_name: destinationLocation,
        destination_lat: destinationLatitude,
        destination_long: destinationLongitude,
        google_place_id_source: sourceGooglePlaceId,
        google_place_id_destination: destinationGooglePlaceId,
      },
      pick_up_locations: pickupLocations.map((location, index) => ({
        name: location,
        lat: pickupLatitude[index],
        long: pickupLongitude[index],
        google_place_id: pickupGooglePlaceIds[index],
      })),
      drop_locations: dropLocations.map((location, index) => ({
        name: location,
        lat: dropLatitude[index],
        long: dropLongitude[index],
        google_place_id: dropGooglePlaceIds[index],
      })),
      users: selectedUsers,
      return_route: returnRoute,
    };
  
    if (isEditEnabled) {
      dispatch(updateRouteAction(payload));

    } else {
      dispatch(createRouteAction(payload));

    }
  }
  };

  useEffect(()=>{
    if(updateStatus == STATUS.SUCESS){
      handleSnackbar('Route Updated Successfully', 'success');
      setTimeout(() => {
          navigate('/routes');
        }, 1000);
        dispatch(setUpdateRouteStatus(STATUS.NOT_STARTED))
      }

      if(updateStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
      }
  },[updateStatus])

  useEffect(()=>{
    if(createStatus == STATUS.SUCESS){
      handleSnackbar('Route Added Successfully', 'success');
        setTimeout(() => {
          navigate('/routes');
        }, 1000);
        dispatch(setCreateRouteStatus(STATUS.NOT_STARTED))
      }

      if(createStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either this route already exist or you entered wrong information', 'warning');
        }, 7000);
      }
  },[createStatus])

return (
  <>
   <TypographyWithoutMargin variant="h1">Route Details</TypographyWithoutMargin>
      <Box component="form" noValidate sx={{ mt: '16px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Source Location */}
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '50%' }}>
            <TextfieldName variant="h1">Source City</TextfieldName>
            {renderSourceLocationField()}
          </div>
          {/* Destination Location */}
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <TextfieldName variant="h1">Destination City</TextfieldName>
            {renderDestinationLocationField()}
          </div>
        </div>
        
        {/* Pickup & Drop Locations */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      
        <div style={{display:'flex', flexDirection:'column'}}>
     <TextfieldNameWithMargin variant="h1">Pickup Locations</TextfieldNameWithMargin>
          <div style={{ marginTop: '0.5rem', display: 'flex', flexDirection: 'column' }}>
            {pickupLocations.map((location, index) => (
              <div
              key={index}
              style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}
              data-index={index}
              onDragOver={(e) => handleDragOver(e)}
              onDrop={(e) => handleDrop(e, setPickupLocations, pickupLocations, setPickupLocations)}
              draggable
              onDragStart={(e) => handleDragStart(e, index, 'pickup')}
            >
                <div
                  style={{
                    marginRight: '0.5rem',
                    width: '18px',
                    height: '18px',
                    padding: '0px, 3px, 0px, 3px',
                    borderRadius: '36px',
                    border: '1px solid #10383D',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#10383D',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                  }}
                >
                  {index + 1}
                </div>
                <TextField
                  id={`pickup-location-${index}`}
                  value={location}
                  placeholder='Enter Pickup Location'
                  variant="outlined"
                  size="small"
                  sx={{width:'507px'}}
                  onChange={(e) => {
                    const newLocations = [...pickupLocations];
                    newLocations[index] = e.target.value;
                    setPickupLocations(newLocations);
                  }}
                  InputProps={{
                    style: { cursor: 'move' },
                    startAdornment: <DragHandleTwoTone />,
                    endAdornment: <CancelIcon onClick={() => handleDeleteLocation(index, setPickupLocations)} style={{ cursor: 'pointer' }}/>,
                  }}
                  autoComplete="off"
                  error={!!errors.pickupLocations[index]}
                  helperText={errors.pickupLocations[index]}
                />
              </div>
            ))}
            <AddMoreButton 
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            onFocus={() => handleAddLocation(setPickupLocations, () => initializePickupAutocomplete(`pickup-location-${pickupLocations.length}`))}
            >
                Add Pickup Location
            </AddMoreButton>
          </div>
        </div>
        
        <div style={{display:'flex', flexDirection:'column', marginLeft:'1rem'}}>
    <TextfieldNameWithMargin variant="h1">Drop Locations</TextfieldNameWithMargin>
    <div style={{ marginTop: '0.5rem', display: 'flex', flexDirection: 'column' }}>
      {dropLocations.map((location, index) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}
          data-index={index}
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, setDropLocations, dropLocations, setDropLocations)}
          draggable
          onDragStart={(e) => handleDragStart(e, index, 'drop')}
        >
          <div
            style={{
              marginRight: '0.5rem',
              width: '18px',
              height: '18px',
              padding: '0px, 3px, 0px, 3px',
              borderRadius: '36px',
              border: '1px solid #10383D',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#10383D',
              fontFamily: 'Montserrat',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '18px',
              letterSpacing: '0px',
              textAlign: 'center',
            }}
          >
            {index + 1}
          </div>
          <TextField
            id={`drop-location-${index}`}
            value={location}
            placeholder='Enter Drop Location'
            variant="outlined"
            size="small"
            sx={{width:'507px'}}
            onChange={(e) => {
              const newLocations = [...dropLocations];
              newLocations[index] = e.target.value;
              setDropLocations(newLocations);
            }}
            InputProps={{
              style: { cursor: 'move' },
              startAdornment: <DragHandleTwoTone />,
              endAdornment: <CancelIcon onClick={() => handleDeleteLocation(index, setDropLocations)} style={{ cursor: 'pointer' }} />,
            }}
            autoComplete="off"
            error={!!errors.dropLocations[index]}
            helperText={errors.dropLocations[index]}
          />
        </div>
      ))}
      <AddMoreButton 
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            onFocus={() => handleAddLocation(setDropLocations, () => initializeDropAutocomplete(`drop-location-${dropLocations.length}`))}
            >
            Add Drop Location
      </AddMoreButton>
    </div>
  </div>
        </div>
      </Box>
      <TypographyWithMargin variant="h1">User Details</TypographyWithMargin>
      <MultipleSelectPlaceholder
        source={sourceName}
        destination={destinationName}
        onUpdate={handleUpdateForm}
        initialCorporate={corporate}
        initialMotorOwner={motorOwner}
        initialTransporter={transporter}
      />
      <FormGroup>
      <FormControlLabel
  control={<Checkbox checked={returnRoute} onChange={handleCheckboxChange} />}
  label="Do you want same routes for returning route?"
/>

      </FormGroup>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          type="button"
          variant="contained"
          sx={{ mt: 1, mb: 2, width: '100%', backgroundColor: '#03BBD2', textTransform: 'none', '&:hover': { backgroundColor: '#03BBD2' } }}
          onClick={handleSubmit}
        >
          {isEditEnabled ? 'Update Route' : 'Save Route'}
        </Button>

        <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
      </Box>

      {sourceCoordinates && destinationCoordinates && (
        <Map
          sourceCoordinates={sourceCoordinates}
          destinationCoordinates={destinationCoordinates}
        />
      )}
    
  </>
);
            }

export default RouteDetails;