import { MenuItem, MenuList, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { fetchUsersAction } from '../redux/route/routeUserSlice';
import Chip from '@mui/material/Chip';
import { TextfieldNameWithMargin } from '../../assets/typography';
import CloseIcon from '@mui/icons-material/Close';
import { getUserModalType } from '../redux/company and model slice/userModelSlice';

export interface FormState {
  corporate: string[];
  motor_owner: string[];
  transporter: string[];
  driver:string[];
}

interface Props {
  source: string;
  destination: string;
  onUpdate: (selectedUsers: string[], fieldName: keyof FormState) => void;
  initialCorporate: string[];
  initialMotorOwner: string[];
  initialTransporter: string[];
}

export const MultipleSelectPlaceholder: React.FC<Props> = ({ source, destination, onUpdate, initialCorporate, initialMotorOwner, initialTransporter }) => {
  const [companyDetails, setCompanyDetails] = useState<FormState>({
    corporate: initialCorporate || [],
    motor_owner: initialMotorOwner || [],
    transporter: initialTransporter || [],
    driver: [],
  });

  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteCorporate = (valueToDelete:any) => {
    setCompanyDetails((prevCompanyDetails) => ({
      ...prevCompanyDetails,
      corporate: prevCompanyDetails.corporate.filter((value) => value !== valueToDelete),
    }));
  };

  const handleDeleteTransporter = (valueToDelete:any) => {
    setCompanyDetails((prevCompanyDetails) => ({
      ...prevCompanyDetails,
      transporter: prevCompanyDetails.transporter.filter((value) => value !== valueToDelete),
    }));
  };
  const handleDeleteOwner = (valueToDelete:any) => {
    setCompanyDetails((prevCompanyDetails) => ({
      ...prevCompanyDetails,
      motor_owner: prevCompanyDetails.motor_owner.filter((value) => value !== valueToDelete),
    }));
  };
  const handleDeleteDriver = (valueToDelete:any) => {
    setCompanyDetails((prevCompanyDetails) => ({
      ...prevCompanyDetails,
      driver: prevCompanyDetails.driver.filter((value) => value !== valueToDelete),
    }));
  };

  useEffect(() => {
    // Update companyDetails state with initial values
    setCompanyDetails({
      corporate: initialCorporate || [],
      motor_owner: initialMotorOwner || [],
      transporter: initialTransporter || [],
      driver: [],
    });
  }, [initialCorporate, initialMotorOwner, initialTransporter]);

  const handleSelectFieldChange = (
    event: SelectChangeEvent<string[]>,
    fieldName: keyof FormState
  ) => {
    const value = event.target.value;
    let selectedValues: string[] = [];

    if (typeof value === 'string') {
      selectedValues = [value];
    } else {
      selectedValues = value;
    }

    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: selectedValues,
    }));

    
  };

  useEffect(() => {
    const fetchData = (role: string) => {
      const params = {
        city_name_source: source,
        city_name_destination: destination,
        role: role,
      };
      if (source && destination) {
        dispatch(fetchUsersAction(params));
      }
    };

    fetchData('CORPORATE');
    fetchData('MOTOR OWNER');
    fetchData('TRANSPORTER');
    fetchData('DRIVER');
  }, [source, destination, dispatch]);

  const userroutes = useSelector((state: RootState) => state.userroutes);
  const userModal = useSelector((state: RootState) => state.userModal.userModal)

  useEffect(()=>{
     dispatch(getUserModalType({}))
  },[source,destination,dispatch])

  const corporateCompanies = userroutes.companies.filter((company: any) => company.role === 'CORPORATE');
  const motorOwnerCompanies = userroutes.companies.filter((company: any) => company.role === 'MOTOR OWNER');
  const transporterCompanies = userroutes.companies.filter((company: any) => company.role === 'TRANSPORTER');
  const driverCompanies = userModal.results.filter(user => user.user_role === 'DRIVER');

  const isFieldsDisabled = !source || !destination;

  useEffect(() => {
   
    const usersArray = [
      ...companyDetails.corporate,
      ...companyDetails.motor_owner,
      ...companyDetails.transporter,
      ...companyDetails.driver,
    ];
    const filteredUsersArray = usersArray.filter((id) => id !== '');
    onUpdate(filteredUsersArray, 'driver');
  }, [companyDetails, onUpdate]);

  

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '50%' }}>
        <TextfieldNameWithMargin variant="h1">Select Transporter</TextfieldNameWithMargin>
          <Select
            value={companyDetails.transporter}
            onChange={(e) => handleSelectFieldChange(e, 'transporter')}
            fullWidth
            displayEmpty
            size='small'
            disabled={isFieldsDisabled}
            multiple
            autoFocus
            sx={{height:'40px'}}
            renderValue={(selected: any) => {
              const filteredSelected = selected.filter((value: any) => value !== '');
              
              if (filteredSelected.length === 0) {
                  return (
                      <div style={{ color: '#616161' }}>
                          Select Transporters
                      </div>
                  );
              } else {
                <div style={{ color: '#616161' }}>
                {filteredSelected.length} {filteredSelected.length === 1 ? 'Transporter' : 'Transporters'} Selected
            </div>
              }
          }}
          >
            <MenuItem value="">
              <em>Select transporters</em>
            </MenuItem>
            {transporterCompanies.map((company: any) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
          {companyDetails.transporter.length > 0 && (
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
              {companyDetails.transporter.map((value: any) => (
                value !== '' && <Chip key={value} 
                label={transporterCompanies.find((company) => company.id === value)?.name || ''}
                style={{ margin: 2 }} onDelete={() => handleDeleteTransporter(value)}
                deleteIcon={<CloseIcon />}
                />
              ))}
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column',  width: '50%' }}>
        <TextfieldNameWithMargin variant="h1">Select Motor Owner</TextfieldNameWithMargin>
          <Select
            value={companyDetails.motor_owner}
            onChange={(e) => handleSelectFieldChange(e, 'motor_owner')}
            fullWidth
            displayEmpty
            size='small'
            disabled={isFieldsDisabled}
            multiple
            autoFocus
            sx={{height:'40px'}}
            renderValue={(selected: any) => {
              const filteredSelected = selected.filter((value: any) => value !== '');
              
              if (filteredSelected.length === 0) {
                  return (
                      <div style={{ color: '#616161' }}>
                          Select motor owners
                      </div>
                  );
              } else {
                <div style={{ color: '#616161' }}>
                {filteredSelected.length} {filteredSelected.length === 1 ? 'Motor Owner' : 'Motor Owners'} Selected
            </div>
              }
          }}
          >
            <MenuItem value="" disabled>
              <em>Select motor owners</em>
            </MenuItem>
            {motorOwnerCompanies.map((company: any) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>

          {companyDetails.motor_owner.length > 0 && (
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
              {companyDetails.motor_owner.map((value: any) => (
                value !== '' && <Chip key={value} 
                label={motorOwnerCompanies.find((company) => company.id === value)?.name || ''}
                style={{ margin: 2 }}  onDelete={() => handleDeleteOwner(value)}
                deleteIcon={<CloseIcon />} />
              ))}
            </div>
          )}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '50%' }}>
        <TextfieldNameWithMargin variant="h1">Select Corporate</TextfieldNameWithMargin>
          <>
          <Select
        value={companyDetails.corporate}
        onChange={(e) => handleSelectFieldChange(e, 'corporate')}
        fullWidth
        displayEmpty
        size='small'
        disabled={isFieldsDisabled}
        multiple
        autoFocus
        sx={{ height: '40px' }}
        renderValue={(selected: any) => {
          const filteredSelected = selected.filter((value: any) => value !== '');
          
          if (filteredSelected.length === 0) {
              return (
                  <div style={{ color: '#616161' }}>
                      Select Corporates
                  </div>
              );
          } else {
            <div style={{ color: '#616161' }}>
            {filteredSelected.length} {filteredSelected.length === 1 ? 'Corporate' : 'Corporates'} Selected
        </div>
          }
      }}
      >
        <MenuItem value="" disabled>
          <em>Select corporates</em>
        </MenuItem>
        {corporateCompanies.map((company: any) => (
          <MenuItem key={company.id} value={company.id}>
            {company.name}
          </MenuItem>
        ))}
      
      </Select>

      {companyDetails.corporate.length > 0 && (
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
          {companyDetails.corporate.map((value: any) => (
            value !== '' && (
              <Chip
                key={value}
                label={corporateCompanies.find((company) => company.id === value)?.name || ''}
                style={{ margin: 2 }}
                onDelete={() => handleDeleteCorporate(value)}
                deleteIcon={<CloseIcon />}
              />
            )
          ))}
        </div>
      )}

          </>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column',width: '50%' }}>
        <TextfieldNameWithMargin variant="h1">Select Driver</TextfieldNameWithMargin>
          <Select
            value={companyDetails.driver}
            onChange={(e) => handleSelectFieldChange(e, 'driver')}
            fullWidth
            displayEmpty
            size='small'
            disabled={isFieldsDisabled}
            multiple
            autoFocus
            sx={{height:'40px'}}
            renderValue={(selected: any) => {
              const filteredSelected = selected.filter((value: any) => value !== '');
              
              if (filteredSelected.length === 0) {
                  return (
                      <div style={{ color: '#616161' }}>
                          Select Drivers
                      </div>
                  );
              } else {
                <div style={{ color: '#616161' }}>
                {filteredSelected.length} {filteredSelected.length === 1 ? 'Driver' : 'Drivers'} Selected
            </div>
              }
          }}
          >
            <MenuItem value="" disabled>
              <em>Select drivers</em>
            </MenuItem>
            {driverCompanies.map((driver: any) => (
              <MenuItem key={driver.id} value={driver.user_id}>
                <Typography sx={{fontFamily:'Montserrat', fontWeight:'400', fontSize:'14px', lineHeight:'20px', color:'#13282A'}}>{`${driver.user_details.first_name} ${driver.user_details.last_name}`}</Typography>
              </MenuItem>
            ))}
          </Select>
          {companyDetails.driver.length > 0 && (
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '8px' }}>
              {companyDetails.driver.map((value: any) => (
                value !== '' &&
                 <Chip key={value} 
                label={driverCompanies.find((driver) => driver.user_id === value)?.user_details.first_name || ''}
                style={{ margin: 2 }}
                onDelete={() => handleDeleteDriver(value)}
                deleteIcon={<CloseIcon />}
                 />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MultipleSelectPlaceholder;
