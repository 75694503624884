import { combineReducers, configureStore } from '@reduxjs/toolkit';
import routeReducer from '../route/routeSlice';
import goodsReducer from '../goodsSlice/goodsSlice'
import expensesReducer from '../expenses/expensesSlice'
import checklistsReducer from '../checklist/checklistSlice'
import usersReducer from '../expenses/userSlice'
import expendituresReducer from '../expenses/expendituesSlice'
import activeGoodsReducer from '../goodsSlice/activeGoodsSlice'
import totalReducer from '../goodsSlice/totalGoodsSlice'
import hotReducer from '../goodsSlice/hotGoodsSlice'
import trucktypeReducer from '../truck type slice/truckTypeSlice'
import trucktypeUserReducer from '../truck type slice/truckTypeUserSlice'
import companyReducer from '../company and model slice/companySlice'
import truckReducer from '../truck slice/addTruckSlice'
import truckCountReducer from '../truck slice/truckKpi'
import adminReducer from '../truck slice/truckAdminSlice'
import userTruckReducer from '../truck slice/userTableTruckSlice'
import driversReducer from '../truck slice/driverSlice'
import branchReducer from '../company and model slice/branchModelSlice'
import UserModalReducer from '../company and model slice/userModelSlice'
import tdsReducer from '../tds/tdsSlice'
import tdsCountReducer from '../tds/tdsKPI'
import routesUserReducer from '../route/routeUserSlice'
import routesKpiReducer from '../route/routeKpi'
import enquiryReducer from '../enquiries/enquiries'
import receiverReducer from '../receiver/receiver'
import truckformReducer from '../reducers/truckformReducer'
import userFormReducer from '../reducers/userFormReducer';
import receiverFormReducer from '../reducers/receiverForm';
import routeFormReducer from '../reducers/routeForm'
import goodsFormReducer from '../reducers/goodsFormReducer'
import biddingformReducer from '../reducers/biddingForm';
import truckListformReducer from '../reducers/truckListForm';
import expensesformReducer from '../reducers/expenseForm';
import checklistformReducer from '../reducers/checklistReducer';
import commissionformReducer from '../reducers/commissionForm';
import bidsReducer from '../bidding/bidSlice'
import dashboardReducer from '../dashboard/dashboard'
import paymentReducer from '../payments/payments'
import goodsNameReducer from '../goodsSlice/fetchGoods'
import userCountReducer from '../company and model slice/userCountReducer'
import expensesGlobalReducer from '../expenses/globalSlice'
import checklistGlobalReducer from '../checklist/globalSlice'


const rootReducer = combineReducers({
  routes: routeReducer,
  goods:goodsReducer,
  expenses: expensesReducer,
  checklists: checklistsReducer,
  users: usersReducer,
  expenditures: expendituresReducer,
  actives: activeGoodsReducer,
  totals: totalReducer,
  hots: hotReducer,
  trucktypes: trucktypeReducer,
  trucktypeusers: trucktypeUserReducer,
  companies: companyReducer,
  trucks: truckReducer,
  truckCount: truckCountReducer,
  admins: adminReducer,
  usertrucks: userTruckReducer,
  drivers: driversReducer,
  branch: branchReducer,
  userModal: UserModalReducer,
  tds: tdsReducer,
  tdscount: tdsCountReducer,
  userroutes: routesUserReducer,
  routeKpi: routesKpiReducer,
  bids: bidsReducer,
  dashboard: dashboardReducer,
  goodsName: goodsNameReducer,
  expenseName: expensesGlobalReducer,
  checklistName: checklistGlobalReducer,
  userCount: userCountReducer,


  enquiries: enquiryReducer,
  receiver: receiverReducer,
  truckformState: truckformReducer,
  userFromState: userFormReducer,
  receriverFormState: receiverFormReducer,
  routeForm: routeFormReducer,
  goodsForm:goodsFormReducer,
  bidForm: biddingformReducer,
  truckListForm: truckListformReducer,
  expenseForm: expensesformReducer,
  checklistForm: checklistformReducer,
  commisionForm: commissionformReducer,
  payments: paymentReducer,
});

export const store = configureStore({
  reducer:  rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
