import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PhoneIcon from '@mui/icons-material/Phone';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Box, Chip, Paper, Typography } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import CheckIcon from '@mui/icons-material/Check';
import ContractListHeading from './contractListHeading';
import  { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import {  MenuItem, Select, FormControl, Divider, TextField, Grid, CircularProgress } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddButton } from '../../assets/button';

function createData(
  name: string,
  userType: string,
  ownerDetails: string,
  upload: string,
  uploadStatus: string,
  countAdded: string
) {
  return { name, userType, ownerDetails, upload, uploadStatus, countAdded };
}

const rows = [
  createData('Narinder Mood', 'Transporter', 'Ram', 'Choose Files', 'Verified', '5 in 2 Corporates'),
  createData('Narinder Mood', 'Transporter', 'Ram', 'Choose Files', 'Uploaded', '5 in 2 Corporates'),
  createData('Narinder Mood', 'Transporter', 'Ram', 'Choose Files', 'Not Uploaded', '5 in 2 Corporates'),
  createData('Narinder Mood', 'Transporter', 'Ram', 'Choose Files', 'Uploaded', '5 in 2 Corporates'),
  createData('Narinder Mood', 'Transporter', 'Ram', 'Choose Files', 'Verified', '5 in 2 Corporates'),
];


export default function ContractListMain() {
  const getChipColor = (status: string) => {
    switch (status) {
      case 'Verified':
        return '#44936B';
      case 'Uploaded':
        return '#D0A91F';
      case 'Not Uploaded':
        return '#939393'; 
      default:
        return '';
    }
  };

  const getChipBg = (status: string) => {
    switch (status) {
      case 'Verified':
        return '#E7F0F1';
      case 'Uploaded':
        return '#F7F2DD'; 
      case 'Not Uploaded':
        return '#F7F9FA'; 
      default:
        return ''; 
    }
  };

  const navigate = useNavigate();

  const handleAddContractClick = () => {
    // handleShowAlert('success', 'New contract added successfully');
    navigate('/add-contract');
  };

  return (
    <>
<div style={{ width: '100vw', overflow: 'hidden' }}>
    <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <ContractListHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
      <Paper
          sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            padding: '1.5rem',
            mb: '2rem',
            boxShadow: 2,
          }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
            //   value={searchTerm}
              size="small"
            //   onChange={handleSearchChange}
              sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
              InputProps={{
                startAdornment: (
                  <SearchIcon color="action" />
                )
              }}
              InputLabelProps={{
                shrink: false
              }}
            />

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', marginRight: '20px' }}>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>SHOW</Typography>
                <FormControl sx={{ m: 1 }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    size='small'
                    IconComponent={ArrowDropDownIcon}
                    sx={{ width: '59px', height: '40px' }}
                    // onChange={handleChangeRowsPerPage}
                  >
                    
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>ENTRIES</Typography>
              </div>
              <AddButton onClick={handleAddContractClick}>Add Contract</AddButton>
            </div>
            </div>
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 }}}>
          <TableRow>
            <TableCell align="left" style={{color:'#10383D', fontSize:'10px', lineHeight:'14px', letterSpacing:'1px'}}>Start Date</TableCell>
            <TableCell align="left" style={{color:'#10383D', fontSize:'10px', lineHeight:'14px', letterSpacing:'1px'}}>USER TYPE</TableCell>
            <TableCell align="left" style={{color:'#10383D', fontSize:'10px', lineHeight:'14px', letterSpacing:'1px'}}>OWNER DETAILS</TableCell>
            <TableCell align="left" style={{color:'#10383D', fontSize:'10px', lineHeight:'14px', letterSpacing:'1px'}}>UPLOAD</TableCell>
            <TableCell align="left" style={{color:'#10383D', fontSize:'10px', lineHeight:'14px', letterSpacing:'1px'}}>UPLOAD STATUS</TableCell>
            <TableCell align="left" style={{color:'#10383D', fontSize:'10px', lineHeight:'14px', letterSpacing:'1px'}}>COUNT ADDED</TableCell>
            <TableCell align="left" style={{color:'#10383D', fontSize:'10px', lineHeight:'14px', letterSpacing:'1px'}}>ACTIONS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
              <TableCell component="th" scope="row">
                <div>
                  {row.name}
                </div>
              </TableCell>
              <TableCell align="left">
                <div>
                  {row.userType}
                </div>
              </TableCell>
              <TableCell align="left">
                {row.ownerDetails}<br />
                  <span style={{ display: 'flex', alignItems: 'right' ,color: '#939393',}}>
                  <PhoneIcon style={{ fontSize: '9px', marginTop:'4px' , marginRight:'1px'}} />
                  <Typography style={{fontSize:'10px'}}>9876543211</Typography>
                  </span>
              </TableCell>
              <TableCell align="right"><span style={{ display: 'flex', alignItems: 'right'}}>
                <Typography style={{fontSize:'12px', fontWeight:'600'}}>{row.upload}</Typography>
                  <KeyboardArrowRightOutlinedIcon style={{ fontSize: '16px' }} />
                  </span>
                  </TableCell>
              <TableCell align="left">
              <Chip
                label={row.uploadStatus}
                variant="outlined"
                sx={{ backgroundColor: getChipBg(row.uploadStatus), color: getChipColor(row.uploadStatus) }}
                icon={row.uploadStatus === 'Verified' ? <CheckIcon style={{color:'#44936B', fontSize:'12.57px'}} /> : undefined}
                />
                </TableCell>
              <TableCell align="left">{row.countAdded}</TableCell>
              <TableCell align="left"><span style={{ display: 'flex', alignItems: 'right'}}>
                <Typography style={{fontSize:'12px',fontWeight:'600'}}>Verify Document</Typography>
                  <KeyboardArrowRightOutlinedIcon style={{ fontSize: '16px' }} />
                  <CampaignIcon style={{color:'#03BBD2', fontSize:'22px', marginTop:0}}/>
                  </span></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
    </Box>
    </div>
    </>
  );
}
