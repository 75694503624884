import React, { ChangeEvent, useState } from 'react';
import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useDispatch, useSelector } from 'react-redux';
import { addCompanyDetails } from '../redux/reducers/commissionForm';

interface FormState {
  user_type: string;
  unit: string;
  value: string;
}

const CommissionForm = () => {
  const dispatch = useDispatch<AppDispatch>()
  const companyDetails = useSelector((state: RootState) => state.commisionForm.companyDetails);

  const handleFieldChange = (index: number, fieldName: keyof FormState, value: string) => {
    dispatch(addCompanyDetails({ index, [fieldName]: value }));
  };



  const addCommission = () => {
    dispatch(addCompanyDetails({ index: companyDetails.length, ...companyDetails[companyDetails.length - 1] }));
  };

  return (
    <>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '0.5rem', marginTop: '2rem' }}>Commissions</Typography>

      {companyDetails.map((commission:FormState, index:number) => (
        <div key={`companyDetails-${index}`}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>User Type</Typography>
              {index === 0 ? (
          <Select
            value="All"
            disabled
            fullWidth
            displayEmpty
            size='small'
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="All">All</MenuItem>
          </Select>
        ) : (
          <Select
            value={commission.user_type}
            onChange={(e) => handleFieldChange(index, 'user_type', e.target.value)}
            fullWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="Corporate">Corporate</MenuItem>
            <MenuItem value="Motor Owner">Motor Owner</MenuItem>
            <MenuItem value="Transporter">Transporter</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        )}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>Commission Unit (Fixed or %)</Typography>
              <Select
                value={commission.unit}
                onChange={(e) => handleFieldChange(index, 'unit', e.target.value)}
                fullWidth
                size='small'
              >
                <MenuItem value="">Select unit</MenuItem>
                <MenuItem value="Fixed">Fixed</MenuItem>
                <MenuItem value="Percentage">Percentage</MenuItem>
              </Select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>Value</Typography>
              <TextField
                placeholder="Enter amount/percentage"
                name="value"
                value={commission.value}
                onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                size="small"
                InputLabelProps={{
                  shrink: false,
                }}
              />
            </div>
          </div>
        </div>
      ))}
      <Button
        onClick={addCommission}
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '2.5%',
          textAlign: 'center',
          color: '#13282A',
          marginTop: '10px',
          border: 'none',
          width: '7rem',
          textTransform: 'none',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
        }}
      >
        Add More
      </Button>
    </>
  );
};

export default CommissionForm;
