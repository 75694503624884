import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store/appStore';
import EnquiryListHeading from './enquiryListHeading';
import EnquiryListTable from './enquiryListTable';

const EnquiryListMain = () => {

  const [alignment, setAlignment] = useState('mac');

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <EnquiryListHeading/>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <ToggleButtonGroup
              color="standard"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
            >
              <ToggleButton
                value="mac"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'mac' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'mac' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Admin
              </ToggleButton>
              <ToggleButton
                value="web"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'web' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'web' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Corporate
              </ToggleButton>
              <ToggleButton
                value="transporter"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'transporter' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'transporter' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Transporter
              </ToggleButton>
              <ToggleButton
                value="android"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'android' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'android' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Motor Owner
              </ToggleButton>
              <ToggleButton
                value="website"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'website' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'website' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Website
              </ToggleButton>
              <ToggleButton
                value="match"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'match' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'match' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Match
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          
          {alignment === 'mac' && (
            <div>
              <EnquiryListTable/>
              
            </div>
          )}

        {alignment === 'web' && (
            <div>
              {/* <AdminTruckTable/> */}
              
            </div>
          )}

        {alignment === 'android' && (
            <div>
              {/* <UsersTable/> */}
              
            </div>
          )}
        </Paper>
      </Box>
    </div>
  )
}

export default EnquiryListMain