import {useState, useEffect, ChangeEvent} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormControl, FormHelperText, Paper, Typography, Snackbar, Icon, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import ExpensesHeading from './expensesHeading';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AdminTable from './adminTable';
import UsersTable from './usersTable';
import { useDispatch, useSelector } from 'react-redux';
import { createExpense, getExpenses,setCreateExpensesStatus } from '../redux/expenses/expensesSlice'; 
import { AppDispatch, RootState } from '../redux/store/appStore';
import SnackbarContent from '@mui/material/SnackbarContent';
import ClearIcon from '@mui/icons-material/Clear';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import { STATUS } from '../../utils/status';
import { getGlobalExpense } from '../redux/expenses/globalSlice';

const ExpensesMain = () => {
  const [unit, setUnit] = useState('');
  const [alignment, setAlignment] = useState('web');
  const [expenseName, setExpenseName] = useState('');
  const [rate, setRate] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<string>('asc');

  const dispatch = useDispatch<AppDispatch>();
  const expenses = useSelector((state: RootState) => state.expenses.expenses);
  const createExpenseStatus = useSelector((state: RootState) => state.expenses.createExpenseStatus);
  console.log({createExpenseStatus})

  useEffect(() => {
      dispatch(getExpenses({ page: currentPage, sort: sortColumn, order: sortOrder }))
  }, [dispatch, currentPage, sortColumn, sortOrder]);


  const handleChange = (event: SelectChangeEvent<string>) => {
    setUnit(event.target.value);
  };

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

const handleAddExpense = async () => {
  const newErrors: { [key: string]: string } = {};
  let valid = true;

  if (!inputValue) {
    newErrors.expenseName = 'Name is required';
    valid = false;
  }
  if (!rate) {
    newErrors.rate = 'Rate is required';
    valid = false;
  }
  if (!unit) {
    newErrors.unit = 'Unit is required';
    valid = false;
  }

  setErrors(newErrors);
  setFormErrors(newErrors);
  setIsFormValid(valid);

  if (!valid) {
    return;
  }

  const newExpense = {
    name: inputValue,
    rate: parseFloat(rate),
    unit: unit as 'TON' | 'KG' | 'QUINTAL' | 'RS' | 'LITRE',
  };
  
  await dispatch(createExpense(newExpense));
  await dispatch(getExpenses({ page: currentPage, sort: sortColumn, order: sortOrder }));

  setInputValue('');
  setRate('');
  setUnit('');
  setIsFormValid(false);
};

const handleCloseSnackbar = () => {
  setSnackbarOpen(false);
};

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(()=>{
    if(createExpenseStatus == STATUS.SUCESS){
      handleSnackbar('Expense Added Successfully', 'success');
        dispatch(setCreateExpensesStatus(STATUS.NOT_STARTED))
      }

      if(createExpenseStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either this expense already exist or you entered wrong information', 'warning');
        }, 5000);
      }
  },[createExpenseStatus])

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [selectedExpense, setSelectedExpense] = useState<{ label: string; value: string; } | null>(null);
  const companies = useSelector((state: RootState) => state.expenseName.expenseName);
  console.log({companies})
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<{ label: string; value: string; }[]>([]);
  useEffect(() => {
    dispatch(getGlobalExpense({}))
  }, [dispatch]);

  useEffect(() => {
    setOptions(companies.results.map((company) => ({ label: company.name, value: company.id })));
  }, [companies]);
  
  const handleInputChange = (event:any, newInputValue:any) => {
    setInputValue(newInputValue);

    console.log(selectedExpense); 
    dispatch(getGlobalExpense({ search: newInputValue }));
  };



  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <ExpensesHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        {/* Table Layout */}
        <Paper
          sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            padding: '1.5rem',
            mb: '2rem',
            boxShadow: 2,
          }}
        >
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <ToggleButtonGroup
              color="standard"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
            >
              <ToggleButton
                value="web"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'web' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'web' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform:'none'
                }}
              >
                Admin
              </ToggleButton>
              <ToggleButton
                value="android"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'android' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'android' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform:'none'
                }}
              >
                Other Users
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {alignment === 'web' && (
            <div style={{ marginBottom: '1rem' }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#10383D',
                  marginTop: 4,
                }}
              >
                Add New Expenses In Your List
              </Typography>
              <div style={{ display: 'flex', marginTop: '2rem', marginBottom:'1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      // fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#10383D',
                    }}
                  >
                    Expenses Name
                  </Typography>
                  <Autocomplete
                      options={companies.results}
                      getOptionLabel={(option:any) => option.name}
                      inputValue={inputValue}
                      onInputChange={handleInputChange}
                      freeSolo
                      sx={{
                        borderRadius: '4px',
                        width: '248px',
                        marginRight: '8px',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Expense Name"
                          error={formErrors.expenseName !== undefined}
                          helperText={formErrors.expenseName}
                          size="small"
                          InputLabelProps={{
                            shrink: false,
                          }}
                        />
                      )}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#10383D',
                    }}
                  >
                    Rate
                  </Typography>
                  <TextField
                    id="rate"
                    placeholder="eg. 50"
                    variant="outlined"
                    size="small"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    error={!!errors.rate}
                    helperText={errors.rate}
                    sx={{
                      borderRadius: '4px',
                      width: '248px',
                      marginRight: '8px',
                    }}
                    InputLabelProps={{
                      shrink: false,
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#10383D',
                    }}
                  >
                    Unit
                  </Typography>
                  <FormControl error={!!errors.unit ? true : false}>
                  <Select
                    id="unit-select"
                    value={unit}
                    onChange={handleChange}
                    variant="outlined"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    // error={!!errors.unit}
                    sx={{
                      borderRadius: '4px',
                      width: '248px',
                      // marginBottom: '4px',
                      marginRight: '8px',
                    }}
                  >
                    <MenuItem value="">
                      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '400', fontSize: '14px', lineHeight:'24px', color:'#13282A' }}>Select Unit</Typography>
                    </MenuItem>
                    <MenuItem value="TON">Ton</MenuItem>
                    <MenuItem value="KG">Kg</MenuItem>
                    <MenuItem value="QUINTAL">Quintal</MenuItem>
                    <MenuItem value="RS">Rs</MenuItem>
                    <MenuItem value="LITRE">Litre</MenuItem>
                  </Select>
                  {/* helperText={errors.rate}*/}
                  {!!errors.unit && (
                    <FormHelperText>{errors.unit}</FormHelperText> 
                  )}
                  </FormControl>
                </div>
                
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  disableElevation
                  onClick={handleAddExpense}
                  sx={{
                    fontWeight:'600',
                    padding: '8px, 16px, 8px, 16px',
                    border: '1px',
                    borderRadius: '8px',
                    width: '161px',
                    height: '40px',
                    marginBottom: '1px',
                    marginTop: '25px',
                    marginLeft: '0',
                    textTransform: 'none',
                    backgroundColor: '#03BBD2',
                    '&:hover': { backgroundColor: '#03BBD2' },
                  }}
                >
                  <Typography style={{ fontSize: '12px' }}>
                    Add Expenses
                  </Typography>
                </Button>
              
              </div>
              <AdminTable data={expenses.results} count={!!expenses?.count ? expenses.count : 0}/>
            </div>
          )}
          {alignment === 'android' && <UsersTable />}
        </Paper>
      </Box>
      {/* Snackbar for showing success and error messages */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{borderRadius:'8px'}}
      >

        <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleCloseSnackbar}/>
            </>
          }
        />
      </Snackbar>
    </div>
  );
};

export default ExpensesMain;
