import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHeaderTypography, FormHeaderTypography2 } from '../../assets/typography';

interface AddRouteProps{
    isEditEnabled?: boolean;
}

const AddRoute :FC<AddRouteProps> = ({isEditEnabled}) => {
  const navigate = useNavigate();

  const handleBackToRoutes = () => {
    navigate('/routes');
  };

  return (
    <div>
      <Box
        sx={{
          bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        color: '#13282A',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        mt:'4rem',
        height:'84px',
        }}
      >
        <Typography paragraph sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px' }}>
          <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 'inherit', cursor: 'pointer' }} onClick={handleBackToRoutes}/> Back to Routes
        </Typography>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
        {isEditEnabled?"Edit Route":"Add Route"} 
        </Typography>
      </Box>
    </div>
  );
}

export default AddRoute;
