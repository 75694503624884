import React, { useEffect, useState } from 'react';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { getGoodsType } from '../redux/goodsSlice/goodsSlice';
import { addCompanyDetails } from '../redux/reducers/goodsFormReducer';

export interface FormState {
  goods_type: string;
  short_allowance: string;
}

const GoodsForm = () => {
  const companyDetails = useSelector((state:RootState) => state.goodsForm.companyDetails);
  const dispatch = useDispatch<AppDispatch>();
  const goodsData = useSelector((state: RootState) => state.goods.goods);
  
  useEffect(() => {
    dispatch(getGoodsType({}));
  }, [dispatch]);

  const handleFieldChange = (index: number, fieldName: keyof FormState, value: string) => {
    dispatch(addCompanyDetails({ index, [fieldName]: value }));
  };

  const handleGoodsTypeChange = (index: number, goodsType: string) => {
    const selectedGoods = goodsData.results.find((goods: any) => goods.goods_name_id === goodsType);
    if (selectedGoods) {
      handleFieldChange(index, 'goods_type', goodsType);
      handleFieldChange(index, 'short_allowance', ''); 
    }
  };

  const handleAddMore = () => {
    dispatch(addCompanyDetails({ index: companyDetails.length, ...companyDetails[companyDetails.length - 1] }));
  };

  return (
    <>
      {companyDetails.map((details: FormState, index: number) => (
        <div key={`companyDetails-${index}`}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>
                Goods Type
              </Typography>
              <Select
                fullWidth
                displayEmpty
                size='small'
                inputProps={{ 'aria-label': 'Without label' }}
                value={details.goods_type}
                onChange={(e) => handleGoodsTypeChange(index, e.target.value as string)}
              >
                <MenuItem value="" disabled>
                  Select goods type
                </MenuItem>
                {goodsData.results.map((goods: any) => (
                  <MenuItem key={goods.goods_name_id} value={goods.goods_name_id}>
                    {goods.goods_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', width: '34%' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', backgroundColor: '#F7F9FA', paddingLeft: '12px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}>Shortage Allowance</Typography>
              <Select
                fullWidth
                displayEmpty
                size='small'
                inputProps={{ 'aria-label': 'Without label' }}
                value={details.short_allowance}
                onChange={(e) => handleFieldChange(index, 'short_allowance', e.target.value as string)}
              >
               <MenuItem value="" disabled>
                  Select short allowance
                </MenuItem>
                {/* Populate short allowance options based on goods type */}
                {goodsData.results
                  .find((goods: any) => goods.goods_name_id === details.goods_type)
                  ?.shortages.map((option: { id: string, short_allowance: string, short_allowance_unit: string }) => (
                    <MenuItem key={option.id} value={option.short_allowance}>
                      {option.short_allowance} {option.short_allowance_unit === 'PERCENTAGE' ? '%' : option.short_allowance_unit}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
        </div>
      ))}

      <Button
        onClick={handleAddMore}
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '2.5%',
          textAlign: 'center',
          color: '#13282A',
          border: 'none',
          width: '10rem',
          textTransform: 'none',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
        }}
      >
        Add Goods Type
      </Button>
    </>
  );
}

export default GoodsForm;
