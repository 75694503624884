import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface ActiveGoodsState {
  activeGoodsCount: number;
  getActiveGoodsStatus: STATUS;
}

const initialState: ActiveGoodsState = {
  activeGoodsCount: 0,
  getActiveGoodsStatus: STATUS.NOT_STARTED,
};

export const getActiveGoods = createAsyncThunk(
  'activeGoods/getActive',
  async (_, thunkAPI) => {
      const response = await api.get('goodstype/active_goods');
      const { status, data } = response;
      if (status === 200) {
        return data.active_goods;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
  }
);

const activeGoodsSlice = createSlice({
  name: 'activeGoods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveGoods.pending, (state, action) => {
        state.getActiveGoodsStatus = STATUS.PENDING;
        console.log('GET ACTIVE GOODS PENDING');
      })
      .addCase(getActiveGoods.fulfilled, (state, action) => {
        state.activeGoodsCount = action.payload;
        console.log(action)
        state.getActiveGoodsStatus = STATUS.SUCESS;
        console.log('GET ACTIVE GOODS SUCCESS');
      })
      .addCase(getActiveGoods.rejected, (state, action) => {
        state.getActiveGoodsStatus = STATUS.FAILED;
        console.log('GET ACTIVE GOODS FAILED');
      });
  },
});

export default activeGoodsSlice.reducer;
