import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { STATUS } from '../../../utils/status';
import api from '../../../utils/api';

interface CreateCompanyTypePayload {
  owners: any;
  id: string;
  name: string;
  role: string;
  users: any;
}

interface CompanyData{
  id:string;
  name:string;
}


interface CompanyState {
  companies: {
    
    count: number | undefined;
    results: CreateCompanyTypePayload[];
    
  };
  loading: boolean;
  error: string | null;
  fetchCompaniesStatus: STATUS;
  createCompaniesStatus: STATUS;
  updateCompaniesStatus: STATUS;
  fetchCompaniesSingleStatus: STATUS;
}

const initialState: CompanyState = {
  companies: {
    count: undefined,
    results: [],
    
  },
  loading: false,
  error: null,
  fetchCompaniesStatus: STATUS.NOT_STARTED,
  createCompaniesStatus: STATUS.NOT_STARTED,
  updateCompaniesStatus: STATUS.NOT_STARTED,
  fetchCompaniesSingleStatus: STATUS.NOT_STARTED
};
interface GetCompanyPayload {
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: string;
  paginate?: boolean;
  id?:string;
}

export const fetchCompanies = createAsyncThunk(
  'companies/get',
  async (payload: GetCompanyPayload = {}, thunkAPI) => {

    const { search, page, pageSize, sort, order, paginate, id} = payload;
    const queryString = new URLSearchParams({
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(id && { id }),
      ...(paginate !== undefined && { paginate: paginate.toString() }),
    }).toString();
    const response = await api.get(`company/?${queryString}`);
        const { status, data } = response;
        if (status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue({ response, data });
        }
      }
);

export const fetchCompaniesSingle = createAsyncThunk(
  'company/get',
  async ({id}:{id:any}, thunkAPI) => {
    const response = await api.get(`company/?id=${id}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const createCompanies = createAsyncThunk(
  'companies/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post('company/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateCompanies = createAsyncThunk(
  'company/patch',
  async (payload: any, thunkAPI) => {
    const response = await api.patch(`company/`, payload); 
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCreateCompanyStatus(state,action){
      state.createCompaniesStatus = action.payload
    },
    setUpdateCompanyStatus(state,action){
      state.updateCompaniesStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.fetchCompaniesStatus = STATUS.PENDING;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
          state.companies = action.payload;
          state.fetchCompaniesStatus = STATUS.SUCESS;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.fetchCompaniesStatus = STATUS.FAILED;
      })
      .addCase(fetchCompaniesSingle.pending, (state) => {
        state.fetchCompaniesSingleStatus = STATUS.PENDING;
      })
      .addCase(fetchCompaniesSingle.fulfilled, (state, action) => {
        let index = current(state).companies.results.map(tt => tt.id).indexOf(action.payload.id)
        state.companies.results[index] = action.payload;
        state.fetchCompaniesSingleStatus = STATUS.SUCESS;
      })
      .addCase(fetchCompaniesSingle.rejected, (state, action) => {
        state.fetchCompaniesSingleStatus = STATUS.FAILED;
      })
      .addCase(createCompanies.pending, (state) => {
        state.createCompaniesStatus = STATUS.PENDING;
      })
      .addCase(createCompanies.fulfilled, (state, action) => {
        state.companies.results.push(action.payload);
        state.createCompaniesStatus = STATUS.SUCESS;
      })
      .addCase(createCompanies.rejected, (state, action) => {
        state.createCompaniesStatus = STATUS.FAILED;
      })
      .addCase(updateCompanies.pending, (state, action) => {
        state.updateCompaniesStatus = STATUS.PENDING
      })
      .addCase(updateCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.updateCompaniesStatus = STATUS.SUCESS
      })
      .addCase(updateCompanies.rejected, (state, action) => {
        state.updateCompaniesStatus = STATUS.FAILED
      })
  },
});

export const {setCreateCompanyStatus, setUpdateCompanyStatus} = companySlice.actions
export default companySlice.reducer;
