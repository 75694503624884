import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { getRoutesAction } from '../redux/route/routeSlice';
import { getTruckTypes } from '../redux/truck type slice/truckTypeSlice';
import { AppDispatch, RootState } from '../redux/store/appStore';

export interface FormState {
  truck_type: string;
  tyre_count: string;
  truck_weight: string;
  pickup_location: string;
  drop_location: string;
  number_of_trucks: string;
  pickup_date: string;
}

const TruckEnquiryForm = () => {
  const [filteredTyreCounts, setFilteredTyreCounts] = useState<string[]>([]);
  const [filteredTruckWeights, setFilteredTruckWeights] = useState<string[]>([]);
  const companyDetails: FormState = useSelector((state: RootState) => state.truckformState);
  const dispatch = useDispatch<AppDispatch>();
  const truckTypes = useSelector((state: RootState) => state.trucktypes.truckTypes);
  const truckTypeStatus = useSelector((state: RootState) => state.trucktypes.getStatus);
 
  useEffect(() => {
    dispatch(getRoutesAction({}));
    dispatch(getTruckTypes({}));
  }, [dispatch]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({ type: 'UPDATE_FORM_STATE', payload: { [name]: value } });
  };

  const handleSelectFieldChange = (event: ChangeEvent<{ value: unknown }>, fieldName: keyof FormState) => {
    dispatch({ type: 'UPDATE_FORM_STATE', payload: { [fieldName]: event.target.value as string } });
  };

  

  useEffect(() => {
    const selectedTruckType = truckTypes.find(truck => truck.truck_type_id === companyDetails.truck_type);
    // Check if selectedTruckType is not undefined before accessing truck_tyres
if (selectedTruckType && selectedTruckType.truck_tyres) {
  setFilteredTyreCounts(selectedTruckType.truck_tyres.map(tyre => tyre.no_of_tyres.toString()));
}

  }, [truckTypes, companyDetails.truck_type]);

  useEffect(() => {
    const selectedTruckType = truckTypes.find(truck => truck.truck_type_id === companyDetails.truck_type);
    if (selectedTruckType && selectedTruckType.truck_tyres) {
      const selectedTyre = selectedTruckType.truck_tyres.find(tyre => tyre.no_of_tyres.toString() === companyDetails.tyre_count);
      if (selectedTyre) {
        setFilteredTruckWeights(selectedTyre.weights.map(weight => weight.weight.toString()));
      }
    }
  }, [companyDetails.truck_type, companyDetails.tyre_count, truckTypes]);
  

    const isFormFilled = Object.values(companyDetails).every(value => value !== '');

  return (
    <>
  <FormControl>
  <FormLabel id="demo-row-radio-buttons-group-label" sx={{color:'#10383D', marginTop:'1rem', marginBottom:'1rem'}}>Select Truck Type</FormLabel>
  <div style={{ overflowX: 'auto', margin:'2px' }}>
  <RadioGroup
  row
  aria-labelledby="demo-row-radio-buttons-group-label"
  name="truck_type"
  value={companyDetails.truck_type} 
  onChange={handleFieldChange} 
>
  {truckTypes.map((truck) => (
    <div key={truck.truck_type_id} style={{ border: '1px solid', borderColor: '#E8EAEF', padding: '12px', marginRight: '1.3rem', flexShrink: 0 }}>
      <FormControlLabel
        value={truck.truck_type_id} 
        control={<Radio />}
        label={
          <React.Fragment>
            <div style={{ display: 'flex' }}>
              <img
                style={{
                  width: '64px',
                  height: '64px',
                  border: '1px solid',
                  borderRadius: '4px',
                }}
                //src={truck.id}
                alt="Image"
              />
              <Typography variant="body1" sx={{ marginLeft: '10px', marginTop: '20px' }}>
                {truck.name}
              </Typography>
            </div>
          </React.Fragment>
        }
      />
          </div>
        ))}
      </RadioGroup>

        </div>
      </FormControl>


        <div style={{display:'flex'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>No. of Tyres</Typography>
          <Select
            value={companyDetails.tyre_count}
            onChange={(e:any) => handleSelectFieldChange(e, 'tyre_count')}
            fullWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="" disabled>
              Select no. of tyres
            </MenuItem>
            {filteredTyreCounts.map(tyreCount => {
    const selectedTruckType = truckTypes.find(truck => truck.truck_type_id === companyDetails.truck_type);
    if (selectedTruckType && selectedTruckType.truck_tyres) {
      const selectedTyre = selectedTruckType.truck_tyres.find(tyre => tyre.no_of_tyres.toString() === tyreCount);
      if (selectedTyre) {
        return (
          <MenuItem key={selectedTyre.id} value={selectedTyre.id}>
            {tyreCount}
          </MenuItem>
        );
      }
    }
    return (
      <MenuItem key={tyreCount} value={tyreCount} disabled>
        {tyreCount}
      </MenuItem>
    );
  })}
          </Select>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Select Truck Weights (Tonne)</Typography>
        <Select
          value={companyDetails.truck_weight}
          onChange={(e:any) => handleSelectFieldChange(e,'truck_weight')}
          fullWidth
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          size='small'
        >
          <MenuItem value="" disabled>
            Select truck weight
          </MenuItem>
          {filteredTruckWeights.map(weight => {
  const selectedTruckType = truckTypes.find(truck => truck.truck_type_id === companyDetails.truck_type);
  if (selectedTruckType && selectedTruckType.truck_tyres) {
    const selectedTyre = selectedTruckType.truck_tyres.find(tyre => tyre.no_of_tyres.toString() === companyDetails.tyre_count);
    if (selectedTyre && selectedTyre.weights) {
      // Find the weight corresponding to the selected tyre
      const selectedWeight = selectedTyre.weights.find(w => w.weight.toString() === weight);
      if (selectedWeight) {
        return (
          <MenuItem key={selectedWeight.id} value={selectedWeight.id}>
            {weight}
          </MenuItem>
        );
      }
    }
  }
  return (
    <MenuItem key={weight} value={weight} disabled>
      {weight}
    </MenuItem>
  );
})}


        </Select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Number of Trucks</Typography>
        <TextField
              placeholder="Enter number of trucks"
              name="number_of_trucks"
              value={companyDetails.number_of_trucks}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
      </div>
        </div>

        <div style={{display:'flex'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Pickup Date</Typography>
        <TextField
              placeholder="Select Date"
              name="pickup_date"
              value={companyDetails.pickup_date}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
      </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Pickup Location</Typography>
          <Select
            value={companyDetails.pickup_location} 
            onChange={(e:any) => handleSelectFieldChange(e, 'pickup_location')}
            fullWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="" disabled>
              Select pickup location
            </MenuItem>
            {/* {routeData.results && routeData.results.map((route: any) => (
              <React.Fragment key={route.city_name_source}>
                {route.pickups && route.pickups.map((pickup: any) => (
                  <MenuItem key={pickup.name} value={pickup.name}> 
                    {pickup.name}
                  </MenuItem>
                ))}
              </React.Fragment>
            ))} */}
            <MenuItem value="Ganga Mandir">Ganga Mandir</MenuItem>
          </Select>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Drop Location</Typography>
        <Select
          value={companyDetails.drop_location}
          onChange={(e:any) => handleSelectFieldChange(e,'drop_location')}
          fullWidth
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          size='small'
        >
          <MenuItem value="" disabled>
            Select drop location
          </MenuItem>
          {/* {routeData.results && routeData.results.map((route: any) => (
            <React.Fragment key={route.city_name_source}>
              {route.drops && route.drops.map((drop: any) => (
                <MenuItem key={drop.name} value={drop.name}>
                  {drop.name}
                </MenuItem>
              ))}
            </React.Fragment>
          ))} */}
          <MenuItem value="Jama Masjid">Jama Masjid</MenuItem>

        </Select>
        </div>
        </div>

    </>
  )
}

export default TruckEnquiryForm