import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface CreateRouteKpiTypePayload {
    id: string,
    CORPORATE?: string,
}

interface RoutesKpiState {
  routeKpi: {
    data: {
        CORPORATE:string,
        "MOTOR OWNER": string,
        TRANSPORTER: string,
        ADMIN: string,
        total_count: string,
    }
  };
  error: string | null;
  getRoutesKpiStatus: STATUS,
}


const initialState: RoutesKpiState = {
  routeKpi: { 
    data:{
        CORPORATE: '',
        "MOTOR OWNER": '',
        TRANSPORTER: '',
        ADMIN: '',
        total_count:''
    }
   },
  error: null,
  getRoutesKpiStatus: STATUS.NOT_STARTED,
};

export const getRoutesKpiAction = createAsyncThunk(
  'routeKpi/get',
  async (_, thunkAPI) => {
    const response = await api.get('route/count_by_company_role');
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const routeKpiSlice = createSlice({
  name: 'routeKpi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoutesKpiAction.pending, (state, action) => {
        state.getRoutesKpiStatus = STATUS.PENDING
        console.log('GET ROUTES PENDING');
      })
      .addCase(getRoutesKpiAction.fulfilled, (state, action) => {
        state.routeKpi = action.payload;
        state.getRoutesKpiStatus = STATUS.SUCESS; 
      })
      .addCase(getRoutesKpiAction.rejected, (state, action) => {
        console.log('GET ROUTES REJECTED');
        state.getRoutesKpiStatus = STATUS.FAILED;
      })
  },
});

export default routeKpiSlice.reducer;
