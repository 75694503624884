import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface Company {
  id: string;
  name: string;
  role: string;
  phone: string;
  owner: any; // Adjust the type as needed
  route_present: boolean;
}

interface FetchUsersParams {
  city_name_source: string;
  city_name_destination: string;
  role: string;
}

interface RoutesUserState {
  companies: Company[];
  error: string | null;
  fetchUsersStatus: STATUS;
}

const initialState: RoutesUserState = {
  companies: [],
  error: null,
  fetchUsersStatus: STATUS.NOT_STARTED,
};

export const fetchUsersAction = createAsyncThunk(
  'users/get',
  async (params: FetchUsersParams, thunkAPI) => {
    const response = await api.get('/company/marked', {
      params: {
        city_name_source: params.city_name_source,
        city_name_destination: params.city_name_destination,
        role: params.role,
      },
    });

    const { status, data } = response;
    if (status === 200) {
      return data.companies; 
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const routeUserSlice = createSlice({
  name: 'userroutes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAction.pending, (state) => {
        state.fetchUsersStatus = STATUS.PENDING;
      })
      .addCase(fetchUsersAction.fulfilled, (state, action) => {
        state.fetchUsersStatus = STATUS.SUCESS;
        state.companies = action.payload;
      })
      .addCase(fetchUsersAction.rejected, (state) => {
        state.fetchUsersStatus = STATUS.FAILED;
      });
  },
});

export default routeUserSlice.reducer;
