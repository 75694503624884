import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TruckTypeForm from './truckTypeForm'
import { Paper } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import TruckTypeHeading from './truckTypeHeading';

const TruckType = () => {
  const navigate = useNavigate(); 

  const [age, setAge] = React.useState('');

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value);
  // }; 

  // const handleAddRouteClick = () => {
  //   navigate('/truck');
  // };

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
      <TruckTypeHeading/>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>   

        {/* Table Layout */}
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <TruckTypeForm />
        </Paper>
      </Box>
    </div>
  );
};

export default TruckType;
