import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTruckType, setCreateTruckTypeStatus, setUpdateTruckTypeStatus, updateTruckType } from '../redux/truck type slice/truckTypeSlice';
import { TextField, Button, Typography, Box, MenuItem, Select, SelectChangeEvent, FormControl, InputLabel, InputAdornment, Snackbar, SnackbarContent, Chip, FormHelperText } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SuccessIcon from '../../assets/img/success.png'
import ClearIcon from '@mui/icons-material/Clear';
import { AdditionalFieldsTypography, TextfieldNameWithMargin, TypographyWithoutMargin } from '../../assets/typography';
import { AdditionaFieldButton, ImageUploadButton, SubmitButton } from '../../assets/button';
import FailIcon from '../../assets/img/error.png'
import { getTruck } from '../redux/truck slice/addTruckSlice';
import { MapSelect } from '../../assets/selectTextfield';
import { STATUS } from '../../utils/status';

interface FormState {
  id?: string;
  name: string;
  photo_url?: string;
  truck_type_id?: string;
  type_tyres: {
    id?: string;
    no_of_tyres: number;
    tyre_weight: WeightItem[];
  }[];
}

interface WeightItem {
  id: string;
  weight: number;
  unit: 'KG' | 'TON';
}

const TruckTypeForm = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [truckType, setTruckType] = useState<FormState>({
    id: '', 
    name: '',
    photo_url: '',
    truck_type_id: '',
    type_tyres: [
      {
        id: '',
        no_of_tyres: 0,
        tyre_weight: [
          {
            id: '', 
            weight: 0,
            unit: 'KG',
          },
        ],
      },
    ],
  });
  
  const { truckTypes: initialTruckType, id: truckId } = location.state || { truckTypes: null, id: '' };

  useEffect(() => {
    
    if (initialTruckType && initialTruckType.truck_tyres) {
      const formattedTruckType = {
        id: initialTruckType.id,
        name: initialTruckType.name,
        photo_url: '', 
        truck_type_id: initialTruckType.truck_type_id,
        type_tyres: initialTruckType.truck_tyres.map((tyre: any) => ({
          id: tyre.id,
          no_of_tyres: tyre.no_of_tyres,
          tyre_weight: tyre.weights
            ? tyre.weights.map((weight: any) => ({ 
                id: weight.id,
                weight: weight.weight,
                unit: weight.unit,
              }))
            : [],
        })),
      };
      setTruckType(formattedTruckType);
      handleSnackbar('You can make your changes now.', 'info');
    }
  }, [initialTruckType]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [uploadButtonVisible, setUploadButtonVisible] = useState<boolean>(true);
  const [weights, setWeights] = useState<WeightItem[]>([]);
  const [currentWeight, setCurrentWeight] = useState<string>('');
  const [isAddingWeight, setIsAddingWeight] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

  const weightUnitOptions = [
    { id: '1', value: 'KG', label: 'KG' },
    { id: '2', value: 'TON', label: 'TON' },
  ];

  useEffect(() => {
    dispatch(getTruck({ search: searchTerm, page: currentPage, sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }));
  }, [dispatch, searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);

  const trucks = useSelector((state: RootState) => state.trucks.trucks);
  const createStatus = useSelector((state: RootState) => state.trucktypes.createStatus);
  const updateStatus = useSelector((state: RootState) => state.trucktypes.updateStatus);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTruckType((prevType) => ({
          ...prevType,
          photo_url: reader.result as string,
        }));
        setImage(file);
        setImageUrl(reader.result as string);
        setUploadButtonVisible(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteImage = () => {
    setTruckType((prevType) => ({
      ...prevType,
      photo_url: '',
    }));
    setImage(null);
    setImageUrl(null);
    setUploadButtonVisible(true);
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTruckType((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // const handleAddWeight = () => {
  //   if (currentWeight.trim() !== '') {
  //     const newWeight: WeightItem = {
  //       weight: parseFloat(currentWeight.trim()), unit: 'KG',
  //       id: ''
  //     };
  //     setWeights((prevWeights) => [...prevWeights, newWeight]);
  //     setTruckType((prevType) => ({
  //       ...prevType,
  //       type_tyres: prevType.type_tyres.map((tyre, index) => {
  //         if (index === prevType.type_tyres.length - 1 && tyre.tyre_weight.length === 1 && tyre.tyre_weight[0].weight === 0) {
  //           return {
  //             ...tyre,
  //             tyre_weight: [newWeight],
  //           };
  //         }
  //         return tyre;
  //       }),
  //     }));
  //     setCurrentWeight('');
  //   }
  //   setIsAddingWeight(false);
  // };

  const handleAddWeight = () => {
    if (currentWeight.trim() !== '') {
      const newWeight: WeightItem = {
        weight: parseFloat(currentWeight.trim()),
        unit: 'KG',
        id: '', // Set id to an empty string initially
      };
      setWeights((prevWeights) => [...prevWeights, newWeight]);
      setTruckType((prevType) => ({
        ...prevType,
        type_tyres: prevType.type_tyres.map((tyre, index) => {
          if (
            index === prevType.type_tyres.length - 1 &&
            tyre.tyre_weight.length === 1 &&
            tyre.tyre_weight[0].weight === 0
          ) {
            return {
              ...tyre,
              tyre_weight: [newWeight],
            };
          }
          return tyre;
        }),
      }));
      setCurrentWeight('');
    }
    setIsAddingWeight(false);
  };
  

  const handleEditTyreWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentWeight(e.target.value);
  };

  const handleEditTyres = (event: ChangeEvent<HTMLInputElement>, typeIndex: number) => {
    const { value } = event.target;
    setTruckType((prevType) => ({
      ...prevType,
      type_tyres: prevType.type_tyres.map((tyre, i) =>
        i === typeIndex ? { ...tyre, no_of_tyres: parseInt(value) } : tyre
      ),
    }));
  };

  const handleEditUnit = (event: SelectChangeEvent<string>, typeIndex: number, weightIndex: number) => {
    const value = event.target.value as 'KG' | 'TON';
    setTruckType((prevType) => ({
      ...prevType,
      type_tyres: prevType.type_tyres.map((tyre, i) =>
        i === typeIndex
          ? {
              ...tyre,
              tyre_weight: tyre.tyre_weight.map((item, j) =>
                j === weightIndex ? { ...item, unit: value } : item
              ),
            }
          : tyre
      ),
    }));
  };

  const addMoreFields = () => {
    setTruckType((prevType) => ({
      ...prevType,
      type_tyres: [
        ...prevType.type_tyres,
        {
          no_of_tyres: 0,
          tyre_weight: [
            {
              id:'',
              weight: 0,
              unit: 'KG',
            },
          ],
        },
      ],
    }));
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById('image-input');
    if (fileInput) {
      fileInput.click();
    }
  };

  const navigate = useNavigate();
  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);

  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
   
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
  
    if (!truckType.name.trim()) {
      errors.name = 'Truck Type Name is required';
    }
    if (truckType.type_tyres.length === 0 || truckType.type_tyres.every(tyre => tyre.tyre_weight.length === 0)) {
      errors.weights = 'Please add at least one tyre with weight';
    } else {
      truckType.type_tyres.forEach((tyre, typeIndex) => {
        if (tyre.no_of_tyres <= 0) {
          errors[`no_of_tyres_${typeIndex}`] = 'Tyre no. is Required.';
        }
        tyre.tyre_weight.forEach((weight, weightIndex) => {
          if (weight.weight <= 0) {
            errors[`weight_${typeIndex}_${weightIndex}`] = 'Field is Required.';
          }
        });
      });
    }
    setFormErrors(errors);
    handleSnackbar('Make sure you fill all required fields.', 'warning');
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);
  
    if (!validateForm()) {
      return;
    }
  
    const updatedTruckType = {
      ...truckType,
      type_tyres: truckType.type_tyres.map((tyre) => ({
        id: tyre.id, 
        no_of_tyres: tyre.no_of_tyres,
        tyre_weight: tyre.tyre_weight.map((weight) => ({
          id: weight.id,
          weight: parseFloat(weight.weight.toString()),
          unit: weight.unit,
        })),
      })),
    };

    const addedTruckType = {
      ...truckType,
      type_tyres: truckType.type_tyres.map((tyre) => ({
        no_of_tyres: tyre.no_of_tyres,
        tyre_weight: tyre.tyre_weight.map((weight) => ({
          weight: parseFloat(weight.weight.toString()),
          unit: weight.unit,
        })),
      })),
    };

    if (truckId) {
    const payload = {
      id: updatedTruckType.id,
      truck_type: {
        id: updatedTruckType.truck_type_id,
        name: updatedTruckType.name,
        photo_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_W5sUP8kCDZJ-DVZNwh3mJcewWs-9wQOoQ3Pet9lnUw&s',
      },
      type_tyres: updatedTruckType.type_tyres,
    };
  
      dispatch(updateTruckType(payload));
    } 
    
    else {

      const payload = {
        truck_type: {
          name: addedTruckType.name,
          photo_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_W5sUP8kCDZJ-DVZNwh3mJcewWs-9wQOoQ3Pet9lnUw&s',
        },
        type_tyres: addedTruckType.type_tyres,
      };

      dispatch(createTruckType(payload))
    }
  };

  useEffect(()=>{
    if(updateStatus == STATUS.SUCESS){
      handleSnackbar('Truck Type Updated Successfully', 'success');
      setTimeout(() => {
          navigate('/truck type');
        }, 1000);
        dispatch(setUpdateTruckTypeStatus(STATUS.NOT_STARTED))
      }

      if(updateStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
      }
  },[updateStatus])

  useEffect(()=>{
    if(createStatus == STATUS.SUCESS){
      handleSnackbar('Truck Type Added Successfully', 'success');
        setTimeout(() => {
          navigate('/truck type');
        }, 1000);
        dispatch(setCreateTruckTypeStatus(STATUS.NOT_STARTED))
      }

      if(createStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either this truck type already exist or you entered wrong information', 'warning');
        }, 7000);
      }
  },[createStatus])
  
  
  const handleRemoveTyreWeight = (typeIndex: number, weightIndex: number) => {
    setTruckType((prevType) => ({
      ...prevType,
      type_tyres: prevType.type_tyres.filter((_, i) => i !== typeIndex),
    }));
  };
  
  
  
  

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <TypographyWithoutMargin variant="h1">Truck Type Details</TypographyWithoutMargin>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '40%' }}>
          <TextfieldNameWithMargin variant="h1">Truck Type</TextfieldNameWithMargin>
          <TextField
              placeholder="Enter Truck Type"
              name="name"
              value={truckType.name}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && !!formErrors.name}
              helperText={formSubmitted && formErrors.name}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextfieldNameWithMargin variant="h1">Truck Image</TextfieldNameWithMargin>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="image-input"
            onChange={handleImageChange}
          />
          {uploadButtonVisible && (
            <ImageUploadButton onClick={triggerFileInput} variant="outlined" type="button">Upload Truck Image <FileUploadIcon fontSize="small" /></ImageUploadButton>
          )}
          {truckType.photo_url && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={truckType.photo_url}
                alt="Truck"
                style={{ width: '50px', height: '50px', marginLeft: '10px' }}
              />
              <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                {image?.name}
              </Typography>
              <IconButton onClick={deleteImage}>
                <DeleteIcon sx={{ color: '#C00F00', width: '20.67px' }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>

      {truckType.type_tyres.map((tyre, typeIndex) => (
        <div key={typeIndex} style={{ display: 'flex', marginTop: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
            <AdditionalFieldsTypography variant='h6'>No. of Tyres</AdditionalFieldsTypography>
            <TextField
              placeholder="Number of Tyres"
              name={`no_of_tyres_${typeIndex}`}
              type="number"
              value={tyre.no_of_tyres}
              sx={{ marginRight: '20px' }}
              size="small"
              onChange={(e: any) => handleEditTyres(e, typeIndex)}
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && !!formErrors[`no_of_tyres_${typeIndex}`]}
  helperText={formSubmitted && formErrors[`no_of_tyres_${typeIndex}`]}
            />
          </div>

          {tyre.tyre_weight.map((weight, weightIndex) => (
            <div style={{ display: 'flex' }} key={weightIndex}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <AdditionalFieldsTypography variant='h6'>Weight</AdditionalFieldsTypography>

                <div>
                  <TextField
                    placeholder="Weight"
                    sx={{ width: '360px', marginRight: '20px' }}
                    size="small"
                    type="text"
                    value={currentWeight}
                    // value = {weight.weight.toString()}
                    onChange={handleEditTyreWeight}
                    disabled={!isAddingWeight}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleAddWeight();
                      }
                    }}
                    InputProps={{
                      startAdornment: weights.map((weight, index) => (
                        <div key={index} style={{ width: 'auto', height: '24px', backgroundColor: '#E7F0F1', padding: '0px 8px', marginRight: '8px', borderRadius: '4px' }}>
                          <span style={{ color: '#13282A', fontWeight: '600', fontSize: '14px', lineHeight: '14px', fontFamily: 'Montserrat' }}>{weight.weight}</span>
                        </div>
                      )),
                      endAdornment: (
                        <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={() => setIsAddingWeight(true)}>
                          <AddIcon
                            fontSize="small"
                            sx={{ width: '24px', height: '24px', backgroundColor: '#03BBD2', color: '#FFFFFF', borderRadius: '4px' }}
                            onClick={() => {
                              setIsAddingWeight(true);
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    error={formSubmitted && !!formErrors[`weight_${typeIndex}_${weightIndex}`]}
                    helperText={formSubmitted && formErrors[`weight_${typeIndex}_${weightIndex}`]}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <AdditionalFieldsTypography variant='h6'>Weight Unit</AdditionalFieldsTypography>
                <FormControl variant="outlined" error={formSubmitted && !!formErrors[`weight_${typeIndex}_${weightIndex}`]}>
                <MapSelect
                  value={weight.unit}
                  placeholder="Select Unit"
                  onChange={(e) => handleEditUnit(e, typeIndex, weightIndex)} 
                  options={weightUnitOptions} 
                  sx={{ width: '360px' }}
                />
                {formSubmitted && formErrors[`weight_${typeIndex}_${weightIndex}`] && (
                  <FormHelperText>{formErrors[`weight_${typeIndex}_${weightIndex}`]}</FormHelperText>
                )}
              </FormControl>

              </div>
              
             
              {typeIndex !== 0 && ( 
            <ClearIcon onClick={() => handleRemoveTyreWeight(typeIndex, weightIndex)} sx={{marginTop:'60px', marginLeft:'10px', cursor:'pointer'}}/>
        )}

            </div>
          ))}
        </div>
      ))}

      <AdditionaFieldButton type="button" variant="outlined" onClick={addMoreFields} startIcon={<AddIcon />}>Add More Tyres</AdditionaFieldButton>
      <SubmitButton type="submit" variant="contained">{truckId ? 'Update Truck Type' : 'Save Truck Type'}</SubmitButton>

      <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Box>
  );
};

export default TruckTypeForm;
