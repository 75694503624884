import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

export interface CreateChecklistsTypePayload {
  id: string;
  item_name: string;
}

interface ErrorResponse {
  details: {
    name: string[];
  };
}
interface ChecklistState {
  checklists: {
    count: number | undefined;
    results: CreateChecklistsTypePayload[];
  };
  error: string | null;
  createStatus: STATUS;
  updateStatus: STATUS;
  getStatus: STATUS;
  deleteChecklistStatus: STATUS
}

const initialState: ChecklistState = {
  checklists: {
    count: undefined,
    results: []
  },
  error: null,
  createStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  deleteChecklistStatus: STATUS.NOT_STARTED
};

export const createChecklistItem = createAsyncThunk(
  'checklist/POST',
  async (payload: any, thunkAPI) => {
    try {
      console.log('Creating checklist item:', payload); 
      const response = await api.post('checklist/', payload);
      const { status, data } = response;
      if (status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (error: any) {
      console.error('API Error:', error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



export const getChecklistItems = createAsyncThunk(
  'checklist/get',
  async ({ page, sort, order }: {page:number, sort: string, order: string }, thunkAPI) => {
    const response = await api.get(`checklist/?page=${page}&sort=${sort}&order=${order}`);
  const { status, data } = response;
  if (status === 200) {
    return data;
  } else {
    return thunkAPI.rejectWithValue({ response, data });
  }
}
);

export const updateChecklistItem = createAsyncThunk(
  'checklist/patch',
  async (payload: any, thunkAPI) => {
      const response = await api.patch(`checklist/`, payload );
      const { status, data: responseData } = response;
      if (status === 200) {
        return responseData;
      } 
  }
);

export const deleteChecklistItem = createAsyncThunk(
  'checklist/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('checklist/',{ data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload: payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const checklistSlice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    setCreateChecklistStatus(state,action){
      state.createStatus = action.payload
    },
    setUpdateChecklistStatus(state,action){
      state.updateStatus = action.payload
    },
    setDeleteChecklistStatus(state,action){
      state.deleteChecklistStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(createChecklistItem.pending, (state) => {
      console.log('CREATE CHECKLIST ITEM PENDING');
      state.createStatus = STATUS.PENDING;
    })
    .addCase(createChecklistItem.fulfilled, (state, action) => {
      console.log('CREATE CHECKLIST ITEM SUCCESS'); 
      state.checklists = action.payload;
      state.createStatus = STATUS.SUCESS;
    })
    .addCase(createChecklistItem.rejected, (state, action) => {
      console.error('CREATE CHECKLIST ITEM FAILED:', action.payload); 
      state.createStatus = STATUS.FAILED;
      const errorResponse = action.payload as ErrorResponse;
      state.error = errorResponse?.details?.name[0] || 'Failed to create checklist item';
    })
      .addCase(getChecklistItems.pending, (state) => {
        state.getStatus = STATUS.PENDING;
        console.log('GET EXPENSES PENDING');
      })
      .addCase(getChecklistItems.fulfilled, (state, action) => {
        state.checklists = action.payload;
        state.getStatus = STATUS.SUCESS;
        console.log('GET EXPENSES SUCCESS');
      })
      .addCase(getChecklistItems.rejected, (state) => {
        state.getStatus = STATUS.FAILED;
        console.log('GET EXPENSES FAILED');
      })
      .addCase(updateChecklistItem.pending, (state) => {
        state.updateStatus = STATUS.PENDING;
      })
      .addCase(updateChecklistItem.fulfilled, (state, action) => {
        const updatedItem = action.payload;
        state.checklists.results = state.checklists.results.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        );
        state.updateStatus = STATUS.SUCESS;
      })
      .addCase(updateChecklistItem.rejected, (state, action) => {
        state.updateStatus = STATUS.FAILED;
      })
      .addCase(deleteChecklistItem.pending, (state, action) => {
        state.deleteChecklistStatus = STATUS.PENDING;
      })
      .addCase(deleteChecklistItem.fulfilled, (state, action) => {
        const deletedId = action.payload.requestPayload.id;
        state.checklists.results = state.checklists.results.filter((item) => item.id !== deletedId);
        state.deleteChecklistStatus = STATUS.SUCESS;
      })
      .addCase(deleteChecklistItem.rejected, (state, action) => {
        state.deleteChecklistStatus = STATUS.FAILED;
      })
  },
});

export const {setCreateChecklistStatus, setUpdateChecklistStatus, setDeleteChecklistStatus} = checklistSlice.actions
export default checklistSlice.reducer;
