export interface FormState {
  expense_id: string;
  selectedExpenseIds: string[]; 
}

const initialState: FormState = {
  expense_id: '',
  selectedExpenseIds: [],
};

const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';
export const UPDATE_SELECTED_EXPENSE_IDS = 'UPDATE_SELECTED_EXPENSE_IDS';

export const updateSelectedExpenseIds = (selectedExpenseIds: string[]) => ({
  type: UPDATE_SELECTED_EXPENSE_IDS,
  payload: selectedExpenseIds,
});

export const updateFormState = (ExpenseId: string) => ({
  type: UPDATE_FORM_STATE,
  payload: { expense_id: ExpenseId },
});


const expensesformReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_FORM_STATE':
      return { ...state, ...action.payload };
    case UPDATE_SELECTED_EXPENSE_IDS:
      return { ...state, selectedExpenseIds: action.payload }; 
    default:
      return state;
  }
};


export default expensesformReducer;
