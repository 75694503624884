import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
interface CreateTdsPayload {
    id:string,
    company_id : string,
    no_of_truck : string,
    tds : string,
    deduct_from_advance : boolean,
    documents_links : string[],
}

export interface GoodsState {
    tds:{
        count: number | undefined;
        results: CreateTdsPayload[];
      }
      getTdsStatus: STATUS;
      deleteTdsStatus: STATUS;
      updateTdsStatus: STATUS;
      createTdsStatus: STATUS;
}

const initialState: GoodsState = {
    tds:{
        count: undefined,
        results: [],
      },
      getTdsStatus: STATUS.NOT_STARTED,
      deleteTdsStatus: STATUS.NOT_STARTED,
      updateTdsStatus: STATUS.NOT_STARTED,
      createTdsStatus: STATUS.NOT_STARTED,
};

export const createTdsType = createAsyncThunk(
  'tds/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post('tds/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getTdsType = createAsyncThunk(
  'tds/get',
  async ({ search, page, tds, pageSize }: { search: string , page: number, tds:boolean, pageSize: number }, thunkAPI) => {
    const response = await api.get(`tds/?search=${search}&page=${page}&page_size=${pageSize}&tds=${tds}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateTdsType = createAsyncThunk(
  'tds/put',
  async (payload: any, thunkAPI) => {
   
    const response = await api.put('tds/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const deleteTdsType = createAsyncThunk(
  'tds/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('tds/', { data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload: payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const TdsSlice = createSlice({
  name: 'tds',
  initialState,
  reducers: {
    setCreateTdsStatus(state,action){
      state.createTdsStatus = action.payload
    },
    setUpdateTdsStatus(state,action){
      state.updateTdsStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(createTdsType.pending, (state, action) => {
      state.createTdsStatus = STATUS.PENDING;
    })
      .addCase(createTdsType.fulfilled, (state, action) => {
        state.tds.results.push(action.payload);
        state.createTdsStatus = STATUS.SUCESS;
      })
      .addCase(createTdsType.rejected, (state, action) => {
        state.createTdsStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
      .addCase(getTdsType.pending, (state, action) => {
        state.getTdsStatus = STATUS.PENDING;

      })
      .addCase(getTdsType.fulfilled, (state, action) => {
        state.tds = action.payload;
        state.getTdsStatus = STATUS.SUCESS;
        console.log('GET BRANCH TYPES SUCCESS');
      })
      .addCase(getTdsType.rejected, (state, action) => {
        state.getTdsStatus = STATUS.FAILED;
        console.log('GET GOODS TYPES FAILED');
      })
      .addCase(deleteTdsType.pending, (state, action) => {
        state.deleteTdsStatus = STATUS.PENDING;
        console.log('DELETE GOODS TYPE PENDING');
      })
      .addCase(deleteTdsType.fulfilled, (state, action) => {
        const deletedId = action.payload.requestPayload.id;
        state.tds.results = state.tds.results.filter((item) => item.id !== deletedId);
        state.deleteTdsStatus = STATUS.SUCESS;
        console.log('DELETE GOODS TYPE SUCCESS');
      })
      .addCase(deleteTdsType.rejected, (state, action) => {
        state.deleteTdsStatus = STATUS.FAILED;
        console.log('DELETE GOODS TYPE FAILED', action.payload);
      })
      .addCase(updateTdsType.pending, (state, action) => {
        state.updateTdsStatus = STATUS.PENDING;
        console.log('UPDATE GOODS TYPE PENDING');
      })
      .addCase(updateTdsType.fulfilled, (state, action) => {
        const updatedGoodsType = action.payload;
        const index = state.tds.results.findIndex((item) => item.id === updatedGoodsType.id);
        if (index !== -1) {
          state.tds.results[index] = updatedGoodsType;
        }
        state.updateTdsStatus = STATUS.SUCESS;
      })
      .addCase(updateTdsType.rejected, (state, action) => {
        state.updateTdsStatus = STATUS.FAILED;
        console.log('UPDATE GOODS TYPE FAILED', action.payload);
      })
  },
});

export const {setCreateTdsStatus, setUpdateTdsStatus} = TdsSlice.actions
export default TdsSlice.reducer;
