import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface TotalGoodsState {
  totalGoodsCount: number | null;
  getTotalGoodsStatus: STATUS;
}

const initialState: TotalGoodsState = {
  totalGoodsCount: null,
  getTotalGoodsStatus: STATUS.NOT_STARTED,
};

export const getTotalGoods = createAsyncThunk(
  'totalGoods/getTotal',
  async (_, thunkAPI) => {
      const response = await api.get('goodstype/total_goods_type');
      const { status, data } = response;
      if (status === 200) {
        return data.total_goods_type;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
);

const totalGoodsSlice = createSlice({
  name: 'totalGoods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTotalGoods.pending, (state, action) => {
        state.getTotalGoodsStatus = STATUS.PENDING;
        console.log('GET TOTAL GOODS PENDING');
      })
      .addCase(getTotalGoods.fulfilled, (state, action) => {
        state.totalGoodsCount = action.payload;
        state.getTotalGoodsStatus = STATUS.SUCESS;
        console.log('GET TOTAL GOODS SUCCESS');
      })
      .addCase(getTotalGoods.rejected, (state, action) => {
        state.getTotalGoodsStatus = STATUS.FAILED;
        console.log('GET TOTAL GOODS FAILED');
      });
  },
});

export default totalGoodsSlice.reducer;
