import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface ExpenditureType {
  name: string;
  rate: number;
  unit: string;
}

interface ExpendituresState {
  expenditures: ExpenditureType[];
  error: string | null;
  getExpenditureStatus: STATUS;
}

const initialState: ExpendituresState = {
  expenditures: [],
  error: null,
  getExpenditureStatus: STATUS.NOT_STARTED,
};

export const getExpenditures = createAsyncThunk(
  'expenditures/getExpenditures',
  async (companyId: string, thunkAPI) => {
    console.log('Fetching expenditures for Company ID:', companyId);
    const response = await api.get(`expense/?company_id=${companyId}`);
    const { status, data } = response;
    if (status === 200) {
      return data.results;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);



const expendituresSlice = createSlice({
  name: 'expenditures',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExpenditures.pending, (state) => {
        state.getExpenditureStatus = STATUS.PENDING;
        console.log('GET EXPENDITURES PENDING');
      })
      .addCase(getExpenditures.fulfilled, (state, action) => {
        state.expenditures = action.payload;
        state.getExpenditureStatus = STATUS.SUCESS;
        console.log('GET EXPENDITURES SUCCESS');
      })
      .addCase(getExpenditures.rejected, (state) => {
        state.getExpenditureStatus = STATUS.FAILED;
        console.log('GET EXPENDITURES FAILED');
      });
  },
});

export default expendituresSlice.reducer;
