import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { deleteGoodsType, getGoodsType } from '../redux/goodsSlice/goodsSlice';
import { STATUS } from '../../utils/status';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SuccessIcon from '../../assets/img/success.png'
import FailIcon from '../../assets/img/error.png'
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, Snackbar, SnackbarContent, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Error from '../error';
import { AddButton } from '../../assets/button';
import { getReceiver } from '../redux/receiver/receiver';

const ReceiverTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('-created_at');
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const receiver = useSelector((state:RootState)=> state.receiver.receiver)
  const getReceiverStatus = useSelector((state:RootState)=> state.receiver.getReceiverStatus)

  const handleSort = (column: string) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handleAddGoodsClick = () => {
    navigate('/receiver-form');
  }; 

  const handleEditClick = (row: any) => {
    handleSnackbar('We are redirecting you in editing mode.', 'info');
    setTimeout(() => {
      navigate(`/receiver-form/${row.id}`, { state: { goodsDetails: row, id: row.id } });
    }, 2000);
    
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(()=>{
    setLoading(true); 
    dispatch(getReceiver({search: searchTerm, page: currentPage, pageSize: itemsPerPage, order: sortOrder}))
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => setLoading(false)); 

  },[dispatch, searchTerm,currentPage, itemsPerPage, sortOrder])

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
              value={searchTerm}
              size="small"
              onChange={handleSearchChange}
              sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
              InputProps={{
                startAdornment: (
                  <SearchIcon color="action" />
                )
              }}
              InputLabelProps={{
                shrink: false
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', marginRight: '20px' }}>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>SHOW</Typography>
                <FormControl sx={{ m: 1 , minWidth:'40'}}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    IconComponent={ArrowDropDownIcon}
                    value={itemsPerPage} 
                    sx={{  height: '40px' }}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>ENTRIES</Typography>
              </div>
              <AddButton onClick={handleAddGoodsClick}>Add New Receiver</AddButton>
            </div>
          </div>  
    <TableContainer>
    {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                  <TableRow>
                      
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} onClick={() => handleSort('registration_date')}>
                     NAME
                     
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} onClick={() => handleSort('added_by')}>
                      EMAIL
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                      PHONE
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                      ACTIONS
                      </TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getReceiverStatus === STATUS.SUCESS && receiver && receiver.results && receiver.results.length > 0 ? ( receiver.results.map((row: any) => (
                      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
                      
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.name? row.name : '-'}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.email? row.email : '-'}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.phone? row.phone : '-'}</TableCell>
                     
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#13282A', fontWeight:'400',fontSize:'14px', lineHeight:'24px', cursor:'pointer'}}>
                      <EditNoteOutlinedIcon onClick={() => handleEditClick(row)}/>
                    </TableCell>
                      
                    </TableRow>
                     ))
                     ) : (
                       <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                         <TableCell colSpan={8} >
                           <Error message="There are no trucks added as of yet. You can add a truck to see it here."/>
                         </TableCell>
                       </TableRow>
                     )}
                  </TableBody>
                </Table>
                )}
              </TableContainer>

              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}> 
            {getReceiverStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getReceiverStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, receiver.count || 0) 
              : '-'}{' '}
            of {getReceiverStatus === STATUS.SUCESS ? (receiver.count || 0) : '-'}
            </Typography>
            </div>
                <Button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronLeftIcon
                      sx={{
                        color: 'black',
                        backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
                <Button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={!receiver.results || receiver.results.length < itemsPerPage}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronRightIcon
                      sx={{
                        color: 'black',
                        backgroundColor: !receiver.results || receiver.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
              </div>

              <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',

            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </>
  )
}

export default ReceiverTable;