import { Button, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { getRoutes, getRoutesAction } from '../redux/route/routeSlice';
import { getGoodsType } from '../redux/goodsSlice/goodsSlice';
import GoodsForm from './goodsForm';
import { addCompanyDetails } from '../redux/reducers/routeForm';

export interface FormState {
  source_name: string;
  destination_name: string;
}

const RouteForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const routeData = useSelector((state: RootState)=> state.routes.routes)
  const [groupData, setGroupData] = useState<string>('group');
  const [destinationOptions, setDestinationOptions] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getRoutes({group:groupData}));
  }, [dispatch]); 

  const companyDetails = useSelector((state: RootState)=> state.routeForm.companyDetails);

  const handleFieldChange = (index: number, fieldName: keyof FormState, value: string) => {
    // If the field name is source_name, update source_name
    // If the field name is destination_name, update destination_name
    dispatch(addCompanyDetails({ index, [fieldName]: value }));
  };
  
  const handleRouteChange = (index: number, source_name: string, destination_name: string) => {
    // Find the selected route details
    const selectedRoute = routeData.results.find(route => route.route_details.id === source_name);
    if (selectedRoute) {
      // Update destination options
      setDestinationOptions([selectedRoute.route_details.destination_name]);
    } else {
      // If no route is selected, reset destination options
      setDestinationOptions([]);
    }
    // Update both source_name and destination_name
    handleFieldChange(index, 'source_name', source_name);
    handleFieldChange(index, 'destination_name', destination_name);
  };
  
  
  
  
  const handleAddMore = () => {
    dispatch(addCompanyDetails({ index: companyDetails.length, ...companyDetails[companyDetails.length - 1] }));
  };
  return (
    <>
     {companyDetails.map((details: FormState, index: number) => (
      <div key={`companyDetails-${index}`}>
      <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', marginLeft:'10px' }}>
          Source City
        </Typography>
        <Select
                fullWidth
                size='small'
                inputProps={{ 'aria-label': 'Without label' }}
                displayEmpty
                sx={{ maxHeight: '200px', overflowY: 'auto' }}
                value={details.source_name}
                onChange={(e) => handleRouteChange(index, e.target.value, details.destination_name)}
              >
                <MenuItem value="" disabled>
                  Select source
                </MenuItem>
                {routeData.results.map((result, index) => (
                  <MenuItem key={index} value={result.route_details.id}>
                    {result.route_details.source_name}
                  </MenuItem>
                ))}
              </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem', marginLeft:'20px' }}>
              Destination City
            </Typography>
            <Select
                fullWidth
                size='small'
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ marginLeft: '20px', marginBottom: '20px' }}
                value={details.destination_name}
                onChange={(e) => handleRouteChange(index, details.source_name, e.target.value)}
              >
                <MenuItem value="" disabled>
                  Select destination
                </MenuItem>
                {destinationOptions.map((destination, index) => (
                  <MenuItem key={index} value={destination}>
                    {destination}
                  </MenuItem>
                ))}
              </Select>
        </div>
      </div>
      </div>
      ))}

      <Button
        onClick={handleAddMore}
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '2.5%',
          textAlign: 'center',
          color: '#03BBD2',
          marginRight: '18rem',
          border: 'none',
          width: '8rem',
          textTransform: 'none',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
        }}
      >
        Add Route
      </Button>

      <GoodsForm/>
    </>
  );
}

export default RouteForm;
