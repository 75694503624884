import React, { createContext, useContext, ReactNode } from 'react';
import { theme } from '../../config/theme';

interface ThemeContextType {
  theme: { [key: string]: string };
}

const ThemeContext = createContext<ThemeContextType>({ theme });

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>;
};