const initialState = {
    companyDetails: [
      {
        type: '',
        unit: '',
        freight: '',
        total_freight: '',
        freight_amount: '',
        number_of_trucks: '',
      }
    ],
    payment: '',
    bidding_types:'',
  };
  
  // Define action types
  const ADD_COMPANY_DETAILS = 'ADD_COMPANY_DETAILS';
  const SET_PAYMENT = 'SET_PAYMENT';
  const SET_BIDDING_TYPE = 'SET_BIDDING_TYPE';
  
  // Define action creators
  export const addCompanyDetails = (details:any) => ({
    type: ADD_COMPANY_DETAILS,
    payload: details,
  });
  
  export const setPayment = (payment:any) => ({
    type: SET_PAYMENT,
    payload: payment,
  });

// Define action creators
export const setBiddingType = (bidding_types:any) => ({
  type: SET_BIDDING_TYPE,
  payload: bidding_types,
});
  
  const biddingformReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case ADD_COMPANY_DETAILS:
        const { index, ...newDetails } = action.payload;
        const updatedDetails = [...state.companyDetails];
        if (index >= 0 && index < updatedDetails.length) {
          // If the index is within range, update the details at the specified index
          updatedDetails[index] = { ...updatedDetails[index], ...newDetails };
        } else {
          // If the index is out of range, add the new details as a new row
          updatedDetails.push(newDetails);
        }
        return {
          ...state,
          companyDetails: updatedDetails,
        };
      case SET_PAYMENT:
        return {
          ...state,
          payment: action.payload,
        };
      case SET_BIDDING_TYPE:
      return {
        ...state,
        bidding_types: action.payload,
      };
      default:
        return state;
    }
  };
  
  
  
  
  export default biddingformReducer;
  