import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { deleteGoodsType, getGoodsType } from '../redux/goodsSlice/goodsSlice';
import { STATUS } from '../../utils/status';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { getTruckUsers } from '../redux/truck slice/userTableTruckSlice';
import { useNavigate } from 'react-router-dom';
import Error from '../error';
import { AddButton } from '../../assets/button';

const UsersTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGoods, setFilteredGoods] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState('truck_number');
  const [sortOrder, setSortOrder] = useState('asc');
  const [alignment, setAlignment] = React.useState('web');
  const [filteredTrucks, setFilteredTrucks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  const handleSort = (column: string) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAddClick =()=>{
    navigate('/add-truck')
  }

  useEffect(() => {
    setLoading(true)
    dispatch(getTruckUsers({search: searchTerm, page:currentPage,  sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }))
    .then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch(() => setLoading(false));
  }, [dispatch,searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);

  function formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }


  const usertrucks  = useSelector((state: RootState) => state.usertrucks.usertrucks);
  const getUserTableStatus = useSelector((state: RootState) => state.usertrucks.getUserTableStatus);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  const totalPages = ((currentPage - 1) * (itemsPerPage || 10) + 1);

  return (
    <>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '1rem' }}>
    <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
              value={searchTerm}
              size="small"
              onChange={handleSearchChange}
              sx={{ padding: '8px, 16px, 8px, 16px', border: '1px', borderRadius: '4px', gap: '12px', width: '320px', marginBottom: '4px' }}
              InputProps={{
                startAdornment: (
                  <SearchIcon color="action" />
                )
              }}
              InputLabelProps={{
                shrink: false
              }}
            />
            {/* Add Truck Button */}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', marginRight: '20px' }}>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>SHOW</Typography>
                <FormControl sx={{ m: 1 , minWidth:'40'}}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    aria-label="Age"
                    autoWidth
                    size='small'
                    IconComponent={ArrowDropDownIcon}
                    value={itemsPerPage} 
                    sx={{ height: '40px' }}
                    onChange={handleChangeRowsPerPage}
                  >
                    
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ paddingTop: '25px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D' }}>ENTRIES</Typography>
              </div>
              <AddButton onClick={handleAddClick}>Add Truck</AddButton>
          </div>
          </div>
    <TableContainer>
    {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                  <TableRow>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                          TRUCK NUMBER
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>CHASSIS NUMBER</TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} >
                      TRUCK DETAILS
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} onClick={() => handleSort('registration_date')}>
                      <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>REGISTRATION ON</span>
                     
                        <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                          <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'asc' ? 'visible' : 'visible' }} />
                          <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === 'desc' ? 'visible' : 'visible' }} />
                        </div>
                      
                    </div>
                     
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} onClick={() => handleSort('added_by')}>
                      <div style={{ display: 'flex',alignItems: 'center' }}>
                    <span>ADDED BY</span>
                     
                        <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                          <KeyboardArrowUpIcon sx={{ fontSize: '12px', visibility: sortOrder === 'asc' ? 'visible' : 'visible' }} />
                          <KeyboardArrowDownIcon sx={{ fontSize: '12px', visibility: sortOrder === 'desc' ? 'visible' : 'visible' }} />
                        </div>
                      
                    </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>
                      USER TYPE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserTableStatus === STATUS.SUCESS && usertrucks && usertrucks.results &&  usertrucks.results.length> 0 ? ( usertrucks.results.map((row: any) => (
                      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
                      <TableCell component="th" scope="row" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>
                        {row.truck_number}
                      </TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.chassis.toUpperCase()}</TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.truck_type.name}<br/><div style={{fontFamily:'Montserrat', color:'#939393', fontWeight:'600',fontSize:'10px', lineHeight:'13px'}}> {row.truck_weight_associated.weight} {row.truck_weight_associated.unit}{' | '}{row.truck_type_tyre_associated.no_of_tyres} Tyres</div></TableCell>
                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{formatDate(row.registration_date)}</TableCell>
                      <TableCell align="left" sx={{ fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px' }}>
                      {row.company && row.company.name ? row.company.name : "-"}
                      </TableCell>

                      <TableCell align="left" sx={{fontFamily:'Montserrat', color:'#10383D', fontWeight:'400',fontSize:'14px', lineHeight:'24px'}}>{row.company && row.company.role  ? row.company.role : "-"}</TableCell>
                    </TableRow>
                    ))
                    ) : (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }} }>
                        <TableCell colSpan={8} >
                          <Error message="There are no trucks added as of yet. You can add a truck to see it here."/>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                 )}
              </TableContainer>

              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{color:'#10383D', fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400',lineHeight:'24px'}}> 
            {getUserTableStatus === STATUS.SUCESS ? totalPages : '-'} to{' '}
            {getUserTableStatus === STATUS.SUCESS
              ? Math.min(totalPages + (itemsPerPage || 10) - 1, usertrucks.count || 0) 
              : '-'}{' '}
            of {getUserTableStatus === STATUS.SUCESS ? (usertrucks.count || 0) : '-'}
            </Typography>
            </div>
                <Button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronLeftIcon
                      sx={{
                        color: 'black',
                        backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
                <Button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={!usertrucks.results || usertrucks.results.length < itemsPerPage}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronRightIcon
                      sx={{
                        color: 'black',
                        backgroundColor: !usertrucks.results || usertrucks.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
              </div>
    </>
  )
}

export default UsersTable;