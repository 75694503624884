import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

export interface CreateExpensesTypePayload {
  id: string;
  name: string;
  unit: 'TON' | 'KG' | 'QUINTAL' | 'RS' | 'LITRE';
  rate: number;
}
interface ExpensesState {
  expenses: {
    count: number | undefined;
    results: CreateExpensesTypePayload[];
  };
  error: string | null;
  getExpensesStatus: STATUS;
  deleteExpenseStatus: STATUS;
  updateExpenseStatus: STATUS;
  createExpenseStatus: STATUS;

}

const initialState: ExpensesState = {
  expenses: { 
    count: undefined,
    results: []
   },
  error: null,
  getExpensesStatus: STATUS.NOT_STARTED,
  deleteExpenseStatus: STATUS.NOT_STARTED,
  updateExpenseStatus: STATUS.NOT_STARTED,
  createExpenseStatus: STATUS.NOT_STARTED,

};
  

export const createExpense = createAsyncThunk(
  'expenses/post',
  async (payload: any, thunkAPI) => {
    const response = await api.post('expense/', payload);
    const { status, data } = response;
    if (status === 201) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

interface GetExpensesPayload {
  page?: number;
  sort?: string;
  order?: string;
}

export const getExpenses = createAsyncThunk(
  'expenses/get',
  async (payload: GetExpensesPayload = {}, thunkAPI) => {
    const { page, sort, order} = payload;
    const queryString = new URLSearchParams({
      ...(page && { page: page.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
    }).toString();
    const response = await api.get(`expense/?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);


export const updateExpense = createAsyncThunk(
  'expenses/patch',
  async (payload: any, thunkAPI) => {
      const response = await api.patch(`expense/`, payload);
      const { status, data: responseData } = response;
      if (status === 200) {
        return responseData;
  }
}
);



export const deleteExpense = createAsyncThunk(
  'expense/delete',
  async (payload: any, thunkAPI) => {
    const response = await api.delete('expense/', { data: payload });

    const { status, data } = response;
    if (status === 200) {
      return { data, requestPayload:payload };
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setCreateExpensesStatus(state,action){
      state.createExpenseStatus = action.payload
    },
    setUpdateExpensesStatus(state,action){
      state.updateExpenseStatus = action.payload
    },
    setDeleteExpenseStatus(state,action){
      state.deleteExpenseStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(createExpense.pending, (state) => {
      state.createExpenseStatus = STATUS.PENDING;
      console.log('CREATE EXPENSES PENDING');
    })
    .addCase(createExpense.fulfilled, (state, action) => {
      state.expenses = action.payload;
      state.createExpenseStatus = STATUS.SUCESS;
      console.log('CREATE EXPENSES SUCCESS');
    })
    .addCase(createExpense.rejected, (state) => {
      state.createExpenseStatus = STATUS.FAILED;
      console.log('CREATE EXPENSES FAILED');
    })
      .addCase(getExpenses.pending, (state) => {
        state.getExpensesStatus = STATUS.PENDING;
        console.log('GET EXPENSES PENDING');
      })
      .addCase(getExpenses.fulfilled, (state, action) => {
        state.expenses = action.payload;
        state.getExpensesStatus = STATUS.SUCESS;
        console.log('GET EXPENSES SUCCESS');
      })
      .addCase(getExpenses.rejected, (state) => {
        state.getExpensesStatus = STATUS.FAILED;
        console.log('GET EXPENSES FAILED');
      })

      .addCase(deleteExpense.pending, (state) => {
        state.deleteExpenseStatus = STATUS.PENDING;
        console.log('DELETE EXPENSE PENDING');
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        const deletedId = action.payload.requestPayload.id;
        state.expenses.results = state.expenses.results.filter((item) => item.id !== deletedId );
        state.deleteExpenseStatus = STATUS.SUCESS;
        console.log('DELETE EXPENSE SUCCESS');
        
      })
      .addCase(deleteExpense.rejected, (state) => {
        state.deleteExpenseStatus = STATUS.FAILED;
        console.log('DELETE EXPENSE FAILED');
      })
      .addCase(updateExpense.pending, (state) => {
        state.updateExpenseStatus = STATUS.PENDING;
        console.log('UPDATE EXPENSE PENDING');
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        const updatedExpense = action.payload;
        state.expenses.results = state.expenses.results.map((expense) => {
          if (expense.id === updatedExpense.id) {
            return updatedExpense;
          }
          return expense;
        });
        state.updateExpenseStatus = STATUS.SUCESS;
        console.log('UPDATE EXPENSE SUCCESS');
      })
      .addCase(updateExpense.rejected, (state) => {
        state.updateExpenseStatus = STATUS.FAILED;
        console.log('UPDATE EXPENSE FAILED');
      });
  },
});

export const {setCreateExpensesStatus, setUpdateExpensesStatus, setDeleteExpenseStatus} = expensesSlice.actions
export default expensesSlice.reducer;
