import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Paper, Typography, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import TrucktypeHeading from './truckTypeListHeading';
import { SelectChangeEvent } from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AdminTable from './truckAdminTable';
import { useDispatch } from 'react-redux';
import { createExpense } from '../redux/expenses/expensesSlice'; 
import { AppDispatch } from '../redux/store/appStore';
import SnackbarContent from '@mui/material/SnackbarContent';
import ClearIcon from '@mui/icons-material/Clear';
import SuccessIcon from '../../assets/img/success.png'
import TruckUsersTable from './truckOtherUsers';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const TruckTypeMain = () => {
  const [unit, setUnit] = React.useState('');
  const [alignment, setAlignment] = React.useState('web');
  const [expenseName, setExpenseName] = React.useState('');
  const [rate, setRate] = React.useState('');
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent<string>) => {
    setUnit(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAddTrucksClick = () => {
    navigate('/truck');
  };

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const handleAddExpense = () => {
    // Form validation
    const newErrors: { [key: string]: string } = {};
    let valid = true;
    if (!expenseName) {
      newErrors.expenseName = 'Expense Name is required';
      valid = false;
    }
    if (!rate) {
      newErrors.rate = 'Rate is required';
      valid = false;
    }
    if (!unit) {
      newErrors.unit = 'Unit is required';
      valid = false;
    }

    setErrors(newErrors);
    setIsFormValid(valid);

    if (!valid) {
      return;
    }

    const newExpense = {
      name: expenseName,
      rate: parseFloat(rate), 
      unit: unit as 'TON' | 'KG' | 'QUINTAL' | 'RS' | 'LITRE', 
    };
    
    // Dispatching action to create expense
    dispatch(createExpense(newExpense));

    // Reset form fields
    setExpenseName('');
    setRate('');
    setUnit('');
    setIsFormValid(false);

    // Show success snackbar
    setSnackbarSeverity('success');
    setSnackbarMessage('Expense added successfully');
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <TrucktypeHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        {/* Table Layout */}
        <Paper
          sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            padding: '1.5rem',
            mb: '2rem',
            boxShadow: 2,
          }}
        >
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <ToggleButtonGroup
              color="standard"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
            >
              <ToggleButton
                value="web"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'web' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'web' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform:'none'
                }}
              >
                Admin
              </ToggleButton>
              <ToggleButton
                value="android"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'android' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'android' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform:'none'
                }}
              >
                Other Users
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          
          {alignment === 'web' && (
            <div >
              <div style={{ display: 'flex' }}> 

              





                {/* <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  disableElevation
                  onClick={handleAddExpense}
                  sx={{
                    padding: '8px, 16px, 8px, 16px',
                    border: '1px',
                    borderRadius: '8px',
                    width: '161px',
                    height: '40px',
                    marginBottom: '1px',
                    marginTop: '25px',
                    marginLeft: 0,
                    textTransform: 'none',
                    backgroundColor: '#03BBD2',
                    '&:hover': { backgroundColor: '#03BBD2' },
                  }}
                >
                  <Typography style={{ fontSize: '12px' }}>
                    Add Truck Type
                  </Typography>
                </Button> */}
              
              </div>
              <AdminTable />
            </div>
          )}
          {alignment === 'android' && <TruckUsersTable/>}
        </Paper>
      </Box>
      {/* Snackbar for showing success and error messages */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{borderRadius:'8px'}}
      >

        <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : '#F44336',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{display:'flex'}}>
              <img src={SuccessIcon} style={{marginRight: '12px'}}/>
              <div style={{fontFamily:'Montserrat', fontWeight:'600'}}>{snackbarMessage}</div>
            </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleCloseSnackbar}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </div>
  );
};

export default TruckTypeMain;
