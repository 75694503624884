import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenses, updateExpense } from '../redux/expenses/expensesSlice'; 
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, Checkbox, TextField } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import AddIcon from '@mui/icons-material/Add'
import { updateFormState, updateSelectedExpenseIds } from '../redux/reducers/expenseForm';
import { AdditionaFieldButton } from '../../assets/button';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ExpenseEnquiryTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedRow, setEditedRow] = useState<any>({});
  const [newExpense, setNewExpense] = useState<any>({ name: '', rate: '', unit: '' });
  const selectedExpenseIds = useSelector((state: RootState) => state.expenseForm.selectedExpenseIds);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, expenseId: string) => {
    const isChecked = event.target.checked;
    dispatch(updateFormState(expenseId)); 
    if (isChecked) {
      dispatch(updateSelectedExpenseIds([...selectedExpenseIds, expenseId])); 
    } else {
      dispatch(updateSelectedExpenseIds(selectedExpenseIds.filter((id:any) => id !== expenseId))); 
    }
  };
  
  const expenses = useSelector((state: RootState) => state.expenses.expenses);
  const getExpensesStatus = useSelector((state: RootState) => state.expenses.getExpensesStatus);
  const visibleExpenses = expenses.results.slice(0, 3);

  useEffect(() => {
      dispatch(getExpenses({}))
  }, [dispatch]);

  const handleEdit = (id: string, row: any) => {
    setEditingId(id);
    setEditedRow({ ...row });
  };

  const handleSave = (editedExpense: any) => {
    dispatch(updateExpense({ id: editedExpense.id, ...editedExpense }));
    setEditingId(null);
    setEditedRow({});
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value;
    setEditedRow((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddExpense = () => {
    // Add new expense to the table
    // Here you can dispatch an action to update Redux state if needed
    // For simplicity, just adding it to the local state for now
    setNewExpense({ name: '', rate: '', unit: '' });
    setEditedRow({});
  };

  const handleNewExpenseFieldChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value;
    setNewExpense((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <>
    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' , marginTop: '2rem'}}>Expense Details</Typography>
    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '0.5rem' }}>Note: The expenses mentioned below may vary and expenses is per Truck</Typography>
     <TableContainer>
      <Table sx={{ minWidth: 650, marginTop:'2rem' }} size='small' aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 }}}>
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>EXPENSE NAME</TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>RATE</TableCell>
            <TableCell align="left" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>UNIT</TableCell>
            <TableCell align="center" sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}}>ACTIONS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getExpensesStatus === STATUS.SUCESS && visibleExpenses && visibleExpenses.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell align="right" sx={{ width: '50px' }}>
                <Checkbox
                  {...label}
                  onChange={(e) => handleCheckboxChange(e, row.id)}
                  checked={selectedExpenseIds.includes(row.id)}
                  sx={{'&.Mui-checked': {color: '#03BBD2'}}}
                />
              </TableCell>
              <TableCell component="th" scope="row" sx={{fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px'}}>
                <div style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left' ,padding: '4px 16px'}}>
                  {row.name}<br />
                </div>
              </TableCell>
              <TableCell align="left" sx={{fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px'}}>
                {editingId === row.id ? (
                  <input
                    type="text"
                    value={editedRow.rate || ''}
                    onChange={(e) => handleFieldChange(e, 'rate')}
                    style={{border: '1px solid', borderColor: '#E8EAEF', height: '32px', borderRadius: '4px', width: 'auto'}}
                  />
                ) : (
                  <div style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', width: '50px' }}>
                    {row.rate}<br />
                  </div>
                )}
              </TableCell>
              <TableCell align="left" sx={{fontFamily: 'Montserrat', color: '#10383D', fontWeight: '400', fontSize: '14px', lineHeight: '24px'}}>
                {editingId === row.id ? (
                  <input
                    type="text"
                    value={editedRow.unit || ''}
                    onChange={(e) => handleFieldChange(e, 'unit')}
                    style={{border: '1px solid', borderColor: '#E8EAEF', height: '32px', borderRadius: '4px', width: 'auto'}}
                  />
                ) : (
                  <div style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', width: '50px' }}>
                    {row.unit}<br />
                  </div>
                )}
              </TableCell>
              <TableCell align="center" sx={{fontFamily: 'Montserrat', color: '#13282A', fontWeight: '400', fontSize: '14px', lineHeight: '24px', cursor: 'pointer'}}>
                {editingId === row.id ? (
                  <>
                    <Button
                      sx={{padding: '8px 16px',border: '1px',borderRadius: '8px',width: '115px',height: '32px',marginBottom: '1px',marginLeft: 0,textTransform: 'none',backgroundColor: '#03BBD2','&:hover': { backgroundColor: '#03BBD2' }}}
                      onClick={() => handleSave({ ...row, ...editedRow })}
                    >
                      <Typography style={{ fontSize: '12px', color: '#FFFFFF'}}>Save Changes</Typography>
                    </Button>
                  </>
                ) : (
                  <EditNoteOutlinedIcon onClick={() => handleEdit(row.id, row)} />
                )}
              </TableCell>
            </TableRow>
          ))}
          {/* Render new expense form fields */}
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell>
              <TextField
                label="Expense Name"
                value={newExpense.name}
                onChange={(e:any) => handleNewExpenseFieldChange(e, 'name')}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Rate"
                value={newExpense.rate}
                onChange={(e:any) => handleNewExpenseFieldChange(e, 'rate')}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Unit"
                value={newExpense.unit}
                onChange={(e:any) => handleNewExpenseFieldChange(e, 'unit')}
              />
            </TableCell>
            <TableCell>
              <Button onClick={handleAddExpense} startIcon={<AddIcon />}>
                Add Expense
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

export default ExpenseEnquiryTable;
