import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface StyledButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const AddMoreButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.025em',
        marginRight: '20.5rem',
        color: 'inherit',
        border: 'none',
        textTransform: 'none',
        '&:hover': {
          border: 'none',
          backgroundColor: 'none',
        },
      }}
    >
      {children}
    </Button>
  );
};

export const AdditionaFieldButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.025em',
        textAlign: 'center',
        color: 'inherit',
        marginRight: '18rem',
        marginTop: '15px',
        marginBottom: '25px',
        border: 'none',
        textTransform: 'none',
        '&:hover': {
          border: 'none',
          backgroundColor: 'none',
        },
      }}
    >
      {children}
    </Button>
  );
};

export const SubmitButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        mt: 2,
        fontSize: '14px',
        textTransform: 'none',
        color: '#FFFFFF',
        width: '100%',
        backgroundColor: '#03BBD2',
        borderRadius: '8px',
      }}
    >
      {children}
    </Button>
  );
};

export const ImageUploadButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.025em',
        textAlign: 'center',
        color: '#03BBD2',
        border: 'none',
        textTransform: 'none',
        mt: '10px',
        '&:hover': {
          border: 'none',
          backgroundColor: 'none',
        },
      }}
    >
      {children}
    </Button>
  );
};

export const AddButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      startIcon={<AddIcon />}
      variant="contained"
      disableElevation
      sx={{
        marginRight: '1rem',
        width: 'auto',
        height: '40px',
        marginTop: '10px',
        borderRadius: '8px',
        padding: '8px, 16px, 8px, 16px',
        backgroundColor: '#03BBD2',
        '&:hover': {
          backgroundColor: '#03BBD2',
        },
      }}
    >
      <Typography sx={{ fontSize: '14px', lineHeight: '24px', textTransform:'none', fontWeight:'600' }}>{children}</Typography>
    </Button>
  );
};

export const AddMoreButtonofUserEnquiry: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      size="small"
      startIcon={<AddIcon />}
      sx={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.025em',
        textAlign: 'center',
        color: 'inherit',
        marginRight: '18rem',
        marginTop:'1rem',
        border: 'none',
        textTransform: 'none',
        '&:hover': {
          border: 'none',
          backgroundColor: 'none',
        },
        }}
    >
      {children}
    </Button>
  );
};

export const SubmitEnquiry: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      variant="contained"
      size="small"
      sx={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '2.5%',
        textAlign: 'center',
        color: '#FFFFFF',
        minWidth: '120px',
        minHeight: '40px',
        padding: '8px, 24px, 8px, 24px',
        borderRadius: '8px',
        backgroundColor: '#03BBD2',
        textTransform: 'none',
      }}
    >
      {children}
    </Button>
  );
};

export const NextButtonEnquiry: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      variant="contained"
      size="small"
      sx={{
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '2.5%',
        textAlign: 'center',
        color: '#FFFFFF',
        minWidth: '120px',
        minHeight: '40px',
        padding: '8px, 24px, 8px, 24px', 
        borderRadius: '8px',
        backgroundColor: '#03BBD2',
        textTransform: 'none',
        marginRight: '1rem',
      }}
    >
      {children}
    </Button>
  );
};

export const PreviousButtonEnquiry: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      size="small"
      sx={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '2.5%',
        textAlign: 'center',
        color: '#10383D',
        border: 'none',
        textTransform: 'none',
        marginRight:'20px',
        '&:hover': {
          border: 'none',
          backgroundColor: 'none',
        },
      }}
    >
      {children}
    </Button>
  );
};





