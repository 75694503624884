import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChecklistItems,
} from '../redux/checklist/checklistSlice'; 
import Checkbox from '@mui/material/Checkbox';
import { Button, CircularProgress, Typography, TextField } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { STATUS } from '../../utils/status';
import AddIcon from '@mui/icons-material/Add'
import { FormState, updateFormState, updateSelectedChecklistIds } from '../redux/reducers/checklistReducer';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ChecklistEnquiryForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string>('item_name');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const selectedChecklistIds = useSelector((state: RootState) => state.checklistForm.selectedChecklistIds);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, expenseId: string) => {
    const isChecked = event.target.checked;
    dispatch(updateFormState(expenseId)); 
    if (isChecked) {
      dispatch(updateSelectedChecklistIds([...selectedChecklistIds, expenseId])); 
    } else {
      dispatch(updateSelectedChecklistIds(selectedChecklistIds.filter((id:any) => id !== expenseId))); 
    }
  };

  const checklistItems = useSelector((state: RootState) => state.checklists.checklists);
  const getChecklistStatus = useSelector((state: RootState) => state.checklists.getStatus);

  const [newChecklistName, setNewChecklistName] = useState('');
  const [additionalChecklistNames, setAdditionalChecklistNames] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getChecklistItems({ page: currentPage, sort: sortColumn, order: sortOrder }));
  }, [dispatch, currentPage, sortColumn, sortOrder]);

  const handleAddChecklist = () => {
    if (newChecklistName.trim() !== '') {
      setAdditionalChecklistNames([...additionalChecklistNames, newChecklistName]);
      setNewChecklistName('');
    }
  };

  return (
    <>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' , marginTop: '2rem'}}>Checklist</Typography>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#13282A' , marginTop: '1rem', marginBottom:'0.5rem'}}>Select Checklist</Typography>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {getChecklistStatus === STATUS.SUCESS &&
          checklistItems.results.slice(0, 3).map((row: any, index: number) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <Checkbox
                        {...label}
                        onChange={(e) => handleCheckboxChange(e, row.id)}
                        checked={selectedChecklistIds.includes(row.id)}
                        sx={{
                            '&.Mui-checked': {
                            color: '#03BBD2',
                            },
                        }}
                        />
              <Typography variant="body1">{row.item_name}</Typography>
            </div>
          ))}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {additionalChecklistNames.map((checklistName, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginRight: '10px' }}>
            <Checkbox
                        {...label}
                        sx={{
                            '&.Mui-checked': {
                            color: '#03BBD2',
                            },
                        }}
                        />
            <Typography variant="body1">{checklistName}</Typography>
          </div>
        ))}

        <TextField
          value={newChecklistName}
          onChange={(e) => setNewChecklistName(e.target.value)}
          label="New Checklist Name"
          variant="outlined"
          size="small"
          sx={{ marginBottom: '10px' }}
        />
        <Button
          onClick={handleAddChecklist}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
            letterSpacing: '2.5%',
            textAlign: 'center',
            color: '#13282A',
            marginTop:'10px',
            border: 'none',
            width:'9rem',
            textTransform: 'none',
            '&:hover': {
              border: 'none',
              backgroundColor: 'none',
            },
          }}
        >
          Add Checklist
        </Button>
      </div>
    </>
  );
};

export default ChecklistEnquiryForm;
