import React, { useState, ChangeEvent, FormEvent, useEffect, InputHTMLAttributes, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Box, MenuItem, Select, SelectChangeEvent, FormGroup, FormControlLabel, Checkbox, IconButton, Snackbar, SnackbarContent, FormControl, FormHelperText } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getTruckTypes } from '../redux/truck type slice/truckTypeSlice';
import { createTruck, updateTruck } from '../redux/truck slice/addTruckSlice';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { createTdsType, setCreateTdsStatus, setUpdateTdsStatus, updateTdsType } from '../redux/tds/tdsSlice';
import { fetchCompanies } from '../redux/company and model slice/companySlice';
import SuccessIcon from '../../assets/img/success.png'
import ClearIcon from '@mui/icons-material/Clear';
import FailIcon from '../../assets/img/error.png'
import { STATUS } from '../../utils/status';

interface FormState {
  id:string,
  company_id : string,
  no_of_truck : string,
  tds : string,
  deduct_from_advance : boolean,
  documents_links : string[],
  owner: string,
  transporter:string
}

const TdsForm = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const companies = useSelector((state: RootState) => state.companies.companies);


  const { tdsDetails: initialGoodsDetails, id: goodsId } = location.state || { tdsDetails: null, id: '' };

  const [tdsDetails, setTdsDetails] = useState<FormState>({
    id:'',
    company_id : '',
    no_of_truck : '',
    tds : '',
    deduct_from_advance : false,
    documents_links : [''],
    owner: '',
    transporter:'',
  });

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<File[]>([]); 
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);
  const [transporterNames, setTransporterNames] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');

  const createTdsStatus = useSelector((state:RootState)=> state.tds.createTdsStatus)
  const updateTdsStatus = useSelector((state:RootState)=> state.tds.updateTdsStatus)

  useEffect(() => {
    dispatch(fetchCompanies({}))
      .unwrap()
      .then((data: any) => {
        const transporterCompanies = data.results.filter((company: any) => company.role === "TRANSPORTER");
        console.log({transporterCompanies})
        const names = transporterCompanies.map((company: any) => company.name);
        const id = transporterCompanies.map((company: any) => company.id);
        setTransporterNames(names);
      })
      .catch((error: any) => {
        console.error('Error fetching companies:', error);
      });
  }, [dispatch]);



  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    setTdsDetails((prevDetails) => ({
      ...prevDetails,
      [name]: fieldValue,
    }));
  };


  const handleSelectChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setTdsDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    const selectedTransporter = companies.results.find((company: any) => company.name === value);
    console.log({selectedTransporter})
    if (selectedTransporter) {
  
      const ownerDetails = selectedTransporter.owners[0]?.user_details;
      const ownerName = ownerDetails ? `${ownerDetails.first_name} ${ownerDetails.last_name}` : '';
  
      setTdsDetails((prevDetails) => ({
        ...prevDetails,
        [fieldName]: value,
        owner: ownerName,
        company_id: selectedTransporter.id,
      }));
    }
  };
  
  
  

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...files]);
      const urls = files.map((file) => URL.createObjectURL(file));
      setPhotoUrls((prevUrls) => [...prevUrls, ...urls]);
    }
  };

  const deleteImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newUrls = [...photoUrls];
    newUrls.splice(index, 1);
    setPhotoUrls(newUrls);
  };

  useEffect(() => {
    if (initialGoodsDetails) {
      setTdsDetails(initialGoodsDetails);
      handleSnackbar('You can make your changes now.', 'info');
    }
  }, [initialGoodsDetails]);

  const navigate = useNavigate();

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);
    const errors: { [key: string]: string } = {};
    if (!tdsDetails?.transporter?.trim()) {
      errors.transporter = 'Field is required';
    }
    if (isNaN(parseFloat(tdsDetails?.tds))) {
      errors.tds = 'TDS% is Required';
    }
    if (isNaN(parseFloat(tdsDetails?.no_of_truck))) {
      errors.no_of_truck = 'No. of Trucks is required';
    }
  
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields.', 'warning');
      return;
    }
      

      const selectedTransporter = transporterNames.find(name => name === tdsDetails.transporter);
      if (selectedTransporter) {
        const transporterCompany = companies.results.find((company: any) => company.name === selectedTransporter);
        if (transporterCompany) {
          const payload = {
            company_id: tdsDetails.company_id,
            no_of_truck: tdsDetails.no_of_truck,
            tds: tdsDetails.tds,
            deduct_from_advance: tdsDetails.deduct_from_advance,
            documents_links: [
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
            ],
          };
  
          if (goodsId) {
            dispatch(updateTdsType(payload));
          } else {
            dispatch(createTdsType(payload));
          }    
        } 
      } 
    }
  

  useEffect(()=>{
    if(updateTdsStatus == STATUS.SUCESS){
      handleSnackbar('TDS Updated Successfully', 'success');
      setTimeout(() => {
          navigate('/tds slab');
        }, 1000);
        dispatch(setUpdateTdsStatus(STATUS.NOT_STARTED))
      }

      if(updateTdsStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
      }
  },[updateTdsStatus])

  useEffect(()=>{
    if(createTdsStatus == STATUS.SUCESS){
      handleSnackbar('TDS Added Successfully', 'success');
        setTimeout(() => {
          navigate('/tds slab');
        }, 1000);
        dispatch(setCreateTdsStatus(STATUS.NOT_STARTED))
      }

      if(createTdsStatus == STATUS.FAILED){
        handleSnackbar('Error Occured, Please try again!', 'error');
        setTimeout(() => {
          handleSnackbar('Either the tds already exist or you entered wrong information', 'warning');
        }, 7000);
      }
  },[createTdsStatus])
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem' }}>TDS Details</Typography>
      <div>
        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight:'20px',  width:'51%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Transporter Name</Typography>
            <FormControl  error={formSubmitted && formErrors.transporter ? true : false}>
            <Select
              fullWidth
              size='small'
              value={tdsDetails.transporter} 
              onChange={(e: SelectChangeEvent<string>) => handleSelectFieldChange(e, 'transporter')}
              displayEmpty
            >
              <MenuItem value="">
                Select transporter
              </MenuItem>
              
              {transporterNames.map((name, index) => (
                <MenuItem key={index} value={name}>{name}</MenuItem>
              ))}
            </Select>
            {formSubmitted && formErrors.transporter && (
                <FormHelperText>{formErrors.transporter}</FormHelperText>
              )}
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' , width:'51%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Transporter Owner Name</Typography>
            <TextField
              placeholder="Owner Name"
              name="owner"
              value={tdsDetails.owner}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              disabled
            />
          </div>
        </div>

        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column' , marginRight:'20px', width:'51%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>No. of Trucks</Typography>
            <TextField
              placeholder="Enter Value"
              name="no_of_truck"
              value={tdsDetails.no_of_truck}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.no_of_truck ? true : false}
              helperText={formSubmitted && formErrors.no_of_truck}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' , width:'51%'}}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>TDS %</Typography>
            <TextField
              placeholder="Enter Percentage"
              name="tds"
              value={tdsDetails.tds}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              error={formSubmitted && formErrors.tds ? true : false}
              helperText={formSubmitted && formErrors.tds}
            />
          </div>
        </div>

        <div style={{ display: 'flex' , }}>
        
        <FormGroup sx={{ width:'52%' }}>
            <FormControlLabel
              control={<Checkbox checked={tdsDetails.deduct_from_advance} onChange={handleFieldChange} name="deduct_from_advance" />}
              label="TDS Deduct From Advance"
            />
          </FormGroup>
          
        <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>
        Declaration Certificate
      </Typography>
      <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            multiple 
          />
      <Button
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '0.025em',
          textAlign: 'center',
          color: '#03BBD2',
          border: 'none',
          textTransform: 'none',
          mt: '10px',
          ml:'none',
          mr:'85%',
          '&:hover': {
            border: 'none',
            backgroundColor: 'none',
          },
        }}
        onClick={() => fileInputRef.current?.click()}
        variant="outlined"
        type="button"
      >
        Upload<FileUploadIcon fontSize="small" />
      </Button>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop:'20px' }}>
        {photoUrls.map((url, index) => (
          <div key={index} style={{ marginRight: '10px', marginBottom: '10px', position:'relative' }}>
            <div style={{display:'flex', alignItems:'center', marginRight:'20px'}}>
            <img src={url} alt="Uploaded File" style={{ width: '50px', height: '50px' }} />
            <div style={{ position: 'absolute', top: '-10px', left: '35px', backgroundColor: 'black', borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
              <CloseIcon sx={{ color: 'white' }} fontSize='small' onClick={() => deleteImage(index)} />
            </div>
            </div>
          </div>
        ))}
      </div>

    </div>

        </div>


        <Button type="submit" variant="contained" sx={{ mt: 2, fontSize: '14px', textTransform: 'none', color: '#FFFFFF', width: '100%', backgroundColor: '#03BBD2', borderRadius: '8px' }}>{goodsId ? 'Update Tds' : 'Save Tds'}</Button>
      </div>

      <Snackbar
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{ borderRadius:'8px' }} 
      >
          <SnackbarContent
          sx={{
            backgroundColor: snackbarSeverity === 'success' ? '#008F46' : snackbarSeverity === 'warning' ? '#FFC107' : snackbarSeverity === 'info' ? '#1976D2' : '#C00F00',
            color: '#FFFFFF',
          }}
          message={
            <>
            <div style={{ display: 'flex' }}>
            {snackbarSeverity === 'success' ? (
              <img src={SuccessIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'warning' ? (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            ) : snackbarSeverity === 'info' ? (
              <img style={{ marginRight: '12px' }} />
            ) : (
              <img src={FailIcon} style={{ marginRight: '12px' }} />
            )}

            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
            </>
          }
         
          action={
            <React.Fragment>
                <ClearIcon sx={{fontSize:'large', color:'#FFFFFF'}} onClick={handleSnackbarClose}/>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Box>
  );
};

export default TdsForm;
