import { Box, OutlinedInput, Paper } from '@mui/material'
import React from 'react'
import BidingHeading from './bidingHeading'
import { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography,  MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AppDispatch, RootState } from '../redux/store/appStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { getTruck } from '../redux/truck slice/addTruckSlice';
import BidingTable from './bidingTable';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
interface FormState {
    source:string;
    destination:string;
    pickup:string;
    drop:string;
    freight_rate:string;
    admin:string;
    no_of_trucks:string;
    weight:string;
    payment_mode:string;
    unit:string;
    truck_type:string;
    truck_unit:string;
    truck_weight:string;
  }

const BidingMain = () => {
    const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const location = useLocation();

  const { enquiryDetails: initialEnquiryDetails, id: goodsId } = location.state || { enquiryDetails: null, id: '' };


  const handleSort = (column: string) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setLoading(true)
    dispatch(getTruck({search: searchTerm,page:currentPage, sort: sortColumn, order: sortOrder, pageSize: itemsPerPage }))
    .then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch(() => setLoading(false));
  }, [dispatch,searchTerm,currentPage, sortColumn, sortOrder, itemsPerPage]);
  
  const [companyDetails, setCompanyDetails] = useState<FormState>({
    source:'',
    destination:'',
    pickup:'',
    drop:'',
    freight_rate:'',
    admin:'',
    no_of_trucks:'',
    weight:'',
    payment_mode:'',
    unit:'',
    truck_type:'',
    truck_unit:'',
    truck_weight:'',
  });


  const [errors, setErrors] = useState<Partial<FormState>>({});

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };


  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };
  // useEffect(()=>{
  //   if (initialEnquiryDetails) {
  //     setCompanyDetails(initialEnquiryDetails);
  //   }
  // },[initialEnquiryDetails])


  useEffect(() => {
    if (initialEnquiryDetails) {
      setCompanyDetails(initialEnquiryDetails);
      const { truck_type_details, truck_tyre_weight_details } = initialEnquiryDetails;
      if (truck_type_details && truck_type_details.name) {
        setCompanyDetails((prevDetails) => ({
          ...prevDetails,
          truck_type: truck_type_details.name,
        }));
      }
      if (truck_tyre_weight_details && truck_tyre_weight_details.weight && truck_tyre_weight_details.unit) {
        setCompanyDetails((prevDetails) => ({
          ...prevDetails,
          truck_weight: `${truck_tyre_weight_details.weight}`,
          truck_unit: `${truck_tyre_weight_details.unit}`,
        }));
      }
    }
  }, [initialEnquiryDetails]);
  
  

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <BidingHeading />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
         
          <div style={{ display: 'flex' , justifyContent:'space-between',flexWrap: 'wrap'}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Source</Typography>
            <Select
              value={companyDetails.source}
              onChange={(e) => handleSelectFieldChange(e,'source')}
              fullWidth
              size='small'
              >
              <MenuItem value="">
                  Select source
              </MenuItem>
              <MenuItem value="JAIPUR">Jaipur</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Destination</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
              value={companyDetails.destination}
              onChange={(e) => handleSelectFieldChange(e,'destination')}
              fullWidth
              size='small'
              >
              <MenuItem value="">
                  Select Destination
              </MenuItem>
              <MenuItem value="BHARATPUR">Bharatpur</MenuItem>
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Rate</Typography>
            <TextField
              placeholder="Enter rate"
              name="freight_rate"
              value={companyDetails.freight_rate}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              
            />
            
          </div>
        </div>

        <div style={{ display: 'flex' , justifyContent:'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Pickup Location</Typography>
            <TextField
              placeholder="Enter pickup"
              name="pickup"
              value={companyDetails.pickup}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Drop Location</Typography>
            <TextField
              placeholder="Enter drop location"
              name="drop"
              value={companyDetails.drop}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Admin Rate</Typography>
            <TextField
              placeholder="Enter rate"
              name="payment_mode"
              value={companyDetails.payment_mode}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              
            />
            
          </div>
          </div>

          <div style={{ display: 'flex' , justifyContent:'flex-start'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%', marginRight:'20px' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>No. of Trucks</Typography>
            <TextField
              placeholder="Enter no. of trucks"
              name="no_of_trucks"
              value={companyDetails.no_of_trucks}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}  
            />   
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Weight to be lifted</Typography>
            <TextField
              placeholder="Enter weight"
              name="weight"
              value={companyDetails.weight}
              onChange={handleFieldChange}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>

          </div>
          <div style={{ display: 'flex' , justifyContent:'space-between', marginTop:'10px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Unit</Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px',color: '#10383D' }}>
                {companyDetails.truck_unit} 
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Truck Type</Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px',color: '#10383D' }}>
                {companyDetails.truck_type} 
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Weight</Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', color: '#10383D'}}>
                {companyDetails.truck_weight} 
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>High</Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px',color: '#10383D'}}>
                {companyDetails.no_of_trucks} 
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Average</Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', color: '#10383D' }}>
                {companyDetails.no_of_trucks} 
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Minimum</Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400, lineHeight: '24px', color: '#10383D' }}>
                {companyDetails.no_of_trucks} 
            </Typography>
          </div>
          </div>
            <div>  
            </div>
        </Paper>

        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
        <BidingTable/>
        </Paper>
      </Box>
    </div>
  )
}

export default BidingMain