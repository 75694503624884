import React, { ReactNode } from 'react';
import MaterialUITypography, { TypographyProps } from '@mui/material/Typography';

interface CustomTypographyProps extends TypographyProps {
  children: ReactNode;
}

export const TypographyWithoutMargin: React.FC<CustomTypographyProps> = ({ children, ...props }) => {
  return <MaterialUITypography sx={{color: '#10383D', fontWeight: 600, fontSize: '16px', lineHeight: '24px' }} {...props}>{children}</MaterialUITypography>;
};

export const TypographyWithMargin: React.FC<CustomTypographyProps> = ({ children, ...props }) => {
  return <MaterialUITypography sx={{color: '#10383D', fontWeight: 600, fontSize: '16px', lineHeight: '24px', marginTop:'20px' }} {...props}>{children}</MaterialUITypography>;
};

export const TextfieldName: React.FC<CustomTypographyProps> = ({ children, ...props }) => {
  return <MaterialUITypography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }} {...props}>{children}</MaterialUITypography>;
};

export const TextfieldNameWithMargin: React.FC<CustomTypographyProps> = ({ children, ...props }) => {
  return <MaterialUITypography sx={{ fontFamily: 'Montserrat', fontSize: '14px', marginTop:'10px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }} {...props}>{children}</MaterialUITypography>;
};

export const FormHeaderTypography: React.FC<CustomTypographyProps> = ({ children, ...props }) => {
  return <MaterialUITypography sx={{ display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px' }} {...props}>{children}</MaterialUITypography>;
};

export const FormHeaderTypography2: React.FC<CustomTypographyProps> = ({ children, ...props }) => {
  return <MaterialUITypography sx={{ fontWeight: 500, fontSize: '24px', lineHeight: '36px' }} {...props}>{children}</MaterialUITypography>;
};

export const AdditionalFieldsTypography: React.FC<CustomTypographyProps> = ({ children, ...props }) => {
  return <MaterialUITypography sx={{ backgroundColor: '#F7F9FA', fontFamily: 'Montserrat', fontSize: '10px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px',textAlign: 'left', color: '#10383D', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px'}} {...props}>{children}</MaterialUITypography>;
};