import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import { useDispatch} from 'react-redux';
import { Typography, Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AppDispatch } from '../redux/store/appStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { createTruck, updateTruck } from '../redux/truck slice/addTruckSlice';

interface FormState {
  truck_type: string;
  tyre_count: string;
  weight: string;
  rate: string;
  unit: string;
  payment: string;
}

const ContractTruckForm = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { companyDetails: initialGoodsDetails, id: goodsId } = location.state || { companyDetails: null, id: '' };

  const [companyDetails, setCompanyDetails] = useState<FormState>({
  truck_type: '',
  tyre_count: '',
  weight: '',
  rate: '',
  unit: '',
  payment:'',
  });


  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, fieldName: keyof FormState) => {
    const value = event.target.value;
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (initialGoodsDetails) {
      setCompanyDetails(initialGoodsDetails);
    }
  }, [initialGoodsDetails]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    const errors: { [key: string]: string } = {};
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const payload = {
        
      };

      if (goodsId) {
        dispatch(updateTruck(payload));
      } else {
        dispatch(createTruck(payload));
      }

      setSuccessMessage('Truck Type saved successfully!');
      setErrorMessage(null);
     
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem', marginTop: '2rem' }}>Truck Details</Typography>
      <div>
        <div style={{ display: 'flex' , justifyContent:'space-between',flexWrap: 'wrap'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Truck Type</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.truck_type}
                onChange={(e:any) => handleSelectFieldChange(e,'truck_type')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select truck type
                </MenuItem>
                <MenuItem value="Open Body">
                    Open Body
                </MenuItem>
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>


          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Tyre Count</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.tyre_count}
                onChange={(e:any) => handleSelectFieldChange(e,'tyre_count')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select tyre count
                </MenuItem>
                <MenuItem value="6">
                    6
                </MenuItem>
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column',width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Weight</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.weight}
                onChange={(e:any) => handleSelectFieldChange(e,'weight')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select weight
                </MenuItem>
                <MenuItem value="40">
                    40
                </MenuItem>
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>

        </div>

        <div style={{ display: 'flex' , justifyContent:'flex-start'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Rate</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.rate}
                onChange={(e:any) => handleSelectFieldChange(e,'rate')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select rate
                </MenuItem>
                <MenuItem value="500">
                    500
                </MenuItem>
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Unit</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.unit}
                onChange={(e:any) => handleSelectFieldChange(e,'unit')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select Unit
                </MenuItem>
                <MenuItem value="KG">
                    Kg
                </MenuItem>
                
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>    
          </div>

          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginBottom: '1rem', marginTop: '2rem' }}>Payment Details</Typography>
      <div>
        <div style={{ display: 'flex' , justifyContent:'space-between',flexWrap: 'wrap'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '1rem' }}>Payment Mode</Typography>
            {/* <FormControl error={formSubmitted && !!formErrors.tyre_count}> */}
            <Select
                value={companyDetails.payment}
                onChange={(e:any) => handleSelectFieldChange(e,'payment')}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
            >
                <MenuItem value="" disabled>
                    Select payment mode
                </MenuItem>
                <MenuItem value="Advanced">
                    Advanced
                </MenuItem>
                
            </Select>
              {/* {formSubmitted && formErrors.tyre_count && (
                <FormHelperText>{formErrors.tyre_count}</FormHelperText>
              )}
            </FormControl> */}
          </div>
        </div>
        </div>
      </div>
    </Box>
  );
};

export default ContractTruckForm;