import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';

interface GoodsType {
    id: string;
    short_allowance: number;
  }
  
  interface Commission {
    role: string;
    commission_unit: string;
    value: string;
  }
  
  interface Enquiry {
    id: string;
    receiver: string;
    pickup_date: string;
    pickup_address: string | null;
    pickup_pincode: string | null;
    drop_address: string | null;
    drop_pincode: string | null;
    routes: string[];
    goods_types: GoodsType[];
    truck_type_id: string;
    truck_tyre_id: string;
    truck_tyre_weight_id: string;
    no_of_trucks: number;
    freight_rate: number;
    total_freight: number;
    single_freight: number | null;
    payment_mode: string;
    advance_percentage: number;
    amount_paid: number;
    balance: number;
    enquiry_type: string;
    bidding_types: string;
    weight: number | null;
    status: string;
    load_unit: string | null;
    checklist: string[];
    expenses: string[];
    freight_unit: string | null;
    commissions: Commission[];
    expense_amount_total: number;
  }
  

export interface EnquiryState {
  enquiries: {
    count: number | undefined;
    results: Enquiry[];
  };
  error: string | null;
  getEnquiryStatus: STATUS;
  createEnquiryStatus: STATUS.NOT_STARTED,
}

const initialState: EnquiryState = {
  enquiries: { 
    count: undefined,
    results: [] },
  error: null,
  
  getEnquiryStatus: STATUS.NOT_STARTED,
  createEnquiryStatus: STATUS.NOT_STARTED,
};

export const createEnquiries = createAsyncThunk(
    'enquiries/post',
    async (payload: any, thunkAPI) => {
      const response = await api.post('enquiry/', payload);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } 
      else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

export const getEnquiries = createAsyncThunk(
  'enquiries/get',
  async (_, thunkAPI) => {
    const response = await api.get('enquiry/');
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const enquirySlice = createSlice({
  name: 'enquiries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createEnquiries.fulfilled, (state, action) => {
        state.enquiries.results.push(action.payload);
      })
      .addCase(getEnquiries.pending, (state, action) => {
        state.getEnquiryStatus = STATUS.PENDING;
        console.log('GET ENQUIRY TYPES PENDING');
      })
      .addCase(getEnquiries.fulfilled, (state, action) => {
        state.enquiries = action.payload;
        state.getEnquiryStatus = STATUS.SUCESS;
        console.log('GET ENQUIRY TYPES SUCCESS');
      })
      .addCase(getEnquiries.rejected, (state, action) => {
        state.getEnquiryStatus = STATUS.FAILED;
        console.log('GET ENQUIRY TYPES FAILED');
      })
  },
});

export default enquirySlice.reducer;
