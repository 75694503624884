// redux.js

export const UPDATE_USER_COUNT = 'UPDATE_USER_COUNT';

export const updateUserCount = (userCount: string) => {
  return {
    type: UPDATE_USER_COUNT,
    payload: userCount
  };
};

const initialState = {
  userCount: ''
};

const reducer = (state = initialState, action: { type: string; payload: any; }) => {
  switch (action.type) {
    case UPDATE_USER_COUNT:
      return {
        ...state,
        userCount: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
