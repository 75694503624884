import axios from 'axios';
import { BASE_URL } from './constants';

export const getAuthTokenFromLocalStorage = () => {
    return localStorage.getItem('authToken') || null;
};

// Create an instance of axios with base URL
const api = axios.create({
    baseURL: BASE_URL,
    timeout: 1000,
});

// Add an interceptor to include the Authorization header with the token
api.interceptors.request.use(
    (config) => {
        const authToken = getAuthTokenFromLocalStorage();
        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'Bearer ' + getAuthTokenFromLocalStorage(),
};

export const createRoute = async (requestData: any): Promise<any> => {
    try {
        const response = await api.post(
            '/route/',
            requestData,
            { headers: headers }
        );
        return response.data;
    } catch (error) {
        handleAxiosError(error);
    }
};

export const handleAxiosError = (error: any) => {
    if (axios.isAxiosError(error)) {
        console.error('Axios Error:', error);
        if (error.response) {
            console.error('Response Data:', error.response.data);
            throw new Error(error.response.data.message || 'Failed to process the request');
        } else if (error.request) {
            console.error('No response received from server');
            throw new Error('No response received from server');
        } else {
            console.error('Error setting up the request');
            throw new Error('Error setting up the request');
        }
    } else {
        console.error('Unexpected Error:', error);
        throw new Error('An unexpected error occurred');
    }
};

export default api;
